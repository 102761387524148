// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2'

// @Services
import { CategoryService } from 'src/app/services/category.service';
import { SubCategoryService } from 'src/app/services/subcategory.service';
import { CommonService } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';

// @Models
import { CategoryVM } from 'src/app/models/CategoryVM';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-subcategory-list',
  templateUrl: './subcategory-list.component.html',
  styleUrls: ['./subcategory-list.component.css']
})
export class SubcategoryListComponent implements OnInit {

  SubCategoryForm: FormGroup;
  getSiteConfig: any;
  pageSize: number = 50;
  subcategoriesData: any;
  distributor: any;
  role: any;
  permissionsData = [];
  noData = false;
  displayedColumns: string[];
  loginUserId: any;
  marked = false;
  marked1 = false;
  marked2 = false;
  pageIndex: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columnSorting: any;
  columnDirection: any;
  totalLength: any;
  value: '';
  filterValue: '';
  constructor(private _subcategory: SubCategoryService,
    private _categoryService: CategoryService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _storageService: StorageService,
    private _commonService: CommonService,
    private _router: Router,
    private _roleService: RoleService,
    private _siteConfigService: SiteConfigService) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.getUserActive();

    this._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        this.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.loadSubCategories(event);
      }
    });

    this.distributor = this._storageService.getDistributorId();

    this.displayedColumns = ['subacategory_name', 'subcategory_code', 'subcategory_display_name', 'parent_category_display_name', 'subcategory_sequence_no', 'subcategory_category_thumbnail_image', 'item_count', 'subcategory_is_active', 'subcategory_date', 'actions'];

  }
  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      self.marked = self.permissionsData[4].sub_permission[1].isSelected;
      self.marked1 = self.permissionsData[4].sub_permission[2].isSelected;
      self.marked2 = self.permissionsData[4].sub_permission[3].isSelected;
      if (self.permissionsData[4].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadSubCategories(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._subcategory.getAllSubCategories(event, this.value, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.subcategoriesData = new MatTableDataSource<CategoryVM>(response.data);
            
            if (response.data != null && response.data.length > 0) {
              self.totalLength = response.data[0].totalcount;
            }
            self.noData = (response.data != null && response.data.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  editSubCategory(subcategory: CategoryVM) {
    this._router.navigate(['/admin/sub-categories/edit', subcategory.subcategory_id]);
  }


  deleteSubCategory(category: CategoryVM) {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
        else {
          const options: SweetAlertOptions = {
            title: 'Are you sure you want to delete this sub category and associated items?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            focusCancel: true
          };
          swal.fire(options).then((result) => {
            if (result.value) {
              self._commonService.showLoader();
              self._categoryService.deleteCategory(category.subcategory_id).subscribe(data => {
                self._commonService.hideLoader();
                self._commonService.showToaster("Sub Category deleted successfully.", "success");
                var event: any = {
                  "previousPageIndex": '0',
                  "pageIndex": '0',
                  "pageSize": this.pageSize,
                  "length": '0',
                }
                self.loadSubCategories(event);
              }, error => {
                self._commonService.hideLoader();
                self._commonService.showToaster(error.message, "error");
              });
            }
          })
        }
      });
  }


  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }
  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadSubCategories(event);
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;

    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadSubCategories(event);
  }
}
