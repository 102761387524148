// @Packages
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

// @Services
import { ToastrService } from 'ngx-toastr';
import { REGEXP } from '../shared/regexp';
import { AppConfig } from '../shared/app-config';
import { StorageService } from './storage.service';
import { RoleService } from './role.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public IMAGE_FILE_FORMAT: string = "jpeg|jpg|png|gif|bmp";
  public PNG_IMAGE_FILE_FORMAT: string = "png";
  public SVG_PNG_IMAGE_FILE_FORMAT: string = "png|svg";

  role: any;

  constructor(private _toastr: ToastrService,
    private _router: Router,
    private _regexp: REGEXP,
    private _api: AppConfig,
    private _roleService: RoleService,
    private _storageService: StorageService) { }

  isNullOrEmpty(item) {
    if (item == null || item == '' || item == undefined) {
      return true;
    } else {
      return false;
    }
  }

  isEmptyObject(obj) {
    if (obj) {
      if (Object.keys(obj).length === 0) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  isEmptyArray(array) {
    if (!array || array == null || array == undefined || array.length == 0) {
      return true;
    }
    else {
      return false;
    }
  }

  isNumeric(item) {
    if (!this.isNullOrEmpty(item)) {
      return !isNaN(item);
    }
    return false;
  }

  isValidNumber(item) {
    if (!this.isNullOrEmpty(item)) {
      var reg = RegExp(this._regexp.NUMBER_REGEXP);
      return reg.test(item);
    }
    return false;
  }

  convertToNumber(value): number {
    if (!this.isNullOrEmpty(value)) {
      return Number(value);
    } else {
      return Number(0);
    }
  }


  parseString(value): string {
    if (!this.isNullOrEmpty(value) && value !== 'null') {
      return value.toString();
    } else {
      return "";
    }
  }

  parseBoolean(value): boolean {
    if (!this.isNullOrEmpty(value) && (value == 1 || value == "1" || value == "true")) {
      return true;
    } else {
      return false
    }
  }

  parseBooleanToString(value): string {
    if (!this.isNullOrEmpty(value) && (value == 1 || value == true)) {
      return "true";
    } else {
      return "false";
    }
  }

  startsWith(text, prefixText): boolean {
    if (!this.isNullOrEmpty(text)) {
      if (text.startsWith(prefixText)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  endsWith(text, postfixText): boolean {
    if (!this.isNullOrEmpty(text)) {
      if (text.endsWith(postfixText)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onlyNumeric(event) {
    //46    -   Period(.)
    if ((event.which != 46 || event.key.indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {
      //event.preventDefault();
      return true;
    }
  }

  numberOnly(event): boolean {
    //46    -   Period(.)
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 46) {
      return false;
    }
    return true;

  }

  isNumericKey(event) {

    let charCode = (event.which) ? event.which : event.keyCode
    let value = event.key;
    let dotcontains = value.indexOf(".") != -1;
    let minuscontains = value.indexOf("-") != -1;
    if (dotcontains) {
      if (charCode == 46) return true;
      else return false;
    }

    if (minuscontains) {
      if (charCode == 45) return true;
      else return false;
    }

    if (charCode == 46) return true;
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  formatNumberWithDecimal(number, decimalPlaces) {
    if (number != null && number != '' && number != undefined) {
      return Number(number).toFixed(decimalPlaces);
    }
    return "0.00";
  }

  lower(item) {
    if (!this.isNullOrEmpty(item)) {
      return item.toLowerCase().trim();
    }
    return "";
  }

  upper(item) {
    if (!this.isNullOrEmpty(item)) {
      return item.toUpperCase().trim();
    }
    return "";
  }

  trim(item) {
    if (!this.isNullOrEmpty(item)) {
      return item.trim();
    }
    return "";
  }

  showLoader() {
    $('#divLoader').show();
  }

  hideLoader() {
    $('#divLoader').hide();
  }

  scrollToTop() {
    window.scroll(0, 0);
  }

  showToaster(message: string, toastrType: string) {
    switch (toastrType) {
      case "success":
        setTimeout(() => this._toastr.success(message, "Success!"));
        break;
      case "error":
        setTimeout(() => this._toastr.error(message, "Error!"));
        break;
      case "warning":
        setTimeout(() => this._toastr.warning(message, "Warning!"));
        break;
      case "info":
        setTimeout(() => this._toastr.info(message, "Info!"));
        break;
    }
  }

  showToasterTopCenter(message: string, toastrType: string) {
    switch (toastrType) {
      case "success":
        setTimeout(() => this._toastr.success(message, "Success!", {
          positionClass: "toast-top-center"
        }));
        break;
      case "error":
        setTimeout(() => this._toastr.error(message, "Error!", {
          positionClass: "toast-top-center"
        }));
        break;
      case "warning":
        setTimeout(() => this._toastr.warning(message, "Warning!", {
          positionClass: "toast-top-center"
        }));
        break;
      case "info":
        setTimeout(() => this._toastr.info(message, "Info!", {
          positionClass: "toast-top-center"
        }));
        break;
    }
  }



  /*** Date Utility Functions START ****/

  parseDate(date: string) {
    let dateFormatString = this._storageService.getsiteConfigDateFormatTerm();
    return moment(date).format(dateFormatString);
  }

  parseDateTime(date: string) {
    let dateFormatString = this._storageService.getsiteConfigDateFormatTerm();
    return moment(date).format(dateFormatString);
  }

  /*** Date Utility Functions END ****/

  redirectToUnauthorizePage() {
    this._router.navigate(['/unauthorize']);
  }

  removeArrayItemByValue(arr, item) {
    if (arr != null && arr.length > 0) {
      const index: number = arr.indexOf(item);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    return arr;
  }

  removeArrayItemByIndex(arr, index) {
    if (arr != null && arr.length > 0) {
      if (index !== -1) {
        arr.splice(index, 1);
      }
    }
    return arr;
  }

  getFullImageUrl(image) {
    if (!this.isNullOrEmpty(image)) {
      let imageUrl = this._api.ImageUrl + image;
      return imageUrl;
    } else {
      return image;
    }
  }

  dropdownArray(array: any, idColumnName, valueColumnName) {
    //let resultArray = [{id:0,text:'All'}];
    let resultArray = [];
    if (array != null && array != undefined && array.length > 0) {
      array.forEach((item, index) => {
        let id = item[idColumnName];
        let name = item[valueColumnName];

        let result = {
          id: id,
          text: name
        }
        resultArray.push(result);
      })
    }
    return resultArray;
  }
  dropdownArrayDistributor(array: any, idColumnName, valueColumnName) {
    let resultArray = [];
    if (array != null && array != undefined && array.length > 0) {
      //resultArray.push({id:0,text:'All'});
      array.forEach((item, index) => {
        let id = item[idColumnName];
        let name = item[valueColumnName];

        let result = {
          id: id,
          text: name
        }
        resultArray.push(result);
      })
    }
    return resultArray;
  }

  dropdownArrayDistributorGroup(array: any, idColumnName, valueColumnName) {
    let resultArray = [];
    if (array != null && array != undefined && array.length > 0) {
      //resultArray.push({id:0,text:'All'});
      array.forEach((item, index) => {
        let id = item[idColumnName];
        let name = item[valueColumnName];

        let result = {
          id: id,
          text: name
        }
        resultArray.push(result);
      })
    }
    return resultArray;
  }

  getAllPermission() {
    let self = this;
    self.role = self._storageService.getUserRoles();
    return new Promise((resolve, reject) => {
      let self = this;
      self.showLoader();
      self._roleService.getAllPermissions(self.role)
        .subscribe(data => {
          self.hideLoader();
          resolve({ data });

        }, error => {
          resolve({ error });

        });
    });

  }


  getDateTimeString(objDate?: Date, dateFormat?: string, dateSeparator?: string): string {
    let d = objDate;
    if (this.isNullOrEmpty(d) || d.toString() == "Invalid Date") {
      return null;
    }

    let month, date, year;
    let MM, dd, yyyy;
    let fullDateTime;
    let hours = 0, minutes = 0, seconds = 0;

    date = d.getDate();
    month = (d.getMonth() + 1); // : Month start with (0-11) e.g. Jan = 0, Feb = 1, Mar = 2 etc.
    year = d.getFullYear();

    //set initial value.
    yyyy = year;
    MM = month;
    dd = date;

    hours = d.getHours();
    minutes = d.getMinutes();
    seconds = d.getSeconds();

    if (hours === 0 && minutes === 0 && seconds === 0) {
      //Get Current Time
      var today = new Date();
      hours = today.getHours();
      minutes = today.getMinutes();
      seconds = today.getSeconds();
    }

    //Append '0' prefix in date.
    if (date >= 1 && date <= 9) {
      dd = ("0" + date);
    }

    //Append '0' prefix in month.
    if (month >= 1 && month <= 9) {
      MM = ("0" + month);
    }

    if (this.isNullOrEmpty(dateSeparator)) {
      dateSeparator = DateSeprator.SLASH;
    }

    if (dateFormat == DateFormat.DDMMYYYY) {
      fullDateTime = dd + dateSeparator + MM + dateSeparator + yyyy + " " + hours + ":" + minutes + ":" + seconds;
    }
    else if (dateFormat == DateFormat.MMDDYYYY) {
      fullDateTime = MM + dateSeparator + dd + dateSeparator + yyyy + " " + hours + ":" + minutes + ":" + seconds;
    }
    else if (dateFormat == DateFormat.YYYYMMDD) {
      fullDateTime = yyyy + dateSeparator + MM + dateSeparator + dd + " " + hours + ":" + minutes + ":" + seconds;
    }
    return fullDateTime;
  }

  objectToFormData(obj) {
    var formData = new FormData();
    this.appendFormData(formData, obj);
    return formData;
  }

  appendFormData(formData, data, root = null) {
    root = root || '';
    if (data instanceof File) {
      formData.append(root, data);
    } else if (Array.isArray(data)) {
      for (var i = 0; i < data.length; i++) {
        if (data[i] instanceof File) {
          this.appendFormData(formData, data[i], root);
        } else {
          this.appendFormData(formData, data[i], root + '[' + i + ']');
        }
      }
    } else if (typeof data === 'object' && data) {
      for (var key in data) {
        if (data.hasOwnProperty(key)) {

          let value = data[key];

          if (root === '') {
            this.appendFormData(formData, value, key);
          } else {
            this.appendFormData(formData, value, root + '.' + key);
          }
        }
      }
    } else {
      if (data !== null && typeof data !== 'undefined') {
        formData.append(root, data);
      }
    }
  }


  getDateString(objDate?: Date, dateFormat?: string, dateSeparator?: string): string {
    let d = objDate;
    if (this.isNullOrEmpty(d) || d.toString() == "Invalid Date") {
      return null;
    }

    let month, date, year;
    let MM, dd, yyyy;
    let fullDate;

    date = d.getDate();
    month = (d.getMonth() + 1); // : Month start with (0-11) e.g. Jan = 0, Feb = 1, Mar = 2 etc.
    year = d.getFullYear();

    //set initial value.
    yyyy = year;
    MM = month;
    dd = date;

    //Append '0' prefix in date.
    if (date >= 1 && date <= 9) {
      dd = ("0" + date);
    }

    //Append '0' prefix in month.
    if (month >= 1 && month <= 9) {
      MM = ("0" + month);
    }

    if (this.isNullOrEmpty(dateSeparator)) {
      dateSeparator = DateSeprator.SLASH;
    }

    if (dateFormat == DateFormat.DDMMYYYY) {
      fullDate = dd + dateSeparator + MM + dateSeparator + yyyy;
    }
    else if (dateFormat == DateFormat.MMDDYYYY) {
      fullDate = MM + dateSeparator + dd + dateSeparator + yyyy;
    }
    else if (dateFormat == DateFormat.YYYYMMDD) {
      fullDate = yyyy + dateSeparator + MM + dateSeparator + dd;
    }
    return fullDate;
  }

  getDateObject(inputDateString, dateFormat, dateSeparator): Date {
    if (this.isNullOrEmpty(inputDateString)) { return null; }

    let dateParts = inputDateString.split(dateSeparator)

    let MM, dd, yyyy;

    if (dateFormat == DateFormat.DDMMYYYY) {
      dd = dateParts[0];
      MM = dateParts[1];
      yyyy = dateParts[2];
    }
    else if (dateFormat == DateFormat.MMDDYYYY) {
      MM = dateParts[0];
      dd = dateParts[1];
      yyyy = dateParts[2];
    }
    else if (dateFormat == DateFormat.YYYYMMDD) {
      yyyy = dateParts[0];
      MM = dateParts[1];
      dd = dateParts[2];
    }
    let dObj = new Date(Number(yyyy), (Number(MM) - 1), Number(dd));
    return dObj;
  }

  parseDateString(inputDateString: string, dateFormat?: string, dateSeparator?: string): string {
    if (this.isNullOrEmpty(inputDateString)) { return null; }
    let onlyDateString = inputDateString.substring(0, 10);

    return this.getDateString(new Date(onlyDateString), dateFormat, dateSeparator);

  }
}

export enum DateFormat {
  DDMMYYYY = "dd/MM/yyyy",
  MMDDYYYY = "MM/dd/yyyy",
  YYYYMMDD = "yyyy/MM/dd",
  DDMMMYYYY = "dd/MMM/yyyy"
}

export enum DateSeprator {
  SLASH = "/",
  DASH = "-",
  DOT = "."
}




