import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { RoleService } from 'src/app/services/role.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubCategoryService } from 'src/app/services/subcategory.service';

@Component({
  selector: 'app-inquiryview',
  templateUrl: './inquiryview.component.html',
  styleUrls: ['./inquiryview.component.css']
})
export class InquiryviewComponent implements OnInit {

  id: number;
  partnerData: any;


  constructor(private _subcategory: SubCategoryService,
    private _commonService: CommonService,
    private _router: Router,
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _siteConfigService: SiteConfigService,
    private _roleService: RoleService,
    private _storageService: StorageService) { }

  ngOnInit() {

    this._route.params.subscribe(params => {
      this.id = params['id'];
      this.getInquieryDetailById(this.id);
    });

  }

  getInquieryDetailById(id) {

    let self = this;
    self._subcategory.getInquieryDetailById(id)
      .subscribe(data => {

        let response: any = data;
        //debugger;
        self.partnerData = response.data[0];
      });


  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

}
