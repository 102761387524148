// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2'

// @Services
import { AttributeService } from 'src/app/services/attribute.service';
import { CommonService } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';

// @Models
import { AttributeVM } from 'src/app/models/AttributeVM';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-attribute-list',
  templateUrl: './attribute-list.component.html',
  styleUrls: ['./attribute-list.component.css']
})
export class AttributeListComponent implements OnInit {
  matData: MatTableDataSource<AttributeVM> = new MatTableDataSource();
  getSiteConfig: any;
  pageSize: number = 50;
  attributesData: any;
  noData = false;
  distributor: any;
  role: any;
  displayedColumns: string[];
  permissionsData = [];
  marked = false;
  marked1 = false;
  marked2 = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private _attributeService: AttributeService,
    private _roleservice: RoleService,
    private _commonService: CommonService,
    private _storageService: StorageService,
    private _router: Router,
    private _siteConfigService: SiteConfigService) {
  }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.loadAttributes();
    this.distributor = this._storageService.getDistributorId();


    this.displayedColumns = ['attribute_name', 'attribute_code', 'default_value', 'input_type_term', 'is_active', 'created_at', 'actions'];

  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      self.marked = self.permissionsData[2].sub_permission[1].isSelected;

      self.marked1 = self.permissionsData[2].sub_permission[2].isSelected;

      self.marked2 = self.permissionsData[2].sub_permission[3].isSelected;

      if (self.permissionsData[2].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });

  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadAttributes() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._attributeService.getAllAttibutes()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.attributesData = new MatTableDataSource<AttributeVM>(response.data);
            self.attributesData.paginator = self.paginator;
            self.attributesData.sort = self.sort;
            self.attributesData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.noData = (response.data.length > 0) ? false : true;
            self.attributesData.filterPredicate = (data: any, filter) => {
              const dataStr = JSON.stringify(data).toLowerCase();
              const strippedString = dataStr.replace(/(<([^>]+)>)/gi, "");
              return strippedString.indexOf(filter) != -1;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  editAttribute(attribute: AttributeVM) {
    this._router.navigate(['/admin/attributes/edit', attribute.attribute_id]);
  }

  deleteAttribute(attribute: AttributeVM) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this attribute?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    swal.fire(options).then((result) => {
      if (result.value) {

        self._commonService.showLoader();
        self._attributeService.deleteAttribute(attribute.attribute_id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Attribute deleted successfully.", "success");
          self.loadAttributes();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  applyFilter(filterValue: string) {
    this.attributesData.filter = filterValue.trim().toLowerCase();
    // this.noData = (this.attributesData._paginator._length > 0) ? false : true;
    this.noData = (this.attributesData.filteredData.length > 0) ? false : true;

  }
}
