import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { ItemService } from 'src/app/services/item.service';
import { ItemVM } from 'src/app/models/ItemVM';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SalesOrderVM } from 'src/app/models/SalesOrderVM';
import { DatePipe } from '@angular/common';
import { SalesOrderService } from 'src/app/services/sales-order.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { OnDestroy } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
declare var $: any;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-sales-orders-add',
  templateUrl: './sales-orders-add.component.html',
  styleUrls: ['./sales-orders-add.component.css'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class SalesOrdersAddComponent implements OnInit {

  itemId: number;
  itemDetail = new ItemVM();
  salesOrder = new SalesOrderVM();
  salesOrderForm: FormGroup;
  orderFormData = new FormData();
  submitted = false;
  show = false;
  mySimpleFormat: any;
  pipe = new DatePipe('en-US');
  itemid: any;
  catid: any;
  isRecording = false;
  recordedTime;
  blobUrl;
  audioSource;
  blob: Blob;
  laravel;
  laravel1;
  minDate;

  constructor(private _itemService: ItemService,
    private _salesOrderService: SalesOrderService,
    private _storageService: StorageService,
    private _commonService: CommonService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _datePipe: DatePipe,
    private _notificationService: NotificationService,

    private sanitizer: DomSanitizer) {
    this.minDate = new Date();





  }
  startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;

    }
  }

  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;

    }
  }

  stopRecording() {
    if (this.isRecording) {

      this.isRecording = false;
    }
  }

  clearRecordedData() {
    this.blobUrl = null;
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.catid = params['item_id'];

    });

    this.initialFormValue();
    this.buildForm();

  }

  initialFormValue() {
    let self = this;
    self._route.params.subscribe(params => { self.itemId = params['item_id']; });
    Promise.all([
      self.getItemDataById()
    ]).then((data: any) => {
      if (data != null && data != undefined) {
        let response = data[0];
        if (!self._commonService.isNullOrEmpty(response.data)) {
          self.itemDetail = response.data.item_detail;


          self.buildForm();

        }
      }
    });
  }

  get f() { return this.salesOrderForm.controls; }

  buildForm() {
    let self = this;
    let currentDate = new Date();
    this.salesOrder.order_date = this._commonService.parseDate(currentDate.toDateString());
    self.salesOrderForm = this._formBuilder.group({
      // sales_note: [this.salesOrder.sales_note, [Validators.required]],
      // is_express_delivery: [this.salesOrder.is_express_delivery],
      // edate: ['', [Validators.required]],
      // customer: [this.salesOrder.customer,[Validators.required]],
      // expected_date_of_delivery: [this.salesOrder.expected_date_of_delivery],
      sales_order_items: this._formBuilder.array([this._formBuilder.group({
        item_id: this.itemId,
        item_name: this.itemDetail.item_name,
        hiddenweight: this.itemDetail.net_weight,
        weight: [this.itemDetail.net_weight],

        metal_id: this.itemDetail.major_metal_id,
        purity: this.itemDetail.purity,
        total: [1, [Validators.required]],
        item_note: [''],
        total_weight: [this.itemDetail.net_weight],
      })])
    });
  }

  get itemForms() {
    return this.salesOrderForm.get('sales_order_items') as FormArray;
  }

  getItemDataById() {
    return new Promise((resolve, reject) => {
      let self = this;
      self._itemService.getItemDetailById(self.itemId).subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  updateWeight(index: number) {
    let self = this;
    let control = self.itemForms.controls[index];
    let qty = control.get('total').value;

    let totalWeight = 0;
    let totalQty = self._commonService.convertToNumber(qty);
    var netweight = control.get('hiddenweight').value;

    var weight = control.get('weight').value;

    // if(parseFloat(weight) < parseFloat(netweight))
    // { 
    //   self._commonService.showToaster("weight should be maximum of approx net weight", "error");
    //   //  return false;

    // }


    let netWeight: any = self._commonService.formatNumberWithDecimal(self.itemDetail.net_weight, 4);
    //totalWeight = totalQty * netWeight;
    totalWeight = totalQty * weight;
    var tweight = self._commonService.formatNumberWithDecimal(totalWeight, 2);
    control.get('total_weight').setValue(tweight);
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {

    this.mySimpleFormat = this.pipe.transform(event.target.value, 'dd/MM/yyyy')
  }
  saveOrder() {
    let self = this;
    self.submitted = true;
    if (self.salesOrderForm.invalid) {
      return;
    }

    let basicFormData = (self.salesOrderForm.value);
    for (const prop in basicFormData) {
      self.orderFormData.delete(prop);
      self.orderFormData.append(prop, basicFormData[prop]);
      if (prop == 'expected_date_of_delivery') {
        // let expected_date_of_delivery = this._datePipe.transform(basicFormData[prop], 'yyyy-MM-dd');
        // self.orderFormData.append(prop, expected_date_of_delivery);
      }
      if (prop == 'sales_order_items') {
        let itemFormData = (self.salesOrderForm.value.sales_order_items);

        self.orderFormData.delete('sales_order_items');

        self.orderFormData.append('sales_order_items', JSON.stringify(itemFormData));
        self.orderFormData.append('recordVoice', this.audioSource);

      }
    }

    if (this.itemDetail.status == 3) {
      self.orderFormData.append("is_request_order", "1")
    } else {
      self.orderFormData.append("is_request_order", "0")
    }
    // if(this.mySimpleFormat!='')
    // {
    //   self.orderFormData.append("expected_date_of_delivery",this.mySimpleFormat);
    // }
    self.orderFormData.append("category_id", this.catid);

    self._commonService.showLoader();

    self._salesOrderService.addSalesOrder(self.orderFormData).subscribe((data: any) => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self._commonService.showToaster("Order - " + data.order_no + " has been added to your cart.", "success");
        self._notificationService.updateNotification(true);
        this._storageService.cartItemChange.emit(true);
        self.gotoCartList();
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });

  }

  gotoSalesOrdersList() {
    this._router.navigate(['/admin/my-sales-orders']);
  }

  gotoCartList() {
    this._router.navigate(['/admin/add-to-cart-list']);
  }
}

