import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { SubscriptionVM } from 'src/app/models/SubscriptionVM';
import { CommonService } from 'src/app/services/common.service';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubscriptionService } from 'src/app/services/subscriprion.service';

@Component({
  selector: 'app-subscription-add',
  templateUrl: './subscription-add.component.html',
  styleUrls: ['./subscription-add.component.css']
})
export class SubscriptionAddComponent implements OnInit {


  addSubscriptionForm: FormGroup;
  SubscriptionVM = new SubscriptionVM();
  submitted = false;
  role: number;
  public select2Options: Options;
  public assignTypeData: Array<Select2OptionData>;

  constructor(
    private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _router: Router,
    private _roleService: RoleService,
    private _storageService: StorageService,
    private _subscriptionService: SubscriptionService
  ) {
    this.assignTypeData = [
      {
        id: '2',
        text: 'Distributor'
      },
      {
        id: '3',
        text: 'Retailer'
      },
      {
        id: '4',
        text: 'Customer',
      }
    ];
  }

  ngOnInit() {
    this.role = this._commonService.convertToNumber(this._storageService.getUserRoles());
    this.createSubscriptionForm();
    this.getSubscriptionCode();

  }

  get f() { return this.addSubscriptionForm.controls; }

  createSubscriptionForm() {
    this.addSubscriptionForm = this._formBuilder.group({
      SubscriptionCode: [this.SubscriptionVM.SubscriptionCode, [Validators.required, Validators.maxLength(3)]],
      SubscriptionName: [this.SubscriptionVM.SubscriptionName, [Validators.required, Validators.maxLength(36)]],
      SubscriptionDetail: [this.SubscriptionVM.SubscriptionDetail, [Validators.required, Validators.maxLength(1780)]],
      ValidDays: [this.SubscriptionVM.ValidDays, [Validators.required, Validators.maxLength(11), RxwebValidators.numeric({ allowDecimal: true })]],
      TotalCredit: [this.SubscriptionVM.TotalCredit, [Validators.required, Validators.maxLength(11), RxwebValidators.numeric({ allowDecimal: true })]],
      IsActive: [1, [Validators.required]],
      price: [this.SubscriptionVM.price, [Validators.required, RxwebValidators.numeric({ allowDecimal: true })]],
      role_id: [this.SubscriptionVM.role_id]
    });
  }


  getSubscriptionCode() {
    let self = this;
    self._subscriptionService.getSubscriptionCode().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        this.addSubscriptionForm.controls.SubscriptionCode.setValue(response.data);
      }
    });
  }

  save() {
    let self = this;
    self.submitted = true;
    if (self.addSubscriptionForm.invalid) {
      return;
    }
    let formData = new FormData();
    self._commonService.showLoader();
    var temp = this._storageService.getSubscriptionAssignItem();

    formData.append("SubscriptionCode", self.addSubscriptionForm.controls.SubscriptionCode.value);
    formData.append("SubscriptionName", self.addSubscriptionForm.controls.SubscriptionName.value);
    formData.append("SubscriptionDetail", self.addSubscriptionForm.controls.SubscriptionDetail.value);
    formData.append("ValidDays", self.addSubscriptionForm.controls.ValidDays.value);
    formData.append("TotalCredit", self.addSubscriptionForm.controls.TotalCredit.value);
    formData.append("IsActive", self.addSubscriptionForm.controls.IsActive.value);
    formData.append("price", self.addSubscriptionForm.controls.price.value);
    formData.append("role_id", self.addSubscriptionForm.controls.role_id.value);

    if (temp.length > 0) {
      formData.append("subscriptionItem", temp);

      self._subscriptionService.addSubscription(formData).subscribe(data => {
        self._commonService.hideLoader();

        if (!self._commonService.isNullOrEmpty(data)) {
          self.gotoSubscriptionList();
          self._commonService.showToaster("Subscription added successfully.", "success");
          self._storageService.clearSubscriptionAssignItem();
        }
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
    }
    else {
      formData.append("subscriptionItem", "");
      self._subscriptionService.addSubscription(formData).subscribe(data => {
        self._commonService.hideLoader();

        if (!self._commonService.isNullOrEmpty(data)) {
          self.gotoSubscriptionList();
          self._commonService.showToaster("Subscription added successfully.", "success");
          self._storageService.clearSubscriptionAssignItem();
        }
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
    }
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

  gotoSubscriptionList() {
    this._router.navigate(['/admin/subscription']);
  }
}
