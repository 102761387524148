import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ChangePasswordVM } from 'src/app/models/ChangePasswordVM';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { RetailerService } from 'src/app/services/retailer.service';
import { UserService } from 'src/app/services/user.service';
import { MustMatch } from 'src/app/validator/must-match.validator';

@Component({
  selector: 'app-retailer-password',
  templateUrl: './retailer-password.component.html',
  styleUrls: ['./retailer-password.component.css']
})
export class RetailerPasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  submitted = false;
  changePasswordVM = new ChangePasswordVM;
  RetailerData: any;
  constructor(private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<RetailerPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _commonService: CommonService,
    private _retailerService: RetailerService,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.changePasswordForm = this._formBuilder.group({
      retailer_id: [(this.data.RetailerId == null) ? '' : this.data.RetailerId],
      distributor_id: [(this.data.DistributerId == null) ? '' : this.data.DistributerId],
  //    old_password: [this.changePasswordVM.old_password, Validators.required],
      new_password: [this.changePasswordVM.new_password, [Validators.required, Validators.minLength(6), Validators.maxLength(14)]],
      confirm_password: [this.changePasswordVM.confirm_password, [Validators.required]]
    },
      {
        validator: MustMatch('new_password', 'confirm_password')
      });
  }

  // get all form controls
  get f() { return this.changePasswordForm.controls; }


  save() {
    let self = this;
    self.submitted = true;

    // stop here if form is invalid
    if (self.changePasswordForm.invalid) {
      return;
    }
    self._commonService.showLoader();
    self._retailerService.changePassword(self.changePasswordForm.value).subscribe(data => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self._commonService.showToaster('Password changed successfully.', 'success');
        self.submitted = false;
        self.dialogRef.close(self.changePasswordForm.value);

      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, 'error');
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
