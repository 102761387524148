// @Packages
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

// @Services
import { AppConfig } from '../shared/app-config';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  apiUrl: String;
  // set http Authorization headers
  httpAuthOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };

  constructor(private _httpService: HttpService, private _api: AppConfig) {
    this.apiUrl = this._api.ApiUrl;
  }

  getActiveCompanyData() {
    return this._httpService.get(`${this.apiUrl}getActiveCompanies`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllCompanyData() {
    return this._httpService.get(`${this.apiUrl}getAllCompanyData`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  addCompany(role) {
    return this._httpService.post(`${this.apiUrl}company/addCompany`, role, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getCompanyById(id) {
    return this._httpService.get(`${this.apiUrl}company/getCompanyById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  updateCompany(company) {
    return this._httpService.post(`${this.apiUrl}company/updateCompany`, company, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }



  deleteCompany(id) {
    return this._httpService.post(`${this.apiUrl}company/deleteCompany/${id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

}
