import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RetailerVM } from 'src/app/models/RetailerVM';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { CommonService } from 'src/app/services/common.service';
import { RetailerService } from 'src/app/services/retailer.service';
import { StorageService } from 'src/app/services/storage.service';
import { SweetAlertOptions } from 'sweetalert2';
import { SubscriptionVM, UserSubscriptionVM } from 'src/app/models/SubscriptionVM';
import { SubscriptionService } from 'src/app/services/subscriprion.service';


import swal from 'sweetalert2';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { ItemVM } from 'src/app/models/ItemVM';
import { MatTableDataSource } from '@angular/material';

@Component({
  selector: 'app-retailer-view',
  templateUrl: './retailer-view.component.html',
  styleUrls: ['./retailer-view.component.css']
})
export class RetailerViewComponent implements OnInit {

  id: number;
  loginUserId: any;
  RetailerVM = new RetailerVM();
  roleType: any;
  reasonData: any;
  role: number;
  permissionsData = [];
  UserSubscriptionVM = new UserSubscriptionVM();
  SubscriptionVM = new SubscriptionVM();
  totalLength: number;
  noData = false;
  displayedColumns: string[] = ['item_name', 'code', 'group_id', 'purity', 'is_active', 'status', 'created_at', 'actions'];
  columnSorting: any;
  columnDirection: any;
  filterValue: '';
  value: '';
  pageSize: number;
  retailerItemViewVM:any;

  constructor(
    private _commonService: CommonService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _retailerService: RetailerService,
    private _storageService: StorageService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _subscriptionService: SubscriptionService,
    private _siteConfigService: SiteConfigService
  ) { }

  ngOnInit() {
    let self = this;
    this.role = this._storageService.getUserRoles();
    this.loginUserId = this._storageService.getUserId();
    this.roleType = this._storageService.getUserRolesType();
    this.getUserActive();

    if (this.role != 1) {
      this.getPermission();
    }
    self._commonService.showLoader();
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.getRetailerById();
        this.getUserSubscriptionById();
        this.retailerItemsById(event);
      }
    });

  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[24].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getUserSubscriptionById() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._subscriptionService.getRetailerSubscriptionById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.UserSubscriptionVM = response.data;

      });
  }


  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getRetailerById() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._retailerService.getRetailerById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.RetailerVM = response.data[0];
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  // showRejectReason() {

  //   let self = this;
  //   const options: SweetAlertOptions = {
  //     title: 'Reason',
  //     html: self.RetailerVM.reject_reason,
  //     confirmButtonText: "Close"
  //   };
  //   swal.fire(options);
  // }

  approveItem(item: RetailerVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to approve this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._retailerService.approveItem(item.RetailerId).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Retailer approved successfully.", "success");
          self.goToDisRetailerItems();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  rejectItem(item: RetailerVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to reject this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._retailerService.rejectItem(item.RetailerId).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Retailer rejected successfully.", "success");
          self.goToDisRetailerItems();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }


  goToDisRetailerItems() {
    this._router.navigate(['/admin/distributor-retailer']);
  }

  retailerItemsById(event) {
    let self = this;
    self._commonService.showLoader();
    self._retailerService.getRetailerItems(self.id, event, this.value, this.columnSorting, this.columnDirection)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (!this._commonService.isEmptyArray(response.data.list)) {
          self.retailerItemViewVM = new MatTableDataSource<ItemVM>(response.data.list)
          if (response.data.list != null && response.data.list.length > 0) {
            self.totalLength = response.data.total_count;
          }
          self.noData = (response.data.list != null && response.data.list.length > 0) ? false : true;
          if (self.noData == true) {
            self.totalLength = 0;
          }
        }
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }

  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.retailerItemsById(event);
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.retailerItemsById(event);
  }

  viewItem(item: ItemVM) {
    this._router.navigate(['/admin/items/detail/', item.item_id], { queryParams: { id: item.item_id } });
  }

}
