import { CompanyVM } from './CompanyVM'

export class SiteConfigVM {
    config_id: number
    company_id: string
    site_name?: string
    admin_item_code?: string
    language_term?: string
    default_currency_term?: string
    acconting_method_term?: string
    default_email?: string
    default_customer_group_term?: string
    default_row_perpage_term?: number
    date_format_term?: string
    timezone_term?: string
    default_warehouse_id?: string
    max_upload_images?: number
    max_scan_upload_images?: number
    max_png_upload_images?: number
    default_biller_id?: number
    image_size_width?: number
    image_size_height?: number
    thumb_nail_width?: number
    thumb_nail_height?: number
    is_watermark_enable?: string
    decimals?: string
    qty_decimals?: string
    decimal_seprator_term?: string
    thousands_seprator_term?: string
    email_protocol_term?: string
    smtp_host?: string
    smtp_user?: string
    smtp_password?: string
    smtp_port?: string
    smtp_crypto?: string
    last_updated_on?: string
    updated_by?: number
    site_logo?: string
    login_logo?: string
    biller_logo?: string
    barcode_symbology_term?: string
    created_at?: string
    updated_at?: string
}
