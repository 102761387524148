import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyVM } from 'src/app/models/CompanyVM';
import { CompanyService } from 'src/app/services/company.service';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { REGEXP } from 'src/app/shared/regexp';

@Component({
  selector: 'app-companies-add',
  templateUrl: './companies-add.component.html',
  styleUrls: ['./companies-add.component.css']
})
export class CompaniesAddComponent implements OnInit {

  @ViewChild("logo", { static: false }) companyLogoFileInput;

  companyForm: FormGroup;
  CompanyVM = new CompanyVM();
  submitted = false;


  constructor(private _formBuilder: FormBuilder,
    private _companyService: CompanyService,
    private _commonService: CommonService,
    private _regex: REGEXP,
    private _router: Router) { }

  ngOnInit() {
    this.createCompanyForm();
  }

  get f() { return this.companyForm.controls; }

  createCompanyForm() {
    this.companyForm = this._formBuilder.group({
      company_code: [this.CompanyVM.company_code, [Validators.required, Validators.maxLength(255)]],
      company_name: [this.CompanyVM.company_name, [Validators.required, Validators.maxLength(255)]],
      display_name: [this.CompanyVM.display_name, [Validators.required, Validators.maxLength(255)]],
      registration_address: [this.CompanyVM.registration_address, [Validators.required, Validators.maxLength(255)]],
      billing_address: [this.CompanyVM.billing_address],
      contact_email: [this.CompanyVM.contact_email, [Validators.required, Validators.maxLength(255)]],
      logo: [this.CompanyVM.logo, requiredFileType(this._commonService.IMAGE_FILE_FORMAT)],
      phone_no: [this.CompanyVM.phone_no, [Validators.required, Validators.pattern(this._regex.NUMBER_REGEXP), Validators.minLength(10), Validators.maxLength(13)]],
      company_reg_no: [this.CompanyVM.company_reg_no, [Validators.required, Validators.maxLength(255)]],
      website_name: [this.CompanyVM.website_name, [Validators.required, Validators.maxLength(255)]],
      is_active: [1, Validators.required],
      is_default_selected: [0, Validators.required],
    });
  }

  gotoCompnayList() {
    this._router.navigate(['/admin/company']);
  }

  save() {
    let self = this;
    self.submitted = true;

    if (self.companyForm.invalid) {
      return;
    }
    // stop here if form is invalid
    let formData = new FormData();

    let siteConfigData = (self.companyForm.value as CompanyVM);
    for (const prop in siteConfigData) {

      let fi = this.companyLogoFileInput.nativeElement;
      if (prop == 'logo') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.companyForm.value.logo = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }
    }


    self._commonService.showLoader();
    self._companyService.addCompany(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoCompnayList();
        self._commonService.showToaster("Company added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }


}
