// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
// @Services
import { REGEXP } from 'src/app/shared/regexp';
import { DistributorService } from 'src/app/services/distributor.service';
import { CommonService } from 'src/app/services/common.service';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
// @Models
import { DistributorVM } from 'src/app/models/DistributorVM';
import { DistributorGroupVM } from 'src/app/models/DistributorGroupVM';
import { UserVM } from 'src/app/models/UserVM';
import { CountryVM } from 'src/app/models/CountryVM';
import { CountryNewVM, StateNewVM } from 'src/app/models/CommonVM';
import { StateVM } from 'src/app/models/StateVM';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { DistrictVM } from 'src/app/models/DistrictVM';
import { MatDialog } from '@angular/material';
//import { MetalModalComponent } from '../../items/metal-modal/metal-modal.component';
//import { AssignItemModalComponent } from '../assign-item-modal/assign-item-modal.component';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';
import { RoleVM } from 'src/app/models/RoleVM';
import { MustMatch } from 'src/app/validator/must-match.validator';
import { SubscriptionService } from 'src/app/services/subscriprion.service';
import { SubscriptionVM } from 'src/app/models/SubscriptionVM';

@Component({
  selector: 'app-distributor-add',
  templateUrl: './distributor-add.component.html',
  styleUrls: ['./distributor-add.component.css']
})

export class DistributorAddComponent implements OnInit {

  @ViewChild("logo", { static: false }) distributorlogoInput;
  @ViewChild("watermark", { static: false }) watermarkInput;
  @ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;

  //Select 2
  public select2Options: Options;
  public select2CountryData: Array<Select2OptionData>;
  public select2StateData: Array<Select2OptionData>;
  public select2OptionsDistributorGroup: Options;
  public select2GroupData: Array<Select2OptionData>;
  public select2DistrictData: Array<Select2OptionData>;
  public select2RolesData: Array<Select2OptionData>;
  public select2SubscriptionData: Array<Select2OptionData>;
  addDistributorForm: FormGroup;
  DistributorVM = new DistributorVM();
  UserVM = new UserVM();
  // userdata: UserVM[];
  groupdata: DistributorGroupVM[];
  countrydata: CountryNewVM[];
  statedata: StateNewVM[];
  districtdata: DistrictVM[];
  assignItem: any = [];
  submitted = false;
  roleData: RoleVM[];
  role: number;
  permissionsData = [];
  subscriptionData: SubscriptionVM[];

  constructor(
    private _formBuilder: FormBuilder,
    private _storageService: StorageService,
    private distributorService: DistributorService,
    private _commonService: CommonService,
    private _regex: REGEXP,
    private _roleService: RoleService,
    private _router: Router,
    public dialog: MatDialog,
    private _subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    // this.assignItem=this._storageService.getAssignItem();
    this.select2OptionsDistributorGroup = {
      multiple: true
    }
    this.getAllGroupData();
    this.getAllCountryData();
    this.getAllStateData();
    // this.getAllDistrictData();
    this.getAllDistrictDataDefault();
    this.createDistributorForm();
    this.getAllRoles();
    this.getAllSubscriptionData();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[13].sub_permission[1].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getAllCountryData() {
    var mCountryNewVM = new CountryNewVM();
    mCountryNewVM.id = 101;
    mCountryNewVM.name = "India";
    this.countrydata = new Array();
    this.countrydata.push(mCountryNewVM);
    let temp: any = [];
    this.countrydata.forEach(item => {
      temp.push({
        id: item.id,
        text: item.name,
      })
    });
    this.select2CountryData = temp
  }

  getAllStateData() {
    /*
      var mStateDataNewVM = new StateNewVM();
      mStateDataNewVM.id = 12;
      mStateDataNewVM.name = "Gujarat";
      this.statedata = new Array();
      this.statedata.push(mStateDataNewVM);
      let temp: any = [];
      this.statedata.forEach(item => {
        temp.push({
          id: item.id,
          text: item.name,
        })
      });
      this.select2StateData = temp
  */

    var mStateDataNewVM = new StateNewVM();
    this.statedata = new Array();
    this.statedata.push(mStateDataNewVM);
    let temp: any = [];
    let self = this;
    self._commonService.showLoader();
    self.distributorService.getStateById(101).subscribe(data => {
      let response: any;
      response = data;
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self.statedata = response.data;
        self.select2StateData = self._commonService.dropdownArray(self.statedata, "id", "name");
      }
    });

  }

  getAllDistrictDataDefault() {
    let self = this;
    // self.distributorService.getAllDistrictData().subscribe(data => {
    self.distributorService.getAllDistrictDataById(12).subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        // self.districtdata = response.district_data;
        self.districtdata = response.data;
        self.select2DistrictData = self._commonService.dropdownArray(self.districtdata, "id", "city_name");
      }
    });
  }

  getAllDistrictData(selectedCode) {
    let self = this;
    // self.distributorService.getAllDistrictData().subscribe(data => {
    self.distributorService.getAllDistrictDataById(selectedCode.data[0].id).subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        // self.districtdata = response.district_data;
        self.districtdata = response.data;
        self.select2DistrictData = self._commonService.dropdownArray(self.districtdata, "id", "city_name");
      }
    });
  }

  getAllGroupData() {
    let self = this;
    self.distributorService.getAllGroupData().subscribe(data => {
      let response: any = data;
      if (response.group_data != null) {
        self.groupdata = response.group_data;
        self.select2GroupData = self._commonService.dropdownArrayDistributorGroup(self.groupdata, "distributor_group_id", "group_name");
      }
    });
  }

  getAllRoles() {
    let self = this;
    self._roleService.getActiveRoles().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.roleData = response.data;
        self.select2RolesData = self._commonService.dropdownArray(self.roleData, "id", "name");
      }
    });
  }

  getAllSubscriptionData() {
    let self = this;
    self._subscriptionService.getAllActiveSubscription().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.subscriptionData = response.data;
        self.select2SubscriptionData = self._commonService.dropdownArray(self.subscriptionData, "SubscriptionId", "SubscriptionName");
      }
    });
  }

  get f() { return this.addDistributorForm.controls; }

  createDistributorForm() {
    this.addDistributorForm = this._formBuilder.group({
      distributor_code: [this.DistributorVM.distributor_code, [Validators.required, Validators.maxLength(255)]],
      distributor_first_name: ['', [Validators.required, Validators.maxLength(255)]],
      distributor_last_name: ['', [Validators.required, Validators.maxLength(255)]],
      company_name: [this.DistributorVM.company_name, [Validators.required, Validators.maxLength(255)]],
      display_name: [this.DistributorVM.display_name, [Validators.required, Validators.maxLength(255)]],
      business_address: [this.DistributorVM.business_address, [Validators.required, Validators.maxLength(255)]],
      email: [this.UserVM.email, [Validators.required, Validators.pattern(this._regex.EMAIL_REGEXP)]],
      mobile_no: [this.DistributorVM.mobile_no, [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
      gst_no: [this.DistributorVM.gst_no, [Validators.required, Validators.pattern(this._regex.GST_EXP), Validators.maxLength(255)]],
      registration_no: [this.DistributorVM.registration_no, [Validators.required, Validators.maxLength(255)]],
      // group_id: ['', Validators.required],
      group_id_multiple: ['', Validators.required],
      item_add_limit: [this.DistributorVM.item_add_limit, [Validators.required, Validators.maxLength(255)]],
      // total_business: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      country: [101],
      state: [12],
      city: [this.DistributorVM.city, [Validators.required, Validators.maxLength(255)]],
      // district: [this.DistributorVM.district, [Validators.required, Validators.maxLength(255)]],
      postal_code: [this.DistributorVM.postal_code],
      role_id: ['', Validators.required],
      subscription_id: ['', Validators.required],
      // latitude: [this.DistributorVM.latitude],
      // longitude: [this.DistributorVM.longitude],
      logo: [this.DistributorVM.logo, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      watermark: [this.DistributorVM.watermark, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      is_active: [1, Validators.required],
      password: [this.DistributorVM.password, [Validators.required, Validators.minLength(6), Validators.maxLength(14)]],
      confirm_password: [this.DistributorVM.confirm_password, [Validators.required]],
      is_item_show:[1]
    },
      {
        validator: MustMatch('password', 'confirm_password')
      });
  }

  gotoDistributerList() {
    this._router.navigate(['/admin/distributor']);
  }

  // openMetalDialog(): void {
  //   let self = this;
  //   const dialogRef = this.dialog.open(AssignItemModalComponent, {
  //     width: '2000px',
  //     height: '3000px',
  //     disableClose: true
  //   });
  // }

  // assignItemDistributor() {
  //   this.assignItem = this._storageService.getAssignItem();
  // }

  save() {
    let self = this;
    self.submitted = true;
    if (self.addDistributorForm.invalid) {
      return;
    }
    // stop here if form is invalid
    let formData = new FormData();
    let siteConfigData = (self.addDistributorForm.value as DistributorVM);
    for (const prop in siteConfigData) {
      let fi = this.distributorlogoInput.nativeElement;
      if (prop == 'logo') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.addDistributorForm.value.logo = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        }
        else {
          let file = new File([], '');
          formData.append(prop, file, "");
        }
      }
      let wi = this.watermarkInput.nativeElement;
      if (prop == 'watermark') {
        if (wi.files && wi.files[0]) {
          let fileToUpload = wi.files[0];
          self.addDistributorForm.value.watermark = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        }
        else {
          let file = new File([], '');
          formData.append(prop, file, "");
        }
      }
      else {
        if (prop == 'group_id_multiple') {
          let group_id_multiple = JSON.stringify(siteConfigData[prop]);
          formData.append(prop, group_id_multiple);
        }
        else {
          formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
        }
      }
    }
    self._commonService.showLoader();
    var temp = this._storageService.getAssignItem();
    if (temp.length > 0) {
      formData.append("item_ids", temp);
      self.distributorService.addDistributor(formData).subscribe(data => {
        self._commonService.hideLoader();
        if (!self._commonService.isNullOrEmpty(data)) {
          self.gotoDistributerList();
          self._commonService.showToaster("Distributor added successfully.", "success");
          self._storageService.clearAssignItem();
        }
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
    } else {
      formData.append("item_ids", "");
      self.distributorService.addDistributor(formData).subscribe(data => {
        self._commonService.hideLoader();
        if (!self._commonService.isNullOrEmpty(data)) {
          self.gotoDistributerList();
          self._commonService.showToaster("Distributor added successfully.", "success");
          self._storageService.clearAssignItem();
        }
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
    }
    // formData.append("item_ids",temp);
  }



  onChangeGetState(selectedCountry) {
    let self = this;
    self._commonService.showLoader();
    self.distributorService.getStateById(selectedCountry.data[0].id).subscribe(data => {
      let response: any;
      response = data;
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self.statedata = response.state;
        self.select2StateData = self._commonService.dropdownArray(self.statedata, "id", "name");
      }
    });
  }

  onChangeGetCode(selectedCode) {
    let self = this;
    self._commonService.showLoader();
    self.distributorService.getcodeById(selectedCode.data[0].id).subscribe(data => {
      let response: any;
      response = data;
      this.f.distributor_code.setValue(response.district_data);
      self._commonService.hideLoader();
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

  public handleAddressChange(business_address) {
    this.addDistributorForm.controls['business_address'].setValue(business_address.formatted_address);
  }
}
