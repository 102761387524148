// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';

// @Models
import { AccessoryVM } from 'src/app/models/AccessoryVM';

// @Services
import { AccessoryService } from 'src/app/services/accessory.service';
import { CommonService } from 'src/app/services/common.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-accessories-add',
  templateUrl: './accessories-add.component.html',
  styleUrls: ['./accessories-add.component.css']
})
export class AccessoriesAddComponent implements OnInit {

  @ViewChild("accessory_image", { static: false }) accessoryimageFileInput;
  addAccessoryForm: FormGroup;
  AccessoryVM = new AccessoryVM();
  submitted = false;
  role: any;
  permissionsData = [];

  constructor(private _formBuilder: FormBuilder,
    private accessoryService: AccessoryService,
    private _storageService: StorageService,
    private _commonService: CommonService,
    private _router: Router) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.createAccessoryForm();
  }

  getPermission() {
    let self = this;
    Promise.all([
      //self.getallPermission()
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[8].sub_permission[1].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  // getallPermission() {
  //   let self = this;
  //   self.role = self._storageService.getUserRoles();
  //   return new Promise((resolve, reject) => {
  //     let self = this;
  //     self._commonService.showLoader();
  //     self._roleService.getAllPermissions(self.role)
  //       .subscribe(data => {
  //         self._commonService.hideLoader();
  //         resolve({ data });
  //       }, error => {
  //         resolve({ error });
  //       });
  //   });
  // }

  get f() { return this.addAccessoryForm.controls; }

  createAccessoryForm() {
    this.addAccessoryForm = this._formBuilder.group({
      accessory_code: [this.AccessoryVM.accessory_code, [Validators.required, Validators.maxLength(255)]],
      accessory_name: [this.AccessoryVM.accessory_name, [Validators.required, Validators.maxLength(255)]],
      details: [this.AccessoryVM.details],
      unit_of_measure_term: [this.AccessoryVM.unit_of_measure_term, [Validators.required, Validators.maxLength(255)]],
      cost_per_unit: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      display_name: [this.AccessoryVM.display_name, [Validators.required, Validators.maxLength(255)]],
      accessory_image: [this.AccessoryVM.accessory_image, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      labour_rate: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      is_active: [1, Validators.required],
    });
  }

  gotoAccessoryList() {
    this._router.navigate(['/admin/accessories']);
  }

  save() {
    let self = this;
    self.submitted = true;
    if (self.addAccessoryForm.invalid) {
      return;
    }
    let formData = new FormData();
    let siteConfigData = (self.addAccessoryForm.value as AccessoryVM);
    for (const prop in siteConfigData) {
      let fi = this.accessoryimageFileInput.nativeElement;
      if (prop == 'accessory_image') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.addAccessoryForm.value.accessory_image = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }
    }

    self._commonService.showLoader();
    self.accessoryService.addAccessory(formData).subscribe(data => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoAccessoryList();
        self._commonService.showToaster("Accessory added successfully.", "success");
      }
    },
      error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

}

