import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConfig } from 'src/app/shared/app-config';
import { CommonService, DateFormat, DateSeprator } from 'src/app/services/common.service';
import { NotificationVM } from 'src/app/models/NotificationVM';
import { NotificationService } from 'src/app/services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, MatTableDataSource, MatSort, PageEvent } from '@angular/material';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { SalesOrderVM, SalesOrderDetailsVM } from 'src/app/models/SalesOrderVM';
import { SalesOrderService } from 'src/app/services/sales-order.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sales-orders-list',
  templateUrl: './sales-orders-list.component.html',
  styleUrls: ['./sales-orders-list.component.css']
})
export class SalesOrdersListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['order_date', 'distributor_id', 'user_id', 'customer','actual_date_of_delivery', 'order_no', 'order_status', 'is_express_delivery', 'actions'];
  salesOrderData: any;
  noData = false;
  show: any;
  submitted = false;
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  filterValue: '';
  value: '';
  date: string;
  dateselect?: any;
  availableItem?: any;
  selectStatus: any;
  pendingValidate = false;
  columnSorting: any;
  columnDirection: any;
  applyseacrhSalesOrderForm: FormGroup;
  orderdate: any;
  deliverydate: any;
  search: { orderDate: any; deliveryDate: any; selectStatus: any; };
  constructor(private _salesOrderService: SalesOrderService,
    private _commonService: CommonService,
    private _router: Router, private _route: ActivatedRoute,
    private _siteConfig: SiteConfigService,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.createCustomizeOrderForm();

    let self = this;

    this._route.params.subscribe(params => {
      this.selectStatus = params['item'];
      if (this.selectStatus == 'pending') {
        this.availableItem = "1";
        this.search = {
          "orderDate": "",
          "deliveryDate": "",
          "selectStatus": this.availableItem
        }
        self.pendingValidate = true;
        this.applyseacrhSalesOrderForm.controls.selectStatus.setValue(this.search.selectStatus)
      }
      // else if (this.selectStatus == 'confirmed') {
      //   this.availableItem = 2;
      // }
      // else if (this.selectStatus == 'completed') {
      //   this.availableItem = 3;
      // }
      // else if (this.selectStatus == 'cancelled') {
      //   this.availableItem = 4;
      // }
      self._siteConfig.getSiteConfigData().subscribe(data => {
        let response: any = data;
        if (response.data != null) {
          self.pageSize = response.data.default_row_perpage_term;
          var event: any = {
            "previousPageIndex": '0',
            "pageIndex": '0',
            "pageSize": this.pageSize,
            "length": '0',
          }
          this.getAllSalesOrder(event);
        }
      });
    });

  }

  createCustomizeOrderForm() {
    this.applyseacrhSalesOrderForm = this._formBuilder.group({
      orderDate: [''],
      deliveryDate: [''],
      selectStatus: [''],
    });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  viewSalesOrderDetail(orderdetail: SalesOrderDetailsVM) {
    this._router.navigate(['/admin/sales-order-details', orderdetail.sales_order_id], { queryParams: { id: orderdetail.sales_order_id } });
  }

  getAllSalesOrder(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._salesOrderService.getAllSalesOrders(event, this.value, this.dateselect, this.availableItem, this.search, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.salesOrderData = new MatTableDataSource<SalesOrderVM>(response.data.list);

            if (response.data.list.length > 0) {
              self.totalLength = response.data.total_count;
            }
            self.noData = (response.data.list.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });

      });
  }
  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  applyFilter(filterValue: any) {

    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllSalesOrder(event);
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;

    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllSalesOrder(event);
  }

  applySearch() {
    let self = this;
    if (this.applyseacrhSalesOrderForm.controls.orderDate.value.start != null && this.applyseacrhSalesOrderForm.controls.orderDate.value.end != null) {
      self.orderdate = {
        start: self._commonService.getDateTimeString(this.applyseacrhSalesOrderForm.controls.orderDate.value.start._d, DateFormat.DDMMYYYY, DateSeprator.DASH),
        end: self._commonService.getDateTimeString(this.applyseacrhSalesOrderForm.controls.orderDate.value.end._d, DateFormat.DDMMYYYY, DateSeprator.DASH)
      }
    }

    if (this.applyseacrhSalesOrderForm.controls.deliveryDate.value.start != null && this.applyseacrhSalesOrderForm.controls.deliveryDate.value.end != null) {
      self.deliverydate = {
        start: self._commonService.getDateTimeString(this.applyseacrhSalesOrderForm.controls.deliveryDate.value.start._d, DateFormat.DDMMYYYY, DateSeprator.DASH),
        end: self._commonService.getDateTimeString(this.applyseacrhSalesOrderForm.controls.deliveryDate.value.end._d, DateFormat.DDMMYYYY, DateSeprator.DASH)
      }
    }
    this.search = {
      "orderDate": (this.applyseacrhSalesOrderForm.controls.orderDate.value.start && this.applyseacrhSalesOrderForm.controls.orderDate.value.end) == undefined ? "" : self.orderdate,
      "deliveryDate": (this.applyseacrhSalesOrderForm.controls.deliveryDate.value.start && this.applyseacrhSalesOrderForm.controls.deliveryDate.value.end) == undefined ? "" : self.deliverydate,
      "selectStatus": this.applyseacrhSalesOrderForm.controls.selectStatus.value
    }
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllSalesOrder(event);

  }

  clearSearch() {
    this.pendingValidate = false;
    this.search = {
      "orderDate": "",
      "deliveryDate": "",
      "selectStatus": "",
    }
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this._router.navigate(["/admin/sales-orders"]);
    this.getAllSalesOrder(event);
  }
}

