// @Packages
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
// @Services
import { AccessoryService } from 'src/app/services/accessory.service';
import { CommonService } from 'src/app/services/common.service';
import { AccessoryVM } from 'src/app/models/AccessoryVM';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-accessory-modal',
  templateUrl: './accessory-modal.component.html',
  styleUrls: ['./accessory-modal.component.css']
})

export class AccessoryModalComponent implements OnInit {

  addAccessoryFormGroup: FormGroup;
  AccessoryVM = new AccessoryVM();
  submitted = false;
  accessoryData: AccessoryVM[] = new Array();
  //Select 2
  public select2Options: Options;
  public select2AccessoryData: Array<Select2OptionData>;

  constructor(
    public dialogRef: MatDialogRef<AccessoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _accessoryService: AccessoryService,
    private _commonService: CommonService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getActiveAccessoriesData();
    this.buildForm();
  }

  buildForm() {
    this.addAccessoryFormGroup = this._formBuilder.group({
      accessory_id: ['', [Validators.required]],
      unit_of_measure_term: ['', [Validators.required, Validators.maxLength(255)]],
      qty: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      rate: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      total: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      labour_rate: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      total_labour_charge: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      sequence_no: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      net_qty: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      wastage_qty: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      accessory: new AccessoryVM()
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.addAccessoryFormGroup.controls; }

  getActiveAccessoriesData() {
    let self = this;
    self._accessoryService.getActiveAccessoryData().subscribe(data => {
      let response: any = data;
      if (response.accessory_data != null) {
        let accessoryDataArray = response.accessory_data;
        accessoryDataArray.forEach(obj => {
          let accessory_id = obj.accessory_id;
          // check wheather the accessory is already added for this item or not
          if (self.data.AccessoryArr.indexOf(accessory_id) == '-1') { // false
            let accessory = new AccessoryVM();
            accessory = obj;
            self.accessoryData.push(accessory); // adding obj to the dropdown array
          }
        });
        self.select2AccessoryData = self._commonService.dropdownArray(self.accessoryData, "accessory_id", "accessory_name");
      }
    });
  }

  saveAccessory() {
    let self = this;
    self.submitted = true;
    if (self.addAccessoryFormGroup.invalid) {
      return;
    }
    self.dialogRef.close(self.addAccessoryFormGroup.value);
  }

  onChangeStoreRate(accessoryId: number) {
    let self = this;
    let accessoryDataArray = self.accessoryData;
    accessoryDataArray.forEach(obj => {
      let accessory_id = obj.accessory_id;
      if (accessory_id == accessoryId) { // true 
        let accessory = new AccessoryVM();
        accessory = obj;
        self.addAccessoryFormGroup.controls['rate'].patchValue(accessory.cost_per_unit);
        self.addAccessoryFormGroup.controls['labour_rate'].patchValue(accessory.labour_rate);
        self.addAccessoryFormGroup.controls['accessory'].patchValue(accessory);
      }
    });
  }
}
