// @Packages
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// @Models
import { ItemVM } from 'src/app/models/ItemVM';
import { CategoryVM } from 'src/app/models/CategoryVM';
// @Services
import { ItemService } from 'src/app/services/item.service';
import { CommonService } from 'src/app/services/common.service';
import { SubCategoryService } from 'src/app/services/subcategory.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubscriptionService } from 'src/app/services/subscriprion.service';
import { MetalService } from 'src/app/services/metal.service';
import { ItemGroupService } from 'src/app/services/item-group.service';
import { RoleService } from 'src/app/services/role.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-show-itemlist-layout',
  templateUrl: './show-itemlist-layout.component.html',
  styleUrls: ['./show-itemlist-layout.component.css']
})
export class ShowItemlistLayoutComponent implements OnInit {

  id: number;
  values = '';
  totalCount: number;
  itemData: any;
  role: any;
  karat_id_array = [];
  metal_id_array = [];
  item_status_array = [];
  item_group_array = [];
  searchText: string;
  subCategoryVM: CategoryVM[];
  showItemList: ItemVM[] = [];
  public pageNumber: number;
  public pageSize: number;
  public paginationValue: number;
  public countMaxBestSeller: number;
  public metaldata: {};
  public itmgroups: any;
  public itemStatusData: any;
  public purityData: any;
  public filteration: any;
  permissionsData = [];
  subcategory_id: any;
  ref_category_id: any;
  watermarkImagePath: any;
  isRelatedSubCategory = true;
  isAllowDownload = false;
  userSubscriptionCreditOnHand = 0;
  show = false;
  buttonName = 'Show';
  hide: any;
  hideMessage = true;
  count: number = 0

  sorting = [{
    id: 1, name: 'Whats New',
  },
  {
    id: 2, name: 'Popular',
  },
  {
    id: 3, name: 'Weight-low to high',
  },
  {
    id: 4, name: 'Weight-high to low',
  },
  ];

  // @HostListener("window:scroll", [])
  // onScroll(): void {
  //   //In chrome and some browser scroll is given to body tag
  //   let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
  //   let max = document.documentElement.scrollHeight;
  //   if (pos == max) {
  //     //Do your action here
  //     if (this.showItemList[0] != undefined) {
  //       if (this.showItemList.length < this.showItemList[0].totalcount) {
  //         this.paginationValue = this.pageNumber * this.pageSize;
  //         this.pageNumber++;
  //         this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values);
  //       }
  //     }
  //   }
  // }

  onScroll() {
    if (this.showItemList[0] != undefined) {
      if (this.showItemList.length < this.totalCount) {
        this.paginationValue = this.pageNumber * this.pageSize;
        this.pageNumber++;
        this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values, this.subcategory_id);
      }
    }
  }

  constructor(private _itemService: ItemService,
    private _storageService: StorageService,
    private _commonService: CommonService,
    private _router: Router,
    private _roleService: RoleService,
    private _subcategoryService: SubCategoryService,
	private _subscriptionService: SubscriptionService,    
    private _route: ActivatedRoute, private MetalService: MetalService, private itemgroups: ItemGroupService) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    // $(document).on('click', 'a', function (e) {
    //   $('a').removeClass("click");
    //   $(this).addClass("click");
    //   if ($('a').hasClass("click")) {
    //     $('a').removeClass("click1");
    //   }
    // });
    this.getPermission();
    this.pageNumber = 1;
    this.pageSize = 12;
    this.paginationValue = 0;

    if (this.role == 1) {
      this._router.navigate(['/admin/dashboard']);
      return false;
    }

    this.MetalService.getActiveMetalData().subscribe(res => {
      let response: any = res;
      if (response.metal_data != null) {
        this.metaldata = response.metal_data;
      }
    });

    this._itemService.getAllItemStatus().subscribe(data => {
      let response: any = data;
      if (response.item_status_data != null) {
        this.itemStatusData = response.item_status_data;
      }
    });

    this._itemService.getActivePurity().subscribe(data => {
      let response: any = data;
      if (response.purity != null) {
        this.purityData = response.purity;
      }
    });

    this.itemgroups.getAllItemGroupData().subscribe(res => {
      let response: any = res;
      if (response.item_group_data != null) {
        this.itmgroups = response.item_group_data;
      }
    });
    this.getSubCategory();
    this.getDownloadStatus();
    this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values, this.subcategory_id);
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[19].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  trackByFn(index, item) {
    if (!item) return null;
    return index; // or item.disaggregationID;
  }

  // getImageUrlItemGallery(item) {
  //   var image_path = '';
  //   image_path = item.main_image_path;
  //   return this._commonService.getFullImageUrl(image_path);
  // }

  getFullUrl(item_image) {
    return this._commonService.getFullImageUrl(item_image);
  }

 getDownloadStatus() {
  
   // this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    
    self._commonService.showLoader();
    self._subscriptionService.getDistSubscriptionStatus()
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (response.data != undefined) {
          self.isAllowDownload = true;
          self.userSubscriptionCreditOnHand = response.data.CreditOnHand;
        }
      });
  }

  getSubCategory() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._subcategoryService.getSubCategory(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (response.subcategory != undefined) {
          self.subCategoryVM = response.subcategory;
        }
      });
  }

  getItemList(paginationValue, pagingationSize, karat_id_array, metal_id_array, item_status_array, item_group_array, filteration, values, subcategory_id) {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._itemService.getItemListByCategoryId(self.id, paginationValue, pagingationSize, karat_id_array, metal_id_array, item_status_array, item_group_array, filteration, values, subcategory_id)
      .subscribe(data => {
        self._commonService.hideLoader();
        self.hideMessage = false;
        let response: any = data;
        if (response.itemlist != undefined) {
          this.totalCount = response.total_count;

          if (self.showItemList.length > 0) {
            response.itemlist.forEach(element => {
              self.showItemList.push(element);
            });
          }
          else if (response.itemlist.length > 0) {
            self.showItemList = response.itemlist;
          } else {
            self.showItemList = [];
          }
          if (self.showItemList != undefined) {
            this.countMaxBestSeller = Math.max.apply(Math, self.showItemList.map(function (o) { return o.total_order_received; }));
          }
        }
      });
  }

  getItemListSearch(paginationValue, pagingationSize, karat_id_array, metal_id_array, item_status_array, item_group_array, filteration, values, subcategory_id) {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._itemService.getItemListByCategoryId(self.id, paginationValue, pagingationSize, karat_id_array, metal_id_array, item_status_array, item_group_array, filteration, values, subcategory_id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        this.showItemList = [];
        if (self.showItemList.length > 0) {
          response.itemlist.forEach(element => {
            self.showItemList.push(element);
          });
        }
        else if (response.itemlist != null && response.itemlist.length > 0) {
          self.showItemList = response.itemlist;
        } else {
          self.showItemList = [];
        }
        if (self.showItemList != undefined) {
          this.countMaxBestSeller = Math.max.apply(Math, self.showItemList.map(function (o) { return o.total_order_received; }));
        }
      });
  }

  showDetail(item: ItemVM) {
    this._router.navigate(['mainshowcase/showdetail', item.item_id], { queryParams: { source: this.id, itemid: item.item_id, metalid: this.metal_id_array, item_status_id: this.item_status_array, item_group_id: this.item_group_array, filter: this.filteration, subcategory_id: this.subcategory_id } });
  }

  // selectChangeHandler(filterVal: number) {
  //   this.showItemList = [];
  //   this.paginationValue = 0;
  //   this.pageNumber = 1;
  //   this.pageSize = 12;
  //   this.filteration = filterVal;
  //   this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values, this.subcategory_id);
  // }

  onOptionsSelected(value: any) {
    this.metal_id_array = value;
    this.showItemList = [];
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values, this.subcategory_id);
  }

  onOptionsSelected1(value1: any) {
    this.item_status_array = value1;
    this.showItemList = [];
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values, this.subcategory_id);
  }

  onOptionsSelected2(value2: any) {
    this.item_group_array = value2;
    this.showItemList = [];
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values, this.subcategory_id);
  }

  onOptionsSelected3(value3: any) {
    this.filteration = value3;
    this.showItemList = [];
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values, this.subcategory_id);
  }

  subCategoryItemGet(subcategory: CategoryVM) {
    this.showItemList = [];
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    this._router.navigate(['mainshowcase/showitemlist', subcategory.category_id]);
    this.isRelatedSubCategory = false;
    this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values, subcategory.category_id);
  }

  // mainCategoryList(subcategory) {
  //   this.ref_category_id = subcategory.ref_category_id;
  //   this.showItemList = [];
  //   this.paginationValue = 0;
  //   this.pageNumber = 1;
  //   this.pageSize = 12;
  //   subcategory.isRelatedSubCategory = false;
  //   this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values, this.ref_category_id);
  // }

  // selectChangeHandler1(filterVal, event) {
  //   this.showItemList = [];
  //   this.paginationValue = 0;
  //   this.pageNumber = 1;
  //   this.pageSize = 12;
  //   const checked = event.target.checked; // stored checked value true or false
  //   if (checked) {
  //     this.metal_id_array.push(filterVal);
  //     this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values);
  //   } else {
  //     const index = this.metal_id_array.findIndex(list => list == filterVal);//Find the index of stored
  //     this.metal_id_array.splice(index, 1); // Then remove
  //     this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values);
  //   }
  // }

  // selectChangeHandler2(filterVal, event) {
  //   this.showItemList = [];
  //   this.paginationValue = 0;
  //   this.pageNumber = 1;
  //   this.pageSize = 12;
  //   const checked = event.target.checked; // stored checked value true or false
  //   if (checked) {
  //     this.item_status_array.push(filterVal);
  //     this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values);
  //   } else {
  //     const index = this.item_status_array.findIndex(list => list == filterVal);//Find the index of stored
  //     this.item_status_array.splice(index, 1); // Then remove
  //     this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values);
  //   }
  // }

  // selectChangeHandler3(filterVal, event) {
  //   this.showItemList = [];
  //   this.paginationValue = 0;
  //   this.pageNumber = 1;
  //   this.pageSize = 12;
  //   const checked = event.target.checked; // stored checked value true or false
  //   if (checked) {
  //     this.item_group_array.push(filterVal);
  //     this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values);
  //   } else {
  //     const index = this.item_group_array.findIndex(list => list == filterVal);//Find the index of stored
  //     this.item_group_array.splice(index, 1); // Then remove
  //     this.getItemList(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values);
  //   }
  // }

  onKey(event: any) {
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    this.values = event.target.value;
    this.getItemListSearch(this.paginationValue, this.pageSize, this.karat_id_array, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values, this.subcategory_id);
  }

  mouseenter(itemImage, original_image_path) {
    itemImage.src = this.getFullUrl(original_image_path);
  }

  mouseleave(itemImage, thumbnail_image_path) {
    itemImage.src = this.getFullUrl(thumbnail_image_path);
  }

  toggle() {
    this.show = !this.show
    if (this.show) {
      this.buttonName = 'Hide'
    }
    else {
      this.buttonName = 'Show'
    }
  }

  watermarkImageDownload(item) {
    let self = this;
    // let totalImageRow = this.count++;
    // if (totalImageRow == 2) {
    //   Swal.fire("You've exceed the maximum number of images for this item");
    // }
    // else {
    self._commonService.showLoader();
    self._itemService.watermarkImageDownload(item).subscribe(data => {
      let response: any = data;
      if (response.status == 1 && response.data != null) {
        self._commonService.hideLoader();
        self.watermarkImagePath = response.data
        const linkSource = self.watermarkImagePath;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = item.item_name;
        downloadLink.click();

        this.getDownloadStatus();
      }else{
        self._commonService.hideLoader();
        self._commonService.showToaster(response.message, "error");
      }
      
    });
    //}
  }

  watermarkPinterest(item) {
    let self = this;
    self._commonService.showLoader();
    self._itemService.watermarkPinterest(item).subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self._commonService.hideLoader();
        self.watermarkImagePath = this.getFullUrl(response.data);
        let redirectURL = 'https://www.pinterest.com/pin/create/button/?media=' + self.watermarkImagePath;
        window.open(redirectURL, '_blank');
      }
    });
  }

  watermarkFacebook(item) {
    let self = this;
    self._commonService.showLoader();
    self._itemService.watermarkFacebook(item).subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self._commonService.hideLoader();
        self.watermarkImagePath = this.getFullUrl(response.data);
        let redirectURL = 'http://www.facebook.com/share.php?u=' + self.watermarkImagePath;
        window.open(redirectURL, '_blank');
      }
    });
  }
}