import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

//@Services
import { CommonService } from 'src/app/services/common.service';

//@Model
import { ForgotPasswordVM } from 'src/app/models/ForgotPasswordVM';
import { REGEXP } from 'src/app/shared/regexp';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  submitted = false;
  SubmitButtonText = "Submit";

  forgotPasswordVM = new ForgotPasswordVM();

  constructor(public router: Router,
    private _regex: REGEXP,
    private _formBuilder: FormBuilder,
    private _userService: UserService,
    private _commonService: CommonService
  ) { }

  ngOnInit() {
    this.buildForm();
  }
  buildForm() {
    this.forgotPasswordForm = this._formBuilder.group({
      email: [this.forgotPasswordVM.email, [Validators.required, Validators.pattern(this._regex.EMAIL_REGEXP)]]
    });
  }
  get f() { return this.forgotPasswordForm.controls; }

  forgotPassword() {
    let self = this;
    self.submitted = true;
    // stop here if form is invalid
    if (self.forgotPasswordForm.invalid) {
      return;
    }
    self.SubmitButtonText = "Processing...";
    self._commonService.showLoader();
    self._userService.getUserByEmail(self.forgotPasswordForm.value).subscribe(data => {
      self.SubmitButtonText = "Submit";

      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        let res: any;
        res = data;
        self._commonService.showToasterTopCenter(res.message, "success");
        self.submitted = false;
        self.buildForm();
      }
    }, error => {
      self._commonService.hideLoader();
      self.SubmitButtonText = "Submit";
      self._commonService.showToasterTopCenter(error.message, "error");
    });
  }

  keyDownForgotPassword(event) {
    if (event.keyCode == 13) {
      this.forgotPassword();
    }
  }

}
