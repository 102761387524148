// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2'
// @Services
import { RoleService } from 'src/app/services/role.service';
import { CommonService } from 'src/app/services/common.service';
// @Models
import { RoleVM } from 'src/app/models/RoleVM';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css']
})
export class RoleListComponent implements OnInit {

  role: any;
  permissionsData = [];
  pageSize: number = 50;
  rolesData: any;
  noData = false;
  displayedColumns: string[] = ['name', 'code', 'display_name', 'is_active', 'is_default', 'created_at', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private _roleService: RoleService,
    private _commonService: CommonService,
    private _router: Router,
    private _storageService: StorageService,
    private _siteConfigService: SiteConfigService) {
  }

  ngOnInit() {
    this.getPermission();
    this.loadRoles();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
    });
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadRoles() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._roleService.getAllRoles()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.rolesData = new MatTableDataSource<RoleVM>(response.data);
            self.rolesData.paginator = self.paginator;
            self.rolesData.sort = self.sort;
            self.rolesData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.noData = (response.data.length > 0) ? false : true;
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  editRole(role: RoleVM) {
    this._router.navigate(['/admin/roles/edit', role.id]);
  }

  editPermission(role: RoleVM) {
    this._router.navigate(['/admin/role-permission/edit', role.id]);
  }

  deleteRole(role: RoleVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this role and its associated users?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._roleService.deleteRole(role.id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Role deleted successfully.", "success");
          self.loadRoles();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  applyFilter(filterValue: string) {
    this.rolesData.filter = filterValue.trim().toLowerCase();
    this.noData = (this.rolesData.filteredData.length > 0) ? false : true;
    // this.noData = (this.rolesData._paginator._length > 0) ? false : true;
  }
}
