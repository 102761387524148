import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConfig } from 'src/app/shared/app-config';
import { CommonService } from 'src/app/services/common.service';
import { NotificationVM } from 'src/app/models/NotificationVM';
import { NotificationService } from 'src/app/services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { CustomOrderVM } from 'src/app/models/CustomOrderVM';
import { SalesOrderService } from 'src/app/services/sales-order.service';
import { Options } from 'selenium-webdriver/chrome';
import { Select2OptionData } from 'ng-select2';
import { PurityVM } from 'src/app/models/PurityVM';
import { REGEXP } from 'src/app/shared/regexp';
import { CustomizeItemService } from 'src/app/services/customize-item.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryVM } from 'src/app/models/CategoryVM';
import { MetalVM } from 'src/app/models/MetalVM';
import { MetalService } from 'src/app/services/metal.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { RoleService } from 'src/app/services/role.service';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-customize-order-add',
  templateUrl: './customize-order-add.component.html',
  styleUrls: ['./customize-order-add.component.css'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class CustomizeOrderAddComponent implements OnInit {
  @ViewChild("item_image", { static: false }) itemImageInput;

  //Select 2
  public select2Options: Options;
  public select2PurityData: Array<Select2OptionData>;
  public select2CategoryData: Array<Select2OptionData>;
  public select2MetalData: Array<Select2OptionData>;


  categoriesData: CategoryVM[];
  metalData: MetalVM[];
  puritydata: PurityVM[];
  role: number;
  permissionsData = [];

  addCustomizeOrderForm: FormGroup;
  CustomOrderVM = new CustomOrderVM();
  submitted = false;
  edc_date: any;
  pipe = new DatePipe('en-US');
  minDate;
  constructor(private _formBuilder: FormBuilder,
    private _customizeService: CustomizeItemService,
    private _metalService: MetalService,
    private _commonService: CommonService,
    private _regex: REGEXP,
    private _storageService: StorageService,
    private _roleService: RoleService,
    private _router: Router) { this.minDate = new Date(); }


  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.getActiveCategories();
    this.getActivePurity();
    this.getActiveMetalsData();
    this.createCustomizeOrderForm();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      //check permission

      if (self.permissionsData[18].sub_permission[1].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }

    });

  }

  getActiveCategories() {
    let self = this;
    self._customizeService.getAllActiveCategories().subscribe(data => {
      let response: any = data;
      if (response.categories != null) {
        self.categoriesData = response.categories;
        self.select2CategoryData = self._commonService.dropdownArray(self.categoriesData, "category_id", "category_name");
      }
    });
  }

  getActivePurity() {
    let self = this;
    self._customizeService.getActivePurity().subscribe(data => {
      let response: any = data;
      if (response.purity != null) {
        self.puritydata = response.purity;
        self.select2PurityData = self._commonService.dropdownArray(self.puritydata, "id", "purity");
      }
    });
  }

  getActiveMetalsData() {
    let self = this;
    self._metalService.getActiveMetalData().subscribe(data => {
      let response: any = data;
      if (response.metal_data != null) {
        self.metalData = response.metal_data;
        self.select2MetalData = self._commonService.dropdownArray(self.metalData, "metal_id", "metal_name");
      }
    });
  }
  get f() { return this.addCustomizeOrderForm.controls; }

  createCustomizeOrderForm() {
    this.addCustomizeOrderForm = this._formBuilder.group({
      item_name: [this.CustomOrderVM.item_name, [Validators.required, Validators.maxLength(255)]],
      item_code: [this.CustomOrderVM.item_code, [Validators.required, Validators.maxLength(255)]],
      category_id: [this.CustomOrderVM.category_id, [Validators.required, Validators.maxLength(255)]],
      purity: [this.CustomOrderVM.purity, [Validators.required, Validators.maxLength(255)]],
      metal_id: [this.CustomOrderVM.metal_id, [Validators.required, Validators.maxLength(255)]],
      weight: [this.CustomOrderVM.weight, [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      item_image: [this.CustomOrderVM.item_image, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      item_note: [this.CustomOrderVM.item_note],
      my_note: [this.CustomOrderVM.item_note],
      edate: ['', [Validators.required]],
      customer: [this.CustomOrderVM.customer, [Validators.required, Validators.maxLength(255)]],

    });
  }

  gotoCustomizeOrderList() {
    this._router.navigate(['/admin/customize-items']);
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {

    this.edc_date = this.pipe.transform(event.target.value, 'dd/MM/yyyy')
  }

  save() {
    let self = this;
    self.submitted = true;

    if (self.addCustomizeOrderForm.invalid) {
      return;
    }

    let formData = new FormData();

    let siteConfigData = (self.addCustomizeOrderForm.value as CustomOrderVM);
    for (const prop in siteConfigData) {

      let fi = this.itemImageInput.nativeElement;
      if (prop == 'item_image') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.addCustomizeOrderForm.value.item_image = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], '');
          formData.append(prop, file, "");
        }
      }

      else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }

    }
    // if(this.edc_date!='')
    // {
    //   formData.append("expected_date_of_delivery",this.edc_date);
    // }
    self._commonService.showLoader();
    self._customizeService.addCustomizeOrder(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoCustomizeOrderList();
        self._commonService.showToaster("Customize order added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }


}

