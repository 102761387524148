import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { UserService } from 'src/app/services/user.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { UserVM } from 'src/app/models/UserVM';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.css']
})
export class UsersListComponent implements OnInit {

  role: any;
  permissionsData = [];
  getSiteConfig: any;
  pageSize: number = 50;
  usersData: any;
  usersData1: any;
  usersData2: any;
  noData = false;
  displayedColumns: string[] = ['first_name', 'last_name', 'display_name', 'mobile_no', 'email', 'is_active', 'created_at', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private _userService: UserService,
    private _commonService: CommonService,
    private _roleservice: RoleService,
    private _storageService: StorageService,
    private _router: Router,
    private _siteConfigService: SiteConfigService) {

  }

  ngOnInit() {
    let self = this;
    self.role = self._storageService.getUserRoles();
    this.getPermission();
    this.loadUsers();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
    });
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  editUsers(users: UserVM) {
    this._router.navigate(['/admin/users/edit', users.id]);
  }

  loadUsers() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._userService.getAllUsers()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            //self.usersData = new MatTableDataSource<UserVM>(response.data);

            self.usersData1 = response.data;
            for (let item of self.usersData1) {
              if (item.association_id == null) {
                let selectedItems = self.usersData1.filter(c => c.association_id == null);
                self.usersData = new MatTableDataSource<UserVM>(selectedItems);
              }
            }
            self.usersData.paginator = self.paginator;
            self.usersData.sort = self.sort;
            self.usersData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.noData = (response.data.length > 0) ? false : true;
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  deleteUsers(user: UserVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this user?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._userService.deleteUsers(user.id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("User deleted successfully.", "success");
          self.loadUsers();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  applyFilter(filterValue: string) {
    this.usersData.filter = filterValue.trim().toLowerCase();
    this.noData = (this.usersData.filteredData.length > 0) ? false : true;
    // this.noData = (this.usersData._paginator._length > 0) ? false : true;
  }
}
