// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2'

// @Services
import { MetalService } from 'src/app/services/metal.service';
import { CommonService } from 'src/app/services/common.service';

// @Models
import { MetalVM } from 'src/app/models/MetalVM';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-metal-list',
  templateUrl: './metal-list.component.html',
  styleUrls: ['./metal-list.component.css']
})
export class MetalListComponent implements OnInit {

  getSiteConfig: any;
  pageSize: number = 50;
  metalData: any;
  noData = false;
  role: any;
  loginUserId: any;
  permissionsData = [];
  marked = false;
  marked1 = false;
  marked2 = false;
  distributor: any;

  displayedColumns: string[] = ['metal_code', 'metal_name', 'current_price', 'unit', 'total_sold', 'total_produce', 'total_in_stock', 'is_active', 'created_at', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private metalService: MetalService,
    private _commonService: CommonService,
    private _router: Router,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _roleService: RoleService,
    private _storageService: StorageService,
    private _siteConfig: SiteConfigService) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.getUserActive();
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.loadMetal();
    this.distributor = this._storageService.getDistributorId();
  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      self.marked = self.permissionsData[7].sub_permission[1].isSelected;
      self.marked1 = self.permissionsData[7].sub_permission[2].isSelected;
      self.marked2 = self.permissionsData[7].sub_permission[3].isSelected;
      if (self.permissionsData[7].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }
  editMetal(metal: MetalVM) {
    this._router.navigate(['/admin/metal/edit', metal.metal_id]);
  }

  loadMetal() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self.metalService.getAllMetal()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.metalData = new MatTableDataSource<MetalVM>(response.data);
            self.metalData.paginator = self.paginator;
            self.metalData.sort = self.sort;
            self.metalData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.metalData.filterPredicate = (data: any, filter) => {
              const dataStr = JSON.stringify(data).toLowerCase();
              const strippedString = dataStr.replace(/(<([^>]+)>)/gi, "");
              return strippedString.indexOf(filter) != -1;
            }
            self.noData = (response.data.length > 0) ? false : true;
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }
  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }
  deleteMetal(metal: MetalVM) {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        } else {
          const options: SweetAlertOptions = {
            title: 'Are you sure you want to delete this metal?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            focusCancel: true
          };
          swal.fire(options).then((result) => {
            if (result.value) {
              self._commonService.showLoader();
              self.metalService.deleteMetal(metal.metal_id).subscribe(data => {
                self._commonService.hideLoader();
                self._commonService.showToaster("Metal deleted successfully.", "success");
                self.loadMetal();
              }, error => {
                self._commonService.hideLoader();
                self._commonService.showToaster(error.message, "error");
              });
            }
          })
        }
      });
  }
  applyFilter(filterValue: string) {
    this.metalData.filter = filterValue.trim().toLowerCase();
    this.noData = (this.metalData.filteredData.length > 0) ? false : true;
    // this.noData = (this.metalData._paginator._length > 0) ? false : true;
  }

}
