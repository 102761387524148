
export class distributorsubscriptionviewVM {
    AssociationType_Term: string
    distributor_name: string
    SubscriptionName: string
    PurchaseDate?: Date;
    expirydate?: Date;
    AmountPaid: string
    Credit: string
    TransactionRefNo: string
}