// @Packages
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

// @Services
import { AttributeService } from 'src/app/services/attribute.service';
import { CommonService } from 'src/app/services/common.service';

// @Models
import { AttributeVM } from 'src/app/models/AttributeVM';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-attribute-add',
  templateUrl: './attribute-add.component.html',
  styleUrls: ['./attribute-add.component.css']
})
export class AttributeAddComponent implements OnInit {

  addAttributeForm: FormGroup;
  AttributeVM = new AttributeVM();
  submitted = false;
  role: any;
  permissionsData = [];


  constructor(private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _storageService: StorageService,
    private _attributeService: AttributeService,
    private _roleservice: RoleService,
    private _router: Router) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.createAttributeForm();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[2].sub_permission[1].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });

  }

  createAttributeForm() {
    this.addAttributeForm = this._formBuilder.group({
      attribute_code: [this.AttributeVM.attribute_code, [Validators.required, Validators.maxLength(255)]],
      attribute_name: [this.AttributeVM.attribute_name, [Validators.required, Validators.maxLength(255)]],
      attribute_details: [this.AttributeVM.attribute_details],
      default_value: [this.AttributeVM.default_value, [Validators.required, Validators.maxLength(255)]],
      input_type_term: [this.AttributeVM.input_type_term, [Validators.required, Validators.maxLength(255)]],
      is_active: [1, Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.addAttributeForm.controls; }

  gotoAttributeList() {
    this._router.navigate(['/admin/attributes']);
  }

  save() {
    let self = this;
    self.submitted = true;

    // stop here if form is invalid
    if (self.addAttributeForm.invalid) {
      return;
    }

    self._commonService.showLoader();
    self._attributeService.addAttribute(self.addAttributeForm.value).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoAttributeList();
        self._commonService.showToaster("Attribute added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }


}
