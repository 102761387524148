import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { SalesOrderVM, SalesOrderDetailsVM } from 'src/app/models/SalesOrderVM';
import { SalesOrderService } from 'src/app/services/sales-order.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { CustomizeItemService } from 'src/app/services/customize-item.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { Lightbox } from 'ngx-lightbox';
import { CustomOrderVM } from 'src/app/models/CustomOrderVM';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { ReadMoreComponent } from 'angular-read-more';
import { RoleService } from 'src/app/services/role.service';
declare var $: any;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-customize-detail',
  templateUrl: './customize-detail.component.html',
  styleUrls: ['./customize-detail.component.css'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class CustomizeDetailComponent implements OnInit {

  id: number;
  itemDetail = new CustomOrderVM();
  submitted = false;
  role: any;
  roleType: any;
  _albums = new Array();
  show = false;
  show1 = false;

  reactiveForm: FormGroup;
  act_date: any;
  minDate;
  pipe = new DatePipe('en-US');
  permissionsData = [];

  constructor(private _salesOrderService: SalesOrderService,
    private _commonService: CommonService,
    private _customizeService: CustomizeItemService,
    private _route: ActivatedRoute,
    private _storageService: StorageService,
    private _router: Router,
    private _siteConfigService: SiteConfigService, private _lightbox: Lightbox,
    private _siteConfig: SiteConfigService,
    private _roleService: RoleService,
    private fb: FormBuilder) {
    this.minDate = new Date();
  }

  ngOnInit() {
    this.createForm();
    this.roleType = this._storageService.getUserRolesType();
    this.role = (this._storageService.getUserRoles() != null && this._storageService.getUserRoles() != '') ? this._commonService.convertToNumber(this._storageService.getUserRoles()) : 0;
    if (this.role != 1) {
      this.getPermission();
    }
    this._route.queryParams.subscribe(params => {
      this.id = params['id'];

      this.getCustomizeOrderDetail();

    });

  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[18].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }

    });

  }

  createForm() {
    this.reactiveForm = this.fb.group({
      edate: ['', Validators.required],

    });
  }
  get f() { return this.reactiveForm.controls; }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {

    this.act_date = this.pipe.transform(event.target.value, 'yyyy-MM-dd');


  }
  getCustomizeOrderDetail() {
    // this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    this._albums = [];
    self._customizeService.getCustomizeOrderDetailById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (response.item_detail != null || response.item_detail != undefined) {
          const src = this._commonService.getFullImageUrl(response.item_detail.item_image);
          const caption = '';
          const thumb = this._commonService.getFullImageUrl(response.item_detail.item_image);
          const album = {
            src: src,
            caption: caption,
            thumb: thumb
          };

          this._albums.push(album);

          self.itemDetail = response.item_detail;
        }
      });
  }
  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }


  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  goToCustomizeOrders() {
    this._router.navigate(['/admin/customize-items']);
  }
  get edate() {

    return this.reactiveForm.get('edate');
  }

  confirmCustomOrder(itemDetail) {
   
    let self = this;
    self.submitted = true;
   
    // if (self.reactiveForm.invalid) {
    //   return;
    // }
    
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to confirm this customize order ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._customizeService.confirmCustomizeOrder(itemDetail.custom_order_id, { "deliverydate": this.act_date }).subscribe((data: any) => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Customize order - " + data.order_no + " confirmed successfully.", "success");
          self.goToCustomizeOrders();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  completeCustomOrder(itemDetail) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to complete this customize order ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._customizeService.completeCustomizeOrder(itemDetail.custom_order_id).subscribe((data: any) => {
          self._commonService.hideLoader();
          self._commonService.showToaster("customize order - " + data.order_no + " completed successfully.", "success");
          self.goToCustomizeOrders();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  cancelCustomOrder(itemDetail) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to cancel this customize order ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._customizeService.cancelCustomizeOrder(itemDetail.custom_order_id).subscribe((data: any) => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Customize order - " + data.order_no + " cancel successfully.", "success");
          self.goToCustomizeOrders();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  requestCancelCustomOrder(itemDetail) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to cancel request this customize order ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._customizeService.requestToCancelCustomizeOrder(itemDetail.custom_order_id).subscribe((data: any) => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Request to cancel Customize order - " + data.order_no + " successfully.", "success");
          self.goToCustomizeOrders();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }
  alreadyrequest() {

    const options: SweetAlertOptions = {
      title: 'you have already request to cancel this order',
      type: 'warning',
    };
    Swal.fire(options).then((result) => {
    })
  }
}
