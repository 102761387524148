// @Packages
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
// @Models
import { CategoryVM } from 'src/app/models/CategoryVM';
// @Services
import { ItemService } from 'src/app/services/item.service';
import { CommonService } from 'src/app/services/common.service';
import { SubCategoryService } from 'src/app/services/subcategory.service';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.css']
})
export class ShowcaseComponent implements OnInit {

  public select2CategoryData: Array<Select2OptionData>;
  itemData: any;
  role: any;
  searchText: string;
  categoryVM = new CategoryVM();
  categorydata: CategoryVM[];
  public selectedCategories = [];
  permissionsData = [];
  itemSelected = false;

  constructor(private _itemService: ItemService,
    private _storageService: StorageService,
    private _commonService: CommonService,
    private _subcategoryService: SubCategoryService,
    private _router: Router,
    private _roleService: RoleService) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role == 1) {
      this._router.navigate(['/admin/dashboard']);
      return false;
    }
    this.getPermission();
    this.getallCategoryData();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[19].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getallCategoryData() {
    let self = this;
    self._subcategoryService.getallShowcaseCategoryData().subscribe(data => {
      let response: any = data;
      Promise.all([
        self._commonService.getAllPermission()
      ]).then((data: any) => {
        let list = data[0].data.data;
        self.permissionsData = list;
        self.itemSelected = self.permissionsData[15].sub_permission[0].isSelected
        // if (response.category_data != null) {
        //   if (self.itemSelected == false) {
        //     self._commonService.showToaster("Not Permission for Add Items", "error");
        //     self.categorydata = [];
        //   }
        // }
        // else {
        //   self.categorydata = response.category_data;
        // }
        self.categorydata = response.category_data;
      });
      // self.select2CategoryData = self._commonService.dropdownArray(self.categorydata, "category_id", "category_name");
    });
  }

  trackByFn(index, item) {
    if (!item) return null;
    return index; // or item.disaggregationID;
  }

  // getImageUrlItemGallery(item) {
  //   var image_path = '';
  //   if (item.item_gallery.length != 0) {
  //     let objItem = item.item_gallery.find(x => x.is_cover_image == "1")
  //     if (objItem == undefined) {
  //       image_path = item.thumbnail_image_path;
  //     }
  //     else {
  //       image_path = objItem.process_image_path;
  //     }
  //   }
  //   else {
  //     image_path = item.thumbnail_image_path;
  //   }

  //   return this._commonService.getFullImageUrl(image_path);
  // }

  getFullUrl(item_image) {
    return this._commonService.getFullImageUrl(item_image);
  }

  // showDetail(item: ItemVM) {
  //   let categoriesLen = this.selectedCategories.length;
  //   if(categoriesLen > 0){
  //    let fianlcats = this.selectedCategories[categoriesLen-1].join();
  //   this._router.navigate(['mainshowcase/showdetail', item.item_id], { queryParams: { source: fianlcats, itemid: item.item_id,}});
  //   }
  // }

  showItemDeatils(category: CategoryVM) {
    this._router.navigate(['mainshowcase/showitemlist', category.category_id]);
  }

  // onChangeGetitem(selectedCategory) {
  //   let self = this;
  //   self._commonService.showLoader();
  //   if (selectedCategory != '' && selectedCategory != undefined && selectedCategory != null && selectedCategory.length > 0) {
  //     self.selectedCategories.push(selectedCategory);
  //     self._itemService.getItemByCatId(selectedCategory).subscribe(data => {
  //       let varArray=JSON.parse(JSON.stringify(data)).item_data;
  //       if(varArray!=null && varArray!=undefined && varArray.length > 0){
  //         self.itemData =  varArray.sort(function(a, b) {return b.total_order_received - a.total_order_received});
  //       }
  //       else{
  //         self.itemData = [];
  //       }
  //       self._commonService.hideLoader();
  //     });
  //   }
  //   else {
  //     const options: SweetAlertOptions = {
  //       title: 'Information',
  //       html: "Please Select Category for item filteration.",
  //       confirmButtonText: "Close"
  //     };
  //     swal.fire(options);
  //     self._commonService.hideLoader();
  //   }
  // }

  placeSalesOrder(item) {
    
    this._router.navigate(['/admin/sales-orders/new/', item.item_id]);
    
  }

  // getRxcui(event){
  //   // var data  = event.target.value.find(event.target.value === this.categorydata);
  //   let data = this.categorydata.find(event.target.value);
  // }
  // searchCategories(search){
  //   let self = this;
  //   self._subcategoryService.getallShowcaseCategoryData(search.searchText).subscribe(data => {
  //     let response: any = data;
  //     if (response.category_data != null) {
  //       self.categorydata = response.category_data;
  //       // self.select2CategoryData = self._commonService.dropdownArray(self.categorydata, "category_id", "category_name");
  //     }
  //   });
  // }

  addItemShowCase() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      self.itemSelected = self.permissionsData[15].sub_permission[0].isSelected
      if (self.itemSelected == false) {
        self._commonService.showToaster("Not Permission for Add Items", "error");
      }
      else {
        /*------------------- Local and qa Url--------------------- */
       let url = "/admin/items"
        /*------------------- Live Url--------------------- */
         //let url = "/avsar/admin/items"
        window.open(url, '_blank');
      }
    });
  }
}
