import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomOrderVM } from 'src/app/models/CustomOrderVM';
import { CommonService } from 'src/app/services/common.service';
import { CustomizeItemService } from 'src/app/services/customize-item.service';
import { RoleService } from 'src/app/services/role.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-customize-items-view',
  templateUrl: './customize-items-view.component.html',
  styleUrls: ['./customize-items-view.component.css']
})
export class CustomizeItemsViewComponent implements OnInit {
  customizeOrderData: any;
  noData = false;
  role: any;
  roleType: any;
  displayedColumns: string[];
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  filterValue: '';
  value: '';
  permissionsData = [];
  marked = false;
  dateselect?: any;
  availableItem?: any;
  columnSorting: any;
  columnDirection: any;
  applyseacrhCustomizeOrderForm: FormGroup;
  search: { orderDate: any; deliveryDate: any; selectStatus: any; };
  orderdate: { start: string; end: string; };
  deliverydate: { start: string; end: string; };
  public pageNumber: number;
  public paginationValue: number;

  onScroll() {
    if (this.customizeOrderData.length < this.customizeOrderData[0].totalcount) {
      let previousPageIndex = this.pageNumber;
      this.pageNumber++;
      this.paginationValue = this.pageNumber * this.pageSize;
      var event: any = {
        "previousPageIndex": previousPageIndex,
        "pageIndex": this.pageNumber,
        "pageSize": this.pageSize,
        "length": this.customizeOrderData[0].totalcount,
      }      
      this.getAllCustomizeOrders(event);
    }
  }
  constructor( private _commonService: CommonService,
    private _customizeService: CustomizeItemService,
    private _router: Router,
    private _storageService: StorageService,
    private _roleService: RoleService,
    private _siteConfig: SiteConfigService,
    private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      this.pageNumber = 1;
      if (response.data != null) {
        this.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.getAllCustomizeOrders(event);
      }
    });
  }
  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }
  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  viewCustomizeOrderDetail(orderdetail: CustomOrderVM) {
    this._router.navigate(['/admin/customize-order-detail', orderdetail.custom_order_id], { queryParams: { id: orderdetail.custom_order_id } });
  }

  getAllCustomizeOrders(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._customizeService.getAllCustomizeOrders(event, this.value, this.dateselect, this.availableItem, this.search, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            //let response: any = data;
            //self.customizeOrderData = response.data as CustomOrderVM;
            let response: any = data;
            if (response.data != undefined && response.data.length > 0) {
              if (self.customizeOrderData != undefined) {
                response.data.forEach(element => {
                  const itemExist = self.customizeOrderData.find(i => i.custom_order_id == element.custom_order_id);
                  if (!itemExist) {
                    self.customizeOrderData.push(element);
                  }
                });
              } else {
                self.customizeOrderData = response.data
              }
            }
    
            if (response.data.length > 0) {
              self.totalLength = response.data[0].totalcount;
            }
            self.noData = (response.data.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllCustomizeOrders(event);
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }
  trackByFn(index, item) {
    if (!item) return null;
    return index; // or item.disaggregationID;
  }
}
