//packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
//services
import { DistributorService } from 'src/app/services/distributor.service';
import { CommonService } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { ItemService } from 'src/app/services/item.service';
import { DistributorSubscriptionService } from 'src/app/services/distributorsubscription.service';
//model
import { ItemVM } from 'src/app/models/ItemVM';
import { DistributorVM } from 'src/app/models/DistributorVM';
import { distributorviewVM } from 'src/app/models/distributorviewVM';
import { distributorsubscriptionviewVM } from 'src/app/models/distributorsubscriptionviewVM';
import { SubscriptionVM, UserSubscriptionVM } from 'src/app/models/SubscriptionVM';
import { SubscriptionService } from 'src/app/services/subscriprion.service';

@Component({
  selector: 'app-distributor-view',
  templateUrl: './distributor-view.component.html',
  styleUrls: ['./distributor-view.component.css']
})
export class DistributorViewComponent implements OnInit {

  id: number;
  DistributorDetail = new DistributorVM();
  DistributorVM = new DistributorVM();
  UserSubscriptionVM = new UserSubscriptionVM();
  SubscriptionVM = new SubscriptionVM();
  DistributorDetailUser = new distributorviewVM();
  DistributorSubscriptionVM = new distributorsubscriptionviewVM();
  DistributoritemData: any;
  DistributorDetailCity = new distributorviewVM();
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  noData = false;
  filterValue: '';
  value: '';
  DistributorDetailItem: DistributorVM[] = new Array();
  displayedColumns: string[] = ['item_name', 'code', 'group_name', 'purity', 'is_active', 'status', 'created_at', 'actions'];
  columnSorting: any;
  columnDirection: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private distributorService: DistributorService,
    private distributorsubscriptionService: DistributorSubscriptionService,
    private _commonService: CommonService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _siteConfigService: SiteConfigService, private _lightbox: Lightbox,
    private _subscriptionService: SubscriptionService) { }

  ngOnInit() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.getDistributorDetailById(event);
        this.getUserSubscriptionById();
      }
    });
  }

  // getSiteConfigData() {
  //   let self = this;
  //   self._siteConfigService.getSiteConfigData().subscribe(data => {
  //     let response: any = data;
  //     if (response.data != null) {
  //       self.pageSize = response.data.default_row_perpage_term;
  //     }
  //   });
  // }

  getUserSubscriptionById() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._subscriptionService.getDistSubscriptionById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.UserSubscriptionVM = response.data;

      });
  }


  getDistributorDetailById(event) {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self.distributorService.getDistributorDetailpById(self.id, event, this.value, this.columnSorting, this.columnDirection)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.DistributorDetail = response.distributor_detail;
        self.DistributorDetailCity = response.distributor_detail.city;
        self.DistributorDetailUser = response.distributor_detail.user;
        self.DistributorDetailItem = response.distributor_detail.item_detail;
        self.DistributoritemData = new MatTableDataSource<ItemVM>(response.distributor_detail.item_detail);
        if (response.distributor_detail.item_detail != null && response.distributor_detail.item_detail.length > 0) {
          self.totalLength = response.distributor_detail.item_total_count;
        }
        self.noData = (response.distributor_detail.item_detail != null && response.distributor_detail.item_detail.length > 0) ? false : true;
        if (self.noData == true) {
          self.totalLength = 0;
        }
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }

  getdistributorsubscriptionById() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self.distributorsubscriptionService.getdistributorsubscriptionById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.DistributorSubscriptionVM = response.data;
      });
    /* response.distributor*/
  }

  viewItem(item: ItemVM) {
    this._router.navigate(['/admin/items/detail/', item.item_id], { queryParams: { id: item.item_id } });
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getDistributorDetailById(event);
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getDistributorDetailById(event);
  }
}
