import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

// @Services
import { HttpService } from './http.service';
import { AppConfig } from '../shared/app-config';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ItemGroupService {
  apiUrl: String;
  // set http Authorization headers
  httpAuthOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };

  constructor(private _httpService: HttpService,
    private _api: AppConfig) {
    this.apiUrl = this._api.ApiUrl;
  }

  getAllItemGroups() {
    return this._httpService.post(`${this.apiUrl}item-groups/getAllItemGroups`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  addItemGroup(itemGroup) {
    return this._httpService.post(`${this.apiUrl}item-groups/addItemGroup`, itemGroup, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getItemGroupById(id) {
    return this._httpService.get(`${this.apiUrl}item-groups/getItemGroupById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  updateItemGroup(itemGroup) {
    return this._httpService.post(`${this.apiUrl}item-groups/updateItemGroup`, itemGroup, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  deleteItemGroup(id) {
    return this._httpService.post(`${this.apiUrl}item-groups/deleteItemGroup/${id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllItemGroupData() {
    return this._httpService.get(`${this.apiUrl}getAllItemGroupData`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }


}
