import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public profileImageChange: EventEmitter<boolean> = new EventEmitter();
  public displayNameChange: EventEmitter<boolean> = new EventEmitter();
  public cartItemChange: EventEmitter<boolean> = new EventEmitter();
  public siteLogoChange: EventEmitter<boolean> = new EventEmitter();
  public siteNameChange: EventEmitter<boolean> = new EventEmitter();
  public siteDateFormatTerm: EventEmitter<boolean> = new EventEmitter();
  public siteBillerLogoChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  setCredentials(authResult: any): void {

    // Save session data and update login status subject
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + Date.now());

    localStorage.setItem('access_token', authResult.access_Token);
    localStorage.setItem('expires_at', expiresAt);
    localStorage.setItem('expires_in', authResult.expiresIn);
    //set users data.
    localStorage.setItem("userid", (authResult.user.id == null || authResult.user.id == undefined) ? "" : authResult.user.id);
    localStorage.setItem("first_name", authResult.user.first_name);
    localStorage.setItem("last_name", authResult.user.last_name);
    localStorage.setItem("display_name", authResult.user.display_name);
    localStorage.setItem("roles", authResult.user.role);
    localStorage.setItem("role_type", authResult.user.role_type);
    localStorage.setItem("userRole_display_name", authResult.userRole.display_name);
    localStorage.setItem("distributor_id", authResult.user.distributer_id);
    localStorage.setItem("useremail", authResult.user.email);
    localStorage.setItem("is_active", authResult.user.is_active);
    localStorage.setItem("profile_pic_path", authResult.user.profile_pic_path);
    localStorage.setItem("siteConfigDateFormatTerm", authResult.siteConfig.date_format_term);
    localStorage.setItem("siteConfigsiteName", authResult.siteConfig.site_name);
    localStorage.setItem("siteConfigsiteLogo", authResult.siteConfig.site_logo);
    localStorage.setItem("siteConfigbillerLogo", authResult.siteConfig.biller_logo);
    localStorage.setItem("assignItem", "");
    localStorage.setItem("subscriptionItem", "");

  }

  setItemList(Follow_list) {
    localStorage.setItem("assignItem", Follow_list);
  }

  setSubscriptionItemList(Follow_list) {
    localStorage.setItem("subscriptionItem", Follow_list);
  }

  setProfileImg(profileImage: string) {
    localStorage.setItem('profile_pic_path', profileImage);
    this.profileImageChange.emit(true);
  }

  setDisplayName(display_name: string) {
    localStorage.setItem('display_name', display_name);
    this.displayNameChange.emit(true);
  }

  setSiteConfigsiteLogo(siteLogo: string) {
    localStorage.setItem('siteConfigsiteLogo', siteLogo);
    this.siteLogoChange.emit(true);
  }

  setSiteConfigsiteName(siteName: string) {
    localStorage.setItem('siteConfigsiteName', siteName);
    this.siteNameChange.emit(true);
  }

  setSiteConfigDateFormatTerm(date_format_term: string) {
    localStorage.setItem('siteConfigDateFormatTerm', date_format_term);
    this.siteDateFormatTerm.emit(true);
  }

  setSiteConfigbillerLogo(siteConfigbillerLogo: string) {
    localStorage.setItem('siteConfigbillerLogo', siteConfigbillerLogo);
    this.siteBillerLogoChange.emit(true);
  }

  getsiteConfigDateFormatTerm(): string {
    return localStorage.getItem('siteConfigDateFormatTerm');
  }

  getsiteConfigsiteName(): string {
    return localStorage.getItem('siteConfigsiteName');
  }

  getsiteConfigsiteLogo(): string {
    return localStorage.getItem('siteConfigsiteLogo');
  }

  getsiteConfigbillerLogo(): string {
    return localStorage.getItem('siteConfigbillerLogo');
  }

  getTokenExpireAt(): string {
    return localStorage.getItem('expires_at');
  }

  getToken(): string {
    return localStorage.getItem('access_token');
  }

  getUserId(): string {
    return localStorage.getItem('userid');
  }

  getUserFirstName(): string {
    return localStorage.getItem('first_name');
  }

  getUserLastName(): string {
    return localStorage.getItem('last_name');
  }

  getProfileImage(): string {
    return localStorage.getItem('profile_pic_path');
  }

  getDisplayName(): string {
    return localStorage.getItem('display_name');
  }

  getUserRoles(): any {
    return localStorage.getItem('roles');
  }


  getUserRolesType(): any {
    return localStorage.getItem('role_type');
  }

  getUserRolesName(): any {
    return localStorage.getItem('userRole_display_name');
  }

  getUserEmail(): string {
    return localStorage.getItem('useremail');
  }
  getDistributorId(): string {
    return localStorage.getItem('distributor_id');
  }
  getActiveUser(): string {
    return localStorage.getItem('is_active');
  }

  getAssignItem(): any {
    var temp = localStorage.getItem('assignItem');
    if (temp.length > 0) {
      var assign = temp.split(',');
      return assign;
    } else {
      return [];
    }

  }

  getSubscriptionAssignItem(): any {
    var temp = localStorage.getItem('subscriptionItem');
    if (temp.length > 0) {
      var assign = temp.split(',');
      return assign;
    } else {
      return [];
    }
  }

  clearCredentials(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('userid');
    localStorage.removeItem('first_name');
    localStorage.removeItem('last_name');
    localStorage.removeItem('roles');
    localStorage.removeItem('useremail');
  }

  clearAssignItem(): void {
    localStorage.setItem("assignItem", "");

  }

  clearSubscriptionAssignItem(): void {
    localStorage.setItem("subscriptionItem", "");

  }

}
