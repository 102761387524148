import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { TermsService } from 'src/app/services/terms.service';
import { Router } from '@angular/router';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { TermsVM } from 'src/app/models/TermsVM';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-terms-list',
  templateUrl: './terms-list.component.html',
  styleUrls: ['./terms-list.component.css']
})
export class TermsListComponent implements OnInit {

  constructor(private _termsService: TermsService,
    private _commonService: CommonService,
    private _roleService: RoleService,
    private _storageService: StorageService,
    private _router: Router,
    private _siteConfigService: SiteConfigService) {

  }


  getSiteConfig: any;
  pageSize: number = 50;
  termsData: any;
  noData = false;
  role: any;
  permissionsData = [];
  displayedColumns: string[] = ['term_name', 'term_code', 'term_icon', 'back_color', 'for_color', 'ideal_value', 'hand_code_rule', 'is_default', 'is_active', 'created_at', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.getPermission();
    this.loadTerms();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
    });

  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadTerms() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._termsService.getAllTermsData()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.termsData = new MatTableDataSource<TermsVM>(response.data);
            self.termsData.paginator = self.paginator;
            self.termsData.sort = self.sort;
            self.termsData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.noData = (response.data.length > 0) ? false : true;
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  editTerm(company: TermsVM) {
    this._router.navigate(['/admin/terms/edit', company.term_id]);
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  deleteTerm(company: TermsVM) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this term?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    Swal.fire(options).then((result) => {
      if (result.value) {

        self._commonService.showLoader();
        self._termsService.deleteTerm(company.term_id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Terms deleted successfully.", "success");
          self.loadTerms();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }
  applyFilter(filterValue: string) {
    this.termsData.filter = filterValue.trim().toLowerCase();
    this.noData = (this.termsData._paginator._length > 0) ? false : true;
  }
}
