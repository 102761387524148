export class DistributorGroupVM {
  distributor_group_id: number
  group_code: string
  group_name: string
  group_details: string
  is_active: number
  update_log?: string
  created_at?: string
  updated_at?: string
}
