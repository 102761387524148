import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { ItemService } from 'src/app/services/item.service';
import { ItemVM } from 'src/app/models/ItemVM';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { SalesOrderVM } from 'src/app/models/SalesOrderVM';
import { DatePipe } from '@angular/common';
import { SalesOrderService } from 'src/app/services/sales-order.service';
import { NotificationService } from 'src/app/services/notification.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { OnDestroy } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
declare var $: any;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-edit-cart-item',
  templateUrl: './edit-cart-item.component.html',
  styleUrls: ['./edit-cart-item.component.css'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class EditCartItemComponent implements OnInit {

  itemId: number;
  itemDetail: any;
  salesOrder = new SalesOrderVM();
  salesOrderForm: FormGroup;
  orderFormData = new FormData();
  submitted = false;
  show = false;
  mySimpleFormat: any;
  pipe = new DatePipe('en-US');
  itemid: any;
  catid: any;
  isRecording = false;
  recordedTime;
  blobUrl;
  audioSource;
  blob: Blob;
  minDate;

  constructor(private _itemService: ItemService,
    private _salesOrderService: SalesOrderService,
    private _storageService: StorageService,
    private _commonService: CommonService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _datePipe: DatePipe,
    private _notificationService: NotificationService,

    private sanitizer: DomSanitizer) {
    this.minDate = new Date();
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.catid = params['id'];

    });
    this.initialFormValue();
    this.buildForm();

  }

  initialFormValue() {
    let self = this;
    self._commonService.showLoader();
    self._route.params.subscribe(params => { self.itemId = this.catid; });
    Promise.all([
      self.getItemDataById()
    ]).then((data: any) => {
      if (data != null && data != undefined) {
        self._commonService.hideLoader();
        let response = data[0];
        if (!self._commonService.isNullOrEmpty(response.data.cart_item)) {
          self.itemDetail = response.data.cart_item;


          self.buildForm();
          // let optionControl = self.salesOrderForm.controls.sales_order_items as FormArray;
          // if (optionControl.length > 0) {
          //   let controlindex = <FormGroup>optionControl.controls[0];
          //   let value = controlindex.controls.metal_id.setValue(this.itemDetail.metal_name);
          //   console.log(controlindex);
          //   console.log('1',value);
          // }

        }

      }
    });
  }

  get f() { return this.salesOrderForm.controls; }

  buildForm() {
    let self = this;
    let currentDate = new Date();
    this.salesOrder.order_date = this._commonService.parseDate(currentDate.toDateString());

    self.salesOrderForm = this._formBuilder.group({
      sales_order_items: this._formBuilder.array([this._formBuilder.group({
        item_id: [this.itemId],
        item_name: [self.itemDetail.item_name],
        hiddenweight: [self.itemDetail.weight],
        weight: [self.itemDetail.weight],

        metal_id: [self.itemDetail.metal_name],
        purity: [self.itemDetail.purity],
        total: [self.itemDetail.total_quantity, [Validators.required]],
        item_note: [self.itemDetail.item_note],
        total_weight: [self.itemDetail.item_total_weight],
      })])
    });
  }

  get itemForms() {
    return this.salesOrderForm.get('sales_order_items') as FormArray;
  }

  getItemDataById() {
    return new Promise((resolve, reject) => {
      let self = this;
      self._itemService.editCartItemById(self.itemId).subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  updateWeight(index: number) {
    let self = this;
    let control = self.itemForms.controls[index];
    let qty = control.get('total').value;

    let totalWeight = 0;
    let totalQty = self._commonService.convertToNumber(qty);
    var netweight = control.get('hiddenweight').value;

    var weight = control.get('weight').value;

    // if(parseFloat(weight) < parseFloat(netweight))
    // {
    //   self._commonService.showToaster("weight should be maximum of approx net weight", "error");
    //   //  return false;

    // }


    let netWeight: any = self._commonService.formatNumberWithDecimal(self.itemDetail.net_weight, 4);
    //totalWeight = totalQty * netWeight;
    totalWeight = totalQty * weight;
    var tweight = self._commonService.formatNumberWithDecimal(totalWeight, 2);
    control.get('total_weight').setValue(tweight);
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {

    this.mySimpleFormat = this.pipe.transform(event.target.value, 'dd/MM/yyyy')
  }
  saveOrder() {
    let self = this;
    self.submitted = true;
    if (self.salesOrderForm.invalid) {
      return;
    }

    let basicFormData = (self.salesOrderForm.value);

    for (const prop in basicFormData) {
      self.orderFormData.delete(prop);
      self.orderFormData.append(prop, basicFormData[prop]);
      if (prop == 'expected_date_of_delivery') {
        // let expected_date_of_delivery = this._datePipe.transform(basicFormData[prop], 'yyyy-MM-dd');
        // self.orderFormData.append(prop, expected_date_of_delivery);
      }
      if (prop == 'sales_order_items') {
        let itemFormData = (self.salesOrderForm.value.sales_order_items);
        self.orderFormData.delete('sales_order_items');

        self.orderFormData.append('sales_order_items', JSON.stringify(itemFormData));
        // self.orderFormData.append('recordVoice', this.audioSource);
      }
    }

    // if (this.itemDetail.status == 3) {
    //   self.orderFormData.append("is_request_order", "1")
    // } else {
    //   self.orderFormData.append("is_request_order", "0")
    // }

    self.orderFormData.append("category_id", this.catid);

    self._commonService.showLoader();

    self._salesOrderService.updateCartItem(self.orderFormData).subscribe((data: any) => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self._commonService.showToaster("Item updated successfully.", "success");
        self._notificationService.updateNotification(true);
        self.gotoSalesOrdersList();
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });

  }

  gotoSalesOrdersList() {
    this._router.navigate(['/admin/my-sales-orders']);
  }

}

