import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { RetailerVM } from 'src/app/models/RetailerVM';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { RetailerService } from 'src/app/services/retailer.service';
import { RoleService } from 'src/app/services/role.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { RetailerPasswordComponent } from '../retailer-password/retailer-password.component';

@Component({
  selector: 'app-retailer-list',
  templateUrl: './retailer-list.component.html',
  styleUrls: ['./retailer-list.component.css']
})
export class RetailerListComponent implements OnInit {

  role: any;
  roleType: any;
  permissionsData = [];
  retailersData: any;
  noData = false;
  marked = false;
  marked1 = false;
  marked2 = false;
  columnSorting: any;
  columnDirection: any
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  value: '';
  displayedColumns: string[];
  distributor: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(
    private _commonService: CommonService,
    private _router: Router,
    private _auth: AuthService,
    private _siteConfigService: SiteConfigService,
    private _roleService: RoleService,
    private _storageService: StorageService,
    private _retailerService: RetailerService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();
    if (this.role != 1) {
      this.getPermission();
    }
    this.distributor = this._commonService.convertToNumber(this._storageService.getDistributorId());
    if (this.roleType == 1) {
      this.displayedColumns = ['display_name', 'RetailerName', 'ContactName', 'email', 'RetailerLogo', 'IsActive', 'assign_item_count', 'created_at', 'actions'];
    } else {
      this.displayedColumns = ['display_name', 'RetailerName', 'ContactName', 'email', 'RetailerLogo', 'IsActive', 'assign_item_count', 'RetailerStatus_Term', 'created_at', 'actions'];
    }

    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.getAllRetailer(event);
      }
    });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      //check permission
      self.marked = self.permissionsData[24].sub_permission[1].isSelected;
      self.marked1 = self.permissionsData[24].sub_permission[2].isSelected;
      self.marked2 = self.permissionsData[24].sub_permission[3].isSelected;
      if (self.permissionsData[24].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }


  getAllRetailer(event) {
    let self = this;
    self._commonService.showLoader();
    self._retailerService.getAllRetailer(event, this.value, this.columnSorting, this.columnDirection)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.retailersData = new MatTableDataSource<RetailerVM>(response.data);
        if (response.data.length > 0) {
          self.totalLength = response.data[0].totalcount;
        }
        self.noData = (response.data.length > 0) ? false : true;
        if (self.noData == true) {
          self.totalLength = 0;
        }
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }


  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  editRetailer(retailerGroup: RetailerVM) {
    this._router.navigate(['/admin/retailer/edit', retailerGroup.RetailerId]);
  }

  viewRetailer(retailer: RetailerVM) {
    this._router.navigate(['/admin/retailer/view/', retailer.RetailerId], { queryParams: { id: retailer.RetailerId } });
  }

  deleteRetailer(item: RetailerVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this Retailer ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._retailerService.deleteRetailer(item.RetailerId).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Retailer deleted successfully.", "success");
          var event: any = {
            "previousPageIndex": '0',
            "pageIndex": '0',
            "pageSize": this.pageSize,
            "length": '0',
          }
          self.getAllRetailer(event);
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllRetailer(event);
  }


  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllRetailer(event);
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  openPasswordDialog(retailerData: RetailerVM) {
    const dialogRef = this.dialog.open(RetailerPasswordComponent, {
      width: '50%',
      data: { dialogTitle: 'Change Password', RetailerId: retailerData.RetailerId, DistributerId: retailerData.DistributerId },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
