import { ItemVM } from './ItemVM'

export class ItemGroupVM {
    item_group_id: number
    group_code: string
    ref_group_id: number
    group_name: string
    item_type_term: string
    is_active: number
    update_log?: string
    created_at?: string
    updated_at?: string
    items_count?: string
    items?: ItemVM[]
    distributor_id: number
}
