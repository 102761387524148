import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PurityVM } from 'src/app/models/PurityVM';
import { CommonService } from 'src/app/services/common.service';
import { REGEXP } from 'src/app/shared/regexp';
import { Route, Router } from '@angular/router';
import { PurityService } from 'src/app/services/purity.service';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-purity-add',
  templateUrl: './purity-add.component.html',
  styleUrls: ['./purity-add.component.css']
})
export class PurityAddComponent implements OnInit {

  PurityForm: FormGroup;
  PurityVM = new PurityVM();
  submitted = false;
  role: number;
  permissionsData = [];
  constructor(private _formBuilder: FormBuilder,
    private _purityService: PurityService,
    private _commonService: CommonService,
    private _regex: REGEXP,
    private _router: Router,
    private _roleService: RoleService,
    private _storageService: StorageService
  ) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.createPurityForm();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[22].sub_permission[1].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  get f() { return this.PurityForm.controls; }

  createPurityForm() {
    this.PurityForm = this._formBuilder.group({
      purity: [this.PurityVM.purity, [Validators.required]],
      is_active: [1, Validators.required],
    });
  }

  gotoPurityList() {
    this._router.navigate(['/admin/purity']);
  }

  save() {
    let self = this;
    self.submitted = true;

    if (self.PurityForm.invalid) {
      return;
    }
    self._commonService.showLoader();
    self._purityService.addPurity(self.PurityForm.value).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoPurityList();
        self._commonService.showToaster("Purity added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

}
