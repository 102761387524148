// @Packages
import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

//@Models
import { LoginVM } from 'src/app/models/LoginVM';

// @Services
import { REGEXP } from 'src/app/shared/regexp';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginLogoUrl: string;
  loginForm: FormGroup;
  submitted = false;
  loginButtonText = "Login";
  role: number;
  roleType: number;

  loginVM = new LoginVM();
  roleName: any;

  constructor(private _router: Router,
    private _formBuilder: FormBuilder,
    private _regex: REGEXP,
    private _auth: AuthService,
    private _commonService: CommonService,
    private _storageService: StorageService,
    private _siteConfigService: SiteConfigService,
  ) {
  }

  ngOnInit() {
    this.buildForm();
    if (this._auth.isAuthenticated()) {
      this._router.navigate(['/admin/dashboard']);
      return false;
    }
    this.getSiteConfigData();
  }

  buildForm() {
    this.loginForm = this._formBuilder.group({
      email: [this.loginVM.email, [Validators.required, Validators.pattern(this._regex.EMAIL_REGEXP)]],
      password: [this.loginVM.password, Validators.required],
    });
  }

  // get all form controls
  get f() { return this.loginForm.controls; }

  login() {
    let self = this;
    self.submitted = true;
    // stop here if form is invalid
    if (self.loginForm.invalid) {
      return;
    }

    self.loginButtonText = "Processing...";
    self._commonService.showLoader();
    let credentials = self.loginForm.value;

    self._auth.login(credentials).subscribe(data => {
      self.loginButtonText = "Login";
      if (!self._commonService.isNullOrEmpty(data)) {
        self._commonService.hideLoader();
        //Store session details.
        self._auth.setSession(data.success);
        this.role = this._storageService.getUserRoles();
        this.roleType = this._storageService.getUserRolesType();
        this.roleName = this._storageService.getUserRolesName();
        // let defaultPageUrl = self._storageService.getDefaultPageUrl();
        // if (!self._commonService.isNullOrEmpty(defaultPageUrl)) {
        //   window.location.href = self._commonService.removePrefixChar(defaultPageUrl, "/");
        // } else {
        // Go to Dashboard page.
        if (this.roleType == 2) {
          window.location.href = "mainshowcase/showcase";
        } else {
          window.location.href = "admin/dashboard";
        }
        // }
        // window.location.href = "admin/dashboard";
        // this._router.navigate(['/admin']);       
      }
    }, error => {
      self._commonService.hideLoader();
      self.loginButtonText = "Login";
      self._commonService.showToasterTopCenter(error.message, "error");
    });
  }

  keyDownLogin(event) {
    if (event.keyCode == 13) {
      this.login();
    }
  }

  getSiteConfigData() {
    let self = this;
    this._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any;
      response = data;
      let siteConfigData = response.data;
      self.loginLogoUrl = self._commonService.getFullImageUrl(siteConfigData.login_logo);
    });
  }
}
