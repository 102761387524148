import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

// @Services
import { HttpService } from './http.service';
import { AppConfig } from '../shared/app-config';
import { map, catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class RetailerService {
    apiUrl: String;
    // set http Authorization headers
    httpAuthOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        })
    };

    constructor(private _httpService: HttpService,
        private _storageService: StorageService,
        private _api: AppConfig) {
        this.apiUrl = this._api.ApiUrl;
    }

    getAllRetailer(event, filterValue, columnSorting, columnDirection) {
        var body = {
            "event": event,
            "filterValue": filterValue,
            "columnSorting": columnSorting,
            "columnDirection": columnDirection
        }
        return this._httpService.post(`${this.apiUrl}retailer/getAllRetailer`, body, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    deleteRetailer(id) {
        return this._httpService.get(`${this.apiUrl}retailer/retailerDelete/${id}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getAllActiveDistributor() {
        return this._httpService.post(`${this.apiUrl}distributor/getAllDistributorData`, '', this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    addRetailer(retailer) {
        return this._httpService.post(`${this.apiUrl}retailer/add`, retailer, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getRetailerById(id) {
        return this._httpService.get(`${this.apiUrl}retailer/getRetailerById/${id}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    updateRetailer(retailer) {
        return this._httpService.post(`${this.apiUrl}retailer/retailerUpdate`, retailer, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getAllDistributorRetailer(event, filterValue, columnSorting, columnDirection, search) {
        var body = {
            "event": event,
            "filterValue": filterValue,
            "columnSorting": columnSorting,
            "columnDirection": columnDirection,
            "search": search,
        }
        return this._httpService.post(`${this.apiUrl}retailer/getAllDistributorRetailerList`, body, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    deleteDistributorRetailer(id) {
        return this._httpService.get(`${this.apiUrl}retailer/retailerDelete/${id}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    approveItem(itemId) {
        return this._httpService.get(`${this.apiUrl}retailer/retailerApprove/${itemId}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    rejectItem(itemId) {
        return this._httpService.get(`${this.apiUrl}retailer/retailerReject/${itemId}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    changePassword(data) {
        return this._httpService.post(`${this.apiUrl}retailer/changeRetailerPassword`, data, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    retailerAssignItemList(id) {
        return this._httpService.get(`${this.apiUrl}retailer/retailerAssignItemList/${id}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    newAddedItem(id, offset, limit, values, values1, values2) {

        var body = {
            "offset": offset,
            "limit": limit,
            "values": values,
            "values1": values1,
            "values2": values2

        }

        // if (id == '') {
        return this._httpService.post(`${this.apiUrl}items/newAddedItem`, body, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
        // } else {
        //     return this._httpService.post(`${this.apiUrl}items/newEditItem/${id}`, body, this.httpAuthOptions)
        //         .pipe(
        //             map((response: any) => {
        //                 return this._httpService.extractData(response);
        //             }), catchError((errorRes: any) => {
        //                 return this._httpService.handleError(errorRes);
        //             })
        //         );
        // }


    }

    getAssignItemToRetailerById(id, offset, limit, values, dis_id, values1, values2) {

        var body = {
            "offset": offset,
            "limit": limit,
            "values": values,
            "dis_id": dis_id,
            "values1": values1,
            "values2": values2
        }
        return this._httpService.post(`${this.apiUrl}items/getAssignItemById/${id}`, body, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    editAssignItem(id, Follow_list) {

        var body = {
            "Follow_list": Follow_list
        }
        return this._httpService.post(`${this.apiUrl}retailer/editRetailerAssignItem/${id}`, body, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getSelectedRetailerItemCatId(id, dis_id, value, values1, values2) {

        var body = {
            "dis_id": dis_id,
            "value": value,
            "values1": values1,
            "values2": values2,
        }
        return this._httpService.post(`${this.apiUrl}items/getSelectedItemAddCatId/${id}`, body, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getRetailerItems(id,event, filterValue, columnSorting, columnDirection) {
        var body = {
            "id":id,
            "event": event,
            "value": filterValue,
            "columnSorting": columnSorting,
            "columnDirection": columnDirection
        }
        return this._httpService.post(`${this.apiUrl}retailer/getRetailerItems`, body, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

  
}
