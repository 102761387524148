// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
// @Models
import { UserVM } from 'src/app/models/UserVM';
import { RoleVM } from 'src/app/models/RoleVM';
// @Services
import { REGEXP } from 'src/app/shared/regexp';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.css']
})
export class UsersEditComponent implements OnInit {

  @ViewChild("profile_pic_path", { static: false }) userPhotoFileInput;
  id: number;
  profile_pic_url: string;
  UsersForm: FormGroup;
  UserVM = new UserVM();
  roleData: RoleVM[];
  submitted = false;
  public select2Options: Options;
  public select2RolesData: Array<Select2OptionData>;
  constructor(
    private _formBuilder: FormBuilder,
    private _regex: REGEXP,
    private _commonService: CommonService,
    private _usersService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) { }


  ngOnInit() {
    this.buildForm();
    Promise.all([
      this.getAllRoles()
    ]).then((data: any) => {
      this.getSingleUser();
    });
  }

  getAllRoles() {
    let self = this;
    self._usersService.getAllActiveRoles().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.roleData = response.data;
        self.select2RolesData = self._commonService.dropdownArray(self.roleData, "id", "name");
      }
    });
  }

  get f() { return this.UsersForm.controls; }

  gotoUsersList() {
    this._router.navigate(['/admin/users']);
  }

  getSingleUser() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._usersService.getUserById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.UserVM = response.user;
        self.buildForm();
      });
  }

  buildForm() {
    let roleid = (this.UserVM.role) ? this.UserVM.role.id : '';
    let UsersFormGroup = {
      id: [this._commonService.convertToNumber(this.UserVM.id)],
      first_name: [this.UserVM.first_name, [Validators.required, Validators.maxLength(255)]],
      last_name: [this.UserVM.last_name, [Validators.required, Validators.maxLength(255)]],
      user_detail: [this.UserVM.user_detail],
      display_name: [this.UserVM.display_name, [Validators.required, Validators.maxLength(255)]],
      email: [this.UserVM.email, [Validators.required, Validators.maxLength(255), Validators.pattern(this._regex.EMAIL_REGEXP)]],
      mobile_no: [this.UserVM.mobile_no, [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
      is_active: [this.UserVM.is_active, Validators.required],
      gender: [this._commonService.convertToNumber(this.UserVM.gender), Validators.required],
      role_id: [this._commonService.convertToNumber(roleid), Validators.required]
    };

    if (this._commonService.isNullOrEmpty(this.UserVM.profile_pic_path)) {
      UsersFormGroup['profile_pic_path'] = [this.UserVM.profile_pic_path, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
    } else {
      UsersFormGroup['profile_pic_path'] = [this.UserVM.profile_pic_path, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
      this.profile_pic_url = this._commonService.getFullImageUrl(this.UserVM.profile_pic_path);
    }
    this.UsersForm = this._formBuilder.group(UsersFormGroup);
  }

  save() {
    let self = this;
    self.submitted = true;
    if (self.UsersForm.invalid) {
      return;
    }
    let formData = new FormData();
    let siteConfigData = (self.UsersForm.value as UserVM);
    for (const prop in siteConfigData) {
      let fi = this.userPhotoFileInput.nativeElement;
      if (prop == 'profile_pic_path') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.UsersForm.value.profile_pic_path = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }
    }
    self._commonService.showLoader();
    self._usersService.updateUser(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoUsersList();
        self._commonService.showToaster("User updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }
}
