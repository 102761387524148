import { UserVM } from './UserVM';
import { CountryVM } from './CountryVM';
import { StateVM } from './StateVM';


export class RetailerVM {
    RetailerId: number
    DistributerId: string
    RetailerName: string
    RetailerLogo: string
    WatermarkImage: string
    RetailerAddress: string
    ContactName: string
    ContactMobNo: number
    ContactEmail: string
    DateofRegistration: string
    DateofApproved: string
    GstNo: string
    RetailerStatus_Term: number
    ApprovalId: number
    IsActive: number
    UpdateLog: string
    SeqNo: number
    TotalDesignAssigned: number
    LastOrderDate: string
    TotalOrdered: number
    created_at?: string
    updated_at?: string
    email?: UserVM
    display_name?: UserVM
    reject_reason: string
    password: UserVM
    confirm_password: UserVM
    country: CountryVM
    state: StateVM
    city: string
    district: string
    postal_code: string

    rcountry: string
    rstate: string
    rcity: string
    rdistrict: string
    rpostal_code: string
    subscription_id?: any;
    rcnt: string
    rst: string
    rct: string
    is_item_show: any;
}