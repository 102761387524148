export class AccessoryVM {
  accessory_id: number
  accessory_code: string
  accessory_name: string
  unit_of_measure_term: string
  cost_per_unit: string
  preferred_supplier_id: number
  display_name: string
  details: string
  accessory_image?: string
  labour_rate: string
  distributor_id: number
  is_active: number
  created_at?: string
  updated_at?: string
}
