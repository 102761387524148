import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TermsVM } from 'src/app/models/TermsVM';
import { REGEXP } from 'src/app/shared/regexp';
import { CommonService } from 'src/app/services/common.service';
import { TermscategoryService } from 'src/app/services/termscategory.service';
import { TermsService } from 'src/app/services/terms.service';
import { Router, ActivatedRoute } from '@angular/router';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Options } from 'selenium-webdriver/safari';
import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'app-terms-edit',
  templateUrl: './terms-edit.component.html',
  styleUrls: ['./terms-edit.component.css']
})
export class TermsEditComponent implements OnInit {
  @ViewChild("term_icon", { static: false }) termIconFileInput;
  public select2Options: Options;
  public select2TermsData: Array<Select2OptionData>;

  id: number;
  TermsForm: FormGroup;
  termModel = new TermsVM();
  termIconImageUrl: string;
  submitted = false;
  temrsCategory_Data = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _regex: REGEXP,

    private _commonService: CommonService,
    private _termscategoryService: TermscategoryService,
    private _termsService: TermsService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.buildForm();
    Promise.all([
      this.getActiveTermsCategories()
    ]).then((data: any) => {
      this.getSingleTerm();
    });

  }


  getActiveTermsCategories() {
    let self = this;
    self._termscategoryService.getActiveTermsCategories().subscribe(data => {
      let response: any = data;

      if (response.data != null) {
        self.temrsCategory_Data = response.data;
        self.select2TermsData = self._commonService.dropdownArray(self.temrsCategory_Data, "term_category_id", "category_name");
      }
    });
  }

  get f() { return this.TermsForm.controls; }

  gotoCompnayList() {
    this._router.navigate(['/admin/terms']);
  }

  getSingleTerm() {

    this._route.params.subscribe(params => { this.id = params['id']; });

    let self = this;

    self._commonService.showLoader();
    self._termsService.getTermById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.termModel = response.terms;
        self.buildForm();
      });

  }
  buildForm() {

    let TermsFormGroup = {
      term_id: [this._commonService.convertToNumber(this.id)],
      term_code: [this.termModel.term_code, [Validators.required, Validators.maxLength(255)]],
      term_category_id: [this.termModel.term_category_id, [Validators.required]],
      term_name: [this.termModel.term_name, [Validators.required]],
      term_details: [this.termModel.term_details],
      back_color: [this.termModel.back_color, [Validators.required]],
      for_color: [this.termModel.for_color, [Validators.required]],
      sequence_no: [this.termModel.sequence_no, [Validators.required]],
      ideal_value: [this.termModel.ideal_value, [Validators.required, Validators.maxLength(255)]],
      hand_code_rule: [this.termModel.hand_code_rule, [Validators.required, Validators.maxLength(255)]],
      is_active: [this._commonService.convertToNumber(this.termModel.is_active), [Validators.required]],
      is_default: [this._commonService.convertToNumber(this.termModel.is_default), [Validators.required]]
    };


    if (this._commonService.isNullOrEmpty(this.termModel.term_icon)) {

      TermsFormGroup['term_icon'] = [this.termModel.term_icon, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
    } else {

      TermsFormGroup['term_icon'] = [this.termModel.term_icon, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
      this.termIconImageUrl = this._commonService.getFullImageUrl(this.termModel.term_icon);
    }
    this.TermsForm = this._formBuilder.group(TermsFormGroup);

  }

  save() {
    let self = this;
    self.submitted = true;

    // stop here if form is invalid
    if (self.TermsForm.invalid) {
      return;
    }

    let formData = new FormData();

    let siteConfigData = (self.TermsForm.value as TermsVM);

    for (const prop in siteConfigData) {

      let fi = this.termIconFileInput.nativeElement;
      if (prop == 'term_icon') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.TermsForm.value.term_icon = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }
    }

    self._commonService.showLoader();
    self._termsService.updateTerm(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoCompnayList();
        self._commonService.showToaster("Term updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

}
