import { ItemVM } from './ItemVM'
import { UserVM } from './UserVM'
import { CategoryVM } from './CategoryVM'
import { MetalVM } from './MetalVM'
import { DistributorVM } from './DistributorVM'
import { PurityVM } from './PurityVM'

export class SalesOrderVM {
  sales_order_id: number
  order_date: string
  order_no: string
  production_house_id: number
  association_id: number
  association_type_term: string
  biller_id: number
  order_status: string
  is_express_delivery: string
  expected_date_of_delivery: string
  actual_date_of_delivery: string
  payment_term_in_days: string
  payment_status: string
  labour_rate_discount: string
  sales_note: string
  staff_note: string
  order_amt: string
  final_order_amt: string
  total_weight: string
  total_labour_rate: string
  invoice_id: string
  user_id: number
  distributor_id: number
  production_start_date: string
  created_at?: string
  updated_at?: string
  user?: UserVM
  distributor?: DistributorVM
  sales_order_items?: SalesOrderDetailsVM[]
  sales_order_item?: SalesOrderDetailsVM
  sales_logs?: SalesOrderActivityLogVM[]
  customer: string;
}

export class SalesOrderDetailsVM {
  sales_order_details_id: number
  sales_order_id: number
  item_id: number
  category_id: number
  item_name: string
  item_code: string
  weight: string
  metal_id: number
  purity: string
  total: string
  item_image: string
  item_note: string
  created_at?: string
  updated_at?: string
  sales_order?: SalesOrderVM
  item?: ItemVM
  category?: CategoryVM
  metal?: MetalVM
  purity_data?: PurityVM
  customer: string;
}

export class SalesOrderActivityLogVM {
  sales_activity_id: number
  sales_order_id: number
  association_id: number
  association_type_term: string
  activity_date: string
  activity_type_term: string
  activity_details: string
  activity_by: UserVM
  image_post: string
  created_at?: string
  updated_at?: string
  sales_order?: SalesOrderVM
}
