// @Packages
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// @Models
import { DistributorGroupVM } from 'src/app/models/DistributorGroupVM';
// @Services
import { DistributorGroupService } from 'src/app/services/distributorgroup.service';
import { CommonService } from 'src/app/services/common.service';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-distributorgroup-add',
  templateUrl: './distributorgroup-add.component.html',
  styleUrls: ['./distributorgroup-add.component.css']
})

export class DistributorgroupAddComponent implements OnInit {

  addDistributorGroupForm: FormGroup;
  DistributorGroupVM = new DistributorGroupVM();
  submitted = false;
  role: any;
  permissionsData = [];

  constructor(private _formBuilder: FormBuilder,
    private distributorgroupService: DistributorGroupService,
    private _commonService: CommonService,
    private _roleService: RoleService,
    private _storageService: StorageService,
    private _router: Router) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.createDistributorGroupForm();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[12].sub_permission[1].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  createDistributorGroupForm() {
    this.addDistributorGroupForm = this._formBuilder.group({
      group_code: [this.DistributorGroupVM.group_code, [Validators.required, Validators.maxLength(255)]],
      group_name: [this.DistributorGroupVM.group_name, [Validators.required, Validators.maxLength(255)]],
      group_details: [this.DistributorGroupVM.group_details],
      is_active: [1, Validators.required]
    });
  }

  get f() { return this.addDistributorGroupForm.controls; }

  gotoDistributorGroupList() {
    this._router.navigate(['/admin/distributor-groups']);
  }

  save() {
    let self = this;
    self.submitted = true;
    // stop here if form is invalid
    if (self.addDistributorGroupForm.invalid) {
      return;
    }
    self._commonService.showLoader();
    self.distributorgroupService.addDistributorGroup(self.addDistributorGroupForm.value).subscribe(data => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoDistributorGroupList();
        self._commonService.showToaster("Distributor Group added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

}
