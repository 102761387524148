// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';


// @Models
import { ProductionhouseVM } from 'src/app/models/ProductionhouseVM';
import { UserVM } from 'src/app/models/UserVM';

// @Services

import { ProductionhouseService } from 'src/app/services/productionhouse.service';
import { CommonService } from 'src/app/services/common.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Options } from 'selenium-webdriver/ie';
import { Select2OptionData } from 'ng-select2';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';


@Component({
  selector: 'app-productionhouse-add',
  templateUrl: './productionhouse-add.component.html',
  styleUrls: ['./productionhouse-add.component.css']
})
export class ProductionhouseAddComponent implements OnInit {

  public select2Options: Options;
  public select2ProductionhouseData: Array<Select2OptionData>;

  @ViewChild("primary_image", { static: false }) productionhouseimageFileInput;
  @ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;

  addProductionHouseForm: FormGroup;
  ProductionhouseVM = new ProductionhouseVM();
  userdata: UserVM[];
  submitted = false;
  role: number;
  permissionsData = [];
  selectedItems: any = [];
  constructor(private _formBuilder: FormBuilder,
    private _productionhouseService: ProductionhouseService,
    private _commonService: CommonService,
    private _router: Router,
    private _roleService: RoleService,
    private _storageService: StorageService
  ) { }

  ngOnInit() {
    this.role = this._commonService.convertToNumber(this._storageService.getUserRoles());
    if (this.role != 1) {
      this.getPermission();
    }
    this.getAllUserData();
    this.createProductionhouseForm();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[11].sub_permission[1].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }

    });

  }

  getAllUserData() {
    let self = this;

    self._productionhouseService.getAllUserData().subscribe(data => {
      let response: any = data;
      if (response.user_data != null) {
        self.userdata = response.user_data;
        let select2ProductionhouseData: any = [];
        if (self.userdata != null && self.userdata.length > 0) {

          self.userdata.forEach(item => {
            if (item.association_id == null) {
              self.selectedItems = self.userdata.filter(c => c.association_id == null);
              // select2ProductionhouseData.push({
              //   id: item.id,
              //   text: `${item.first_name} ${item.last_name}`,
              // })
            }
          });
        }
      }
    });
  }

  get f() { return this.addProductionHouseForm.controls; }

  createProductionhouseForm() {
    this.addProductionHouseForm = this._formBuilder.group({
      production_house_code: [this.ProductionhouseVM.production_house_code, [Validators.required, Validators.maxLength(255)]],
      production_house_name: [this.ProductionhouseVM.production_house_name, [Validators.required, Validators.maxLength(255)]],
      production_house_details: [this.ProductionhouseVM.production_house_details],
      address: [this.ProductionhouseVM.address, [Validators.required, Validators.maxLength(255)]],
      latitude: [this.ProductionhouseVM.latitude],
      longitude: [this.ProductionhouseVM.longitude],
      primary_image: [this.ProductionhouseVM.primary_image, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      production_manage_id: ['', Validators.required],
      is_active: [1, Validators.required],
    });
  }

  gotoProductionhouseList() {
    this._router.navigate(['/admin/production-houses']);
  }

  save() {
    let self = this;
    self.submitted = true;

    if (self.addProductionHouseForm.invalid) {
      return;
    }

    // stop here if form is invalid
    let formData = new FormData();

    let siteConfigData = (self.addProductionHouseForm.value as ProductionhouseVM);
    for (const prop in siteConfigData) {

      let fi = this.productionhouseimageFileInput.nativeElement;
      if (prop == 'primary_image') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.addProductionHouseForm.value.primary_image = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }
    }

    self._commonService.showLoader();
    self._productionhouseService.addProductionhouse(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoProductionhouseList();
        self._commonService.showToaster("Production house added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }


  //   readUrl(event: any) {

  //     if (event.target.files && event.target.files[0]) {
  //       if (event.target.files[0].size > 2000000) {
  //         alert(0);// checking size here - 2MB}

  // }
  //     }
  //   }


  public handleAddressChange(address) {
    this.addProductionHouseForm.controls['address'].setValue(address.formatted_address);
    this.addProductionHouseForm.controls['latitude'].setValue(address.geometry.location.lat());
    this.addProductionHouseForm.controls['longitude'].setValue(address.geometry.location.lng())
  }


}
