// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


// @Models
import { WarehouseVM } from 'src/app/models/WarehouseVM';

// @Services
import { REGEXP } from 'src/app/shared/regexp';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { CommonService } from 'src/app/services/common.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { UserVM } from 'src/app/models/UserVM';
import { Options } from 'selenium-webdriver/chrome';
import { Select2OptionData } from 'ng-select2';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-warehouse-add',
  templateUrl: './warehouse-add.component.html',
  styleUrls: ['./warehouse-add.component.css']
})
export class WarehouseAddComponent implements OnInit {

  public select2Options: Options;
  public select2UserData: Array<Select2OptionData>;

  addWarehouseForm: FormGroup;
  WarehouseVM = new WarehouseVM();
  submitted = false;
  userdata: UserVM[];
  role: number;
  permissionsData = [];
  @ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;


  constructor(private _formBuilder: FormBuilder,
    private _warehouseService: WarehouseService,
    private _commonService: CommonService,
    private _regex: REGEXP,
    private _router: Router,
    private _roleService: RoleService,
    private _storageService: StorageService) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.getAllActiveUserData();
    this.createWarehouseForm();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[10].sub_permission[1].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }

    });

  }

  getAllActiveUserData() {
    let self = this;
    self._warehouseService.getAllActiveUserData().subscribe(data => {
      let response: any = data;
      if (response.user_data != null) {
        self.userdata = response.user_data;
        let select2UserData: any = [];
        if (self.userdata != null && self.userdata.length > 0) {
          self.userdata.forEach(item => {
            select2UserData.push({
              id: item.id,
              text: `${item.first_name} ${item.last_name}`,
            })
          });
        }

        self.select2UserData = select2UserData;
      }
    });
  }

  createWarehouseForm() {
    this.addWarehouseForm = this._formBuilder.group({
      code: [this.WarehouseVM.code, [Validators.required, Validators.maxLength(255)]],
      ware_house_name: [this.WarehouseVM.ware_house_name, [Validators.required, Validators.maxLength(255)]],
      contact_user_id: [this.WarehouseVM.contact_user_id, Validators.required],
      phone_no: [this.WarehouseVM.phone_no, [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
      email: [this.WarehouseVM.email, [Validators.required, Validators.pattern(this._regex.EMAIL_REGEXP)]],
      address: [this.WarehouseVM.address, [Validators.required, Validators.maxLength(255)]],
      latitude: [this.WarehouseVM.latitude],
      longitude: [this.WarehouseVM.longitude],
      is_active: [1, Validators.required]

    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.addWarehouseForm.controls; }

  gotoWarehouseList() {
    this._router.navigate(['/admin/warehouses']);
  }

  save() {
    let self = this;
    self.submitted = true;

    // stop here if form is invalid
    if (self.addWarehouseForm.invalid) {
      return;
    }

    self._commonService.showLoader();
    self._warehouseService.addWarehouse(self.addWarehouseForm.value).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoWarehouseList();
        self._commonService.showToaster("Warehouse added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

  public handleAddressChange(address) {
    this.addWarehouseForm.controls['address'].setValue(address.formatted_address);
    this.addWarehouseForm.controls['latitude'].setValue(address.geometry.location.lat());
    this.addWarehouseForm.controls['longitude'].setValue(address.geometry.location.lng())
  }


}
