// @Packages
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

// @Services
import { HttpService } from './http.service';
import { AppConfig } from '../shared/app-config';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BankDetailService {
    apiUrl: String;
    // set http Authorization headers
    httpAuthOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        })
    };
    constructor(private _httpService: HttpService, private _api: AppConfig) {
        this.apiUrl = this._api.ApiUrl;
    }

    getBankDetailsData() {
        return this._httpService.get(`${this.apiUrl}bankDetail/getBankDetail`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    saveBankDetail(bankDetail) {
        return this._httpService.post(`${this.apiUrl}bankDetail/getBankDetailUpdate`, bankDetail, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    // getPaymentDetailById() {
    //     return this._httpService.get(`${this.apiUrl}getPaymentDetailById`, this.httpAuthOptions)
    //       .pipe(
    //         map((response: any) => {
    //           return this._httpService.extractData(response);
    //         }), catchError((errorRes: any) => {
    //           return this._httpService.handleError(errorRes);
    //         })
    //       );
    //   }

}
