import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MatDatepickerInputEvent, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { SubscriptionVM, UserSubscriptionVM } from 'src/app/models/SubscriptionVM';
import { UserVM } from 'src/app/models/UserVM';
import { CommonService, DateFormat, DateSeprator } from 'src/app/services/common.service';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubscriptionService } from 'src/app/services/subscriprion.service';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-assign-subscription-edit',
  templateUrl: './assign-subscription-edit.component.html',
  styleUrls: ['./assign-subscription-edit.component.css'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class AssignSubscriptionEditComponent implements OnInit {

  editUserSubscriptionForm: FormGroup;
  UserSubscriptionVM = new UserSubscriptionVM();
  UserVM = new UserVM();
  SubscriptionVM = new SubscriptionVM();
  submitted = false;
  role: number;
  id: number;
  activeSubscription = [];
  activeSubscriptionSelected = [];  
  edc_date: any;
  purchase_date: any;
  expiry_date: any;
  pipe = new DatePipe('en-US');
  display_name: string;
  minDate;
  isloading:boolean = true;
  unavailability = { PurchaseDate: "2017-11-04 16:36:33", DateofExpiry: "2017-11-24 16:36:33" }
  @ViewChild("ReferenceImage", { static: false }) ReferenceImage;
  referenceImageimageurl: string;

  constructor(
    private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _router: Router,
    private _roleService: RoleService,
    private _storageService: StorageService,
    private _subscriptionService: SubscriptionService,
    private _route: ActivatedRoute
  ) {
    
   }

  ngOnInit() {
    this.role = this._commonService.convertToNumber(this._storageService.getUserRoles());
    
    this.getAllActiveSubscription();
    this.getUserSubscriptionById();
    
  //  this.updateUserSubscriptionForm();
    // this.bindData();
    
    //this.getUserSubscriptionById();
  }

  get f() { return this.editUserSubscriptionForm.controls; }

  updateUserSubscriptionForm() {
    //this.editUserSubscriptionForm = this._formBuilder.group({
    // UserSubscriptionId: [this._commonService.convertToNumber(this.UserSubscriptionVM.UserSubscriptionId)],
    // AssociationType_Term: [this.UserVM.display_name, [Validators.required]],
    // SubscriptionName: [this.UserSubscriptionVM.SubscriptionName, [Validators.required]],
    // PurchaseDate: [this.UserSubscriptionVM.PurchaseDate, [Validators.required]],
    // DateofExpiry: [this.UserSubscriptionVM.DateofExpiry, [Validators.required]],
    // AmountPaid: [this.UserSubscriptionVM.AmountPaid, [Validators.required, Validators.maxLength(11), RxwebValidators.numeric({ allowDecimal: true })]],
    // Credit: [this.UserSubscriptionVM.Credit, [Validators.required]],
    // role_type: ['']
    
    if(this.UserSubscriptionVM.RetailerName)
    	this.display_name = this.UserSubscriptionVM.RetailerName;
    else
    	this.display_name = this.UserSubscriptionVM.distributor_name;    	
    console.log('this.UserSubscriptionVM' ,this.UserSubscriptionVM)
    let UserSubscriptionFormGroup = {
      UserSubscriptionId: [this._commonService.convertToNumber(this.UserSubscriptionVM.UserSubscriptionId)],
      AssociationType_Term: [this.UserSubscriptionVM.AssociationType_Term],
      SubscriptionId: [this.UserSubscriptionVM.SubscriptionId, [Validators.required]],
      PurchaseDate: [this.UserSubscriptionVM.PurchaseDate, [Validators.required]],
      DateofExpiry: [this.UserSubscriptionVM.DateofExpiry, [Validators.required]],
      AmountPaid: [this.UserSubscriptionVM.AmountPaid, [Validators.required, Validators.maxLength(11), RxwebValidators.numeric({ allowDecimal: true })]],
      Credit: [this.UserSubscriptionVM.Credit],
      CreditOnHand: [this.UserSubscriptionVM.CreditOnHand],
      role_type: [this.UserSubscriptionVM.user_type.charAt(0).toUpperCase() + this.UserSubscriptionVM.user_type.slice(1)],
      TransactionRefNo: [this.UserSubscriptionVM.TransactionRefNo],
      RetailerName: [this.UserSubscriptionVM.user_display_name],
      // DistributorName: [this.UserSubscriptionVM.distributor_name],
      ReferenceImage: ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]]
      // });
    }
    if (this._commonService.isNullOrEmpty(this.UserSubscriptionVM.ReferenceImage)) {
      UserSubscriptionFormGroup['ReferenceImage'] = [this.UserSubscriptionVM.ReferenceImage, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
    } else {
      UserSubscriptionFormGroup['ReferenceImage'] = [this.UserSubscriptionVM.ReferenceImage, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
      this.referenceImageimageurl = this._commonService.getFullImageUrl(this.UserSubscriptionVM.ReferenceImage);
    }
    this.editUserSubscriptionForm = this._formBuilder.group(UserSubscriptionFormGroup);

    this.isloading = false

    this._commonService.hideLoader();
  }

  getUserSubscriptionById() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._subscriptionService.getUserSubscriptionById(self.id)
      .subscribe(data => {
        let response: any = data;
        self.UserSubscriptionVM = response.data;
        self.updateUserSubscriptionForm();
      });
  }

  save() {
    let self = this;
    self.submitted = true;
    if (self.editUserSubscriptionForm.invalid) {
      return;
    }
    let formData = new FormData();

    let siteConfigData = (self.editUserSubscriptionForm.value as UserSubscriptionVM);
    for (const prop in siteConfigData) {
      let fi = self.ReferenceImage.nativeElement;
      if (prop == 'ReferenceImage') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.editUserSubscriptionForm.value.ReferenceImage = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }
    }
     self._commonService.showLoader();
     self._subscriptionService.updateUserSubscription(formData).subscribe(data => {
       self._commonService.hideLoader();
       if (!self._commonService.isNullOrEmpty(data)) {
         self._commonService.showToaster("Subscription updated successfully.", "success");
    this._router.navigate(['/admin/userassign-subscription-list']);
       }
     }, error => {
       self._commonService.hideLoader();
       self._commonService.showToaster(error.message, "error");
     });

  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.purchase_date = this.pipe.transform(event.target.value, 'dd/MM/yyyy')
  }

  addDateofExpiryEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.expiry_date = this.pipe.transform(event.target.value, 'dd/MM/yyyy')
  }

  getAllActiveSubscription() {
    let self = this;
    self._subscriptionService.getAllActiveSubscription().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.activeSubscription = response.data;
        self.activeSubscription.forEach(element => {
          // this.editUserSubscriptionForm.controls.SubscriptionName.setValue(element.SubscriptionId)
        });
        // this.updateUserSubscriptionForm();
      }
    });
  }

  getSubscription(selectName) {
   // this.editUserSubscriptionForm.controls.Credit.setValue("100")
     let self = this;
     self._subscriptionService.getAllActiveSubscriptionSelected(selectName.value).subscribe(data => {
       let response: any = data;
       if (response.data != null) {
       		self.activeSubscriptionSelected = response.data;
       		
       		self.activeSubscriptionSelected.forEach(element => {
          this.editUserSubscriptionForm.controls.Credit.setValue(element.TotalCredit);
          this.editUserSubscriptionForm.controls.CreditOnHand.setValue(element.TotalCredit);
        });
       		
           
           
       }
     });
  }

  bindData() {
    this.updateUserSubscriptionForm();
    
    
   /*  if(this.UserSubscriptionVM.RetailerName != "")
     	this.editUserSubscriptionForm.controls.RetailerName.setValue(this.UserSubscriptionVM.RetailerName);
     else
     	this.editUserSubscriptionForm.controls.RetailerName.setValue(this.UserSubscriptionVM.distributor_name);
      
     */
   /* this.editUserSubscriptionForm.controls.AssociationType_Term.setValue("username")
    //this.editUserSubscriptionForm.controls.SubscriptionName.setValue("1")
    //this.editUserSubscriptionForm.controls.PurchaseDate.setValue(this.unavailability.PurchaseDate)
    //this.editUserSubscriptionForm.controls.DateofExpiry.setValue("2017-11-10T22:00:00.000Z")
    this.editUserSubscriptionForm.controls.AmountPaid.setValue("100")
    this.editUserSubscriptionForm.controls.Credit.setValue("120")
    this.editUserSubscriptionForm.controls.role_type.setValue("1")*/
  }


}
