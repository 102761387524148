// @Packages
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

// @Services
import { AppConfig } from 'src/app/shared/app-config';
import { HttpService } from '../http.service';
import { StorageService } from '../storage.service';
import { HttpHeaders } from '@angular/common/http';
import { DIR_DOCUMENT } from '@angular/cdk/bidi';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Create a stream of logged in status to communicate throughout app
  loggedIn: boolean;
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);

  private apiUrl: string;

  // set http Authorization headers
  httpAuthOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };

  constructor(private _api: AppConfig,
    private _httpService: HttpService,
    private _storageService: StorageService,
    private _router: Router) {
    if (this.tokenValid) {
      //check user active or not.

      this.setLoggedIn(true);
    } else {
      this.logout();
    }
    this.apiUrl = _api.ApiUrl;
  }

  login(credentials) {

    this._storageService.clearCredentials();
    const self = this;
    return self._httpService.post(`${self.apiUrl}login`, credentials, { headers: this._api.getHeaders() })
      .pipe(
        map((response: any) => {
          return response;
        }), catchError((errorRes: any) => {
          return self._httpService.handleError(errorRes);
        })
      );
  }

  setLoggedIn(value: boolean) {
    // Update login status subject
    this.loggedIn$.next(value);
    this.loggedIn = value;
  }

  setSession(authResult) {
    // Set tokens and expiration in localStorage and props
    this._storageService.setCredentials(authResult);
    // Update login status in loggedIn$ stream
    this.setLoggedIn(true);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    //return localStorage.getItem("isAuthenticated") == "true" ? true : false ;
    const expiresAt = JSON.parse(this._storageService.getTokenExpireAt());

    return new Date().getTime() < expiresAt;
  }

  get tokenValid(): boolean {
    // Check if current time is past access token's expiration
    const expiresAt = JSON.parse(this._storageService.getTokenExpireAt());
    return Date.now() < expiresAt;
  }

  logout() {
    //this.doLogout(false);

    // Ensure all auth items removed from localStorage
    this._storageService.clearCredentials();

    // Reset local properties, update loggedIn$ stream
    this.setLoggedIn(false);

    // Return to login
    this._router.navigate(['/login']);
  }


  checkAdminPassword(password) {

    var body = {
      "password": password
    }

    return this._httpService.post(`${this.apiUrl}admin/checkpassword`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );

  }

  checkDistributorPassword(password) {

    var body = {
      "password": password
    }
    return this._httpService.post(`${this.apiUrl}distributor/checkpassword`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  checkUserActive(userId) {

    const self = this;
    return self._httpService.get(`${self.apiUrl}users/getUserActive/${userId}`, { headers: this._api.getHeaders() })
      .pipe(
        map((response: any) => {
          return response;
        }), catchError((errorRes: any) => {
          return self._httpService.handleError(errorRes);
        })
      );
  }


}
