// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2'

// @Services
import { ProductionhouseService } from 'src/app/services/productionhouse.service';
import { CommonService } from 'src/app/services/common.service';

// @Models
import { ProductionhouseVM } from 'src/app/models/ProductionhouseVM';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-productionhouse-list',
  templateUrl: './productionhouse-list.component.html',
  styleUrls: ['./productionhouse-list.component.css']
})
export class ProductionhouseListComponent implements OnInit {

  getSiteConfig: any;

  pageSize: number = 50;
  productionhouseData: any;
  role: any;
  permissionsData = [];
  noData = false;
  marked = false;
  marked1 = false;
  marked2 = false;
  displayedColumns: string[] = ['production_house_code', 'production_house_name', 'address', 'primary_image', 'is_active', 'production_manage_id', 'created_at', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private productionhouseService: ProductionhouseService,
    private _commonService: CommonService,
    private _router: Router,
    private _storageService: StorageService,
    private _roleService: RoleService,
    private _siteConfig: SiteConfigService) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }

    this.loadProductionhouse();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      self.marked = self.permissionsData[11].sub_permission[1].isSelected;

      self.marked1 = self.permissionsData[11].sub_permission[2].isSelected;

      self.marked2 = self.permissionsData[11].sub_permission[3].isSelected;

      if (self.permissionsData[11].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });

  }

  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadProductionhouse() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self.productionhouseService.getAllProductionhouseData()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.productionhouseData = new MatTableDataSource<ProductionhouseVM>(response.data);
            self.productionhouseData.paginator = self.paginator;
            self.productionhouseData.sort = self.sort;
            self.productionhouseData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.noData = (response.data.length > 0) ? false : true;
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }
  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  editProductionHouse(productionhouse: ProductionhouseVM) {
    this._router.navigate(['/admin/production-houses/edit', productionhouse.production_house_id]);
  }

  deleteproductionhouse(productionhouse: ProductionhouseVM) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this production house and associated items?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    swal.fire(options).then((result) => {
      if (result.value) {

        self._commonService.showLoader();
        self.productionhouseService.deleteproductionhouse(productionhouse.production_house_id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Production house deleted successfully.", "success");
          self.loadProductionhouse();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  applyFilter(filterValue: string) {
    this.productionhouseData.filter = filterValue.trim().toLowerCase();
    this.noData = (this.productionhouseData.filteredData.length > 0) ? false : true;
    // this.noData = (this.productionhouseData._paginator._length > 0) ? false : true;
  }




}
