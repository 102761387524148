
export class distributorviewVM {
    first_name: string
    last_name: string
    distributor_code: string
    display_name: string
    dis_group_name: string
    company_name: string
    email: string
    mobile_no: string
    business_address: string
    country: string
    state: string
    city: string
    postal_code
    gst_no: string
    registration_no: string
    logo: string
    item_add_limit: number
    is_active: number
    item_id: number
    item_name: string
    code: string
    group_name: string
    ware_house_name: string
    dis_active: number
    status_name: string
    created_at: string
    is_approve: string
    distributor_id: string
    purity: string
    totalcount: number
    name: string
}