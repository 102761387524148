// @Packages
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// @Models
import { AttributeVM } from 'src/app/models/AttributeVM';

// @Services
import { AttributeService } from 'src/app/services/attribute.service';
import { CommonService } from 'src/app/services/common.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-attribute-edit',
  templateUrl: './attribute-edit.component.html',
  styleUrls: ['./attribute-edit.component.css']
})
export class AttributeEditComponent implements OnInit {

  id: number;
  editAttributeForm: FormGroup;
  attributeVM = new AttributeVM();
  submitted = false;
  role: any;
  permissionsData = [];


  constructor(private _formBuilder: FormBuilder,
    private _attributeService: AttributeService,
    private _commonService: CommonService,
    private _router: Router,
    private _storageService: StorageService,
    private _roleservice: RoleService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.buildForm();
    this.initializeFormValue();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[2].sub_permission[2].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });

  }

  initializeFormValue() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;

    self._commonService.showLoader();
    self._attributeService.getAttributeById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.attributeVM = response.attribute;
        this.buildForm();
      });
  }

  buildForm() {
    this.editAttributeForm = this._formBuilder.group({
      attribute_id: [this.attributeVM.attribute_id, [Validators.required]],
      attribute_code: [this.attributeVM.attribute_code, [Validators.required, Validators.maxLength(255)]],
      attribute_name: [this.attributeVM.attribute_name, [Validators.required, Validators.maxLength(255)]],
      attribute_details: [this.attributeVM.attribute_details],
      default_value: [this.attributeVM.default_value, [Validators.required, Validators.maxLength(255)]],
      input_type_term: [this.attributeVM.input_type_term, [Validators.required, Validators.maxLength(255)]],
      is_active: [this.attributeVM.is_active, Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.editAttributeForm.controls; }

  gotoAttributeList() {
    this._router.navigate(['/admin/attributes']);
  }

  save() {
    let self = this;
    self.submitted = true;

    // stop here if form is invalid
    if (self.editAttributeForm.invalid) {
      return;
    }

    self._commonService.showLoader();
    self._attributeService.updateAttribute(self.editAttributeForm.value).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoAttributeList();
        self._commonService.showToaster("Attribute updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

}
