export class RoleVM {
    id: number
    name: string
    code: string
    display_name: string
    description: string
    is_active: number
    role_type: number
    is_default: number
    update_log?: string
    created_at: string
    updated_at?: string
}
