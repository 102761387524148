import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ItemGroupService } from 'src/app/services/item-group.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { ItemGroupVM } from 'src/app/models/ItemGroupVM';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-item-group-list',
  templateUrl: './item-group-list.component.html',
  styleUrls: ['./item-group-list.component.css']
})
export class ItemGroupListComponent implements OnInit {

  pageSize: number = 50;
  itemGroupsData: any;
  distributor: any;
  role: any;
  permissionsData = [];
  noData = false;
  displayedColumns: string[];
  loginUserId: any;
  marked = false;
  marked1 = false;
  marked2 = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private _itemGroupService: ItemGroupService,
    private _storageService: StorageService,
    private _commonService: CommonService,
    private _router: Router,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _roleService: RoleService,
    private _siteConfigService: SiteConfigService) {
  }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.getUserActive();
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.loadItemGroups();
    this.distributor = this._storageService.getDistributorId();
    this.displayedColumns = ['group_name', 'group_code', 'item_count', 'is_active', 'created_at', 'actions'];
  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      //self.permissionsData[14].sub_permission[0].isSelected);
      //check permission
      self.marked = self.permissionsData[14].sub_permission[1].isSelected;
      self.marked1 = self.permissionsData[14].sub_permission[2].isSelected;
      self.marked2 = self.permissionsData[14].sub_permission[3].isSelected;
      if (self.permissionsData[14].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadItemGroups() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._itemGroupService.getAllItemGroups()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.itemGroupsData = new MatTableDataSource<ItemGroupVM>(response.data);
            self.itemGroupsData.paginator = self.paginator;
            self.itemGroupsData.sort = self.sort;
            self.itemGroupsData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.noData = (response.data.length > 0) ? false : true;
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  editItemGroup(itemGroup: ItemGroupVM) {
    this._router.navigate(['/admin/item-groups/edit', itemGroup.item_group_id]);
  }

  deleteItemGroup(itemGroup: ItemGroupVM) {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        } else {
          const options: SweetAlertOptions = {
            title: 'Are you sure you want to delete this item group and associated items?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            focusCancel: true
          };
          Swal.fire(options).then((result) => {
            if (result.value) {
              self._commonService.showLoader();
              self._itemGroupService.deleteItemGroup(itemGroup.item_group_id).subscribe(data => {
                self._commonService.hideLoader();
                self._commonService.showToaster("Item group deleted successfully.", "success");
                self.loadItemGroups();
              }, error => {
                self._commonService.hideLoader();
                self._commonService.showToaster(error.message, "error");
              });
            }
          })
        }
      });
  }

  applyFilter(filterValue: string) {
    this.itemGroupsData.filter = filterValue.trim().toLowerCase();
    // this.noData = (this.itemGroupsData._paginator._length > 0) ? false : true;
    this.noData = (this.itemGroupsData.filteredData.length > 0) ? false : true;
  }
}
