import { Component, OnInit } from '@angular/core';
import { ItemService } from 'src/app/services/item.service';
import { SalesOrderService } from 'src/app/services/sales-order.service';
import { StorageService } from 'src/app/services/storage.service';
import { CommonService } from 'src/app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NotificationService } from 'src/app/services/notification.service';
import { SalesOrderVM } from 'src/app/models/SalesOrderVM';
import { ItemVM } from 'src/app/models/ItemVM';

@Component({
  selector: 'app-sales-orders-edit',
  templateUrl: './sales-orders-edit.component.html',
  styleUrls: ['./sales-orders-edit.component.css'],
  providers: [
    DatePipe
  ]
})
export class SalesOrdersEditComponent implements OnInit {

  salesOrderId: number;
  itemId: number;
  itemDetail = new ItemVM();
  salesOrder = new SalesOrderVM();
  editSalesOrderForm: FormGroup;
  orderFormData = new FormData();
  submitted = false;

  constructor(
    private _salesOrderService: SalesOrderService,
    private _commonService: CommonService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _notificationService: NotificationService) { }

  ngOnInit() {
    this.initialFormValue();
    // this.buildForm();
  }

  initialFormValue() {
    let self = this;
    self._route.params.subscribe(params => { self.salesOrderId = params['id']; });
    Promise.all([
      self.getSalesOrderDataById()
    ]).then((data: any) => {
      if (data != null && data != undefined) {
        let response = data[0];
        if (!self._commonService.isNullOrEmpty(response.data)) {
          self.salesOrder = response.data.sales_order;
          self.itemDetail = response.data.item_detail;
          self.itemId = response.data.item_detail.item_id;
          self.buildForm();
        }
      }
    });
  }

  get f() { return this.editSalesOrderForm.controls; }

  buildForm() {
    let self = this;
    this.salesOrder.order_date = self._commonService.parseDate(self.salesOrder.order_date);
    self.editSalesOrderForm = self._formBuilder.group({
      sales_note: [self.salesOrder.sales_note],
      is_express_delivery: [self.salesOrder.is_express_delivery],
      // expected_date_of_delivery: [this.salesOrder.expected_date_of_delivery],
      sales_order_items: this._formBuilder.array([self._formBuilder.group({
        item_id: (self.salesOrder.sales_order_item.item_id) ? '' : self.salesOrder.sales_order_item.item_id,
        item_name: self.salesOrder.sales_order_item.item_name,
        weight: self.salesOrder.sales_order_item.weight,
        metal_id: self.salesOrder.sales_order_item.metal.metal_name,
        purity: self.salesOrder.sales_order_item.purity_data.purity,
        total: self.salesOrder.sales_order_item.total,
        item_note: self.salesOrder.sales_order_item.item_note
      })])
    });
  }

  get itemForms() {
    return this.editSalesOrderForm.get('sales_order_items') as FormArray;
  }

  getSalesOrderDataById() {
    return new Promise((resolve, reject) => {
      let self = this;
      self._salesOrderService.getSalesOrderDetailById(self.salesOrderId).subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  updateWeight(index: number, qty: number) {
    let self = this;
    let totalWeight = 0;
    let totalQty = self._commonService.convertToNumber(qty);
    let netWeight: any = self._commonService.formatNumberWithDecimal(self.itemDetail.net_weight, 4);
    totalWeight = totalQty * netWeight;
    let control = self.itemForms.controls[index];
    control.get('weight').setValue(totalWeight);
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  saveOrder() {
    let self = this;
    self.submitted = true;
    if (self.editSalesOrderForm.invalid) {
      return;
    }
    let basicFormData = (self.editSalesOrderForm.value);
    for (const prop in basicFormData) {
      self.orderFormData.delete(prop);
      self.orderFormData.append(prop, basicFormData[prop]);
      if (prop == 'expected_date_of_delivery') {
        // let expected_date_of_delivery = this._datePipe.transform(basicFormData[prop], 'yyyy-MM-dd');
        // self.orderFormData.append(prop, expected_date_of_delivery);
      }
      if (prop == 'sales_order_items') {
        let itemFormData = (self.editSalesOrderForm.value.sales_order_items);
        self.orderFormData.delete('sales_order_items');
        self.orderFormData.append('sales_order_items', JSON.stringify(itemFormData));
      }
    }
    self._commonService.showLoader();
    self._salesOrderService.addSalesOrder(self.orderFormData).subscribe(data => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self._commonService.showToaster("Order placed successfully.", "success");
        self._notificationService.updateNotification(true);
        self.gotoSalesOrdersList();
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });

  }

  gotoSalesOrdersList() {
    this._router.navigate(['/admin/sales-orders']);
  }
}
