export class Permissions {
    permission_id: number;
    display_name?: string;   
    //sub_permission:[] 
    isAll?: boolean;
    isView?: boolean;
    isViewPermission?: boolean;
    isCreate?: boolean;
    isCreatePermission?: boolean;
    isEdit?: boolean;
    isEditPermission?: boolean;
    isDelete?: boolean;
    isDeletePermission?: boolean;
    isExport?: boolean;
    isExportPermission?: boolean;
}

export class RolePermissionVM {
    role_id: number;   
    //permissions: Permissions[];
    permission_id: number;
    role_type:number;
}

export class SubPermissionVM {
    id?: number;
    name?: string;
    display_name?: string;
    description?: string;
    parent_id?: number;
    isSelected?: boolean;
    role_types:string;
    isShowPermission? : boolean = false;
}

export class GetRolePermissionsVM {
    id?: number;
    name?: string;
    display_name?: string;
    description?: string;
    parent_id?: number;
    isSelected?: boolean;
    sub_permission: SubPermissionVM[];
    role_types:string;
    isShowPermission? : boolean = false;

}