import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

// @Services
import { HttpService } from './http.service';
import { AppConfig } from '../shared/app-config';
import { map, catchError } from 'rxjs/operators';
import { date } from '@rxweb/reactive-form-validators';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderService {

  apiUrl: String;
  // set http Authorization headers
  httpAuthOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };

  constructor(private _httpService: HttpService,
    private _api: AppConfig) {
    this.apiUrl = this._api.ApiUrl;
  }

  getImage(imageUrl: string) {
    return this._httpService.get(imageUrl, { observe: 'response', responseType: 'blob' })
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllSalesOrders(event, filter, orderdate, itemstatus, search, columnSorting, columnDirection) {
    var body = {
      "event": event,
      "filter": filter,
      "orderdate": orderdate,
      "itemstatus": itemstatus,
      "search": search,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection,
    }
    return this._httpService.post(`${this.apiUrl}sales-orders/getAllSalesOrders`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
  distsalesorder() {
    return this._httpService.get(`${this.apiUrl}sales-orders/distsalesorder`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
  getAllUserSalesOrders(event, filter, orderdDate, deliveryDate, itemstatus, search, columnSorting, columnDirection) {

    var body = {
      "event": event,
      "filter": filter,
      "orderdDate": orderdDate,
      "deliveryDate": deliveryDate,
      "itemstatus": itemstatus,
      "search": search,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection,

    }
    return this._httpService.post(`${this.apiUrl}sales-orders/getAllUserSalesOrders`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  addSalesOrder(salesOrder) {
    return this._httpService.post(`${this.apiUrl}sales-orders/addSalesOrder`, salesOrder, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getSalesOrderDetailById(id) {
    return this._httpService.get(`${this.apiUrl}sales-orders/getSalesOrderDetailById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getScannedImageWatermark(id, scannedImage, item_id) {
    var body = {
      "id": id,
      "scannedImage": scannedImage,
      "item_id" : item_id

    }
    return this._httpService.post(`${this.apiUrl}sales-orders/getScannedImageWatermark`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  confirmSalesOrder(id, deliverydate) {
    return this._httpService.post(`${this.apiUrl}sales-orders/confirmSalesOrder/${id}`, deliverydate, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  completeSalesOrder(id) {

    return this._httpService.post(`${this.apiUrl}sales-orders/completeSalesOrder/${id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  cancelSalesOrder(id) {

    return this._httpService.post(`${this.apiUrl}sales-orders/cancelSalesOrder/${id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  cancelSalesOrderItem(sales_order_details_id) {

    return this._httpService.post(`${this.apiUrl}sales-orders/cancelSalesOrderItem/${sales_order_details_id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  requestToOrder(id, user) {
    var body = {
      "user": user
    }
    return this._httpService.post(`${this.apiUrl}sales-orders/requestToOrder/${id}`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  updateCartItem(updateCartItem) {
    return this._httpService.post(`${this.apiUrl}sales-orders/updateCartItem`, updateCartItem, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
}
