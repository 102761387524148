import { CategoryAttributeVM } from './CategoryAttributeVM';

export class TermscategoryVM {
    term_category_id: number
    category_name: string
    category_details: string      
    is_active: number    
    created_at?: string
    updated_at?: string    
}
