import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService, DateFormat, DateSeprator } from 'src/app/services/common.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { SalesOrderVM, SalesOrderDetailsVM } from 'src/app/models/SalesOrderVM';
import { SalesOrderService } from 'src/app/services/sales-order.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2'
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-my-sales-orders-list',
  templateUrl: './my-sales-orders-list.component.html',
  styleUrls: ['./my-sales-orders-list.component.css'],

})
export class MySalesOrdersListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['order_date', 'order_no', 'customer', 'total_weight', 'total_items', 'is_express_delivery', 'order_status', 'actual_date_of_delivery', 'actions'];
  salesOrderData: any;
  noData = false;
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  filterValue: '';
  value: '';
  date: string;
  availableItem?: any;
  pendingValidate = false;
  selectPendingStatus: any;
  applyseacrhMySalesOrderForm: FormGroup;
  orderDate1: string;
  deliveryDate1: string;
  search: { orderDate: any; deliveryDate: any; selectStatus: any; };
  columnSorting: any;
  columnDirection: any;
  orderdate: any;
  deliverydate: any;
  constructor(private _salesOrderService: SalesOrderService,
    private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _siteConfig: SiteConfigService,
    private _route: ActivatedRoute,
    private _router: Router) { }

  ngOnInit() {
    this.createCustomizeOrderForm();
    let self = this;
    this._route.params.subscribe(params => {
      this.selectPendingStatus = params['item'];
      if (this.selectPendingStatus == 'pending') {
        this.availableItem = "1";
        this.search = {
          "orderDate": "",
          "deliveryDate": "",
          "selectStatus": this.availableItem
        }
        self.pendingValidate = true;
        this.applyseacrhMySalesOrderForm.controls.selectStatus.setValue(this.search.selectStatus)
      }
      // else if (this.selectStatus == 'confirmed') {
      //   this.availableItem = 2;
      // }
      // else if (this.selectStatus == 'completed') {
      //   this.availableItem = 3;
      // }
      // else if (this.selectStatus == 'cancelled') {
      //   this.availableItem = 4;
      // }
      self._siteConfig.getSiteConfigData().subscribe(data => {
        let response: any = data;
        if (response.data != null) {
          self.pageSize = response.data.default_row_perpage_term;
          var event: any = {
            "previousPageIndex": '0',
            "pageIndex": '0',
            "pageSize": this.pageSize,
            "length": '0',
          }
          this.getAllSalesOrder(event);
        }
      });
    });
  }

  createCustomizeOrderForm() {
    this.applyseacrhMySalesOrderForm = this._formBuilder.group({
      orderDate: [''],
      deliveryDate: [''],
      selectStatus: [''],
    });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  goToMySalesOrders() {
    this._router.navigate(['/admin/my-sales-orders']);
  }

  getAllSalesOrder(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._salesOrderService.getAllUserSalesOrders(event, this.value, this.orderDate1, this.deliveryDate1, this.availableItem, this.search, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.salesOrderData = new MatTableDataSource<SalesOrderVM>(response.data.list);
            if (!self._commonService.isNullOrEmpty(response.data)) {
              if (response.data.list.length > 0) {
                self.totalLength = response.data.total_count;
              }
              self.noData = (response.data.list.length > 0) ? false : true;
              if (self.noData == true) {
                self.totalLength = 0;
              }
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllSalesOrder(event);
  }

  viewSalesOrderDetail(orderdetail: SalesOrderDetailsVM) {
    this._router.navigate(['/admin/sales-order-details', orderdetail.sales_order_id], { queryParams: { id: orderdetail.sales_order_id } });
  }

  cancelOrder(salesorder) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to cancel this order?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    swal.fire(options).then((result) => {
      if (result.value) {

        self._commonService.showLoader();
        self._salesOrderService.cancelSalesOrder(salesorder.sales_order_id).subscribe((data: any) => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Order - " + data.order_no + " cancelled successfully.", "success");
          var event: any = {
            "previousPageIndex": '0',
            "pageIndex": '0',
            "pageSize": this.pageSize,
            "length": '0',
          }
          self.getAllSalesOrder(event);
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  // selectAvailable(availableItem) {
  //   this.availableItem = availableItem;
  //   var event: any = {
  //     "previousPageIndex": '0',
  //     "pageIndex": '0',
  //     "pageSize": this.pageSize,
  //     "length": '0',
  //   }
  //   this._router.navigate(['admin/my-sales-orders']);
  //   this.getAllSalesOrder(event);
  // }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  applySearch() {
    let self = this;
    if (this.applyseacrhMySalesOrderForm.controls.orderDate.value.start != null && this.applyseacrhMySalesOrderForm.controls.orderDate.value.end != null) {
      self.orderdate = {
        start: self._commonService.getDateTimeString(this.applyseacrhMySalesOrderForm.controls.orderDate.value.start._d, DateFormat.DDMMYYYY, DateSeprator.DASH),
        end: self._commonService.getDateTimeString(this.applyseacrhMySalesOrderForm.controls.orderDate.value.end._d, DateFormat.DDMMYYYY, DateSeprator.DASH)
      }
    }

    if (this.applyseacrhMySalesOrderForm.controls.deliveryDate.value.start != null && this.applyseacrhMySalesOrderForm.controls.deliveryDate.value.end != null) {
      self.deliverydate = {
        start: self._commonService.getDateTimeString(this.applyseacrhMySalesOrderForm.controls.deliveryDate.value.start._d, DateFormat.DDMMYYYY, DateSeprator.DASH),
        end: self._commonService.getDateTimeString(this.applyseacrhMySalesOrderForm.controls.deliveryDate.value.end._d, DateFormat.DDMMYYYY, DateSeprator.DASH)
      }
    }
    this.search = {
      "orderDate": (this.applyseacrhMySalesOrderForm.controls.orderDate.value.start && this.applyseacrhMySalesOrderForm.controls.orderDate.value.end) == undefined ? "" : self.orderdate,
      "deliveryDate": (this.applyseacrhMySalesOrderForm.controls.deliveryDate.value.start && this.applyseacrhMySalesOrderForm.controls.deliveryDate.value.end) == undefined ? "" : self.deliverydate,
      "selectStatus": this.applyseacrhMySalesOrderForm.controls.selectStatus.value
    }
    // this.search = {
    //   "orderDate": this.applyseacrhMySalesOrderForm.controls.orderDate.value,
    //   "deliveryDate": this.applyseacrhMySalesOrderForm.controls.deliveryDate.value,
    //   "selectStatus": this.applyseacrhMySalesOrderForm.controls.selectStatus.value
    // }
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllSalesOrder(event);
  }

  clearSearch() {
    this.pendingValidate = false;
    this.value = '';
    this.search = {
      "orderDate": "",
      "deliveryDate": "",
      "selectStatus": "",
    }
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this._router.navigate(["/admin/my-sales-orders"]);
    this.getAllSalesOrder(event);
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllSalesOrder(event);
  }
}