import { ItemVM } from './ItemVM'
import { UserVM } from './UserVM'

export class WarehouseVM {
  warehouse_id: number
  code: string
  ware_house_name: string
  contact_user_id: number
  phone_no: string
  email: number
  address: number
  latitude: string
  longitude: string
  is_active: string
  update_log?: string
  created_at: string
  updated_at?: string
  items_count?: string
  items?: ItemVM[]
  user?: UserVM[]
}
