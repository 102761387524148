// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
// @Services
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { REGEXP } from 'src/app/shared/regexp';
// @Models
import { CategoryVM } from 'src/app/models/CategoryVM';
import { CategoryAttributeVM } from 'src/app/models/CategoryAttributeVM';
import { AttributeVM } from 'src/app/models/AttributeVM';
import { Options } from 'selenium-webdriver/safari';
import { Select2OptionData } from 'ng-select2';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';
@Component({
  selector: 'app-category-add',
  templateUrl: './category-add.component.html',
  styleUrls: ['./category-add.component.css']
})
export class CategoryAddComponent implements OnInit {
  @ViewChild('category_image', { static: false }) CategoryImageFileInput;
  @ViewChild('icon', { static: false }) CategoryIconFileInput;

  //Select 2
  public select2Options: Options;
  public select2AttributeData: Array<Select2OptionData>;

  addCategoryForm: FormGroup;
  categoryVM = new CategoryVM();
  categoryAttributeVM = [];
  submitted = false;
  attributeData: AttributeVM[];
  loginUserId: any;
  permissionsData = [];
  role: any;
  AttributeArr: any = [];
  constructor(private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _storageService: StorageService,
    private _categoryService: CategoryService,
    private _router: Router,
    private _roleService: RoleService,
    private _attributeService: AttributeService,
    private _regex: REGEXP) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.getUserActive();
    this.createCategoryForm();
    Promise.all([
      this.getAllAttributesData()
    ]).then((data: any) => {
      this.createCategoryForm();
    });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;

      if (self.permissionsData[3].sub_permission[1].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }
  createCategoryForm() {
    this.addCategoryForm = this._formBuilder.group({
      category_code: [this.categoryVM.category_code, [Validators.required, Validators.maxLength(255)]],
      category_name: [this.categoryVM.category_name, [Validators.required, Validators.maxLength(255)]],
      display_name: [this.categoryVM.display_name, [Validators.required, Validators.maxLength(255)]],
      sequence_no: [this.categoryVM.sequence_no, [Validators.required, Validators.maxLength(255), Validators.pattern(this._regex.NUMBER_REGEXP)]],
      description: [this.categoryVM.description],
      category_image: [this.categoryVM.category_image, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      icon: [this.categoryVM.icon, [Validators.required, requiredFileType(this._commonService.SVG_PNG_IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      is_active: [1, Validators.required],
      category_item_credit: [this.categoryVM.category_item_credit, [Validators.required, Validators.maxLength(11), RxwebValidators.numeric({ allowDecimal: true })]],
      attributeRows: this._formBuilder.array([])
    });
    this.addNewAttributeRow();
  }
  getAllAttributesData() {
    let self = this;
    self._attributeService.getAllAttributeData().subscribe(data => {
      let response: any = data;
      if (response.attribute_data != null) {
        self.attributeData = response.attribute_data;
        self.select2AttributeData = self._commonService.dropdownArray(self.attributeData, "attribute_id", "attribute_name");
      }
    });
  }
  get attributeForms() {
    return this.addCategoryForm.get('attributeRows') as FormArray;
  }
  addNewAttributeRow() {
    let self = this;
    self.AttributeArr = self.f.attributeRows as FormArray;
    self.AttributeArr.push(this._formBuilder.group({
      attribute_id: ['', [Validators.required, RxwebValidators.unique()]],
      default_value: ['', [Validators.required, Validators.maxLength(255)]],
      is_compulsory: [0],
      sequence_no: ['', [Validators.required, RxwebValidators.unique(), Validators.maxLength(255), Validators.pattern(self._regex.NUMBER_REGEXP)]],
      is_active: [1, Validators.required]
    }));

  }
  deleteAttributeRow(index: number) {
    let AttributeArr = this.f.attributeRows as FormArray;
    AttributeArr.removeAt(index);
  }
  // convenience getter for easy access to form fields
  get f() { return this.addCategoryForm.controls; }
  gotoCategoryList() {
    this._router.navigate(['/admin/categories']);
  }
  // save() {
  //   let self = this;
  //   self.submitted = true;
  //   // stop here if form is invalid
  //   if (self.addCategoryForm.invalid) {
  //     self._commonService.scrollToTop();
  //     return;
  //   }
  //   self._commonService.showLoader();
  //   let formData = new FormData();
  //   let fi = this.CategoryImageFileInput.nativeElement;
  //   if (fi.files && fi.files[0]) {
  //     let fileToUpload = fi.files[0];
  //     formData.append('category_image', fileToUpload, (fileToUpload as File).name);
  //     self._categoryService.uploadFile(formData)
  //       .subscribe(file_upload => {
  //         let data: any;
  //         data = file_upload;
  //         self.addCategoryForm.value.category_image = data.filePath;
  //         self._categoryService.addCategory(self.addCategoryForm.value).subscribe(data => {
  //           self._commonService.hideLoader();
  //           if (!self._commonService.isNullOrEmpty(data)) {
  //             self.gotoCategoryList();
  //             self._commonService.showToaster("Category added successfully.", "success");
  //           }
  //         }, error => {
  //           self._commonService.hideLoader();
  //           self._commonService.showToaster(error.message, "error");
  //         });
  //       });
  //   } else {
  //     self._categoryService.addCategory(self.addCategoryForm.value).subscribe(data => {
  //       self._commonService.hideLoader();
  //       if (!self._commonService.isNullOrEmpty(data)) {
  //         self.gotoCategoryList();
  //         self._commonService.showToaster("Category added successfully.", "success");
  //       }
  //     }, error => {
  //       self._commonService.hideLoader();
  //       self._commonService.showToaster(error.message, "error");
  //     });
  //   }
  // }

  save() {
    // debugger;
    let self = this;
    self.submitted = true;
    if (self.addCategoryForm.invalid) {
      return;
    }
    // stop here if form is invalid
    let formData = new FormData();

    let siteConfigData = (self.addCategoryForm.value as CategoryVM);
    for (const prop in siteConfigData) {

      let fi = this.CategoryImageFileInput.nativeElement;
      let fi_icon = this.CategoryIconFileInput.nativeElement;
      if (prop == 'category_image') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.addCategoryForm.value.category_image = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else if (prop == 'icon') {
        if (fi_icon.files && fi_icon.files[0]) {
          let fileToUpload = fi_icon.files[0];
          self.addCategoryForm.value.icon = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
        // formData.append('attributeRows', JSON.stringify(self.AttributeArr));
      }
    }

    self._commonService.showLoader();
    formData.append("AttributeArr", JSON.stringify(self.AttributeArr.value));
    self._categoryService.addCategory(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoCategoryList();
        self._commonService.showToaster("Category added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }


  onChangeGetAttribute(index: number, attribute_id: number) {
    let self = this;
    self._commonService.showLoader();
    self._attributeService.getAttributeById(attribute_id).subscribe(data => {
      let response: any;
      response = data;
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        let attribute = response.attribute;
        if (attribute != null) {
          let attributeDefaultValue = response.attribute.default_value;
          if (attributeDefaultValue != null && attributeDefaultValue != undefined) {
            let control = self.attributeForms.controls[index];
            control.get('default_value').setValue(attributeDefaultValue);
          }
        }
      }
    });
  }
}
