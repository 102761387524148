// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2'

// @Services
import { AccessoryService } from 'src/app/services/accessory.service';
import { CommonService } from 'src/app/services/common.service';

// @Models
import { AccessoryVM } from 'src/app/models/accessoryVM';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';


@Component({
  selector: 'app-accessories-list',
  templateUrl: './accessories-list.component.html',
  styleUrls: ['./accessories-list.component.css']
})
export class AccessoriesListComponent implements OnInit {

  getSiteConfig: any;

  pageSize: number = 50;
  accessoriesData: any;
  distributor: any;
  role: any;
  permissionsData = [];
  noData = false;
  loginUserId: any;
  marked = false;
  marked1 = false;
  marked2 = false;

  displayedColumns: string[] = ['accessory_name', 'accessory_code', 'display_name', 'unit_of_measure_term', 'cost_per_unit', 'labour_rate', 'accessory_image', 'is_active', 'created_at', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private accessoryService: AccessoryService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _storageService: StorageService,
    private _roleService: RoleService,
    private _commonService: CommonService,
    private _router: Router,
    private _siteConfig: SiteConfigService) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.getUserActive();
    this.loadAccessory();
    this.distributor = this._storageService.getDistributorId();

    this.displayedColumns = ['accessory_name', 'accessory_code', 'display_name', 'unit_of_measure_term', 'cost_per_unit', 'labour_rate', 'accessory_image', 'is_active', 'created_at', 'actions'];

  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }


  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      self.marked = self.permissionsData[8].sub_permission[1].isSelected;

      self.marked1 = self.permissionsData[8].sub_permission[2].isSelected;

      self.marked2 = self.permissionsData[8].sub_permission[3].isSelected;

      if (self.permissionsData[8].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });

  }

  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadAccessory() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self.accessoryService.getAllAccessoryData()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.accessoriesData = new MatTableDataSource<AccessoryVM>(response.data);
            self.accessoriesData.paginator = self.paginator;
            self.accessoriesData.sort = self.sort;
            self.accessoriesData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.noData = (response.data.length > 0) ? false : true;
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  editaccessory(accessory: AccessoryVM) {
    this._router.navigate(['/admin/accessories/edit', accessory.accessory_id]);
  }

  deleteaccessory(accessory: AccessoryVM) {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        } else {
          const options: SweetAlertOptions = {
            title: 'Are you sure you want to delete this accessory?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            focusCancel: true
          };

          swal.fire(options).then((result) => {
            if (result.value) {
              self._commonService.showLoader();
              self.accessoryService.deleteAccessory(accessory.accessory_id).subscribe(data => {
                self._commonService.hideLoader();
                self._commonService.showToaster("Accessory deleted successfully.", "success");
                self.loadAccessory();
              }, error => {
                self._commonService.hideLoader();
                self._commonService.showToaster(error.message, "error");
              });
            }
          })
        }
      });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  applyFilter(filterValue: string) {
    this.accessoriesData.filter = filterValue.trim().toLowerCase();
    this.noData = (this.accessoriesData.filteredData.length > 0) ? false : true;
    // this.noData = (this.accessoriesData._paginator._length > 0) ? false : true;
  }


}
