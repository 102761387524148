import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSubscriptionVM } from 'src/app/models/SubscriptionVM';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubscriptionService } from 'src/app/services/subscriprion.service';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SiteConfigService } from 'src/app/services/site-config.service';
@Component({
  selector: 'app-assign-subscription-view',
  templateUrl: './assign-subscription-view.component.html',
  styleUrls: ['./assign-subscription-view.component.css']
})
export class AssignSubscriptionViewComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  UserSubscriptionVM = new UserSubscriptionVM();
  loginUserId: any;
  id: number;
  pageSize: number = 50;
  columnSorting: any;
  columnDirection: any;
  totalLength: any;
  value: '';
  filterValue: '';
  userAssignSubscriptionHistory : any;
  noData: any;
  constructor(private _commonService: CommonService,
    private _storageService: StorageService,
    private _checkUser: CheckActiveUserService,
    private _subscriptionService: SubscriptionService,
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _siteConfigService: SiteConfigService) { }
    displayedColumns: string[];
  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.getUserActive();
    this.getUserSubscriptionById();

    this._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        this.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "pageIndex": '0',
          "pageSize": this.pageSize,
        }
        this.loadHistoryList(event);
      }
    });


    this.displayedColumns = ['SubscriptionName','created_at' ,'end_date' ,'credit_on_hand','updated_at'];
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }


  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadHistoryList(event);
  }

  getUserSubscriptionById() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._subscriptionService.getUserSubscriptionById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.UserSubscriptionVM = response.data;
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }


  public loadHistoryList(event){
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
        self._subscriptionService.getAssignSubscriptionHistory(event, this.value, this.columnSorting, this.columnDirection, self.id)
          .subscribe((data :any)=> {
            self._commonService.hideLoader();
            let response: any = data;
            if (!self._commonService.isNullOrEmpty(response.data)) {

              self.userAssignSubscriptionHistory = new MatTableDataSource<UserSubscriptionVM>(response.data.list);
              if (response.data.list.length > 0) {
                self.totalLength = response.data.total_count;
              }

              self.noData = (response.data.list != null && response.data.list.length > 0) ? false : true;
              if (self.noData == true) {
                self.totalLength = 0;
              }
            }

          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
     
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

}
