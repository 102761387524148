// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// @Models
import { WarehouseVM } from 'src/app/models/WarehouseVM';

// @Services
import { REGEXP } from 'src/app/shared/regexp';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { CommonService } from 'src/app/services/common.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { UserVM } from 'src/app/models/UserVM';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'selenium-webdriver/ie';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-warehouse-edit',
  templateUrl: './warehouse-edit.component.html',
  styleUrls: ['./warehouse-edit.component.css']
})
export class WarehouseEditComponent implements OnInit {
  public select2Options: Options;
  public select2UserData: Array<Select2OptionData>;
  id: number;
  editWarehouseForm: FormGroup;
  warehouseVM = new WarehouseVM();
  submitted = false;
  userdata: UserVM[];
  role: number;
  permissionsData = [];
  @ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;

  constructor(
    private _formBuilder: FormBuilder,
    private _warehouseService: WarehouseService,
    private _commonService: CommonService,
    private _regex: REGEXP,
    private _router: Router,
    private _route: ActivatedRoute,
    private _roleService: RoleService,
    private _storageService: StorageService
  ) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.initializeFormValue();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[10].sub_permission[2].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getAllActiveUserData() {
    let self = this;
    self._warehouseService.getAllActiveUserData().subscribe(data => {
      let response: any = data;
      if (response.user_data != null) {
        self.userdata = response.user_data;
        let select2UserData: any = [];
        if (self.userdata != null && self.userdata.length > 0) {
          self.userdata.forEach(item => {
            select2UserData.push({
              id: item.id,
              text: `${item.first_name} ${item.last_name}`,
            })
          });
        }

        self.select2UserData = select2UserData;
      }
    });
  }

  initializeFormValue() {
    let self = this;
    Promise.all([
      self.getAllActiveUserData()

    ]).then((data: any) => {
      this._route.params.subscribe(params => { this.id = params['id']; });
      let self = this;

      self._commonService.showLoader();
      self._warehouseService.getWarehouseById(self.id)
        .subscribe(data => {
          self._commonService.hideLoader();
          let response: any = data;
          self.warehouseVM = response.warehouse;
          self.buildForm();
        });
    });

  }

  buildForm() {
    this.editWarehouseForm = this._formBuilder.group({
      warehouse_id: [this._commonService.convertToNumber(this.warehouseVM.warehouse_id)],
      code: [this.warehouseVM.code, [Validators.required, Validators.maxLength(255)]],
      ware_house_name: [this.warehouseVM.ware_house_name, [Validators.required, Validators.maxLength(255)]],
      contact_user_id: [this._commonService.convertToNumber(this.warehouseVM.contact_user_id), Validators.required],
      phone_no: [this.warehouseVM.phone_no, [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
      email: [this.warehouseVM.email, [Validators.required, Validators.pattern(this._regex.EMAIL_REGEXP)]],
      address: [this.warehouseVM.address, [Validators.required, Validators.maxLength(255)]],
      latitude: [this.warehouseVM.latitude],
      longitude: [this.warehouseVM.longitude],
      is_active: [this.warehouseVM.is_active, Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.editWarehouseForm.controls; }

  gotoWarehouseList() {
    this._router.navigate(['/admin/warehouses']);
  }

  save() {
    let self = this;
    self.submitted = true;

    // stop here if form is invalid
    if (self.editWarehouseForm.invalid) {
      return;
    }

    self._commonService.showLoader();
    self._warehouseService.updateWarehouse(self.editWarehouseForm.value).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoWarehouseList();
        self._commonService.showToaster("Warehouse updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

  public handleAddressChange(address) {
    this.editWarehouseForm.controls['address'].setValue(address.formatted_address);
    this.editWarehouseForm.controls['latitude'].setValue(address.geometry.location.lat());
    this.editWarehouseForm.controls['longitude'].setValue(address.geometry.location.lng())
  }

}
