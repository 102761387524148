//packages
import { Component, OnInit, ViewChild, NgZone, HostListener, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { LightboxModule, Lightbox } from 'ngx-lightbox';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2';
//#region  Functions defined in assets/js/customn/custom-script.js.
declare function bindSlickPlugin(carouselElement, carouselNavElement): any;
//#endregion

//services
import { ItemService } from 'src/app/services/item.service';
import { CommonService } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { SubscriptionService } from 'src/app/services/subscriprion.service';
//model
import { ItemVM } from 'src/app/models/ItemVM';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleService } from 'src/app/services/role.service';
import { NgxImgZoomService } from 'ngx-img-zoom';
declare var $: any;

@Component({
  selector: 'app-show-detail-layout',
  templateUrl: './show-detail-layout.component.html',
  styleUrls: ['./show-detail-layout.component.css']
})

export class ShowDetailLayoutComponent implements OnInit {

  id: number;
  imageLoaded: boolean = true;
  ItemVM = new ItemVM();
  relatedItem: ItemVM[] = [];
  relatedItemCatId: ItemVM[] = [];
  submitted = false;
  _albums = new Array();
  reasonData: string;
  role: number;
  user: any;
  item_id: any;
  selected_cats_from_listing: string;
  public pageNumber: number;
  public pageSize: number;
  public paginationValue: number;
  public countMaxBestSeller: number;
  permissionsData = [];
  metal_id_array = [];
  item_status_array = [];
  item_group_array = [];
  filteration: any;
  public subcategory_id: any;
  enableZoom: Boolean = true;
  watermarkImagePath: any;
  values = '';
  hideMessage = true;
  loadingImage: boolean = true
  isAllowDownload = false;
  totalcount:any;

  onScroll() {
    if (this.relatedItem.length < this.totalcount) {
      this.paginationValue = this.pageNumber * this.pageSize;
      this.pageNumber++;
      this.getRelatedItemById(this.id, this.paginationValue, this.pageSize, this.filteration, this.values);
    }
  }

  constructor(private _itemService: ItemService,
    private _commonService: CommonService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _roleService: RoleService,
    private _lightbox: Lightbox,
    private _storageService: StorageService,
    private _subscriptionService: SubscriptionService,   
    private ngxImgZoom: NgxImgZoomService) {
    // this.ngxImgZoom.setZoomBreakPoints([{w: 100, h: 100}, {w: 150, h: 150}, {w: 200, h: 200}, {w: 250, h: 250}, {w: 300, h: 300}]);
  }

  ngOnInit() {
    this.getPermission();
    this._route.queryParams.subscribe(params => {
      this.selected_cats_from_listing = params['source'];
      this.id = params['itemid'];
      this.metal_id_array = params['metalid'];
      this.item_status_array = params['item_status_id'];
      this.item_group_array = params['item_group_id'];
      this.filteration = params['filter'];
      this.subcategory_id = params['subcategory_id'];
      this.ngxImgZoom.setZoomBreakPoints([{ w: 100, h: 100 }, { w: 150, h: 150 }, { w: 200, h: 200 }, { w: 250, h: 250 }, { w: 300, h: 300 }]);
      this.pageNumber = 1;
      this.pageSize = 12;
      this.paginationValue = 0;
      this.getItemDetailById();
      this.relatedItem = [];
      this.getRelatedItemById(this.id, this.paginationValue, this.pageSize, this.filteration, this.values);
      this.getRelatedItemBycatId(this.id, this.paginationValue, this.pageSize);
      this.getDownloadStatus();
    });

    this.role = this._commonService.convertToNumber(this._storageService.getUserRoles());
    //  bindSlickPlugin('carousel', 'carousel-nav');
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[19].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getDownloadStatus() {
   // this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._subscriptionService.getDistSubscriptionStatus()
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (response.data != undefined) {
          self.isAllowDownload = true;
        }
      });
  }

  // ngAfterViewInit() {
  //   bindSlickPlugin('carousel', 'carousel-nav');
  // }

  trackByFn(index, item) {
    if (!item) return null;
    return index; // or item.disaggregationID;
  }

  getItemDetailById() {
    //this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    this._albums = [];
    self._itemService.getItemDetailById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (response.item_detail.item_gallery_data.length > 0) {
          for (let i = 0; i < response.item_detail.item_gallery_data.length; i++) {
            if (response.item_detail.item_gallery_data[i].original_image_path != null && response.item_detail.item_gallery_data[i].process_image_path != null) {
              const src = this._commonService.getFullImageUrl(response.item_detail.item_gallery_data[i].original_image_path);
              const caption = response.item_detail.item_gallery_data[i].image_details;
              const thumb = this._commonService.getFullImageUrl(response.item_detail.item_gallery_data[i].process_image_path);
              const album = {
                src: src,
                caption: caption,
                thumb: thumb
              };
              this._albums.push(album);
            }
          }
        }
        self.ItemVM = response.item_detail;
        //self.imageLoaded = true;
        bindSlickPlugin('carousel', 'carousel-nav');
        this.ngxImgZoom.setZoomBreakPoints([{ w: 100, h: 100 }, { w: 150, h: 150 }, { w: 200, h: 200 }, { w: 250, h: 250 }, { w: 300, h: 300 }]);
      });
  }

  getRelatedItemById(id, paginationValue, pagingationSize, filteration, values) {
    let self = this;
    self._commonService.showLoader();
    self._itemService.getRelatedItemById(id, self.selected_cats_from_listing, paginationValue, pagingationSize, this.metal_id_array, this.item_status_array, this.item_group_array, filteration, values, this.subcategory_id)
      .subscribe(data => {
        self._commonService.hideLoader();
        self.hideMessage = false;
        let response: any = data;
        if (response.item_data != undefined) {
          self.totalcount = response.total_count;
          
          if (self.relatedItem.length > 0) {
            response.item_data.forEach(element => {
              const itemExist = self.relatedItem.find(i => i.item_id == element.item_id);
              if (!itemExist) {
                self.relatedItem.push(element);
              }
            });
          }
          else {
            self.relatedItem = response.item_data;
          }
        }
        // this.countMaxBestSeller = Math.max.apply(Math, self.relatedItem.map(function (o) { return o.total_order_received; }));
      });
  }

  getRelatedItemBycatId(id, paginationValue, pagingationSize) {
    let self = this;
    self.relatedItemCatId = [];
    self._commonService.showLoader();
    self._itemService.getRelatedItemBycatId(id, self.selected_cats_from_listing, paginationValue, pagingationSize, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.subcategory_id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (response.item_data != undefined) {
          if (self.relatedItemCatId.length > 0) {
            response.item_data.forEach(element => {
              //Don't remove this logic: strict warning!!!
              const itemExist = self.relatedItemCatId.find(i => i.item_id == element.item_id);
              if (!itemExist) {
                self.relatedItemCatId.push(element);
              }
            });
          }
          else {
            self.relatedItemCatId = response.item_data;
          }
        }
        // this.countMaxBestSeller = Math.max.apply(Math, self.relatedItem.map(function (o) { return o.total_order_received; }));
      });
  }

  itemdetail(relatedData: ItemVM) {
    this.relatedItem = [];
    this.relatedItemCatId = [];
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    if (this.subcategory_id == undefined) {
      this._router.navigate(['mainshowcase/showdetail', relatedData.item_id], { queryParams: { source: this.selected_cats_from_listing, itemid: relatedData.item_id, } });
    }
    else {
      this._router.navigate(['mainshowcase/showdetail', relatedData.item_id], { queryParams: { source: this.subcategory_id, itemid: relatedData.item_id, } });
    }
  }

  relateditemdetail(relatedData: ItemVM) {
    this.relatedItem = [];
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    this._router.navigate(['mainshowcase/showdetail', relatedData.item_id], { queryParams: { source: relatedData.AssociatedItemCategoryID, itemid: relatedData.item_id, } });
    let self = this;
    self._commonService.showLoader();
    self._itemService.getRelatedItemById(relatedData.item_id, relatedData.AssociatedItemCategoryID, this.paginationValue, this.pageSize, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.values, this.subcategory_id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (self.relatedItem.length > 0) {
          response.item_data.forEach(element => {
            const itemExist = self.relatedItem.find(i => i.item_id == element.item_id);
            if (!itemExist) {
              self.relatedItem.push(element);
            }
          });
        }
        else {
          self.relatedItem = response.item_data;
        }
        //  this.countMaxBestSeller = Math.max.apply(Math, self.relatedItem.map(function (o) { return o.total_order_received; }));
      });

    this.relatedItemCatId = [];
    self._itemService.getRelatedItemBycatId(relatedData.item_id, relatedData.AssociatedItemCategoryID, this.paginationValue, this.pageSize, this.metal_id_array, this.item_status_array, this.item_group_array, this.filteration, this.subcategory_id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (response.item_data != undefined) {
          if (self.relatedItemCatId.length > 0) {
            response.item_data.forEach(element => {
              //Don't remove this logic: strict warning!!!
              const itemExist = self.relatedItemCatId.find(i => i.item_id == element.item_id);
              if (!itemExist) {
                self.relatedItemCatId.push(element);
              }
            });
          }
          else {
            self.relatedItemCatId = response.item_data;
          }
        }
        // this.countMaxBestSeller = Math.max.apply(Math, self.relatedItem.map(function (o) { return o.total_order_received; }));
      });
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  goToDistributorItems() {
    this._router.navigate(['/admin/distributor-items']);
  }

  placeSalesOrder(item_id) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to Add Item to Cart?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    swal.fire(options).then((result) => {
      if (result.value) {
        // code for  password confirmation.
        const options1: SweetAlertOptions = {
          title: 'Enter Your Password',
          //input: 'text',
          html: '<input id="swal-input1" class="string_trim" type="password">',
          inputAttributes: {
            autocapitalize: 'off'
          },
          type: 'error',
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          focusCancel: true,
          preConfirm: function () {
            return new Promise(function (resolve, reject) {
              setTimeout(function () {
                let text = $('#swal-input1').val();
                self._commonService.showLoader();
                self._authService.checkDistributorPassword(text).subscribe(data => {
                  let response: any = data;
                  self._commonService.hideLoader();
                  if (response.success === false) {
                    swal.showValidationMessage('Invalid Password Try Again.');
                    swal.enableButtons();
                  }
                  else {
                    // self._router.navigate(['/admin/sales-orders/new/', item_id], { queryParams: { catid: self.selected_cats_from_listing } });
                    /*------------------- Local and qa Url--------------------- */
                   let url = "/admin/sales-orders/new/" + item_id + "?catid=" + self.selected_cats_from_listing
                    /*------------------- Live Url--------------------- */
                    //let url = "/avsar/admin/sales-orders/new/" + item_id + "?catid=" + self.selected_cats_from_listing
                    window.open(url, '_blank');
                    swal.close();
                  }
                }, error => {
                  self._commonService.hideLoader();
                });
              }, 2000)
            })
          },
          allowOutsideClick: false,
        };
        swal.fire(options1);
      }
    })
  }

  showRejectReason() {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Reason',
      html: self.ItemVM.reject_reason,
      confirmButtonText: "Close"
    };
    swal.fire(options);
  }

  approveItem(item: ItemVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to approve this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._itemService.approveItem(item.item_id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Item approved successfully.", "success");
          self.goToDistributorItems();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  rejectItem(item: ItemVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to reject this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    swal.fire(options).then((result) => {
      if (result.value) {
        // code for admin password confirmation.
        const options1: SweetAlertOptions = {
          title: 'Rejection Reason',
          input: 'textarea',
          inputAttributes: {
            autocapitalize: 'off'
          },
          type: 'question',
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          focusCancel: true,
          preConfirm: function (textarea) {
            return new Promise(function (resolve, reject) {
              setTimeout(function () {
                if (textarea != '' && textarea != null) {
                  self.reasonData = textarea;
                } else {
                  swal.showValidationMessage('Please Enter Reason');
                  swal.enableButtons();
                }
              }, 2000)
            })
          },
          allowOutsideClick: false,
        };
        swal.fire(options1).then((result) => {
          if (result.value != '' && result.value != null) {
            self._commonService.showLoader();
            let formReasonData = new FormData();
            formReasonData.delete('reject_reason');
            formReasonData.append('reject_reason', self._commonService.parseString(self.reasonData));
            self._itemService.rejectItem(item.item_id, formReasonData).subscribe(data => {
              self._commonService.hideLoader();
              self._commonService.showToaster("Item rejected successfully.", "success");
              self.goToDistributorItems();
            }, error => {
              self._commonService.hideLoader();
              self._commonService.showToaster(error.message, "error");
            });
          }
        })
      }
    })
  }

  mouseenter(itemImage, original_image_path) {
    itemImage.src = this.getImageUrl(original_image_path);
  }

  mouseleave(itemImage, thumbnail_image_path) {
    itemImage.src = this.getImageUrl(thumbnail_image_path);
  }

  getFullUrl(item_image) {
    return this._commonService.getFullImageUrl(item_image);
  }

  watermarkImageDownload(item) {
    let self = this;
    self._commonService.showLoader();
    self._itemService.watermarkImageDownload(item).subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self._commonService.hideLoader();
        self.watermarkImagePath = response.data
        const linkSource = self.watermarkImagePath;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = item.item_name;
        downloadLink.click();
      }
       this.getDownloadStatus();
    });
  }

  watermarkPinterest(item) {
    let self = this;
    self._commonService.showLoader();
    self._itemService.watermarkPinterest(item).subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self._commonService.hideLoader();
        self.watermarkImagePath = this.getFullUrl(response.data);
        let redirectURL = 'https://www.pinterest.com/pin/create/button/?media=' + self.watermarkImagePath;
        window.open(redirectURL, '_blank');
      }
    });
  }

  watermarkFacebook(item) {
    let self = this;
    self._commonService.showLoader();
    self._itemService.watermarkFacebook(item).subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self._commonService.hideLoader();
        self.watermarkImagePath = this.getFullUrl(response.data);
        let redirectURL = 'http://www.facebook.com/share.php?u=' + self.watermarkImagePath;
        window.open(redirectURL, '_blank');
      }
    });
  }

  onKey(event: any) {
    this.relatedItem = [];
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    this.values = event.target.value;
    this.getRelatedItemById(this.id, this.paginationValue, this.pageSize, this.filteration, this.values);
  }

  onLoadImage() {
    this.loadingImage = false;
  }
}
