import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
// @Models
import { UserVM } from 'src/app/models/UserVM';
import { DistributorVM } from 'src/app/models/DistributorVM';
import { DistributorGroupVM } from 'src/app/models/DistributorGroupVM';
import { CountryVM } from 'src/app/models/CountryVM';
import { StateVM } from 'src/app/models/StateVM';
// @Services
import { REGEXP } from 'src/app/shared/regexp';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { DistributorService } from 'src/app/services/distributor.service';
import { CommonService } from 'src/app/services/common.service';
import { DistrictVM } from 'src/app/models/DistrictVM';
import { RoleVM } from 'src/app/models/RoleVM';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';
import { CountryNewVM, StateNewVM } from 'src/app/models/CommonVM';
import { SubscriptionVM } from 'src/app/models/SubscriptionVM';
import { SubscriptionService } from 'src/app/services/subscriprion.service';

@Component({
  selector: 'app-distributor-edit',
  templateUrl: './distributor-edit.component.html',
  styleUrls: ['./distributor-edit.component.css']
})

export class DistributorEditComponent implements OnInit {

  @ViewChild("logo", { static: false }) distributorlogoInput;
  @ViewChild("watermark", { static: false }) watermarkInput;
  @ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;

  //Select 2
  public select2Options: Options;
  public select2CountryData: Array<Select2OptionData>;
  public select2StateData: Array<Select2OptionData>;
  public select2OptionsDistributorGroup: Options;
  public select2GroupData: Array<Select2OptionData>;
  public select2DistrictData: Array<Select2OptionData>;

  id: number;
  editDistributorForm: FormGroup;
  DistributorVM = new DistributorVM();
  UserVM = new UserVM();
  groupdata: DistributorGroupVM[];
  distributorimageurl: string;
  watermarkimageurl: string;
  districtdata: DistrictVM[];
  submitted = false;
  roleData: RoleVM[];
  countrydata: CountryVM[];
  statedata: StateVM[];
  role: number;
  permissionsData = [];
  statedataNew: StateNewVM[];
  public select2RolesData: Array<Select2OptionData>;
  public select2SubscriptionData: Array<Select2OptionData>;
  subscriptionData: SubscriptionVM[];

  constructor(
    private _formBuilder: FormBuilder,
    private distributorService: DistributorService,
    private _commonService: CommonService,
    private _regex: REGEXP,
    private _roleService: RoleService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _storageService: StorageService,
    private _subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    let self = this;
    self._route.params.subscribe(params => { self.id = params['id']; });
    this.select2OptionsDistributorGroup = {
      multiple: true
    }
    this.buildForm();
    Promise.all([
      // this.getAllGroupData(),
      // this.getAllCountryData(),
      // this.getAllStateData()
      this.getGroups(),
      this.getCountries(),
      //  this.getStates(),
      this.getDistributorDetail(),
      this.getAllRoles(),
      this.getAllSubscriptionData()
    ]).then((data: any) => {
      if (data != null && data != undefined) {
        let groupResult = data[0];
        let countryResult = data[1];
        // let stateResult = data[2];
        let distributorResult = data[2];
        let subscriptionResult = data[3];
        //Groups
        if (groupResult != null && groupResult.success == true) {
          self.groupdata = groupResult.data.group_data;
          self.select2GroupData = self._commonService.dropdownArrayDistributorGroup(self.groupdata, "distributor_group_id", "group_name");
        }
        //Countries
        if (countryResult != null && countryResult.success == true) {
          self.countrydata = countryResult.data.data;
          self.select2CountryData = self._commonService.dropdownArray(self.countrydata, "id", "name");
        }
        //States
        /*  if (stateResult != null && stateResult.success == true) {
            self.statedata = stateResult.data.state_data;
            self.select2StateData = self._commonService.dropdownArray(self.statedata, "id", "name");
          } */
        //Distributor Detail


        if (distributorResult != null && distributorResult.success == true) {
          self.DistributorVM = distributorResult.data.distributor as DistributorVM;
          this.getAllStateDataNew(self.DistributorVM.country);
          this.getBindDistrictData(self.DistributorVM.state);

        }

        //Subscription
        if (subscriptionResult != null && subscriptionResult.success == true) {
          self.subscriptionData = subscriptionResult.data;
          self.select2SubscriptionData = self._commonService.dropdownArray(self.subscriptionData, "SubscriptionId", "SubscriptionName");
        }

        self.buildForm();
      }
    });


  }


  getAllStateDataNew(country) {
    var mStateDataNewVM = new StateNewVM();
    this.statedataNew = new Array();
    this.statedataNew.push(mStateDataNewVM);
    let temp: any = [];
    let self = this;
    self._commonService.showLoader();
    self.distributorService.getStateById(country).subscribe(data => {
      let response: any;
      response = data;
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self.statedataNew = response.data;
        self.select2StateData = self._commonService.dropdownArray(self.statedataNew, "id", "name");
      }
    });

  }


  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[13].sub_permission[2].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  get f() { return this.editDistributorForm.controls; }

  gotoDistributorList() {
    this._router.navigate(['/admin/distributor']);
  }

  getAllRoles() {
    let self = this;
    self._roleService.getActiveRoles().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.roleData = response.data;
        self.select2RolesData = self._commonService.dropdownArray(self.roleData, "id", "name");
      }
    });
  }

  getAllSubscriptionData() {
    let self = this;
    self._subscriptionService.getAllActiveSubscription().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.subscriptionData = response.data;
        self.select2SubscriptionData = self._commonService.dropdownArray(self.subscriptionData, "SubscriptionId", "SubscriptionName");
      }
    });
  }

  getAllCountryData() {
    let self = this;
    self.distributorService.getAllCountryData().subscribe(data => {
      let response: any = data;
      if (response.country_data != null) {
        self.countrydata = response.country_data;
      }
    });
  }

  getCountries() {
    return new Promise((resolve, reject) => {
      let self = this;
      self.distributorService.getAllCountryData().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getAllStateData() {
    let self = this;
    self.distributorService.getAllStateData().subscribe(data => {
      let response: any = data;
      if (response.state_data != null) {
        self.statedata = response.state_data;
      }
    });
  }

  getStates() {
    return new Promise((resolve, reject) => {
      let self = this;
      self.distributorService.getAllStateData().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getAllDistrictData(selectedCode) {
    let self = this;
    // self.distributorService.getAllDistrictData().subscribe(data => {
    self.distributorService.getAllDistrictDataById(selectedCode.data[0].id).subscribe(data => {
      let response: any = data;
      if (response.district_data != null) {
        self.districtdata = response.district_data;
        self.select2DistrictData = self._commonService.dropdownArray(self.districtdata, "id", "city_name");
      }
    });
  }

  getBindDistrictData(selectedCode) {
    let self = this;
    // self.distributorService.getAllDistrictData().subscribe(data => {
    self.distributorService.getAllDistrictDataById(selectedCode).subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.districtdata = response.data;
        self.select2DistrictData = self._commonService.dropdownArray(self.districtdata, "id", "city_name");
      }
    });
  }

  getAllGroupData() {
    let self = this;
    self.distributorService.getAllGroupData().subscribe(data => {
      let response: any = data;
      if (response.group_data != null) {
        self.groupdata = response.group_data;
      }
    });
  }

  getGroups() {
    return new Promise((resolve, reject) => {
      let self = this;
      self.distributorService.getAllGroupData().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getDistributorDetail() {

    return new Promise((resolve, reject) => {
      let self = this;
      self.distributorService.getDistributorById(self.id).subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getDistributorData() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self.distributorService.getDistributorById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.DistributorVM = response.distributor;
        self.buildForm();
      });
  }

  buildForm() {
    let first_name = (this.DistributorVM.user) ? this.DistributorVM.user.first_name : '';
    let last_name = (this.DistributorVM.user) ? this.DistributorVM.user.last_name : '';
    let email = (this.DistributorVM.user) ? this.DistributorVM.user.email : '';
    let roleid = (this.DistributorVM.role) ? this.DistributorVM.role.id : '';

    let DistributorForm = {
      distributor_id: [this._commonService.convertToNumber(this.DistributorVM.distributor_id)],
      distributor_code: [this.DistributorVM.distributor_code, [Validators.required, Validators.maxLength(255)]],
      distributor_first_name: [first_name, [Validators.required, Validators.maxLength(255)]],
      distributor_last_name: [last_name, [Validators.required, Validators.maxLength(255)]],
      company_name: [this.DistributorVM.company_name, [Validators.required, Validators.maxLength(255)]],
      display_name: [this.DistributorVM.display_name, [Validators.required, Validators.maxLength(255)]],
      business_address: [this.DistributorVM.business_address, [Validators.required, Validators.maxLength(255)]],
      email: [email, [Validators.required, Validators.pattern(this._regex.EMAIL_REGEXP)]],
      mobile_no: [this.DistributorVM.mobile_no, [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
      gst_no: [this.DistributorVM.gst_no, [Validators.required, Validators.pattern(this._regex.GST_EXP), Validators.maxLength(255)]],
      item_add_limit: [this.DistributorVM.item_add_limit, [Validators.required, Validators.maxLength(255)]],
      registration_no: [this.DistributorVM.registration_no, [Validators.required, Validators.maxLength(255)]],
      // group_id: [this.DistributorVM.group_id, Validators.required],
      user_id: [this.DistributorVM.user_id, Validators.required],
      // total_business: [this.DistributorVM.total_business, [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      country: [this.DistributorVM.country, Validators.required],
      state: [this.DistributorVM.state, [Validators.required, Validators.maxLength(255)]],
      city: [this.DistributorVM.city, [Validators.required, Validators.maxLength(255)]],
      // district: [this.DistributorVM.district, [Validators.required, Validators.maxLength(255)]],
      postal_code: [this.DistributorVM.postal_code, RxwebValidators.alphaNumeric()],
      is_active: [this.DistributorVM.is_active, Validators.required],
      role_id: [this._commonService.convertToNumber(roleid), Validators.required],
      subscription_id: [this.DistributorVM.subscription_id, Validators.required],
      is_item_show: [this.DistributorVM.is_item_show == null ? 0 : this.DistributorVM.is_item_show]
    }
    if (this._commonService.isNullOrEmpty(this.DistributorVM.logo)) {
      DistributorForm['logo'] = [this.DistributorVM.logo, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];

    } else {
      DistributorForm['logo'] = [this.DistributorVM.logo, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
      this.distributorimageurl = this._commonService.getFullImageUrl(this.DistributorVM.logo);
    }
    if (this._commonService.isNullOrEmpty(this.DistributorVM.watermark)) {
      DistributorForm['watermark'] = [this.DistributorVM.watermark, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
    } else {
      DistributorForm['watermark'] = [this.DistributorVM.watermark, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
      this.watermarkimageurl = this._commonService.getFullImageUrl(this.DistributorVM.watermark);
    }
    if (this.DistributorVM.group_id_multiple != null && this.DistributorVM.group_id_multiple != undefined) {
      let availble_dis = this.DistributorVM.group_id_multiple.split(',');
      if (availble_dis != undefined && availble_dis.length > 0) {
        //let availble_dis_arr =  this;
        //availble_dis_arr.push('temp');
        // this.select2OptionsDistributor = {
        //     data: availble_dis_arr
        // };
        DistributorForm['group_id_multiple'] = [availble_dis];
      }
      else {
        DistributorForm['group_id_multiple'] = [''];
      }
    } else {
      DistributorForm['group_id_multiple'] = [''];
    }
    this.editDistributorForm = this._formBuilder.group(DistributorForm);
  }

  save() {
    let self = this;
    self.submitted = true;
    if (self.editDistributorForm.invalid) {
      return;
    }
    let formData = new FormData();
    let DistributorData = (self.editDistributorForm.value as DistributorVM);

    for (const prop in DistributorData) {
      if (self.editDistributorForm.controls.is_item_show.value == true) {
        formData.append("is_item_show", "1");
      }
      else {
        formData.append("is_item_show", "0");
      }
      let fi = self.distributorlogoInput.nativeElement;
      if (prop == 'logo') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.editDistributorForm.value.logo = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      }
      else {
        formData.append(prop, self._commonService.parseString(DistributorData[prop]));
      }
      let wi = self.watermarkInput.nativeElement;
      if (prop == 'watermark') {
        if (wi.files && wi.files[0]) {
          let fileToUpload = wi.files[0];
          self.editDistributorForm.value.watermark = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      }
      else {
        formData.append(prop, self._commonService.parseString(DistributorData[prop]));
      }
      if (prop == 'group_id_multiple') {
        let group_id_multipleArray: any = DistributorData[prop];
        let group_id_multiple: string = "";
        if (group_id_multipleArray != null && group_id_multipleArray != undefined && group_id_multipleArray.length > 0) {
          group_id_multipleArray.forEach(element => {
            group_id_multiple += element + ",";
          });
          if (!self._commonService.isNullOrEmpty(group_id_multiple)) {
            group_id_multiple = group_id_multiple.slice(0, -1); //Remove last comma.
          }
        }
        formData.append(prop, group_id_multiple);
      }
    }
    self._commonService.showLoader();
    self.distributorService.updateDistributor(formData).subscribe(data => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoDistributorList();
        self._commonService.showToaster("Distributor updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

  public handleAddressChange(business_address) {
    this.editDistributorForm.controls['business_address'].setValue(business_address.formatted_address);
  }

  onChangeGetState(selectedCountry) {
    let self = this;
    self._commonService.showLoader();
    self.distributorService.getStateById(selectedCountry.data[0].id).subscribe(data => {
      let response: any;
      response = data;
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self.statedata = response.state;
        self.select2StateData = self._commonService.dropdownArray(self.statedata, "id", "name");
      }
    });
  }
}
