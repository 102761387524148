// @Packages
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { filter, map, mergeMap } from 'rxjs/operators';
import { StorageService } from './services/storage.service';
declare var $: any;
//declare function allowedonlynumber(): any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private _storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.setPageTitle();
    $(document).ready(function () {
      // $(document).on('click','a',function(e){
      //   $('a').removeClass("clicked");
      //   $(this).addClass("clicked");
      //   // $(this).css("color", "#008F11");
      // });

      $(document).on('click', 'li.assigndata', function (e) {
        $('.assigninfo').show();
        $('.selectassign').show();
        $('.listview').show();
        $('.listview1').show();
        e.preventDefault();
      });


      // $('.carousel').slick({
      //   autoplay: true,
      //   slidesToShow: 1,
      //   slidesToScroll: 1,
      //   arrows: false,
      //   asNavFor: '.carousel-nav'
      // });
      // $('.carousel-nav').slick({
      //   slidesToShow: 4,
      //   slidesToScroll: 1,
      //   asNavFor: '.carousel',
      //   dots: false,
      //   arrows: false,
      //   centerMode: false,
      //   focusOnSelect: true
      // });

      // disabled right click.
      // $(document).bind("contextmenu", function (e) {
      //   return false;
      // });

         //   $(document).keydown(function (event) {
    //     if (event.keyCode == 123) { // Prevent F12
    //         return false;
    //     } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) { // Prevent Ctrl+Shift+I        
    //         return false;
    //     }
    // });

      // permission module.
      $(document).on('change', '.view_all', function () {

        var tr = $(this).closest('tr');

        var ischecked = $(this).is(':checked');

        if (!ischecked) {
          tr.find(".check_all").prop('checked', false);
        }

      });
      // $(document).on('change','#global_check_all',function() {

      //   var ischecked= $(this).is(':checked');

      //   $(document).find("input:checkbox").prop('checked', this.checked);

      //   if($(document).find("input:checked").not("#global_check_all").length == $(document).find("input:checkbox").not("#global_check_all").length){
      //     $("#global_check_all").prop('checked', true);

      //   }else{

      //     $("#global_check_all").prop('checked', false);

      //   }

      // });

 

      $(document).on("change", ".permission", function () {

        var ischecked = $(this).is(':checked');
        if (ischecked) {
          $(this).parent().parent().parent().find('.view_all').prop('checked', this.checked);
        } else {
          $(this).parent().parent().parent().find('.view_all').removeAttr('checked');
        }


      });

      $(document).on("keyup", ".string_trim", function () {

        var str = $(this).val();
        var s = str.replace(/\s+/, "");
        $('.string_trim').val($.trim(s));


      });
      $(document).on("keyup", ".string_trim1", function () {

        var str = $(this).val();
        var s = str.replace(/\s+/, "");
        $('.string_trim1').val($.trim(s));


      });
      $(document).on("keyup", ".string_trim2", function () {

        var str = $(this).val();
        var s = str.replace(/\s+/, "");
        $('.string_trim2').val($.trim(s));


      });
      $(".collapsible").collapsible({
        accordion: true,
        onOpenStart: function () {
          // Removed open class first and add open at collapsible active
          $(".collapsible > li.open").removeClass("open");
          setTimeout(function () {
            $("#slide-out > li.active > a")
              .parent()
              .addClass("open");
          }, 10);
        }
      });

      // Commom, Translation & Horizontal Dropdown
      $(".dropdown-trigger").dropdown();

      // Commom, Translation
      $(".dropdown-button").dropdown({
        inDuration: 300,
        outDuration: 225,
        constrainWidth: false,
        hover: true,
        gutter: 0,
        coverTrigger: true,
        alignment: "left"
        // stopPropagation: false
      });

      // Notification, Profile, Translation, Settings Dropdown & Horizontal Dropdown
      $(".notification-button, .profile-button, .translation-button, .dropdown-settings, .dropdown-menu").dropdown({
        inDuration: 300,
        outDuration: 225,
        constrainWidth: false,
        hover: false,
        gutter: 0,
        coverTrigger: false,
        alignment: "right"
        // stopPropagation: false
      });
      // $('.string_trim').keyup(function () {
      //   var str = $(this).val();
      //   var s = str.replace(/\s+/, "");
      //   $('.string_trim').val($.trim(s));
      // });

      $(".nav-collapsible .navbar-toggler").click(function () {
        // Toggle navigation expan and collapse on radio click
        if ($(".sidenav-main").hasClass("nav-expanded") && !$(".sidenav-main").hasClass("nav-lock")) {
          $(".sidenav-main").toggleClass("nav-expanded");
          $("#main").toggleClass("main-full");
        } else {
          $("#main").toggleClass("main-full");
        }
        // Set navigation lock / unlock with radio icon
        if (
          $(this)
            .children()
            .text() == "radio_button_unchecked"
        ) {
          $(this)
            .children()
            .text("radio_button_checked");
          $(".sidenav-main").addClass("nav-lock");
          $(".navbar .nav-collapsible").addClass("sideNav-lock");
        } else {
          $(this)
            .children()
            .text("radio_button_unchecked");
          $(".sidenav-main").removeClass("nav-lock");
          $(".navbar .nav-collapsible").removeClass("sideNav-lock");
        }
      });

      // Expand navigation on mouseenter event
      $(".sidenav-main.nav-collapsible, .navbar .brand-sidebar").mouseenter(function () {
        if (!$(".sidenav-main.nav-collapsible").hasClass("nav-lock")) {
          $(".sidenav-main.nav-collapsible, .navbar .nav-collapsible")
            .addClass("nav-expanded")
            .removeClass("nav-collapsed");
          $("#slide-out > li.close > a")
            .parent()
            .addClass("open")
            .removeClass("close");

          setTimeout(function () {
            // Open only if collapsible have the children
            if ($(".collapsible .open").children().length > 1) {
              $(".collapsible").collapsible("open", $(".collapsible .open").index());
            }
          }, 100);
        }
      });

      // Collapse navigation on mouseleave event
      $(".sidenav-main.nav-collapsible, .navbar .brand-sidebar").mouseleave(function () {
        if (!$(".sidenav-main.nav-collapsible").hasClass("nav-lock")) {
          var openLength = $(".collapsible .open").children().length;
          $(".sidenav-main.nav-collapsible, .navbar .nav-collapsible")
            .addClass("nav-collapsed")
            .removeClass("nav-expanded");
          $("#slide-out > li.open > a")
            .parent()
            .addClass("close")
            .removeClass("open");
          setTimeout(function () {
            // Open only if collapsible have the children
            if (openLength > 1) {
              $(".collapsible").collapsible("close", $(".collapsible .close").index());
            }
          }, 100);
        }
      });

    });




    // disabled console inspector.
    // $(document).keydown(function (event) {
    //   if (event.keyCode == 123) { // Prevent F12
    //     return false;
    //   }
    //   if (event.ctrlKey && event.shiftKey && event.keyCode == 73) {
    //     return false;
    //   }
    //   if (event.ctrlKey && event.shiftKey && event.keyCode == 67) {
    //     return false;
    //   }
    //   if (event.ctrlKey && event.shiftKey && event.keyCode == 74) {
    //     return false;
    //   }
    //   if (event.ctrlKey && event.keyCode == 85) {
    //     return false;
    //   }
    // });
  }

  setPageTitle() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        };

        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe((event) => {
      if (this._storageService.getsiteConfigsiteName() == null) {
        this.titleService.setTitle("Avsar Gold" + ' | ' + event['title'])
      }
      else {
        this.titleService.setTitle(this._storageService.getsiteConfigsiteName() + ' | ' + event['title'])

      }
   }
   );
  }
}
