import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

// @Services
import { HttpService } from './http.service';
import { AppConfig } from '../shared/app-config';
import { map, catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  apiUrl: String;
  // set http Authorization headers
  httpAuthOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };

  constructor(private _httpService: HttpService,
    private _storageService: StorageService,
    private _api: AppConfig) {
    this.apiUrl = this._api.ApiUrl;
  }

  getAllItems(event, filterValue, date, selectCode, selectName, selectGroup, availableItem, columnSorting, columnDirection) {

    var body = {
      "event": event,
      "filterValue": filterValue,
      "date": date,
      "selectCode": selectCode,
      "selectName": selectName,
      "selectGroup": selectGroup,
      "availableItem": availableItem,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection
    }
    return this._httpService.post(`${this.apiUrl}items/getAllItems`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getItemCode(itemCode, limitValue, limit) {

    var body = {
      "itemCode": itemCode,
      "limit": limit,
      "offset": limitValue

    }

    return this._httpService.post(`${this.apiUrl}items/getItemCode`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  newAddedItem(id, offset, limit, values, values1, values2) {

    var body = {
      "offset": offset,
      "limit": limit,
      "values": values,
      "values1": values1,
      "values2": values2

    }

    if (id == '') {
      return this._httpService.post(`${this.apiUrl}items/newAddedItem`, body, this.httpAuthOptions)
        .pipe(
          map((response: any) => {
            return this._httpService.extractData(response);
          }), catchError((errorRes: any) => {
            return this._httpService.handleError(errorRes);
          })
        );
    } else {
      return this._httpService.post(`${this.apiUrl}items/newEditItem/${id}`, body, this.httpAuthOptions)
        .pipe(
          map((response: any) => {
            return this._httpService.extractData(response);
          }), catchError((errorRes: any) => {
            return this._httpService.handleError(errorRes);
          })
        );
    }


  }

  editAssignItem(id, Follow_list) {

    var body = {
      "Follow_list": Follow_list
    }
    return this._httpService.post(`${this.apiUrl}items/editAssignItem/${id}`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  editSubscriptionAssignItem(id, Follow_list) {

    var body = {
      "SubscriptionId":id,
      "subscriptionItem": Follow_list
    }
    return this._httpService.post(`${this.apiUrl}subscription/updateSubscriptionItem`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }


  getAllDistributorItems(event, filterValue, columnSorting, columnDirection) {
    var body = {
      "event": event,
      "filterValue": filterValue,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection
    }
    return this._httpService.post(`${this.apiUrl}items/getAllDistributorItems`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  updateItem(item) {
    return this._httpService.post(`${this.apiUrl}items/updateItem`, item, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  addItem(item) {
    return this._httpService.post(`${this.apiUrl}items/addItem`, item, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAttributesByCategoryId(id) {
    return this._httpService.get(`${this.apiUrl}items/getAttributesByCategoryId/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }


  getRelatedItem(id) {
    return this._httpService.get(`${this.apiUrl}items/getRelatedItem/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getPngImagesById(id) {
    return this._httpService.get(`${this.apiUrl}item/getPngImagesById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
  // getItemListByCategoryId(id) {
  //   return this._httpService.get(`${this.apiUrl}items/getItemListByCategoryId/${id}`, this.httpAuthOptions)
  //     .pipe(
  //       map((response: any) => {
  //         return this._httpService.extractData(response);
  //       }), catchError((errorRes: any) => {
  //         return this._httpService.handleError(errorRes);
  //       })
  //     );
  // }

  getItemListByCategoryId(id, offset, limit, karat_id_array, metal_id_array, item_status_array, item_group_array, filteration, values, subcategory_id) {
    var body = {
      "offset": offset,
      "limit": limit,
      "karat_id_array": karat_id_array,
      "metal_id_array": metal_id_array,
      "item_status_array": item_status_array,
      "item_group_array": item_group_array,
      "filteration": filteration,
      "values": values,
      "subcategory_id": subcategory_id
    }
    return this._httpService.post(`${this.apiUrl}items/getItemListByCategoryId/${id}`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAssignItemById(id, offset, limit, values, dis_id, values1, values2) {

    var body = {
      "offset": offset,
      "limit": limit,
      "values": values,
      "dis_id": dis_id,
      "values1": values1,
      "values2": values2
    }
    return this._httpService.post(`${this.apiUrl}items/getAssignItemById/${id}`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getItemById(id) {
    return this._httpService.get(`${this.apiUrl}items/getItemById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  distributorAssignItemList(id) {
    return this._httpService.get(`${this.apiUrl}items/distributorAssignItemList/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  availableDistributor(id) {
    return this._httpService.get(`${this.apiUrl}items/availableDistributor/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  selectCatId(id) {
    return this._httpService.get(`${this.apiUrl}items/selectCatId/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  selectItemImage(id) {
    return this._httpService.get(`${this.apiUrl}items/selectItemImage/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  ownDistributor(id) {
    return this._httpService.get(`${this.apiUrl}items/ownDistributor/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }



  getSelectedItemCatId(id, dis_id, value, values1, values2) {

    var body = {
      "dis_id": dis_id,
      "value": value,
      "values1": values1,
      "values2": values2,
    }
    return this._httpService.post(`${this.apiUrl}items/getSelectedItemAddCatId/${id}`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getItemByCatId(id, offset, limit) {

    var body = {
      "offset": offset,
      "limit": limit
    }
    return this._httpService.post(`${this.apiUrl}items/getitemByCategoryId/${id}`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getitemByCategoryId(id) {

    return this._httpService.post(`${this.apiUrl}getitemByCategoryId/${id}`, id, this.httpAuthOptions)
      .pipe(
        map((response: any) => {

          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );

    // return this._httpService.get(`${this.apiUrl}getitemByCategoryId/${id}`, this.httpAuthOptions)
    //   .pipe(
    //     map((response: any) => {
    //       return this._httpService.extractData(response);
    //     }), catchError((errorRes: any) => {
    //       return this._httpService.handleError(errorRes);
    //     })
    //   );
  }

  getItemDetailById(id) {
    return this._httpService.get(`${this.apiUrl}getItemDetailById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getRelatedItemById(id, selectedcats, offset, limit, metal_id, item_status_id, item_group_id, filteration, values, subcategory_id) {

    var body = {
      "id": id,
      "selectedcats": selectedcats,
      "offset": offset,
      "limit": limit,
      "metal_id": metal_id,
      "item_status_id": item_status_id,
      "item_group_id": item_group_id,
      "filteration": filteration,
      "values": values,
      "subcategory_id": subcategory_id

    }

    return this._httpService.post(`${this.apiUrl}items/getRelatedItems/${id}`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );

  }

  getRelatedItemBycatId(id, selectedcats, offset, limit, metal_id, item_status_id, item_group_id, filteration, subcategory_id) {

    var body = {
      "id": id,
      "selectedcats": selectedcats,
      "offset": offset,
      "limit": limit,
      "metal_id": metal_id,
      "item_status_id": item_status_id,
      "item_group_id": item_group_id,
      "filteration": filteration,
      "subcategory_id": subcategory_id
    }

    return this._httpService.post(`${this.apiUrl}items/getRelatedItemBycatId/${id}`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );

  }

  approveItem(itemId) {
    return this._httpService.post(`${this.apiUrl}items/approveItem/${itemId}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  rejectItem(itemId, formReasonData) {
    return this._httpService.post(`${this.apiUrl}items/rejectItem/${itemId}`, formReasonData, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  deleteItem(id) {
    return this._httpService.post(`${this.apiUrl}items/deleteItem/${id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  deleteItemImage(id) {
    return this._httpService.post(`${this.apiUrl}items/deleteItemImage/${id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getPngImagesDelete(id) {
    return this._httpService.get(`${this.apiUrl}item/getPngImagesDelete/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  updateCoverImage(id, itemid) {
    let itemidar: any = [itemid];
    return this._httpService.post(`${this.apiUrl}items/updateCoverImage/${id}`, itemidar, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );

  }

  updateIsActivePNGImage(id, itemid) {
    let itemidar: any = [itemid];
    return this._httpService.post(`${this.apiUrl}items/pngIsActive/${id}`, itemidar, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );

  }

  updateSequenceNo(id, seqno) {
    let itemseqar: any = [seqno];
    return this._httpService.post(`${this.apiUrl}items/updateSequenceNo/${id}`, itemseqar, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );

  }

  updateImageDetails(id, imgdetails) {
    let imgdetailsar: any = [imgdetails];
    return this._httpService.post(`${this.apiUrl}items/updateImageDetails/${id}`, imgdetailsar, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );

  }

  updateWaterMarkDetails(id, isWaterMark) {
    let isWaterMarkar: any = [isWaterMark];
    return this._httpService.post(`${this.apiUrl}items/updateWaterMarkDetails/${id}`, isWaterMarkar, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );

  }

  updateIsLiveDetails(id, isWaterLive) {
    let isWaterLivear: any = [isWaterLive];
    return this._httpService.post(`${this.apiUrl}items/updateIsLiveDetails/${id}`, isWaterLivear, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );

  }

  getAllActiveCategories() {
    return this._httpService.get(`${this.apiUrl}items/getAllActiveCategories`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllItemStatus() {
    return this._httpService.get(`${this.apiUrl}items/getAllItemStatus`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getActivePurity() {
    return this._httpService.get(`${this.apiUrl}items/getActivePurity`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  varifySequenceNo(sequence_no) {
    return this._httpService.get(`${this.apiUrl}items/varifySequenceNo/${sequence_no}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getImagesGallery(itemid) {
    return this._httpService.get(`${this.apiUrl}items/getImagesGallery/${itemid}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );

  }

  getItemcodeById(id) {
    return this._httpService.get(`${this.apiUrl}getItemcodeById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getItemcodeByHsn(id) {
    return this._httpService.get(`${this.apiUrl}getItemcodeByHsn/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
  checkAssign(Follow_list) {
    this._storageService.setItemList(Follow_list);
    // Update login status in loggedIn$ stream
  }

  checkSubscriptionAssign(Follow_list) {
    this._storageService.setSubscriptionItemList(Follow_list);
    // Update login status in loggedIn$ stream
  }

  getAssignItemList() {
    return this._storageService.getAssignItem();
  }



  watermarkImageDownload(item) {

    var body = {
      "item": item
    }
    return this._httpService.post(`${this.apiUrl}items/watermarkImageDownload`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }


  watermarkPinterest(item) {

    var body = {
      "item": item
    }
    return this._httpService.post(`${this.apiUrl}items/watermarkPinterest`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }


  watermarkFacebook(item) {

    var body = {
      "item": item
    }
    return this._httpService.post(`${this.apiUrl}items/watermarkFacebook`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
  
   getCategoryById(id) {
    return this._httpService.get(`${this.apiUrl}categories/getCategoryDataById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getDistributorRetailerType(type) {
    return this._httpService.get(`${this.apiUrl}getDistributorRetailerType/${type}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  editCartItemById(id) {
    return this._httpService.get(`${this.apiUrl}sales-orders/getCartItemDetails/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  addPlaceOrder(type) {
    return this._httpService.post(`${this.apiUrl}sales-orders/placeOrder`,type, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
}
