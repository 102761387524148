import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { TermscategoryService } from 'src/app/services/termscategory.service';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { TermscategoryVM } from 'src/app/models/TermscategoryVM';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-termcategory-list',
  templateUrl: './termcategory-list.component.html',
  styleUrls: ['./termcategory-list.component.css']
})
export class TermcategoryListComponent implements OnInit {

  addTermsCategoryForm: FormGroup;
  getSiteConfig: any;
  pageSize: number = 50;
  termsCategoriesData: any;
  noData = false;
  role: any;
  permissionsData = [];
  displayedColumns: string[] = ['category_name', 'category_details', 'is_active', 'created_at', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private _termscategoryService: TermscategoryService,
    private _commonService: CommonService,
    private _router: Router,
    private _roleService: RoleService,
    private _storageService: StorageService,
    private _siteConfigService: SiteConfigService) {
  }

  ngOnInit() {
    this.getPermission();
    this.loadCategories();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
    });
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }
  loadCategories() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._termscategoryService.getAllTermsCategories()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.termsCategoriesData = new MatTableDataSource<TermscategoryVM>(response.data);
            self.termsCategoriesData.paginator = self.paginator;
            self.termsCategoriesData.sort = self.sort;
            self.termsCategoriesData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.noData = (response.data.length > 0) ? false : true;
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  editCategory(category: TermscategoryVM) {
    this._router.navigate(['/admin/terms-categories/edit', category.term_category_id]);
  }

  deleteCategory(category: TermscategoryVM) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this term category ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    Swal.fire(options).then((result) => {
      if (result.value) {

        self._commonService.showLoader();
        self._termscategoryService.deleteCategory(category.term_category_id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Term category deleted successfully.", "success");
          self.loadCategories();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  applyFilter(filterValue: string) {
    this.termsCategoriesData.filter = filterValue.trim().toLowerCase();
    this.noData = (this.termsCategoriesData._paginator._length > 0) ? false : true;
  }


}
