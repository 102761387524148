import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

// @Services
import { HttpService } from './http.service';
import { AppConfig } from '../shared/app-config';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomizeItemService {

  apiUrl: String;
  // set http Authorization headers
  httpAuthOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };

  constructor(private _httpService: HttpService,
    private _api: AppConfig) {
    this.apiUrl = this._api.ApiUrl;
  }

  getAllCustomizeOrders(event, filter, orderdate, itemstatus, search, columnSorting, columnDirection) {
    var body = {
      "event": event,
      "filter": filter,
      "orderdate": orderdate,
      "itemstatus": itemstatus,
      "search": search,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection,
    }
    return this._httpService.post(`${this.apiUrl}customize-orders/getAllCustomizeOrders`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getFillerCostomize(reqBody) {
    return this._httpService.post(`${this.apiUrl}customize-orders/getFillerCustomizeOrders`, reqBody, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllUserSalesOrders() {
    return this._httpService.post(`${this.apiUrl}sales-orders/getAllUserSalesOrders`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  addCustomizeOrder(customizeOrder) {
    return this._httpService.post(`${this.apiUrl}customize-orders/addCustomizeOrder`, customizeOrder, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getCustomizeOrderDetailById(id) {
    return this._httpService.get(`${this.apiUrl}customize-orders/getCustomizeOrderDetailById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  confirmCustomizeOrder(id, deliverydate) {
    return this._httpService.post(`${this.apiUrl}customize-orders/confirmCustomizeOrder/${id}`, deliverydate, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  completeCustomizeOrder(id) {
    return this._httpService.post(`${this.apiUrl}customize-orders/completeCustomizeOrder/${id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  cancelCustomizeOrder(id) {

    return this._httpService.post(`${this.apiUrl}customize-orders/cancelCustomizeOrder/${id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  requestToCancelCustomizeOrder(id) {
    return this._httpService.post(`${this.apiUrl}customize-orders/requestToCancelCustomizeOrder/${id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getActivePurity() {
    return this._httpService.get(`${this.apiUrl}items/getActivePurity`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllActiveCategories() {
    return this._httpService.get(`${this.apiUrl}items/getAllActiveCategories`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }


}
