import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryVM } from 'src/app/models/CategoryVM';
import { ItemVM } from 'src/app/models/ItemVM';
import { CommonService } from 'src/app/services/common.service';
import { ItemService } from 'src/app/services/item.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubCategoryService } from 'src/app/services/subcategory.service';
import * as $ from "jquery";
import { SubscriptionService } from 'src/app/services/subscriprion.service';

@Component({
  selector: 'app-assing-item-edit-sub',
  templateUrl: './assing-item-edit-sub.component.html',
  styleUrls: ['./assing-item-edit-sub.component.css']
})
export class AssingItemEditSubComponent implements OnInit {

  id: any;
  values = '';
  values1 = '';
  values2 = '';
  itemData: any;
  itemCheck: any;
  ItemVMData: ItemVM[] = [];
  newItem: ItemVM[];
  selectedAll: any;
  role: any;
  temp_category_id: any = null;
  categoryVM = new CategoryVM();
  categorydata: CategoryVM[];
  subCategorydata: CategoryVM[];
  public pageNumber: number;
  public pageSize: number;
  public paginationValue: number;
  Follow_list = [];
  selectAllfollowlist = [];
  roleType: any;

  // @HostListener("window:scroll", [])
  // onScroll(): void {
  //   //In chrome and some browser scroll is given to body tag
  //   let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
  //   let max = document.documentElement.scrollHeight;
  //   if (pos == max) {
  //     //Do your action here
  //     this.paginationValue = this.pageNumber * this.pageSize;
  //     this.pageNumber++;
  //     if(this.temp_category_id == null){
  //     this.newAddedItem(this.id,this.paginationValue,this.pageSize,this.values,this.values1,this.values2);
  //     }else{
  //       if(this.ItemVMData != undefined){
  //         if(this.ItemVMData.length  < this.ItemVMData[0].totalcount){
  //       this.getItemList(this.temp_category_id,this.paginationValue, this.pageSize, this.values,this.id,this.values1,this.values2);
  //         }
  //       }
  //     }
  //       }
  //     }

  onScroll() {
    this.paginationValue = this.pageNumber * this.pageSize;
    this.pageNumber++;
    if (this.temp_category_id == null) {
      this.newAddedItem(this.id, this.paginationValue, this.pageSize, this.values, this.values1, this.values2);
    } else {
      if (this.ItemVMData != undefined) {
        if (this.ItemVMData.length < this.ItemVMData[0].totalcount) {
          this.getItemList(this.temp_category_id, this.paginationValue, this.pageSize, this.values, this.id, this.values1, this.values2);
        }
      }
    }
  }

  constructor(private _itemService: ItemService,
    private _subcategoryService: SubCategoryService,
    private _commonService: CommonService,
    private _storageService: StorageService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _subscriptionService: SubscriptionService) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();
    if (this.roleType != 1) {
      this._router.navigate(['/admin/dashboard']);
      return false;
    }
    this.pageNumber = 1;
    this.pageSize = 12;
    this.paginationValue = 0;
    this.getSubscriptionById();
    this.getallCategoryData();
    this.newAddedItem(this.id, this.paginationValue, this.pageSize, this.values, this.values1, this.values2);
  }

  getSubscriptionById() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._subscriptionService.getSubscriptionById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (response.data.subscription_item_ids != undefined) {
          self.Follow_list = response.data.subscription_item_ids;
        } else {
          self.Follow_list = [];
        }

      });
  }

  clickevent() {
    $('.gridview').show();
   $('.listview').hide();
  }

  listclickevent() {
    $('.gridview').hide();
   $('.listview').show();
  }

  newAddedItem(id, paginationValue, pageSize, values, values1, values2) {
    let self = this;
    self._itemService.newAddedItem(id, paginationValue, pageSize, values, values1, values2).subscribe(data => {
      let response: any = data;
      if (self.ItemVMData != undefined) {
        response.newItem.forEach(element => {
          self.ItemVMData.push(element);
          const itemExist = this.Follow_list.find(i => i == element.item_id);
          if (itemExist) {
            element.selected = true;
          }
        });
      } else {
        self.ItemVMData = response.newAddedItem;
      }
    });
  }

  newAddedItemSearch(id, paginationValue, pageSize, values, values1, values2) {
    let self = this;
    self._itemService.newAddedItem(id, paginationValue, pageSize, values, values1, values2).subscribe(data => {
      let response: any = data;
      if (response.newItem != undefined) {
        self.ItemVMData = [];
        response.newItem.forEach(element => {
          self.ItemVMData.push(element);
          const itemExist = this.Follow_list.find(i => i == element.item_id);
          if (itemExist) {
            element.selected = true;
          }
        });
      }
      else {
        self.ItemVMData = response.newItem;
      }
    });
  }

  getallCategoryData() {
    let self = this;
    self._subcategoryService.getAssignCategory().subscribe(data => {
      let response: any = data;
      if (response.category_data != null) {
        self.categorydata = response.category_data;
      }
    });
  }

  getFullUrl(item_image) {
    return this._commonService.getFullImageUrl(item_image);
  }

  selectChangeHandler(category_id) {
   
    let self = this;
    self._subcategoryService.getAssignSubCategory(category_id).subscribe(data => {
      let response: any = data;
      if (response != null) {
        self.subCategorydata = response.subcategory;
      }
    });
  }

  selectChangeHandler1(category_id) {
    this.temp_category_id = category_id;
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    let self = this;
    self._itemService.getAssignItemById(category_id, this.paginationValue, this.pageSize, this.values, "", this.values1, this.values2).subscribe(data => {
      let response: any = data;
      self.ItemVMData = response.item_data;
      self.itemData = response.item_data;
      self.itemCheck = response.item_data;
      if (self.itemData != null && self.itemData != undefined && self.itemData.length > 0) {
        self.itemData.forEach(element => {
          const itemExist = self.Follow_list.find(i => i == element.item_id);
          if (itemExist) {
            element.selected = true;
          } else {
            element.selected = false;
          }
        });
        const isItemNotSelectedExist = self.itemData.filter(i => i.selected == false).length;
        if (isItemNotSelectedExist > 0) {
          self.selectedAll = false;
        } else {
          self.selectedAll = true;
        }
      }
    });
  }

  getItemList(temp_category_id, paginationValue, pageSize, values, id, values1, values2) {
    let self = this;
    self._commonService.showLoader();
    self._itemService.getAssignItemById(temp_category_id, paginationValue, pageSize, values, id, values1, values2)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (self.ItemVMData.length > 0) {
          response.item_data.forEach(element => {
            self.ItemVMData.push(element);
            const itemExist = this.Follow_list.find(i => i == element.item_id);
            if (itemExist) {
              element.selected = true;
            }
          });
        }
        else {
          self.ItemVMData = response.item_data;
        }
      });
  }

  getItemListBysearch(temp_category_id, paginationValue, pageSize, values, id, values1, values2) {
    let self = this;
    self._commonService.showLoader();
    self._itemService.getAssignItemById(temp_category_id, paginationValue, pageSize, values, id, values1, values2)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (response.item_data != undefined) {
          // self.itemCheck=response.item_data;
          self.ItemVMData = [];
          // self.ItemVMData = response.item_data;
          response.item_data.forEach(element => {
            self.ItemVMData.push(element);
            const itemExist = this.Follow_list.find(i => i == element.item_id);
            if (itemExist) {
              element.selected = true;
            }
          });
        } else {
          self.ItemVMData = response.item_data;
        }
      });
  }

  selectAll(category_id, event) {
    const checked = event.target.checked; // stored checked value true or false
    for (var i = 0; i < this.ItemVMData.length; i++) {
      this.ItemVMData[i].selected = this.selectedAll;
    }
    if (checked) {
      let self = this;
      self._commonService.showLoader();
      self._itemService.getSelectedItemCatId(category_id, this.id, this.values, this.values1, this.values2)
        .subscribe(data => {
          self._commonService.hideLoader();
          let response: any = data;
          self.selectAllfollowlist = response.item_data;
          var tempAddItem = response.item_data;
          tempAddItem.forEach((obj) => {
            const index = this.Follow_list.findIndex(list => list == obj);
            if (index <= -1) {
              self.Follow_list.push(obj);
            }
          });
        });
    } else {
      let self = this;
      self._commonService.showLoader();
      self._itemService.getSelectedItemCatId(category_id, this.id, this.values, this.values1, this.values2)
        .subscribe(data => {
          self._commonService.hideLoader();
          let response: any = data;
          var temp = response.item_data;
          temp.forEach((obj) => {
            const index = this.Follow_list.findIndex(list => list == obj);//Find the index of stored id
            if (index != -1) {
              this.Follow_list.splice(index, 1); // Then remove
            }
          });
        });
    }
  }

  editAssignItem(id, Follow_list) {
    let self = this;
    self._itemService.editSubscriptionAssignItem(id, Follow_list)
      .subscribe(data => {
        let response: any = data;
        self._commonService.hideLoader();
        self._commonService.showToaster("Assign Item Updated successfully.", "success");
        error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
          return false;
        };
      });
  }

  itemdetail(item: ItemVM) {
    this._router.navigate(['/admin/items/detail/', item.item_id], { queryParams: { id: item.item_id } });
  }

  removewithfilter(arr) {
    let outputArray = arr.filter(function (v, i, self) {
      // It returns the index of the first
      // instance of each value
      return i == self.indexOf(v);
    });
    return outputArray;
  }

  toggleVisibility(Id, event) {
    const checked = event.target.checked; // stored checked value true or false
    if (checked) {
      const itemExist = this.Follow_list.find(i => i == Id);
      if (!itemExist) {
        this.Follow_list.push(Id); // push the Id in array if checked
      }
    } else {
      const index = this.Follow_list.findIndex(list => list == Id);//Find the index of stored id
      this.Follow_list.splice(index, 1); // Then remove
    }
  }

  onKey(event: any) {
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    this.values = event.target.value;
    if (this.temp_category_id != null) {
      this.getItemListBysearch(this.temp_category_id, this.paginationValue, this.pageSize, this.values, this.id, this.values1, this.values2);
    } else {
      this.newAddedItemSearch(this.id, this.paginationValue, this.pageSize, this.values, this.values1, this.values2);
    }
  }

  onKey1(event: any) {
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    this.values1 = event.target.value;
    if (this.temp_category_id != null) {
      this.getItemListBysearch(this.temp_category_id, this.paginationValue, this.pageSize, this.values, this.id, this.values1, this.values2);
    } else {
      this.newAddedItemSearch(this.id, this.paginationValue, this.pageSize, this.values, this.values1, this.values2);
    }
  }

  onKey2(event: any) {
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;
    this.values2 = event.target.value;
    if (this.temp_category_id != null) {
      this.getItemListBysearch(this.temp_category_id, this.paginationValue, this.pageSize, this.values, this.id, this.values1, this.values2);
    } else {
      this.newAddedItemSearch(this.id, this.paginationValue, this.pageSize, this.values, this.values1, this.values2);
    }
  }
}
