import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

// @Services
import { HttpService } from './http.service';
import { AppConfig } from '../shared/app-config';

@Injectable({
  providedIn: 'root'
})
export class SubCategoryService {
  apiUrl: String;
  // set http Authorization headers
  httpAuthOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };

  constructor(private _httpService: HttpService,
    private _api: AppConfig) {
    this.apiUrl = this._api.ApiUrl;
  }

  getAllSubCategories(event, filter, columnSorting, columnDirection) {
    var body = {
      "event": event,
      "filter": filter,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection,
    }
    return this._httpService.post(`${this.apiUrl}subcategories/getAllSubCategories`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllInquieries(event, filter, columnSorting, columnDirection) {
    var body = {
      "event": event,
      "filterValue": filter,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection
    }
    return this._httpService.post(`${this.apiUrl}categories/getAllInquieries`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllPartners(event, filter, columnSorting, columnDirection) {
    var body = {
      "event": event,
      "filterValue": filter,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection,
    }
    return this._httpService.post(`${this.apiUrl}categories/getAllPartners`,body,this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getPartnerById(id){
    return this._httpService.post(`${this.apiUrl}categories/getPartnersById/`+id,'',this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
  getInquieryDetailById(id){

    return this._httpService.post(`${this.apiUrl}categories/getByInquieryId/`+id,'',this.httpAuthOptions)
    .pipe(
      map((response: any) => {
        return this._httpService.extractData(response);
      }), catchError((errorRes: any) => {
        return this._httpService.handleError(errorRes);
      })
    );

  }

  getallCategoryData() {
    return this._httpService.get(`${this.apiUrl}getallCategoryData`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
  getallCategory() {
    return this._httpService.get(`${this.apiUrl}getallCategory`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAssignCategory() {
    return this._httpService.get(`${this.apiUrl}getAssignCategory`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
  getallShowcaseCategoryData() {

    return this._httpService.get(`${this.apiUrl}getallShowcaseCategoryData`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );

  }

  addSubCategory(subcategory) {
    return this._httpService.post(`${this.apiUrl}subcategories/addSubCategory`, subcategory, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getSubCategoryById(id) {
    return this._httpService.get(`${this.apiUrl}subcategories/getSubCategoryById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAssignSubCategory(id) {
    return this._httpService.get(`${this.apiUrl}subcategories/getAssignSubCategory/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  updateSubCategory(subcategory) {
    return this._httpService.post(`${this.apiUrl}subcategories/updateSubCategory`, subcategory, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  deleteCategory(id) {
    return this._httpService.post(`${this.apiUrl}categories/deleteCategory/${id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }


  getSubCategory(id) {
    return this._httpService.get(`${this.apiUrl}subcategories/getSubCategory/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getallCategoryGridData() {
    return this._httpService.get(`${this.apiUrl}getallCategoryGridData`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

}
