import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { ItemService } from 'src/app/services/item.service';
import { ItemVM } from 'src/app/models/ItemVM';
import { SalesOrderVM } from 'src/app/models/SalesOrderVM';
import { SalesOrderService } from 'src/app/services/sales-order.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { Lightbox } from 'ngx-lightbox';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { RoleService } from 'src/app/services/role.service';
declare var $: any;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-order-detail-list',
  templateUrl: './order-detail-list.component.html',
  styleUrls: ['./order-detail-list.component.css'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class OrderDetailListComponent implements OnInit {

  id: number;
  salesOrder = new SalesOrderVM();
  itemDetail: ItemVM[] = [];
  submitted = false;
  _albums = new Array();
  role: any;
  roleType: any;
  loginUserId: any;
  events: string[] = [];
  salesOrderForm: FormGroup;
  reactiveForm: FormGroup;
  mySimpleFormat: any;
  pipe = new DatePipe('en-US');
  audioUrl;
  blobUrl;
  audioSource;
  basic_albums = new Array();
  minDate;
  show = false;
  show1 = false;
  permissionsData = [];
  scannedImage: any;

  constructor(private _itemService: ItemService,
    private _salesOrderService: SalesOrderService,
    private _storageService: StorageService,
    private _commonService: CommonService,
    private _route: ActivatedRoute,
    private _siteConfigService: SiteConfigService, private _lightbox: Lightbox,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _router: Router,
    private fb: FormBuilder
  ) {
    this.minDate = new Date();
  }

  ngOnInit() {
    this.createForm();
    this.loginUserId = this._storageService.getUserId();
    this.getUserActive();
    this.role = (this._storageService.getUserRoles() != null && this._storageService.getUserRoles() != '') ? this._commonService.convertToNumber(this._storageService.getUserRoles()) : 0;
    this.roleType = this._storageService.getUserRolesType();
    if (this.role != 1) {
      this.getPermission();
    }
    this._route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.getSalesOrderDetail();

    });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      if (this.roleType == 1 && self.permissionsData[20].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
      else if (this.roleType == 2 && self.permissionsData[21].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }

    });

  }

  createForm() {
    this.reactiveForm = this.fb.group({
      edate: ['', Validators.required],

    });
  }
  get f() { return this.reactiveForm.controls; }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {

    this.mySimpleFormat = this.pipe.transform(event.target.value, 'yyyy-MM-dd');


  }
  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  downloadImage(imageURL: string) {
    if (!this._commonService.isNullOrEmpty(imageURL)) {
      var imgSrcData: any = window.btoa(imageURL);
      var url = window.URL.createObjectURL(imgSrcData);
      window.open(url, '_blank');

    }
    else {
      this._commonService.showToaster("Invalid image path.", "error");
    }
  }

  getSalesOrderDetail() {
    //this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    this._albums = [];
    this.basic_albums = [];
    self._salesOrderService.getSalesOrderDetailById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;

        self.salesOrder = response.sales_order;
        self.itemDetail = response.sales_order_items;

        // self.audioUrl = atob(response.sales_order.recording);
        // const file = new File([response.sales_order.recording], "voice.wav")
        // this.audioSource = response.sales_order.recording;

        // for (var i = 0; i < self.itemDetail.length; i++) {
        //   if (self.itemDetail[i].thumbnail_image_path != null) {
        //     const src = this._commonService.getFullImageUrl(self.itemDetail[i].thumbnail_image_path);
        //     const caption = '';
        //     const thumb = this._commonService.getFullImageUrl(self.itemDetail[i].thumbnail_image_path);
        //     const album1 = {
        //       src: src == null ? '' : src,
        //       caption: caption == undefined ? '' : caption,
        //       thumb: thumb == null ? '' : thumb
        //     };

        //     this.basic_albums.push(album1);
        //   }
        // }

        for (var j = 0; j < self.itemDetail.length; j++) {
          
          self.itemDetail[j]['_albums'] = [];
          
          if (self.itemDetail[j].is_scan.length > 0) {
            for (let i = 0; i < self.itemDetail[j].is_scan.length; i++) {
              if (self.itemDetail[j].is_scan[i].original_image_path != null && self.itemDetail[j].is_scan[i].process_image_path != null) {
                const src = self.itemDetail[j].is_scan[i].original_image_path;
                const caption = self.itemDetail[j].is_scan[i].image_details;
                const thumb = self.itemDetail[j].is_scan[i].process_image_path;
                const album = {
                  src: src == null ? '' : src,
                  caption: caption == undefined ? '' : caption,
                  thumb: thumb == null ? '' : thumb
                };

                self.itemDetail[j]['_albums'].push(album);
              }

            }
          }
          
        }
        console.log(self.itemDetail);

      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }


  open(index: number): void {

    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }


  open_basic_albumb(index: number): void {

    // open lightbox
    this._lightbox.open(this.basic_albums, index);
  }




  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }
  goToSalesOrders() {
    this._router.navigate(['/admin/sales-orders']);
  }
  confirmSalesOrder(salesOrder) {
    let self = this;
    self.submitted = true;

    if (self.reactiveForm.invalid) {
      return;
    }
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to confirm this sales order ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();

        self._salesOrderService.confirmSalesOrder(salesOrder.sales_order_id, { "deliverydate": this.mySimpleFormat }).subscribe((data: any) => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Sales order - " + data.order_no + " confirmed successfully.", "success");
          self.goToSalesOrders();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  completeSalesOrder(salesOrder) {
    let self = this;


    const options: SweetAlertOptions = {
      title: 'Are you sure you want to complete this sales order ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._salesOrderService.completeSalesOrder(salesOrder.sales_order_id).subscribe((data: any) => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Sales order - " + data.order_no + " completed successfully.", "success");
          self.goToSalesOrders();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }
  get edate() {

    return this.reactiveForm.get('edate');
  }

  cancelSalesOrder(salesOrder) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to cancel this sales order ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._salesOrderService.cancelSalesOrder(salesOrder.sales_order_id).subscribe((data: any) => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Sales order - " + data.order_no + " cancel successfully.", "success");
          self.goToSalesOrders();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }
  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }


  // moveToSelectedTab(tabName: string) {
  //   for (let i = 0; i < document.querySelectorAll('.mat-tab-label-content').length; i++) {
  //     if ((<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i]).innerText == tabName) {
  //       (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
  //     }
  //   }
  // }

  SacnnedImage(salesOrder, scannedImage, item_id) {
    
    let self = this;
    self._commonService.showLoader();
    self._salesOrderService.getScannedImageWatermark(salesOrder.sales_order_id, scannedImage.src, item_id).subscribe((data: any) => {
      let response: any = data;
      if (response.data != null) {
        self._commonService.hideLoader();
        self.scannedImage = response.data;
        if (self.scannedImage != null && self.scannedImage != undefined) {
          let printContents, popupWin;
          if (document.getElementById("scan-section") != null) {
            let imgTag = document.getElementById("scan-section").childNodes[0];

            if (imgTag != null && imgTag != undefined) {
              let printImage = document.getElementById("scan-section").childNodes[0] as HTMLImageElement;
              printImage.src = self.scannedImage;

              printContents = document.getElementById("scan-section").innerHTML;
              const stylesHtml = this.getTagsHtml('style');
              popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
              popupWin.document.open();
              popupWin.document.write(`
               <html>
                 <head>
                 <title>
              ${(self.itemDetail != null) ? self.itemDetail[0].item_name : ' - '}
                 </title>
                 ${stylesHtml}
                 </head>
             <body onload="window.print();window.close()">${printContents}</body>
               </html>`
              );
              popupWin.document.close();
            }
          }
        }
      }
    });
  }

  getTagsHtml(tagName: keyof HTMLElementTagNameMap): string {
    const htmlStr: string[] = [];
    const elements = document.getElementsByTagName(tagName);
    for (let idx = 0; idx < elements.length; idx++) {
      htmlStr.push(elements[idx].outerHTML);
    }

    return htmlStr.join('\r\n');
  }

  
  deleteItem(sales_order_details_id) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._salesOrderService.cancelSalesOrderItem(sales_order_details_id).subscribe(data => {
          let response: any = data;
          if (response.status == 1) {
            self._commonService.showToaster("Item cancelled successfully.", "success");
            self.getSalesOrderDetail();
          } else{
            self._commonService.showToaster(response.message, "error");    
          }
          self._commonService.hideLoader();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }
}
