export class TermsVM {
    term_id: number
    term_category_id: number
    term_code : string
    term_name: string
    is_default:number
    term_details: string
    term_icon: string
    back_color: string
    for_color: string
    sequence_no: number
    ideal_value: string
    hand_code_rule:string
    is_active: number
    created_at?: string
    updated_at?: string
    update_log?: string

}
