// @Packages
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';

import { Options } from 'select2';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2';

// @Models
import { ItemVM } from 'src/app/models/ItemVM';
import { CategoryVM } from 'src/app/models/CategoryVM';
import { RootObject } from 'src/app/models/GridItenViewVM';


// @Services
import { ItemService } from 'src/app/services/item.service';
import { CommonService } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { SubCategoryService } from 'src/app/services/subcategory.service';
import { StorageService } from 'src/app/services/storage.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-item-view',
  templateUrl: './item-view.component.html',
  styleUrls: ['./item-view.component.css']
})
export class ItemViewComponent implements OnInit {

  //public select2Options: Options;
  public select2CategoryOptions: Options;
  public select2CategoryData: Array<Select2OptionData>;
  public pageNumber: number;
  public pageSize: number;
  public paginationValue: number;
  permissionsData = [];
  // @HostListener("window:scroll", [])
  // onScroll(): void {

  //   //In chrome and some browser scroll is given to body tag
  //   let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
  //   let max = document.documentElement.scrollHeight;
  //   if (pos == max) {
  //     //Do your action here

  //       if(this.itemData.length  < this.itemData[0].totalcount){
  //     this.paginationValue = this.pageNumber * this.pageSize;
  //     this.pageNumber++;
  //     this.getCategoryList(this.temp_category_id,this.paginationValue, this.pageSize);

  //     }
  //   }
  // }

  onScroll() {
    if (this.itemData.length < this.itemData[0].totalcount) {
      this.paginationValue = this.pageNumber * this.pageSize;
      this.pageNumber++;
      this.getCategoryList(this.temp_category_id, this.paginationValue, this.pageSize);
    }
  }
  itemData: any;
  role: any;
  roleType: any;
  temp_category_id: any;
  categoryVM = new CategoryVM();
  categorydata: CategoryVM[];
  public countMaxBestSeller: number;
  loginUserId: any;

  constructor(private _itemService: ItemService,
    private _storageService: StorageService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _commonService: CommonService,
    private _subcategoryService: SubCategoryService,
    private _router: Router,
    private _roleService: RoleService) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.getUserActive();
    this.getallCategoryData();
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();
    this.select2CategoryOptions = {
      multiple: true
    }
    if (this.role != 1) {
      this.getPermission();
    }
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      //check permission
      if (self.permissionsData[15].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getallCategoryData() {
    let self = this;
    self._subcategoryService.getallCategoryGridData().subscribe(data => {
      let response: any = data;
      if (response.category_data != null) {
        self.categorydata = response.category_data;
        self.select2CategoryData = self._commonService.dropdownArray(self.categorydata, "category_id", "category_name");
      }
    });
  }

  trackByFn(index, item) {
    if (!item) return null;
    return index; // or item.disaggregationID;
  }

  // getImageUrlItemGallery(item) {
  //   var image_path = '';
  //   if (item.item_gallery.length != 0) {
  //     let objItem = item.item_gallery.find(x => x.is_cover_image == "1")
  //     if (objItem == undefined) {
  //       image_path = item.thumbnail_image_path;
  //     }
  //     else {
  //       image_path = objItem.process_image_path;
  //     }
  //   }
  //   else {
  //     image_path = item.thumbnail_image_path;
  //   }
  //   return this._commonService.getFullImageUrl(image_path);
  // }

  // getFullUrl(item_image) {
  //   return this._commonService.getFullImageUrl(item_image);
  // }

  itemdetail(item: ItemVM) {
    this._router.navigate(['/admin/items/detail/', item.item_id], { queryParams: { id: item.item_id } });
  }

  onChangeGetitem(selectedCategory) {
    this.temp_category_id = selectedCategory;
    this.paginationValue = 0;
    this.pageNumber = 1;
    this.pageSize = 12;

    let self = this;
    self._commonService.showLoader();
    if (selectedCategory != '' && selectedCategory != undefined && selectedCategory != null && selectedCategory.length > 0) {
      self._itemService.getItemByCatId(self.temp_category_id, this.paginationValue, this.pageSize).subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        this.itemData = [];
        if (response.item_data != undefined && response.item_data.length > 0) {

          if (self.itemData != undefined) {
            response.item_data.forEach(element => {
              const itemExist = self.itemData.find(i => i == element.item_id);
              if (!itemExist) {
                self.itemData.push(element);
              }
            });
          } else {
            self.itemData = response.item_data
          }
        }
        this.countMaxBestSeller = Math.max.apply(Math, self.itemData.map(function (o) { return o.total_order_received; }));
      });
    }
    else {
      const options: SweetAlertOptions = {
        title: 'Information',
        html: "Please Select Category or Subcategory for item filteration.",
        confirmButtonText: "Close"
      };
      swal.fire(options);
      self._commonService.hideLoader();
      self.itemData = [];
    }
  }

  getCategoryList(temp_category_id, paginationValue, pageSize) {
    let self = this;
    self._commonService.showLoader();
    if (temp_category_id != '' && temp_category_id != undefined && temp_category_id != null && temp_category_id.length > 0) {
      self._itemService.getItemByCatId(self.temp_category_id, paginationValue, pageSize).subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (response.item_data != undefined && response.item_data.length > 0) {
          if (self.itemData != undefined) {
            response.item_data.forEach(element => {
              const itemExist = self.itemData.find(i => i == element.item_id);
              if (!itemExist) {
                self.itemData.push(element);
              }
            });
          } else {
            self.itemData = response.item_data
          }
        }
      });
    }
    else {
      const options: SweetAlertOptions = {
        title: 'Information',
        html: "Please Select Category or Subcategory for item filteration.",
        confirmButtonText: "Close"
      };
      swal.fire(options);
      self._commonService.hideLoader();
    }
  }

  getFullUrl(item_image) {
    return this._commonService.getFullImageUrl(item_image);
  }

  placeSalesOrder(item) {
    this._router.navigate(['/admin/sales-orders/new/', item.item_id]);
  }

}
