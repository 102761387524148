import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, MatSort, PageEvent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesOrderDetailsVM, SalesOrderVM } from 'src/app/models/SalesOrderVM';
import { CommonService } from 'src/app/services/common.service';
import { SalesOrderService } from 'src/app/services/sales-order.service';
import { SiteConfigService } from 'src/app/services/site-config.service';

@Component({
  selector: 'app-sales-orders-view',
  templateUrl: './sales-orders-view.component.html',
  styleUrls: ['./sales-orders-view.component.css']
})
export class SalesOrdersViewComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = ['order_date', 'distributor_id', 'user_id', 'item_name', 'customer','actual_date_of_delivery', 'order_no', 'order_status', 'is_express_delivery', 'cover_image', 'actions'];
  salesOrderData: any;
  noData = false;
  show: any;
  submitted = false;
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  filterValue: '';
  value: '';
  date: string;
  dateselect?: any;
  availableItem?: any;
  selectStatus: any;
  pendingValidate = false;
  columnSorting: any;
  columnDirection: any;
  applyseacrhSalesOrderForm: FormGroup;
  orderdate: any;
  deliverydate: any;
  public pageNumber: number;
  public paginationValue: number;

  onScroll() {
    if (this.salesOrderData.length < this.salesOrderData[0].totalcount) {
      let previousPageIndex = this.pageNumber;
      this.pageNumber++;
      this.paginationValue = this.pageNumber * this.pageSize;
      var event: any = {
        "previousPageIndex": previousPageIndex,
        "pageIndex": this.pageNumber,
        "pageSize": this.pageSize,
        "length": this.salesOrderData[0].totalcount,
      }      
      this.getAllSalesOrder(event);
    }
  }

  search: { orderDate: any; deliveryDate: any; selectStatus: any; };
  constructor(
    private _salesOrderService: SalesOrderService,
    private _commonService: CommonService,
    private _router: Router, private _route: ActivatedRoute,
    private _siteConfig: SiteConfigService,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        this.pageNumber = 1;
        this.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.getAllSalesOrder(event);
      }
    });
  }
  getAllSalesOrder(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._salesOrderService.getAllSalesOrders(event, this.value, this.dateselect, this.availableItem, this.search, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            //let response: any = data;
            //self.salesOrderData = response.data as SalesOrderVM;
            let response: any = data;
        if (response.data != undefined && response.data.length > 0) {
          if (self.salesOrderData != undefined) {
            response.data.forEach(element => {
              const itemExist = self.salesOrderData.find(i => i.sales_order_id == element.sales_order_id);
              if (!itemExist) {
                self.salesOrderData.push(element);
              }
            });
          } else {
            self.salesOrderData = response.data
          }
        }
            if (response.data.length > 0) {
              self.totalLength = response.data[0].totalcount;
            }
            self.noData = (response.data.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });

      });
  }
  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }
  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }
  viewSalesOrderDetail(orderdetail: SalesOrderDetailsVM) {
    this._router.navigate(['/admin/sales-order-details', orderdetail.sales_order_id], { queryParams: { id: orderdetail.sales_order_id } });
  }
  trackByFn(index, item) {
    if (!item) return null;
    return index; // or item.disaggregationID;
  }
  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }
}
