import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerDetailVM, CustomerVM } from 'src/app/models/CustomerVM';
import { ItemVM } from 'src/app/models/ItemVM';
import { RetailerVM } from 'src/app/models/RetailerVM';
import { SubscriptionVM } from 'src/app/models/SubscriptionVM';
import { CommonService } from 'src/app/services/common.service';
import { DistributorService } from 'src/app/services/distributor.service';
import { SiteConfigService } from 'src/app/services/site-config.service';

@Component({
  selector: 'app-customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.css']
})
export class CustomerViewComponent implements OnInit {

  customerId?: any;
  customerViewVM: CustomerDetailVM = new CustomerDetailVM();
  customerItemViewVM: any;
  customerSubscriptionViewVM: SubscriptionVM = new SubscriptionVM();
  totalLength: number;
  noData = false;
  displayedColumns: string[] = ['item_name', 'code', 'group_id', 'purity', 'is_active', 'status', 'created_at', 'actions'];
  columnSorting: any;
  columnDirection: any;
  filterValue: '';
  value: '';
  pageSize: number;

  constructor(private _route: ActivatedRoute, private _commonService: CommonService, private distributorService: DistributorService,
    private _siteConfigService: SiteConfigService, private _router: Router) {
  }

  ngOnInit() {
    let self = this;
    self._commonService.showLoader();
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.getCustomerById();
        this.getCustomerItemsById(event);
      }
    });
  }


  getCustomerById() {
    this._route.params.subscribe(params => { this.customerId = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self.distributorService.getCustomerById(this.customerId)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (!this._commonService.isNullOrEmpty(response.data.details)) {
          self.customerViewVM = response.data.details;
        }


        if (!this._commonService.isNullOrEmpty(response.data.subscription)) {
          self.customerSubscriptionViewVM = response.data.subscription;
        }
      });
  }

  getCustomerItemsById(event) {
    let self = this;
    self._commonService.showLoader();
    self.distributorService.getCustomerItemsById(self.customerId, event, this.value, this.columnSorting, this.columnDirection)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (!this._commonService.isEmptyArray(response.data.item_detail)) {
          self.customerItemViewVM = new MatTableDataSource<ItemVM>(response.data.item_detail)
          if (response.data.item_detail != null && response.data.item_detail.length > 0) {
            self.totalLength = response.data.item_total_count;
          }
        }

        self.noData = (response.data.item_detail.length > 0) ? false : true;
        if (self.noData == true) {
          self.totalLength = 0;
        }
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }

  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getCustomerItemsById(event);
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getCustomerItemsById(event);
  }

  viewItem(item: ItemVM) {
    this._router.navigate(['/admin/items/detail/', item.item_id], { queryParams: { id: item.item_id } });
  }

}
