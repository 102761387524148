import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { CustomerVM } from 'src/app/models/CustomerVM';
import { RetailerVM } from 'src/app/models/RetailerVM';
import { CommonService } from 'src/app/services/common.service';
import { DistributorService } from 'src/app/services/distributor.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css']
})
export class CustomerListComponent implements OnInit {

  pageSize: number = 50;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columnSorting: any;
  columnDirection: any;
  totalLength: any;
  value: '';
  filterValue: '';
  customerData: any;
  customerList: any;
  noData = false;
  displayedColumns: string[];

  constructor(private _siteConfigService: SiteConfigService, private _commonService: CommonService, private _distributorService: DistributorService,
    private router: Router) { }

  ngOnInit() {
    this._commonService.showLoader();
    this._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        this.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "pageIndex": '0',
          "pageSize": this.pageSize,
        }
        this.loadSubCustomers(event);
      }
    });

    this.displayedColumns = ['RetailerName', 'mobile_no', 'email', 'created_at', 'actions'];
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadSubCustomers(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._distributorService.getAllCustomers(event, this.value, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            if (!self._commonService.isNullOrEmpty(response.customer)) {

              self.customerData = new MatTableDataSource<RetailerVM>(response.customer.list);
              if (response.customer.list.length > 0) {
                self.totalLength = response.customer.total_count;
              }

              self.noData = (response.customer.list != null && response.customer.list.length > 0) ? false : true;
              if (self.noData == true) {
                self.totalLength = 0;
              }
            }

          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }


  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadSubCustomers(event);
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;

    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadSubCustomers(event);
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  redirectToCustomerView(customer: RetailerVM) {
    this.router.navigate(['/admin/customer-view', customer.RetailerId])
  }

}
