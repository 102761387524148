// @Packages
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { MatStepper, MAT_DATE_FORMATS, DateAdapter } from '@angular/material';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { REGEXP } from 'src/app/shared/regexp';
import { APP_DATE_FORMATS, AppDateAdapter } from 'src/app/shared/_helpers/format-datepicker';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import Swal, { SweetAlertOptions } from 'sweetalert2';

// @Services
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { ItemGroupService } from 'src/app/services/item-group.service';
import { ItemService } from 'src/app/services/item.service';
import { WarehouseService } from 'src/app/services/warehouse.service';
import { CommonService } from 'src/app/services/common.service';
import { DistributorService } from 'src/app/services/distributor.service';
import { ProductionhouseService } from 'src/app/services/productionhouse.service';
import { MetalService } from 'src/app/services/metal.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';

// @Models
import { ItemVM } from 'src/app/models/ItemVM';
import { ItemGroupVM } from 'src/app/models/ItemGroupVM';
import { WarehouseVM } from 'src/app/models/WarehouseVM';
import { MetalVM } from 'src/app/models/MetalVM';
import { DistributorVM } from 'src/app/models/DistributorVM';
import { ProductionhouseVM } from 'src/app/models/ProductionhouseVM';
import { CategoryVM } from 'src/app/models/CategoryVM';
import { ItemMetalVM } from 'src/app/models/ItemMetalVM';
import { ItemGalleryVM, ItemPngVM } from 'src/app/models/ItemGalleryVM';
import { ItemAccessoryVM } from 'src/app/models/ItemAccessoryVM';
import { ItemStatusVM } from 'src/app/models/ItemStatusVM';

// @Components
import { MetalModalComponent } from '../metal-modal/metal-modal.component';
import { AccessoryModalComponent } from '../accessory-modal/accessory-modal.component';
import { PurityVM } from 'src/app/models/PurityVM';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RoleService } from 'src/app/services/role.service';
import { RelatedVM } from 'src/app/models/RelatedItemVM';
declare var $: any;

@Component({
  selector: 'app-item-edit',
  templateUrl: './item-edit.component.html',
  styleUrls: ['./item-edit.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    DatePipe
  ]
})

export class ItemEditComponent implements OnInit {

  id: number;
  submitted = false;
  submittedRelatedItemFormGroup = false;
  isLinear = true;
  itemLimitReached = false;
  basicFormGroup: FormGroup;
  //attributesFormGroup: FormGroup;
  //metalsFormGroup: FormGroup;
  //accessoriesFormGroup: FormGroup;
  itemImagesFormGroup: FormGroup;
  itemImagesScannnedFormGroup: FormGroup;
  relatedItemFormGroup: FormGroup;
  itemPngImagesFormGroup: FormGroup;
  //productionFormGroup: FormGroup;
  itemImageUrl: string;
  getItemData: ItemVM[];
  e: any;
  im: any;
  ai: any;
  role: number;
  roleType: number;
  url: string;
  show = false;
  relatedItemArray = [];
  selectDisabled = false;
  ItemVM = new ItemVM();
  ItemVMData: ItemVM[];
  getDistributorDesingData: any[];
  itemGroupData: ItemGroupVM[];
  warehouseData: WarehouseVM[];
  metalData: MetalVM[];
  distributorData: DistributorVM[];
  productionHouseData: ProductionhouseVM[];
  categoriesData: CategoryVM[];
  categoryVM = new CategoryVM();
  purityData: PurityVM[];
  imageGalleryData: [];
  imagePngData: [];
  itemStatusData: ItemStatusVM[];
  //metalRows: ItemMetalVM[] = new Array();
  //metalIds = [];
  imageRows: ItemGalleryVM[] = new Array();
  pngImageRows: ItemPngVM[] = new Array();
  imageScannedRows: ItemGalleryVM[] = new Array();
  //attributeData = new Array();
  //accessoryRows: ItemAccessoryVM[] = new Array();
  //accessoryIds = [];
  formData = new FormData();
  max_upload_images: number;
  max_png_upload_images: number;
  max_scan_images: number;
  loginUserId: any;
  getcatid: ItemVM[];
  deletedItem: any = [];
  source?: string = "";


  //Select 2
  public select2Options: Options;
  public select2CategoryOptions: Options;
  public select2CategoryData: Array<Select2OptionData>;
  public select2PurityData: Array<Select2OptionData>;
  public select2ItemGroupData: Array<Select2OptionData>;
  public select2OptionsDistributor: Options;
  public select2DistributorData: Array<Select2OptionData>;
  public select2MetalData: Array<Select2OptionData>;
  public select2ProductionHData: Array<Select2OptionData>;
  public select2WarehouseData: Array<Select2OptionData>;
  public select2StatusData: Array<Select2OptionData>;
  public select2GetItem: Array<Select2OptionData>;
  public select2GetCat: Array<Select2OptionData>;
  public totalfiles: Array<File> = [];
  public totalPngfiles: Array<File> = [];
  public totalScannedfiles: Array<File> = [];
  public totalRelatedfiles: Array<File> = [];
  public totalrelatedFileName = [];
  public totalFileName = [];
  public totalPngFileName = [];
  public totalScannedFileName = [];
  public lengthCheckToaddMore = 0;
  public lengthPngCheckToaddMore = 0;
  public editimagesData = [];
  public editPngImagesData = [];
  public editscanimagesData = [];
  public tempImage = [];
  public tempPngImage = [];
  public tempScanImage = [];
  public selectedDistributor = [];
  permissionsData = [];
  relatedItemIndex: number = -1;
  public bodyPartTypeData: Array<Select2OptionData>;
  scannnedSubmitted = false;
  @ViewChild("main_image_path", { static: false }) mainImageFileInput;
  @ViewChild("related_item_thumbnail_image_path", { static: false }) related_item_thumbnail_image_path: ElementRef;

  constructor(private _router: Router,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _itemGroupService: ItemGroupService,
    private _itemService: ItemService,
    private _commonService: CommonService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _warehouseService: WarehouseService,
    private _metalService: MetalService,
    private _distributorService: DistributorService,
    private _productionHouseService: ProductionhouseService,
    public dialog: MatDialog,
    private _roleService: RoleService,
    private _datePipe: DatePipe,
    private _storageService: StorageService,
    private _siteConfigService: SiteConfigService,
  ) {
  }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.getUserActive();
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();
    if (this.role != 1) {
      this.getPermission();
    }
    this.select2OptionsDistributor = {
      multiple: true
    }
    this.select2CategoryOptions = {
      multiple: true,
    }

    this._route.queryParams.subscribe(params => {
      this.source = params['source'];
    });

    this.buildForm();

    // Promise.all([
    //   this.loadItems()
    // ]).then((data: any) => {
    //   this.getDistributorDetail();
    //   this.getSiteConfigData();
    //   this.getActiveItemGroups();
    //   this.getActiveWarehouseData();
    //   this.getActiveMetalsData();
    //   this.getActiveDistributors();
    //   this.getActiveProductionHouse();
    //   this.getActiveCategories();
    //   this.getItemStatusData();
    //   this.getActivePurity();
    //   this.getItem();
    // });

    Promise.all([
      this.getDistributorDetail_V2(),
      this.getItem_V2(),
      this.getActiveDistributors_V2(),
      this.getSiteConfigData(),
      this.getActiveItemGroups(),
      this.getActiveWarehouseData(),
      //this.getActiveProductionHouse(),
      this.getActiveCategories(),
      this.getItemStatusData(),
      this.getActivePurity(),
      this.getActiveMetalsData(),
      this.getItemImageGallery(),
      this.getDistributorDesignItem(),
      this.getActiveCategoriess()
    ]).then((data: any) => {
      let self = this;


      let distributorDetailResult = data[0];
      let itemResult = data[1];
      let activeDistributorsResult = data[2];
      let getSiteConfigDataResult = data[3];
      let getActiveItemGroupsResult = data[4];
      let getActiveWarehouseDataResult = data[5];
      let getActiveCategoriesResult = data[6];
      let getItemStatusDataResult = data[7];
      let getActivePurityResult = data[8];
      let getMetalResult = data[9];
      let getImageGalleryResult = data[10];
      let getDistributorDesingResult = data[11];
      let getActiveCategoriessResult = data[12];

      //getMetalResult
      if (getMetalResult != null && getMetalResult != undefined && getMetalResult.success == true) {
        let response: any = getMetalResult.data;
        if (response.metal_data != null) {
          self.metalData = response.metal_data;
          self.select2MetalData = self._commonService.dropdownArray(self.metalData, "metal_id", "metal_name");
        }
      }

      //DistributorDetail
      if (distributorDetailResult != null && distributorDetailResult != undefined && distributorDetailResult.success == true) {
        let response: any = distributorDetailResult.data;
        if (response.single_distributor != null) {
          let items_count = response.single_distributor.items_count;
          let item_add_limit = response.single_distributor.item_add_limit;
          let distributorId = response.single_distributor.distributor_id;
          if (distributorId != 1) {
            if (items_count < item_add_limit) {
              self.itemLimitReached = false;
            } else {
              self.itemLimitReached = true;
            }
          }
        }
      }

      //getDistributorDesingResult
      if (getDistributorDesingResult != null && getDistributorDesingResult != undefined && getDistributorDesingResult.success == true) {
        let response: any = getDistributorDesingResult.data;
        if (response.distributor_design_data != null) {
          self.getDistributorDesingData = response.distributor_design_data;
        }
      }

      //Active Distributors
      if (activeDistributorsResult != null && activeDistributorsResult != undefined && activeDistributorsResult.success == true) {
        var distributorFinalData: DistributorVM[] = [];
        let response: any = activeDistributorsResult.data;
        if (response.distributor_data != null) {
          self.distributorData = response.distributor_data;
          if (self.source != null && self.source != undefined && self.source != '') {
            let i = 0;
            self.distributorData.forEach(element => {
              if (element.distributor_id == self._commonService.convertToNumber(self.source)) {
                self.distributorData.splice(i, 1);
              }
              i++;
            });
            self.distributorData.forEach(element => {
              const itemExist = self.getDistributorDesingData.find(i => i.distributor_id == element.distributor_id);
              if (itemExist) {
                self.selectedDistributor.push(element.distributor_id.toString());
              }
            });
          } else {
            self.distributorData.forEach(element => {
              const itemExist = self.getDistributorDesingData.find(i => i.distributor_id == element.distributor_id);
              if (itemExist) {
                self.selectedDistributor.push(element.distributor_id.toString());
              }
            });
          }
          self.select2DistributorData = self._commonService.dropdownArrayDistributor(self.distributorData, "distributor_id", "display_name");
        }
      }

      //Item Detail
      if (itemResult != null && itemResult != undefined && itemResult.success == true) {
        let response: any = itemResult.data;
        self.editimagesData = response.item_detail.item_gallery_data;
        self.editscanimagesData = response.item_detail.is_scan;
        self.imageGalleryData = response.item_detail.item_gallery;
        self.ItemVM = response.item_detail;
      }

      //getSiteConfigDataResult
      if (getSiteConfigDataResult != null && getSiteConfigDataResult != undefined && getSiteConfigDataResult.success == true) {
        let response: any = getSiteConfigDataResult.data;
        if (response.data != null) {
          self.max_upload_images = response.data.max_upload_images;
          self.max_scan_images = response.data.max_scan_upload_images;
          self.max_png_upload_images = response.data.max_png_upload_images;
        }
      }

      //getActiveItemGroupsResult
      if (getActiveItemGroupsResult != null && getActiveItemGroupsResult != undefined && getActiveItemGroupsResult.success == true) {
        let response: any = getActiveItemGroupsResult.data;
        if (response.item_group_data != null) {
          self.itemGroupData = response.item_group_data;
          self.select2ItemGroupData = self._commonService.dropdownArray(self.itemGroupData, "item_group_id", "group_name");
        }
      }

      //getActiveWarehouseDataResult
      if (getActiveWarehouseDataResult != null && getActiveWarehouseDataResult != undefined && getActiveWarehouseDataResult.success == true) {
        let response: any = getActiveWarehouseDataResult.data;
        if (response.warehouse_data != null) {
          self.warehouseData = response.warehouse_data;
          self.select2WarehouseData = self._commonService.dropdownArray(self.warehouseData, "warehouse_id", "ware_house_name");
        }
      }

      //getActiveCategoriesResult
      if (getActiveCategoriesResult != null && getActiveCategoriesResult != undefined && getActiveCategoriesResult.success == true) {
        let response: any = getActiveCategoriesResult.data;
        if (response.categories != null) {
          self.categoriesData = response.categories;
          self.select2CategoryData = self._commonService.dropdownArray(self.categoriesData, "category_id", "category_name");
        }
      }

      //getActiveCategoriessResult
      if (getActiveCategoriessResult != null && getActiveCategoriessResult != undefined && getActiveCategoriessResult.success == true) {
        let response: any = getActiveCategoriessResult.data;
        if (response.categories != null) {
          self.getcatid = response.categories;
          let select2GetCat: any = [];
          if (self.getcatid != null && self.getcatid.length > 0) {
            self.getcatid.forEach(item => {
              select2GetCat.push({
                id: `${item.category_id},${item.display_name}`,
                text: `${item.display_name}`
              })
            });
          }
          self.select2GetCat = select2GetCat;
        }
      }

      //getItemStatusDataResult
      if (getItemStatusDataResult != null && getItemStatusDataResult != undefined && getItemStatusDataResult.success == true) {
        let response: any = getItemStatusDataResult.data;
        if (response.item_status_data != null) {
          self.itemStatusData = response.item_status_data;
          self.select2StatusData = self._commonService.dropdownArray(self.itemStatusData, "status_id", "status_name");
        }
      }

      //getActivePurityResult
      if (getActivePurityResult != null && getActivePurityResult != undefined && getActivePurityResult.success == true) {
        let response: any = getActivePurityResult.data;
        if (response.purity != null) {
          self.purityData = response.purity;
          self.select2PurityData = self._commonService.dropdownArray(self.purityData, "id", "purity");
        }
      }

      //getImageGalleryResult
      if (getImageGalleryResult != null && getImageGalleryResult != undefined && getImageGalleryResult.success == true) {
        // let response: any = getImageGalleryResult.data;
        //if (response.data != null) {
        //self.imageGalleryData = response.data;

        //self.select2PurityData = self._commonService.dropdownArray(self.purityData, "id", "purity");
        //}
      }
      self.buildForm();
      this.getRelatedItem();
      this.getPngImagesById();
    });

    this.bodyPartTypeData = [
      {
        id: '1',
        text: 'ForeHead'
      },
      {
        id: '2',
        text: 'Earing Left'
      },
      {
        id: '3',
        text: 'Earing Right'
      },
      {
        id: '4',
        text: 'Nose'
      },
      {
        id: '5',
        text: 'Neck'
      }
    ];
  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getRelatedItem() {
    let self = this;
    self._itemService.getRelatedItem(this.id)
      .subscribe(data => {
        let response: any = data
        if (response.data != undefined) {
          this.relatedItemArray = response.data;
        }
      });
  }

  getPngImagesById() {
    let self = this;
    self._itemService.getPngImagesById(this.id)
      .subscribe(data => {
        let response: any = data
        if (response.data != undefined) {
          self.editPngImagesData = response.data;
          self.bindDbPngImagesToFrom();
        }
      });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      //check permission
      if (self.permissionsData[15].sub_permission[2].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  // getItem() {
  //   this._route.params.subscribe(params => { this.id = params['id']; });
  //   let self = this;
  //   self._commonService.showLoader();
  //   self._itemService.getItemDetailById(self.id)
  //     .subscribe(data => {
  //       self._commonService.hideLoader();
  //       let response: any = data;
  //       self.ItemVM = response.item_detail;
  //       self.buildForm();
  //     });
  // }

  // getActiveCategoriess(){
  //   let self = this;
  //   self._itemService.getAllActiveCategories().subscribe(data => {
  //     let response: any = data;
  //     if (response.categories != null) {
  //       self.getcatid = response.categories;
  //       let select2GetCat: any = [];
  //       if (self.getcatid != null && self.getcatid.length > 0) {
  //         self.getcatid.forEach(item => {
  //           select2GetCat.push({
  //             id:`${item.category_id},${item.display_name}`,
  //             text: `${item.display_name}`
  //           })
  //         });
  //       }
  //       self.select2GetCat = select2GetCat;
  //     }
  //   });
  // }


  getItem_V2() {
    let self = this;
    this._route.params.subscribe(params => { this.id = params['id']; });
    return new Promise((resolve, reject) => {
      self._itemService.getItemDetailById(self.id).subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getSiteConfigData() {
    let self = this;
    this._route.params.subscribe(params => { this.id = params['id']; });
    return new Promise((resolve, reject) => {
      self._siteConfigService.getSiteConfigData().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  buildForm() {
    let basic = {
      item_id: [this._commonService.convertToNumber(this.ItemVM.item_id)],
      item_name: [this.ItemVM.item_name, [Validators.required, Validators.maxLength(255)]],
      code: [this.ItemVM.code, [Validators.required, Validators.maxLength(255)]],
      item_unique_id: [this.ItemVM.item_unique_id],
      group_id: [this.ItemVM.group_id, Validators.required],
      category_id: [''],
      display_name: [this.ItemVM.display_name, Validators.required],
      // sequence_no: [this.ItemVM.sequence_no],
      main_image_path: [this.ItemVM.main_image_path, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      item_details: [this.ItemVM.item_details],
      size: [this.ItemVM.size],
      gross_wt: [this.ItemVM.gross_wt, [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      purity: [this.ItemVM.purity, [Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      wastage: [this.ItemVM.wastage, [Validators.required, Validators.maxLength(40)]],
      less_weight: [this.ItemVM.less_weight, [Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      net_weight: [this.ItemVM.net_weight, [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      no_of_days_required: [this.ItemVM.no_of_days_required, [Validators.required, Validators.maxLength(4), Validators.max(9999)]],
      pcs: [this.ItemVM.pcs, [Validators.required, Validators.maxLength(4), Validators.max(9999)]],
      extra_rate: [this.ItemVM.extra_rate, [Validators.maxLength(8), RxwebValidators.numeric({ allowDecimal: true })]],
      express_delivery_percentage: [this.ItemVM.express_delivery_percentage, [RxwebValidators.numeric({ allowDecimal: true }), Validators.max(100)]],
      is_available_on_for_display: [this._commonService.convertToNumber(this.ItemVM.is_available_on_for_display)],
      is_active: [this.ItemVM.is_active, Validators.required],
      status: [this.ItemVM.status, Validators.required],
      major_metal_id: [this.ItemVM.major_metal_id, Validators.required],
      credit_per_image: [this.ItemVM.credit_per_image],
      // distributor_id: ['']
    };

    if (this._commonService.isNullOrEmpty(this.ItemVM.main_image_path)) {
      this.itemImageUrl = '';
    } else {
      this.itemImageUrl = this._commonService.getFullImageUrl(this.ItemVM.main_image_path);
    }
    if (this.roleType == 1) {
      basic['warehouse_id'] = [this.ItemVM.warehouse_id, Validators.required];
      if (this.source != null && this.source != undefined && this.source != '') {
        basic['distributor_id'] = [this._commonService.convertToNumber(this.source)];
      } else {
        basic['distributor_id'] = [1];
      }
      if (this.selectedDistributor != null && this.selectedDistributor != undefined) {
        // let availble_dis = this.ItemVM.is_available_for_distributor.split(',');
        //if (availble_dis != undefined && availble_dis.length > 0) {
        //let availble_dis_arr =  this;
        //availble_dis_arr.push('temp');
        // this.select2OptionsDistributor = {
        //     data: availble_dis_arr
        // };
        basic['is_available_for_distributor'] = [this.selectedDistributor];
        // }
        // else {
        //   basic['is_available_for_distributor'] = [''];
        // }
      } else {
        basic['is_available_for_distributor'] = [''];
      }

      // multiple category
      if (this.ItemVM.category_id_mutiple != null && this.ItemVM.category_id_mutiple != undefined) {
        let availble_dis = this.ItemVM.category_id_mutiple.split(',');
        if (availble_dis != undefined && availble_dis.length > 0) {
          //let availble_dis_arr =  this;
          //availble_dis_arr.push('temp');
          // this.select2OptionsDistributor = {
          //     data: availble_dis_arr
          // };
          basic['category_id_mutiple'] = [availble_dis, Validators.required];
        }
        else {
          basic['category_id_mutiple'] = ['', Validators.required];
        }
      } else {
        basic['category_id_mutiple'] = ['', Validators.required];
      }
    } else {
      basic['warehouse_id'] = [this.ItemVM.warehouse_id, Validators.required];
      //basic['distributor_id'] = [this.ItemVM.distributor_id];
      basic['distributor_id'] = [1];
      basic['is_available_for_distributor'] = [''];
      // multiple category
      if (this.ItemVM.category_id_mutiple != null && this.ItemVM.category_id_mutiple != undefined) {
        let availble_dis = this.ItemVM.category_id_mutiple.split(',');
        if (availble_dis != undefined && availble_dis.length > 0) {
          //let availble_dis_arr =  this;
          //availble_dis_arr.push('temp');
          // this.select2OptionsDistributor = {
          //     data: availble_dis_arr
          // };
          basic['category_id_mutiple'] = [availble_dis, Validators.required];
        }
        else {
          basic['category_id_mutiple'] = ['', Validators.required];
        }
      } else {
        basic['category_id_mutiple'] = ['', Validators.required];
      }
    }

    this.basicFormGroup = this._formBuilder.group(basic);
    // this.metalsFormGroup = this._formBuilder.group({
    //   metalRows: [[], Validators.required]
    // });

    // this.attributesFormGroup = this._formBuilder.group({
    //   attributeRows: this._formBuilder.array([])
    // });

    // this.accessoriesFormGroup = this._formBuilder.group({
    //   accessoryRows: [[], Validators.required]
    // });

    this.itemImagesFormGroup = this._formBuilder.group({
      imageRows: this._formBuilder.array([])
    });

    this.itemPngImagesFormGroup = this._formBuilder.group({
      pngImageRows: this._formBuilder.array([])
    });

    this.itemImagesScannnedFormGroup = this._formBuilder.group({
      imageScannedRows: this._formBuilder.array([])
    });

    this.relatedItemFormGroup = this._formBuilder.group({
      AssociatedItemCategoryID: [''],
      AssociatedItemID: [''],
      Comment: [''],
      RelatedItemID: [0],
      fileName: [''],
      base64String: [''],
  //related_item_thumbnail_image_path: ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]]
      related_item_thumbnail_image_path: ['']
    });
    // for limit end no first element can be come.
    // if(this.editimagesData.length >= this.max_upload_images){
    //   this.addNewItemImageRow();
    // }
    //this.addNewItemImageRow();
    this.bindScanDbImagesToFrom();
    this.bindDbImagesToFrom();
    // this.bindDbPngImagesToFrom();
    // this.productionFormGroup = this._formBuilder.group({
    //   production_house_id: ['', Validators.required],
    //   production_in_change_id: [''],
    //   date_of_production: [''],
    //   product_details: [''],
    //   product_details_on_invoice: [''],
    //   tax_method_term: [''],
    //   sales_rate: [''],
    //   income_account_id: [''],
    //   labour_on_id: ['']
    // });
  }

  bindDbImagesToFrom() {
    let self = this;
    const ImageArr = self.f1.imageRows as FormArray;
    let totalImageRow = ImageArr.length;
    if (totalImageRow == self.max_upload_images) {
      Swal.fire("You've exceed the maximum number of images for this item");
    } else {
      self.editimagesData.forEach(item => {
        // let indicatorDetail = self.indicatorList.find(i => i.indicatorID == item.indicatorID);
        // self.itemImagesFormGroup.value.imageRows.push(this._formBuilder.group({
        //   imagepath:[this._commonService.getFullImageUrl(item.process_image_path)],
        //   image: ['', [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
        //   is_cover_image: [item.is_cover_image, Validators.required],
        //   sequence_no: [item.sequence_no, [Validators.required, Validators.maxLength(25), RxwebValidators.unique()]],
        //   image_details: [item.image_details],
        //   is_water_mark: [item.is_water_mark],
        //   is_live: [item.is_live]
        // }));
        ImageArr.push(this._formBuilder.group({
          item_gallery_id: [item.item_gallery_id],
          imagepath: [this._commonService.getFullImageUrl(item.process_image_path)],
          image: ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
          is_cover_image: [item.is_cover_image, Validators.required],
          sequence_no: [item.sequence_no, [Validators.required, Validators.maxLength(25), RxwebValidators.unique()]],
          image_details: [item.image_details],
          is_water_mark: [this._commonService.convertToNumber(item.is_water_mark)],
          is_live: [this._commonService.convertToNumber(item.is_live)]
        }));
        //let control = self.questionForms.controls[index];
        // index++;
      });
    }
  }

  bindDbPngImagesToFrom() {
    let self = this;
    const ImageArr = self.f2.pngImageRows as FormArray;
    let totalImageRow = ImageArr.length;
    if (totalImageRow == self.max_png_upload_images) {
      Swal.fire("You've exceed the maximum number of PNG images for this item");
    } else {
      self.editPngImagesData.forEach(item => {
        ImageArr.push(this._formBuilder.group({
          item_png_image_id: [item.ItemPngImageId],
          body_partType_term: [item.BodyPartType_Term],
          left_image_path: [(item.LeftImage == null) ? '' : this._commonService.getFullImageUrl(item.LeftImage)],
          left_image: ['', [requiredFileType(this._commonService.PNG_IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
          right_image_path: [(item.RightImage == null) ? '' : this._commonService.getFullImageUrl(item.RightImage)],
          right_image: ['', [requiredFileType(this._commonService.PNG_IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
          center_image_path: [(item.CenterImage == null) ? '' : this._commonService.getFullImageUrl(item.CenterImage)],
          center_image: ['', [requiredFileType(this._commonService.PNG_IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
          is_active: [item.IsActive],
          isActiveCenterImage: [(!this._commonService.isNullOrEmpty(item.CenterImage)) ? true : false],
          isActiveLeftImage: [(!this._commonService.isNullOrEmpty(item.LeftImage)) ? true : false],
          isActiveRightImage: [(!this._commonService.isNullOrEmpty(item.RightImage)) ? true : false]
        }));
      });
    }
  }

  bindScanDbImagesToFrom() {
    let self = this;
    const ImageArr = self.f7.imageScannedRows as FormArray;
    let totalImageRow = ImageArr.length;
    if (totalImageRow == self.max_scan_images) {
      Swal.fire("You've exceed the maximum number of images for this item");
    } else {
      self.editscanimagesData.forEach(item => {
        // let indicatorDetail = self.indicatorList.find(i => i.indicatorID == item.indicatorID);
        // self.itemImagesFormGroup.value.imageRows.push(this._formBuilder.group({
        //   imagepath:[this._commonService.getFullImageUrl(item.process_image_path)],
        //   image: ['', [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
        //   is_cover_image: [item.is_cover_image, Validators.required],
        //   sequence_no: [item.sequence_no, [Validators.required, Validators.maxLength(25), RxwebValidators.unique()]],
        //   image_details: [item.image_details],
        //   is_water_mark: [item.is_water_mark],
        //   is_live: [item.is_live]
        // }));
        ImageArr.push(this._formBuilder.group({
          item_gallery_id: [item.item_gallery_id],
          scan_imagepath: [this._commonService.getFullImageUrl(item.process_image_path)],
          scanned_Image: ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]]
        }));
        //let control = self.questionForms.controls[index];
        // index++;
      });
    }
  }

  get imageForms() {
    return this.itemImagesFormGroup.get('imageRows') as FormArray;
  }

  get pngImageForms() {
    return this.itemPngImagesFormGroup.get('pngImageRows') as FormArray;
  }

  get imageScannedForms() {
    return this.itemImagesScannnedFormGroup.get('imageScannedRows') as FormArray;
  }

  addNewItemImageRow(el: HTMLElement) {
    el.scrollIntoView();
    let self = this;
    const ImageArr = self.f1.imageRows as FormArray;
    let totalImageRow = ImageArr.length;
    let imglen = 0;
    if (self.imageGalleryData != undefined) {
      imglen = self.imageGalleryData.length;
    } else {
      //imglen = totalImageRow;
    }
    totalImageRow = totalImageRow + 1;
    if (totalImageRow > self.max_upload_images) {
      Swal.fire("You've exceed the maximum number of images for this item");
    } else {
      ImageArr.push(this._formBuilder.group({
        item_gallery_id: [0],
        imagepath: [''],
        image: ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
        is_cover_image: [0, Validators.required],
        sequence_no: [0, [Validators.required, Validators.maxLength(25), RxwebValidators.unique()]],
        image_details: [''],
        is_water_mark: [0],
        is_live: [0]
      }));
    }
  }

  addNewItemPngImageRow(el: HTMLElement) {
    el.scrollIntoView();
    let self = this;
    const ImageArr = self.f2.pngImageRows as FormArray;
    let totalImageRow = ImageArr.length;
    totalImageRow = totalImageRow + 1;
    if (totalImageRow > self.max_png_upload_images) {
      Swal.fire("You've exceed the maximum number of PNG images for this item");
    } else {
      ImageArr.push(this._formBuilder.group({
        item_png_image_id: [0],
        body_partType_term: [''],
        left_image_path: [''],
        left_image: ['', [requiredFileType(this._commonService.PNG_IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
        right_image_path: [''],
        right_image: ['', [requiredFileType(this._commonService.PNG_IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
        center_image_path: [''],
        center_image: ['', [requiredFileType(this._commonService.PNG_IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
        is_active: [1],
        isActiveCenterImage: [false],
        isActiveLeftImage: [false],
        isActiveRightImage: [false]
      }));
    }
  }

  deleteItemImageRow(index: number) {
    let self = this;
    let ImageArr = this.f1.imageRows as FormArray;
    let item_gallery_id = ImageArr.controls[index].get('item_gallery_id').value
    if (item_gallery_id != null && item_gallery_id != undefined && item_gallery_id > 0) {
      self._commonService.showLoader();
      self._itemService.deleteItemImage(item_gallery_id).subscribe(data => {
        self._commonService.hideLoader();
        self._commonService.showToaster("Image deleted successfully.", "success");
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
      ImageArr.removeAt(index);
    } else {
      ImageArr.removeAt(index);
    }
  }

  deleteItemPngImageRow(index: number) {
    let self = this;
    let ImageArr = this.f2.pngImageRows as FormArray;
    let item_png_image_id = ImageArr.controls[index].get('item_png_image_id').value
    if (item_png_image_id != null && item_png_image_id != undefined && item_png_image_id > 0) {
      self._commonService.showLoader();
      self._itemService.getPngImagesDelete(item_png_image_id).subscribe(data => {
        self._commonService.hideLoader();
        self._commonService.showToaster("PNG Image deleted successfully.", "success");
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
      ImageArr.removeAt(index);
    } else {
      ImageArr.removeAt(index);
    }
  }

  addNewScanItemImageRow(el: HTMLElement) {
    el.scrollIntoView();
    let self = this;
    const ImageArr = self.f7.imageScannedRows as FormArray;
    let totalImageRow = ImageArr.length;
    let imglen = 0;
    if (self.imageGalleryData != undefined) {
      imglen = self.imageGalleryData.length;
    } else {
      //imglen = totalImageRow;
    }

    totalImageRow = totalImageRow + 1;
    if (totalImageRow > self.max_scan_images) {
      Swal.fire("You've exceed the maximum number of images for this item");
    } else {
      ImageArr.push(this._formBuilder.group({
        item_gallery_id: [0],
        scan_imagepath: [''],
        scanned_Image: ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]]
      }));
    }
  }

  deleteScannedItemImageRow(index: number) {
    let self = this;
    let ImageArr = this.f7.imageScannedRows as FormArray;
    let item_gallery_id = ImageArr.controls[index].get('item_gallery_id').value
    if (item_gallery_id != null && item_gallery_id != undefined && item_gallery_id > 0) {
      self._commonService.showLoader();
      self._itemService.deleteItemImage(item_gallery_id).subscribe(data => {
        self._commonService.hideLoader();
        self._commonService.showToaster("Scan Image deleted successfully.", "success");
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
      ImageArr.removeAt(index);
    } else {
      ImageArr.removeAt(index);
    }
  }

  onSubmit() {
    let self = this;
    self.submittedRelatedItemFormGroup = true;
    this.selectDisabled = false;
    if (self.relatedItemFormGroup.invalid) {
      return false;
    } 
    var data =
    {
      AssociatedItemCategoryID: this.relatedItemFormGroup.value.AssociatedItemCategoryID.split(',')[0],
      AssociatedItemCategoryName: this.relatedItemFormGroup.value.AssociatedItemCategoryID.split(',')[1],
      AssociatedItemID: this.relatedItemFormGroup.value.AssociatedItemID.split(',')[0],
      AssociatedItemName: this.relatedItemFormGroup.value.AssociatedItemID.split(',')[1],
      Comment: this.relatedItemFormGroup.value.Comment,
      RelatedItemID: this.relatedItemFormGroup.value.RelatedItemID,
      related_item_thumbnail_image_path: this.url ? this.url : this.relatedItemFormGroup.value.base64String,
      isNewOrUpdatedImage: (!this._commonService.isNullOrEmpty(this.related_item_thumbnail_image_path)) ? true : false,
      fileName: this.relatedItemFormGroup.value.fileName,
      base64String: this.url
    }
    let itemIndex = this.relatedItemArray.findIndex(item => item.AssociatedItemCategoryID == data.AssociatedItemCategoryID && item.AssociatedItemID == data.AssociatedItemID);
    if (itemIndex < 0) {
      this.submittedRelatedItemFormGroup = false;
      this.relatedItemArray.push(data);
      this.relatedItemFormGroup.reset();
      this.totalrelatedFileName.forEach((element) => {

        if (element.index == -1) {
          element.index = (this.relatedItemArray.length - 1)
        }
      });
    }
    else {
      this.relatedItemArray[itemIndex] = data;
      let item = this.relatedItemArray[itemIndex];
      if (item.RelatedItemID > 0 && item.base64String != undefined) {
        data.related_item_thumbnail_image_path = item.related_item_thumbnail_image_path;
        data.isNewOrUpdatedImage = true;
      }
      else if (item.RelatedItemID > 0) {
        data.related_item_thumbnail_image_path = item.related_item_thumbnail_image_path;
        data.isNewOrUpdatedImage = false;
      }
      this.relatedItemArray[itemIndex] = data;
      this.relatedItemFormGroup.reset();
    }
    this.relatedItemIndex = -1;
    this.related_item_thumbnail_image_path.nativeElement.value = "";
    this.url = null;
  }

  getImageUrl(image, isNewOrUpdatedImage: boolean = false) {
    if (isNewOrUpdatedImage == false) {
      return this._commonService.getFullImageUrl(image);
    } else {
      return image;
    }
  }


  deleteIndex(obj, index: number) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        this.deletedItem.push(obj.RelatedItemID);
        this.relatedItemArray = this.relatedItemArray.filter(item => item !== obj);
        if (this.totalrelatedFileName != null && this.totalrelatedFileName != undefined
          && this.totalrelatedFileName.length > 0) {
          this.totalrelatedFileName = this.totalrelatedFileName.filter(i => i.index !== index);
        }
        self._commonService.hideLoader();
        self._commonService.showToaster("Item deleted successfully.", "success");
      }
    })
  }

  changeLabelName(item: RelatedVM, index: number) {
    let self = this;
    this.selectDisabled = true;
    self.relatedItemIndex = index;
    self._itemService.selectCatId(item.AssociatedItemCategoryID).subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.getItemData = response.data;
        let select2GetItem: any = [];
        if (self.getItemData != null && self.getItemData.length > 0) {
          self.getItemData.forEach(item => {
            select2GetItem.push({
              id: `${item.item_id},${item.display_name}-${item.code}`,
              text: `${item.display_name} - ${item.code}`,
            })
          });
        }
        self.select2GetItem = select2GetItem;
      }
    });
    var data =
    {
      AssociatedItemName: item.AssociatedItemName.split('-')[0],
      AssociatedItemcode: item.AssociatedItemName.split('-')[1],
    }

    //category
    this.relatedItemFormGroup.controls.AssociatedItemCategoryID.setValue((String(`${item.AssociatedItemCategoryID},${item.AssociatedItemCategoryName}`)));
    //item
    this.relatedItemFormGroup.controls.AssociatedItemID.setValue((String(`${item.AssociatedItemID},${data.AssociatedItemName}-${data.AssociatedItemcode}`)));
    //comment
    this.relatedItemFormGroup.controls.Comment.setValue(item.Comment);
    //relatedItemId
    this.relatedItemFormGroup.controls.RelatedItemID.setValue(item.RelatedItemID);
    //image
    // this.relatedItemFormGroup.controls.related_item_thumbnail_image_path.setValue(item.related_item_thumbnail_image_path);
    let base64String = item.related_item_thumbnail_image_path;
    this.relatedItemFormGroup.controls.base64String.setValue(base64String);
    this.relatedItemFormGroup.controls.related_item_thumbnail_image_path.setValue(item.fileName);
    this.relatedItemFormGroup.controls.fileName.setValue(item.fileName);
    this.relatedItemFormGroup.updateValueAndValidity();
  }

  // getDistributorDetail() {
  //   let self = this;
  //   self._distributorService.getLoginDistributorDetails().subscribe(data => {
  //     let response: any = data;
  //     if (response.single_distributor != null) {
  //       let items_count = response.single_distributor.items_count;
  //       let item_add_limit = response.single_distributor.item_add_limit;
  //       let distributorId = response.single_distributor.distributor_id;
  //       if (distributorId != 1) {
  //         if (items_count < item_add_limit) {
  //           self.itemLimitReached = false;
  //         } else {
  //           self.itemLimitReached = true;
  //         }
  //       }
  //     }
  //   });
  // }

  getDistributorDetail_V2() {
    let self = this;
    return new Promise((resolve, reject) => {
      self._distributorService.getLoginDistributorDetails().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getActiveItemGroups() {
    let self = this;
    return new Promise((resolve, reject) => {
      self._itemGroupService.getAllItemGroupData().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  onChangeGetItem(selectCategory) {
    let self = this;
    self._itemService.selectCatId(selectCategory.value).subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.getItemData = response.data;
        let select2GetItem: any = [];
        if (self.getItemData != null && self.getItemData.length > 0) {
          self.getItemData.forEach(item => {
            select2GetItem.push({
              id: `${item.item_id},${item.display_name}-${item.code}`,
              text: `${item.display_name} - ${item.code}`,
            })
          });
        }
        self.select2GetItem = select2GetItem;
      }
    });
  }

 onChangeGetCredit(selectCategory) {
    let self = this;
    if(selectCategory.value > 0)
    {
		
		 self._itemService.getCategoryById(selectCategory.value).subscribe(data => {
	      let response: any = data;
	      if (response.category != null) {
	        self.categoryVM = response.category;
	         
	       if(this.categoryVM.category_item_credit > 0)
	          	 {
				   this.basicFormGroup.controls.credit_per_image.setValue(this.categoryVM.category_item_credit);  
				 }
	          
	      }
	    });
    
	}
  }

  // getActiveDistributors() {
  //   let self = this;
  //   self._distributorService.getAllActiveDistributor().subscribe(data => {
  //     let response: any = data;
  //     if (response.distributor_data != null) {
  //       self.distributorData = response.distributor_data;
  //       self.select2DistributorData = self._commonService.dropdownArray(self.distributorData, "distributor_id", "display_name");
  //     }
  //   });
  // }

  getActiveDistributors_V2() {
    let self = this;
    return new Promise((resolve, reject) => {
      self._distributorService.getAllActiveDistributor().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getActiveProductionHouse() {
    let self = this;
    self._productionHouseService.getAllActiveProductionhouse().subscribe(data => {
      let response: any = data;
      if (response.production_house_data != null) {
        self.productionHouseData = response.production_house_data;
        self.select2ProductionHData = self._commonService.dropdownArray(self.productionHouseData, "production_house_id", "production_house_name");
      }
    });
  }

  getActiveWarehouseData() {
    let self = this;
    return new Promise((resolve, reject) => {
      self._warehouseService.getActiveWarehouseData().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getActiveMetalsData() {
    let self = this;
    return new Promise((resolve, reject) => {
      self._metalService.getActiveMetalData().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getActiveCategories() {
    let self = this;
    return new Promise((resolve, reject) => {
      self._itemService.getAllActiveCategories().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getActiveCategoriess() {
    let self = this;
    return new Promise((resolve, reject) => {
      self._itemService.getAllActiveCategories().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getActivePurity() {
    let self = this;
    return new Promise((resolve, reject) => {
      self._itemService.getActivePurity().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getItemStatusData() {
    let self = this;
    return new Promise((resolve, reject) => {
      self._itemService.getAllItemStatus().subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  // addAttributeRows(categoryId: number) {
  //   let self = this;
  //   const AttributeArr = self.f2.attributeRows as FormArray;
  //   self.clearFormArray(AttributeArr);
  //   self._itemService.getAttributesByCategoryId(categoryId).subscribe(data => {
  //     let response: any = data;
  //     if (response.category_attributes != null) {
  //       self.attributeData = response.category_attributes;
  //       self.attributeData.forEach(singleAttribute => {
  //         let is_compulsory = (singleAttribute.is_compulsory == null) ? 0 : singleAttribute.is_compulsory;
  //         let attribute_name = (singleAttribute.attribute != null) ? singleAttribute.attribute.attribute_name : '';
  //         let attribute_id = (singleAttribute.attribute != null) ? singleAttribute.attribute.attribute_id : '';
  //         AttributeArr.push(this._formBuilder.group({
  //           attribute_name: [attribute_name, [Validators.required, RxwebValidators.unique()]],
  //           attribute_id: [attribute_id, [Validators.required, RxwebValidators.unique()]],
  //           attribute_value: [singleAttribute.default_value, [(is_compulsory == 1) ? Validators.required : Validators.nullValidator, Validators.maxLength(255)]],
  //           is_compulsory: [is_compulsory],
  //           sequence_no: [singleAttribute.sequence_no, [(is_compulsory == 1) ? Validators.required : Validators.nullValidator, RxwebValidators.unique(), Validators.maxLength(255)]],
  //         }));
  //       });
  //     }
  //   });
  // }

  // get attributeForms() {
  //   return this.attributesFormGroup.get('attributeRows') as FormArray;
  // }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  // onChangeGetAttributes(selectedCategory) {
  //   let self = this;
  //   self.addAttributeRows(selectedCategory);
  // }

  // convenience getter for easy access to form fields
  get f() { return this.basicFormGroup.controls; }

  get f1() { return this.itemImagesFormGroup.controls; }

  get f2() { return this.itemPngImagesFormGroup.controls; }

  //get f2() { return this.attributesFormGroup.controls; }

  //get f4() { return this.accessoriesFormGroup.controls; }

  //get f6() { return this.productionFormGroup.controls; }

  get f7() { return this.itemImagesScannnedFormGroup.controls; }

  get f8() { return this.relatedItemFormGroup.controls; }

  saveBasicDetails() {
    let self = this;
    self.submitted = true;
    if (self.basicFormGroup.invalid) {
      return;
    }
    let basicFormData = (self.basicFormGroup.value);
    for (const prop in basicFormData) {
      self.formData.delete(prop);
      if (prop == 'main_image_path') {
        let fi = this.mainImageFileInput.nativeElement;
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.basicFormGroup.value.main_image_path = fileToUpload;
          self.formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          self.formData.append(prop, file, "");
        }
      } else {
        if (prop == 'is_available_for_distributor') {
          let is_available_for_distributorArray: any = basicFormData[prop];
          let is_available_for_distributor: string = "";
          if (is_available_for_distributorArray != null && is_available_for_distributorArray != undefined && is_available_for_distributorArray.length > 0) {
            is_available_for_distributorArray.forEach(element => {
              is_available_for_distributor += element + ",";
            });
            if (!self._commonService.isNullOrEmpty(is_available_for_distributor)) {
              is_available_for_distributor = is_available_for_distributor.slice(0, -1); //Remove last comma.
            }
          }
          self.formData.append(prop, is_available_for_distributor);
        }
        else if (prop == 'category_id_mutiple') {
          let category_id_mutipleArray: any = basicFormData[prop];
          let category_id_mutiple: string = "";
          if (category_id_mutipleArray != null && category_id_mutipleArray != undefined && category_id_mutipleArray.length > 0) {
            category_id_mutipleArray.forEach(element => {
              category_id_mutiple += element + ",";
            });
            if (!self._commonService.isNullOrEmpty(category_id_mutiple)) {
              category_id_mutiple = category_id_mutiple.slice(0, -1); //Remove last comma.
            }
          }
          self.formData.append(prop, category_id_mutiple);
        }
        else if (prop == 'is_active' || prop == 'status' || prop == 'is_available_on_for_display') {
          self.formData.append(prop, basicFormData[prop]);
        } else {
          self.formData.append(prop, this._commonService.parseString(basicFormData[prop]));
        }
      }
    }
  }

  // saveMetals() {
  //   this.saveBasicDetails();
  //   let self = this;
  //   self.submitted = true;
  //   // if (self.metalRows.length == 0) {
  //   //   self._commonService.showToaster("Please add metals", "warning");
  //   //   return;
  //   // }
  //   if (self.metalRows.length > 0) {
  //     self.formData.delete('metalRows');
  //     self.formData.append('metalRows', JSON.stringify(self.metalRows));
  //   }
  // }

  // saveAttributes() {
  //   this.saveBasicDetails();
  //   this.saveMetals();
  //   let self = this;
  //   self.submitted = true;
  //   if (self.attributesFormGroup.invalid) {
  //     return;
  //   }
  //   let attributeFormData = (self.attributesFormGroup.value);
  //   self.formData.delete('attributeRows');
  //   self.formData.append('attributeRows', JSON.stringify(attributeFormData.attributeRows));
  // }

  saveImages() {
    //this.saveBasicDetails();
    //this.saveMetals();
    //this.saveAttributes();
    let self = this;
    self.submitted = true;
    // if (self.itemImagesFormGroup.invalid) {
    //   return;
    // }
    // if (self.itemImagesFormGroup.value.imageRows.length == 0) {
    //   self._commonService.showToaster("Please add item images", "warning");
    //   return;
    // }
    for (let k = 0; k < self.tempImage.length; k++) {
      self.totalfiles.push(<File>self.tempImage[k].file);
      self.totalFileName.push(self.tempImage[k].name);
    }

    for (let j = 0; j < self.totalfiles.length; j++) {
      //self.formData.delete(self.totalFileName[j]);
      self.formData.append(self.totalFileName[j], <File>self.totalfiles[j]);
    }

    let AllFilesObj = [];
    self.itemImagesFormGroup.value.imageRows.forEach((element, index) => {
      if (self.totalFileName[index] != undefined) {
        let fileNameCurrent = self.totalFileName[index];
        let fileNameSave = fileNameCurrent.replace('.', '_');
        fileNameSave = fileNameSave.replace(' ', '_');
        //fileNameSave = fileNameSave.replace(/  +/g, '_');
        let eachObj =
        {
          'item_gallery_id': element.item_gallery_id,
          'file_name': fileNameSave,
          'image_details': element.image_details,
          'is_cover_image': element.is_cover_image,
          'sequence_no': element.sequence_no,
          'is_water_mark': element.is_water_mark,
          'is_live': element.is_live
        }
        AllFilesObj.push(eachObj);
      }
      else {
      }
    });

    if (self.itemImagesFormGroup.value.imageRows.length > 0) {
      self.formData.delete("imageRows");
      self.formData.append("imageRows", JSON.stringify(AllFilesObj));
    }
  }

  savePngImages() {
    let self = this;
    self.submitted = true;
    if (self.itemPngImagesFormGroup.invalid) {
      return;
    }
    for (let k = 0; k < self.tempPngImage.length; k++) {
      self.totalPngfiles.push(<File>self.tempPngImage[k].file);
      self.totalPngFileName.push(self.tempPngImage[k].name);
    }

    for (let j = 0; j < self.totalPngfiles.length; j++) {
      //self.formData.delete(self.totalFileName[j]);
      self.formData.append(self.totalPngFileName[j], <File>self.totalPngfiles[j]);
    }

    let AllFilesObj = [];
    self.itemPngImagesFormGroup.value.pngImageRows.forEach((element, index) => {
      if (self.totalPngFileName[index] != undefined) {
        let fileNameCurrent = self.totalPngFileName[index];
        let fileNameSave = fileNameCurrent.replace('.', '_');
        fileNameSave = fileNameSave.replace(' ', '_');
        //fileNameSave = fileNameSave.replace(/  +/g, '_');
        let eachObj =
        {
          'item_png_image_id': element.item_png_image_id,
          'file_name': fileNameSave,
          'body_partType_term': element.body_partType_term,
          'is_active': element.is_active,
        }
        AllFilesObj.push(eachObj);
      }
      else {
      }
    });

    if (self.itemPngImagesFormGroup.value.pngImageRows.length > 0) {
      self.formData.delete("pngImageRows");
      self.formData.append("pngImageRows", JSON.stringify(AllFilesObj));
    }
  }

  saveRelatedDetails() {
    this.saveBasicDetails();
    let self = this;
    // self.submitted = true;
    //  if(self.totalRelatedfiles.length>0){
    for (let j = 0; j < self.totalRelatedfiles.length; j++) {
      self.formData.delete(self.totalrelatedFileName[j].fileName);
      self.formData.append(self.totalrelatedFileName[j].fileName, <File>self.totalRelatedfiles[j]);
    }

    let AllFilesObj = [];
    self.relatedItemArray.forEach((element, index) => {
      let fileNameSave = '';
      if (element.isNewOrUpdatedImage == true || element.isNewOrUpdatedImage == 1) {
        let currentFileName = self.totalrelatedFileName.find(i => i.index == index);
        if (currentFileName != null && currentFileName != undefined) {
          let fileNameCurrent = currentFileName.fileName || '';
          if (fileNameCurrent != undefined && fileNameCurrent != null && fileNameCurrent != '') {
            fileNameSave = fileNameCurrent.replace('.', '_');
            fileNameSave = fileNameSave.replace(' ', '_');
          }
        }
      }
      var eachObj =
      {
        'file_name': fileNameSave,
        'MainItemID': element.MainItemID,
        'RelatedItemID': element.RelatedItemID,
        'isNewOrUpdatedImage': element.isNewOrUpdatedImage,
        'AssociatedItemCategoryID': element.AssociatedItemCategoryID,
        'AssociatedItemCategoryName': element.AssociatedItemCategoryName,
        'AssociatedItemID': element.AssociatedItemID,
        'AssociatedItemName': element.AssociatedItemName,
        'Comment': element.Comment,
      }
      AllFilesObj.push(eachObj);
    });
    self.formData.append("relatedRows", JSON.stringify(AllFilesObj));
    // else{
    //   let AllFilesObj = [];
    //   self.relatedItemArray.forEach((element, index) => {
    //     let fileNameSave = '';
    //     if (element.isNewOrUpdatedImage == true || element.isNewOrUpdatedImage == 1) {          
    //       let currentFileName = self.totalrelatedFileName.find(i => i.index == index);
    //       if (currentFileName != null && currentFileName != undefined) {
    //         let fileNameCurrent = currentFileName.fileName || '';
    //         if (fileNameCurrent != undefined && fileNameCurrent != null && fileNameCurrent != '') {
    //           fileNameSave = fileNameCurrent.replace('.', '_');
    //           fileNameSave = fileNameSave.replace(' ', '_');
    //         }
    //       }
    //     }
    //     var eachObj =
    //     {
    //       'file_name': fileNameSave,
    //       'MainItemID': element.MainItemID,
    //       'RelatedItemID': element.RelatedItemID,
    //       'isNewOrUpdatedImage':1,
    //       'AssociatedItemCategoryID': element.AssociatedItemCategoryID,
    //       'AssociatedItemCategoryName': element.AssociatedItemCategoryName,
    //       'AssociatedItemID': element.AssociatedItemID,
    //       'AssociatedItemName': element.AssociatedItemName,
    //       'Comment': element.Comment,
    //     }
    //     AllFilesObj.push(eachObj);
    //   });
    //   self.formData.append("relatedRows", JSON.stringify(AllFilesObj));
    // }
    var deletedItems = {
      'delete_item': this.deletedItem
    }
    self.formData.append("deleteItemRows", JSON.stringify(this.deletedItem));
    // else {
    //   let AllFilesObj = [];
    //   self.relatedItemArray.forEach((element) => {
    //     var eachObj =
    //     {
    //       'file_name': '',
    //       'AssociatedItemCategoryID': element.AssociatedItemCategoryID,
    //       'AssociatedItemCategoryName': element.AssociatedItemCategoryName,
    //       'AssociatedItemID': element.AssociatedItemID,
    //       'AssociatedItemName': element.AssociatedItemName,
    //       'Comment': element.Comment,
    //     }
    //     AllFilesObj.push(eachObj);
    //   });
    //   self.formData.append("relatedRows", JSON.stringify(AllFilesObj));
    // }
  }

  saveScannedImages() {
    //this.saveBasicDetails();
    //this.saveMetals();
    //this.saveAttributes();
    let self = this;
    self.scannnedSubmitted = true;
    if (self.itemImagesScannnedFormGroup.invalid) {
      return false;
    }
    // if (self.itemImagesFormGroup.value.imageRows.length == 0) {
    //   self._commonService.showToaster("Please add item images", "warning");
    //   return;
    // }
    for (let k = 0; k < self.tempScanImage.length; k++) {
      self.totalScannedfiles.push(<File>self.tempScanImage[k].file);
      self.totalScannedFileName.push(self.tempScanImage[k].name);
    }

    for (let j = 0; j < self.totalScannedfiles.length; j++) {
      self.formData.append(self.totalScannedFileName[j], <File>self.totalScannedfiles[j]);
    }

    let AllFilesObj = [];
    self.itemImagesScannnedFormGroup.value.imageScannedRows.forEach((element, index) => {
      if (self.totalScannedFileName[index] != undefined) {
        let fileNameCurrent = self.totalScannedFileName[index];
        let fileNameSave = fileNameCurrent.replace('.', '_');
        fileNameSave = fileNameSave.replace(' ', '_');
        //fileNameSave = fileNameSave.replace(/  +/g, '_');
        let eachObj =
        {
          'item_gallery_id': element.item_gallery_id,
          'file_name': fileNameSave,
        }
        AllFilesObj.push(eachObj);
      }
      else {
      }
    });
    if (self.itemImagesScannnedFormGroup.value.imageScannedRows.length > 0) {
      self.formData.delete("imageScannedRows");
      self.formData.append("imageScannedRows", JSON.stringify(AllFilesObj));
    }
  }

  saveAccessories() {
    this.saveBasicDetails();
    // this.saveMetals();
    // this.saveAttributes();
    //this.saveScannedImages();
    this.saveImages();
    this.savePngImages();
    let self = this;
    self.submitted = true;
    // if (self.accessoryRows.length == 0) {
    //   self._commonService.showToaster("Please add accessories", "warning");
    //   return;
    // }
    // if (self.accessoryRows.length > 0) {
    //   self.formData.delete('accessoryRows');
    //   self.formData.append('accessoryRows', JSON.stringify(self.accessoryRows));
    // }
    self.finalSave();
  }

  // saveProductionDetails() {
  //   this.saveBasicDetails();
  //   this.saveMetals();
  //   this.saveAttributes();
  //   this.saveImages();
  //   this.saveAccessories();
  //   let self = this;
  //   self.submitted = true;
  //   if (self.productionFormGroup.invalid) {
  //     return;
  //   }
  //   let productionFormData = (self.productionFormGroup.value);
  //   for (const prop1 in productionFormData) {
  //     self.formData.delete(prop1);
  //     if (prop1 == 'date_of_production') {
  //       let dateOfProduction = this._datePipe.transform(productionFormData[prop1], 'yyyy-MM-dd');
  //       self.formData.append(prop1, dateOfProduction);
  //     } else {
  //       self.formData.append(prop1, productionFormData[prop1]);
  //     }
  //   }
  //   self.finalSave();
  // }

  hasDuplicates(arr) {
    var counts = [];
    for (var i = 0; i <= arr.length; i++) {
      //if(!this._commonService.isNullOrEmpty(counts[arr[i]])){
      if (counts[arr[i]] === undefined) {
        counts[arr[i]] = 1;
      } else {
        return true;
      }
      //}
    }
    return false;
  }

  finalSave() {
    let self = this;
    self.submitted = true;
    if (self.itemPngImagesFormGroup.invalid) {
      return;
    }
    if (self.itemImagesFormGroup.value.imageRows.length != 0) {
      let seqList: string[] = [];
      if (this.itemImagesFormGroup.invalid) {
        return
      }
      self.itemImagesFormGroup.value.imageRows.forEach(data => seqList.push(data.sequence_no));
      let isDuplicate = this.hasDuplicates(seqList);
      if (isDuplicate) {
        self._commonService.showToaster("Please enter diffrent sequence no.", "error");
        return false;
      }
      else {
        self._commonService.showLoader();
        self._itemService.updateItem(self.formData).subscribe(data => {
          self._commonService.hideLoader();
          if (!self._commonService.isNullOrEmpty(data)) {
            self._commonService.showToaster("Item updated successfully.", "success");
            if (this.source != null && this.source != undefined && this.source != '') {
              this._router.navigate(['/admin/distributor-items']);
            } else {
              self.gotoItemsList();
            }
          }
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    }
    else {
      self._commonService.showLoader();
      self._itemService.updateItem(self.formData).subscribe(data => {
        self._commonService.hideLoader();
        if (!self._commonService.isNullOrEmpty(data)) {
          self._commonService.showToaster("Item updated successfully.", "success");
          if (this.source != null && this.source != undefined && this.source != '') {
            this._router.navigate(['/admin/distributor-items']);
          } else {
            self.gotoItemsList();
          }
        }
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
    }
  }

  gotoItemsList() {
    this._router.navigate(['/admin/items']);
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goForward(stepper: MatStepper) {
    stepper.next();
  }

  // openMetalDialog(): void {
  //   let self = this;
  //   const dialogRef = this.dialog.open(MetalModalComponent, {
  //     width: '750px',
  //     data: { dialogTitle: 'Add New Metal', MetalArr: self.metalIds },
  //     disableClose: true
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     let metal = new ItemMetalVM();
  //     metal = result;
  //     if (!this._commonService.isNullOrEmpty(metal.metal_id)) {
  //       this.metalRows.push(metal);
  //       this.metalIds.push(this._commonService.convertToNumber(metal.metal_id));
  //     }
  //   });
  // }

  // deleteMetalRow(metalRow, metalID) {
  //   this.metalIds.splice(this.metalIds.indexOf(metalID), 1);
  //   this.metalRows.splice(this.metalRows.indexOf(metalRow), 1);
  // }

  // openAccessoryDialog(): void {
  //   let self = this;
  //   const dialogRefAccessory = this.dialog.open(AccessoryModalComponent, {
  //     width: '750px',
  //     data: { dialogTitle: 'Add New Accessory', AccessoryArr: self.accessoryIds },
  //     disableClose: true
  //   });

  //   dialogRefAccessory.afterClosed().subscribe(result => {
  //     let accessory = new ItemAccessoryVM();
  //     accessory = result;
  //     if (!this._commonService.isNullOrEmpty(accessory.accessory_id)) {
  //       this.accessoryRows.push(accessory);
  //       this.accessoryIds.push(this._commonService.convertToNumber(accessory.accessory_id));
  //     }
  //   });
  // }

  // deleteAccessoryRow(accessoryRow, accessoryId) {
  //   this.accessoryIds.splice(this.accessoryIds.indexOf(accessoryId), 1);
  //   this.accessoryRows.splice(this.accessoryRows.indexOf(accessoryRow), 1);
  // }

  public fileSelectionEvent(fileInput: any, oldIndex) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
      }
      if (oldIndex == 0) {
        this.totalfiles.unshift((fileInput.target.files[0]));
        let filename = fileInput.target.files[0].name;
        this.totalFileName.unshift(filename.replace('.', '_'));
      }
      else {
        let filename = fileInput.target.files[0].name;
        let replacefilename = filename.replace('.', '_');
        this.totalfiles[oldIndex] = (fileInput.target.files[0]);
        this.totalFileName[oldIndex] = replacefilename;
        this.tempImage.push({
          file: fileInput.target.files[0],
          name: replacefilename,
        });
        //this.tempImage.push(fileInput.target.files[0]);
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
    if (this.totalfiles.length == 1) {
      this.lengthCheckToaddMore = 1;
    }
  }

  public filePngSelectionEvent(fileInput: any, oldIndex) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
      }
      if (oldIndex == 0) {
        this.totalPngfiles.unshift((fileInput.target.files[0]));
        let filename = fileInput.target.files[0].name;
        this.totalPngFileName.unshift(filename.replace('.', '_'));
      }
      else {
        let filename = fileInput.target.files[0].name;
        let replacefilename = filename.replace('.', '_');
        this.totalPngfiles[oldIndex] = (fileInput.target.files[0]);
        this.totalPngFileName[oldIndex] = replacefilename;
        this.tempPngImage.push({
          file: fileInput.target.files[0],
          name: replacefilename,
        });
        //this.tempPngImage.push(fileInput.target.files[0]);
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
    if (this.totalPngfiles.length == 1) {
      this.lengthPngCheckToaddMore = 1;
    }
  }

  isControlHasError(file: string) {
    const control = this.relatedItemFormGroup.controls['related_item_thumbnail_image_path'];
    let types = this._commonService.IMAGE_FILE_FORMAT;
    if (file) {
      //const extension = file.split('.')[1].toLowerCase();
      const extension = file.split('.').pop();
      if (!types.toLowerCase().includes(extension.toLowerCase())) {
        this.relatedItemFormGroup.controls['related_item_thumbnail_image_path'].setErrors({ 'requiredFileType': true });
        // this.relatedItemFormGroup.controls['related_item_thumbnail_image_path'].setErrors({ 'fileSize': true });
        //  this.relatedItemFormGroup.controls['related_item_thumbnail_image_path'].updateValueAndValidity();
      } else {
        this.relatedItemFormGroup.controls['related_item_thumbnail_image_path'].setErrors(null);
        this.relatedItemFormGroup.controls['related_item_thumbnail_image_path'].updateValueAndValidity();
      }
      let f = this.relatedItemFormGroup.controls;
    }
  }

  public relatedfileSelectionEvent(fileInput: any) {
    this.url = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
     
          const fileSize = fileInput.target.files[0].size;
        
          const fileSizeInKB = Math.round(fileSize / 1024);
            let controls = this.relatedItemFormGroup.controls;  
       
          if (fileSizeInKB >= 5000) {
               controls.related_item_thumbnail_image_path.setErrors({ 'fileSize': true }); 
              return;
          } else {
               controls.related_item_thumbnail_image_path.setErrors(null);
          }


      var reader = new FileReader();
      reader.onload = (fileInput: any) => {
        this.url = fileInput.target.result;
      }

      this.totalRelatedfiles.push((fileInput.target.files[0]));
      let filename = fileInput.target.files[0].name;
      //this.totalrelatedFileName.unshift(filename.replace('.', '_') );
      this.totalrelatedFileName.push({ index: this.relatedItemIndex, fileName: filename.replace('.', '_') });
      let frmControls = this.relatedItemFormGroup.controls;
      frmControls.fileName.setValue(filename);
      frmControls.fileName.updateValueAndValidity();
      reader.readAsDataURL(fileInput.target.files[0]);
      this.isControlHasError(filename);
      // this.relatedItemFormGroup.controls.related_item_thumbnail_image_path.setValidators(requiredFileType(this._commonService.IMAGE_FILE_FORMAT,));
      //  let isFormValid = this.relatedItemFormGroup.invalid;
    }
  }

  public scannedfileSelectionEvent(fileInput: any, oldIndex) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
      }
      if (oldIndex == 0) {
        this.totalScannedfiles.unshift((fileInput.target.files[0]));
        //this.totalFileName.unshift(fileInput.target.files[0].name)
        let filename = fileInput.target.files[0].name;
        this.totalScannedFileName.unshift(filename.replace('.', '_'));
      }
      else {
        let filename = fileInput.target.files[0].name;
        let replacefilename = filename.replace('.', '_');
        this.totalScannedfiles[oldIndex] = (fileInput.target.files[0]);
        this.totalScannedFileName[oldIndex] = replacefilename;
        this.tempScanImage.push({
          file: fileInput.target.files[0],
          name: replacefilename,
        });
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
    if (this.totalScannedfiles.length == 1) {
      this.lengthCheckToaddMore = 1;
    }
  }

  selectCoverImage(data) {
    let self = this;
    let item_gallery_id = data.value.item_gallery_id;
    if (item_gallery_id != null && item_gallery_id != undefined && item_gallery_id > 0) {
      self._commonService.showLoader();
      self._itemService.updateCoverImage(item_gallery_id, self.id).subscribe(data => {
        self._commonService.hideLoader();

      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
    } else {
    }
  }

  updateSeuanceNo(data) {
    let self = this;
    let item_gallery_id = data.value.item_gallery_id;
    let seqno = data.value.sequence_no;
    if (item_gallery_id != null && item_gallery_id != undefined && item_gallery_id > 0) {
      self._commonService.showLoader();
      self._itemService.updateSequenceNo(item_gallery_id, seqno).subscribe(data => {
        self._commonService.hideLoader();
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
    } else {
    }
  }

  // updateImageDetails(data) {
  //   let self = this;
  //   let item_gallery_id = data.value.item_gallery_id;
  //   let image_details = data.value.image_details;
  //   if (item_gallery_id != null && item_gallery_id != undefined && item_gallery_id > 0) {
  //     self._commonService.showLoader();
  //     self._itemService.updateImageDetails(item_gallery_id, image_details).subscribe(data => {
  //       self._commonService.hideLoader();
  //     }, error => {
  //       self._commonService.hideLoader();
  //       self._commonService.showToaster(error.message, "error");
  //     });
  //   } else {
  //   }
  // }

  // updateWaterMarkDetails(data) {
  //   let self = this;
  //   let item_gallery_id = data.value.item_gallery_id;
  //   let is_water_mark = data.value.is_water_mark;
  //   if (item_gallery_id != null && item_gallery_id != undefined && item_gallery_id > 0) {
  //     self._commonService.showLoader();
  //     self._itemService.updateWaterMarkDetails(item_gallery_id, is_water_mark).subscribe(data => {
  //       self._commonService.hideLoader();
  //     }, error => {
  //       self._commonService.hideLoader();
  //       self._commonService.showToaster(error.message, "error");
  //     });
  //   } else {
  //   }
  // }

  // updateIsLiveDetails(data) {
  //   let self = this;
  //   let item_gallery_id = data.value.item_gallery_id;
  //   let is_live = data.value.is_live;
  //   if (item_gallery_id != null && item_gallery_id != undefined && item_gallery_id > 0) {
  //     self._commonService.showLoader();
  //     self._itemService.updateIsLiveDetails(item_gallery_id, is_live).subscribe(data => {
  //       self._commonService.hideLoader();
  //     }, error => {
  //       self._commonService.hideLoader();
  //       self._commonService.showToaster(error.message, "error");
  //     });
  //   } else {
  //   }
  // }

  getItemImageGallery() {
    let self = this;
    this._route.params.subscribe(params => { self.id = params['id']; });
    return new Promise((resolve, reject) => {
      self._itemService.getImagesGallery(self.id).subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  getDistributorDesignItem() {
    let self = this;
    this._route.params.subscribe(params => { self.id = params['id']; });
    return new Promise((resolve, reject) => {
      self._distributorService.getDistributorDesign(self.id).subscribe(data => {
        resolve({ success: true, data: data });
      }, error => {
        resolve({ success: false, error: error });
      });
    });
  }

  // onChangeGetMetalRate(selectedMetal) {
  //   let self = this;
  //   let metalDataArray = self.metalData;
  //   metalDataArray.forEach(obj => {
  //     let metal = obj;
  //     let metal_id = obj.metal_id;
  //     if (metal_id == selectedMetal) { // true
  //       self.basicFormGroup.controls['rate'].setValue(obj.current_price);
  //       $('.unit_term_display').html('<small> (Unit: ' + metal.unit.unit_name + ')</small>');
  //     }
  //   });
  // }

  onChangeGetItemCode(selectedCode: any) {
    let self = this;
    self._commonService.showLoader();
    self._itemService.getItemcodeById(selectedCode).subscribe(data => {
      let response: any;
      response = data;
      this.f.item_unique_id.setValue(response.itemUniqueID);
      self._commonService.hideLoader();
    });
  }

  onChangeGetPngItem(selectBodyPartType, index: number) {
    if (selectBodyPartType.value == 1 || selectBodyPartType.value == 4 || selectBodyPartType.value == 5) {
      let ImageArr = this.f2.pngImageRows as FormArray;
      let formControlObj = ImageArr.controls[index] as FormGroup;
      let center_image = formControlObj.controls['center_image'].value;
      formControlObj.controls['isActiveCenterImage'].setValue(true);
      formControlObj.controls['isActiveLeftImage'].setValue(false);
      formControlObj.controls['isActiveRightImage'].setValue(false);
    }
    else if (selectBodyPartType.value == 2) {
      let ImageArr = this.f2.pngImageRows as FormArray;
      let formControlObj = ImageArr.controls[index] as FormGroup;
      let left_image = formControlObj.controls['left_image'].value;
      let right_image = formControlObj.controls['right_image'].value;
      formControlObj.controls['isActiveLeftImage'].setValue(true);
      formControlObj.controls['isActiveCenterImage'].setValue(false);
      formControlObj.controls['isActiveRightImage'].setValue(false);
    }
    else {
      let ImageArr = this.f2.pngImageRows as FormArray;
      let formControlObj = ImageArr.controls[index] as FormGroup;
      let left_image = formControlObj.controls['left_image'].value;
      let right_image = formControlObj.controls['right_image'].value;
      formControlObj.controls['isActiveRightImage'].setValue(true);
      formControlObj.controls['isActiveCenterImage'].setValue(false);
      formControlObj.controls['isActiveLeftImage'].setValue(false);
    }
  }

  selectIsActiveImage(data, image_value) {
    let self = this;
    let item_png_image_id = image_value.controls.item_png_image_id.value
    if (item_png_image_id != null && item_png_image_id != undefined && item_png_image_id > 0) {
      self._commonService.showLoader();
      self._itemService.updateIsActivePNGImage(item_png_image_id, data).subscribe(data => {
        self._commonService.hideLoader();

      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
    } else {
    }
  }
}