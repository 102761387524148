import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';

// @Models
import { ProductionhouseVM } from 'src/app/models/ProductionhouseVM';

// @Services
import { REGEXP } from 'src/app/shared/regexp';
import { ProductionhouseService } from 'src/app/services/productionhouse.service';
import { CommonService } from 'src/app/services/common.service';
import { UserVM } from 'src/app/models/UserVM';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'selenium-webdriver/edge';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';

declare var $: any;

@Component({
  selector: 'app-productionhouse-edit',
  templateUrl: './productionhouse-edit.component.html',
  styleUrls: ['./productionhouse-edit.component.css']
})
export class ProductionhouseEditComponent implements OnInit {

  public select2Options: Options;
  public select2ProductionhouseData: Array<Select2OptionData>;

  @ViewChild("primary_image", { static: false }) productionhouseprimary_image;
  @ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;

  id: number;
  editProductionHouseForm: FormGroup;
  ProductionhouseVM = new ProductionhouseVM();
  userdata: UserVM[];
  productionhouseimageurl: string;
  submitted = false;
  role: number;
  permissionsData = [];
  selectedItems: any = [];
  constructor(
    private _formBuilder: FormBuilder,
    private _regex: REGEXP,
    private _commonService: CommonService,
    private _productionhouseService: ProductionhouseService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _roleService: RoleService,
    private _storageService: StorageService
  ) { }

  ngOnInit() {
    this.role = this._commonService.convertToNumber(this._storageService.getUserRoles());
    if (this.role != 1) {
      this.getPermission();
    }
    this.buildForm();
    Promise.all([
      this.getAllUserData()
    ]).then((data: any) => {
      this.getProductionhouse();
    });
  }


  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[11].sub_permission[2].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  get f() { return this.editProductionHouseForm.controls; }

  gotoProductionhouseList() {
    this._router.navigate(['/admin/production-houses']);
  }

  getAllUserData() {
    let self = this;
    self._productionhouseService.getAllUserData().subscribe(data => {
      let response: any = data;
      if (response.user_data != null) {
        self.userdata = response.user_data;
        let select2ProductionhouseData: any = [];
        if (self.userdata != null && self.userdata.length > 0) {
          self.userdata.forEach(item => {
            if (item.association_id == null) {
              self.selectedItems = self.userdata.filter(c => c.association_id == null);
              // select2ProductionhouseData.push({
              //   id: item.id,
              //   text: `${item.first_name} ${item.last_name}`,
              // })
            }
          });
        }

        self.select2ProductionhouseData = select2ProductionhouseData;
      }
    });
  }

  getProductionhouse() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._productionhouseService.getProductionhousehouseById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.ProductionhouseVM = response.productionhouse;
        self.buildForm();
      });
  }

  buildForm() {

    let ProductionFormGroup = {
      production_house_id: [this._commonService.convertToNumber(this.ProductionhouseVM.production_house_id)],
      production_house_code: [this.ProductionhouseVM.production_house_code, [Validators.required, Validators.maxLength(255)]],
      production_house_name: [this.ProductionhouseVM.production_house_name, [Validators.required, Validators.maxLength(255)]],
      production_house_details: [this.ProductionhouseVM.production_house_details],
      address: [this.ProductionhouseVM.address, [Validators.required, Validators.maxLength(255)]],
      latitude: [this.ProductionhouseVM.latitude],
      longitude: [this.ProductionhouseVM.longitude],
      production_manage_id: [this._commonService.convertToNumber(this.ProductionhouseVM.production_manage_id), Validators.required],
      is_active: [this.ProductionhouseVM.is_active, Validators.required]
    }
    if (this._commonService.isNullOrEmpty(this.ProductionhouseVM.primary_image)) {
      ProductionFormGroup['primary_image'] = [this.ProductionhouseVM.primary_image, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
    } else {
      ProductionFormGroup['primary_image'] = [this.ProductionhouseVM.primary_image, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
      this.productionhouseimageurl = this._commonService.getFullImageUrl(this.ProductionhouseVM.primary_image);
    }
    this.editProductionHouseForm = this._formBuilder.group(ProductionFormGroup);
  }

  save() {
    let self = this;
    self.submitted = true;

    if (self.editProductionHouseForm.invalid) {
      return;
    }
    let formData = new FormData();

    let productionHouseData = (self.editProductionHouseForm.value as ProductionhouseVM);
    for (const prop in productionHouseData) {
      let fi = self.productionhouseprimary_image.nativeElement;
      if (prop == 'primary_image') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.editProductionHouseForm.value.primary_image = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, self._commonService.parseString(productionHouseData[prop]));
      }
    }

    self._commonService.showLoader();
    self._productionhouseService.updateProductionhouse(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoProductionhouseList();
        self._commonService.showToaster("Production house updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

  public handleAddressChange(address) {
    this.editProductionHouseForm.controls['address'].setValue(address.formatted_address);
    this.editProductionHouseForm.controls['latitude'].setValue(address.geometry.location.lat());
    this.editProductionHouseForm.controls['longitude'].setValue(address.geometry.location.lng())
  }

}



