import { UnitVM } from './UnitVM'

export class MetalVM {
  metal_id: number
  metal_code: string
  metal_name: string
  metal_detail: string
  current_price: string
  unit_of_mesure_id: number
  is_active: string
  update_log?: string
  total_sold: string
  total_produce: string
  total_in_stock: string
  created_at: string
  updated_at?: string
  unit?: UnitVM
}
