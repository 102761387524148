import { Injectable, DebugElement } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _authService: AuthService, private _storageService: StorageService, private _router: Router) {

  }

  canActivate() {
    if (this._authService.isAuthenticated()) {
      //check user active or not.

      return true;
    } else {
      this._router.navigate(['/login']);
      return false;
    }
  }

  canActivateChild(): boolean {
    if (this._authService.isAuthenticated()) {
      const userId = this._storageService.getUserId();

      if (userId != null && userId != undefined && userId != "") {

        this._authService.checkUserActive(userId).subscribe(data => {
          let response: any = data
          if (response != null && response.user.is_active != 1) {
            this._authService.logout();
          } else {
            return true;
          }
        }, error => {
          console.log(error);
        })

      }
    } else {
      //this._router.navigate(['/login']);
      this._authService.logout();
      return false;
    }

    return true;
  }
}
