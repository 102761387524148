// @packages
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';

// @Models
import { MetalVM } from 'src/app/models/MetalVM';
import { PurityVM } from 'src/app/models/PurityVM';

// @Services
import { MetalService } from 'src/app/services/metal.service';
import { CommonService } from 'src/app/services/common.service';
import { ItemService } from 'src/app/services/item.service';

declare var $: any;
@Component({
  selector: 'app-metal-modal',
  templateUrl: './metal-modal.component.html',
  styleUrls: ['./metal-modal.component.css']
})
export class MetalModalComponent implements OnInit {

  addMetalFormGroup: FormGroup;
  MetalVM = new MetalVM();
  submitted = false;
  metalData: MetalVM[] = new Array();
  purityData: PurityVM[];

  //Select 2
  public select2Options: Options;
  public select2MetalData: Array<Select2OptionData>;
  public select2PurityData: Array<Select2OptionData>;

  constructor(
    public dialogRef: MatDialogRef<MetalModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _itemService: ItemService,
    private _metalService: MetalService,
    private _commonService: CommonService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getActiveMetalsData();
    this.getActivePurity();
    this.buildForm();
  }

  buildForm() {
    this.addMetalFormGroup = this._formBuilder.group({
      metal_id: ['', [Validators.required]],
      purity_term: ['', [Validators.required, Validators.maxLength(255)]],
      weight: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      net_weight: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      wastage: ['', [Validators.required, Validators.maxLength(40)]],
      labour_rate: ['', [Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      metal_rate: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      total_labour: ['', [Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      total_metal_cost: ['', [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      metal_rate_unit: [''],
      metal: new MetalVM(),
      purity_data: new PurityVM()
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.addMetalFormGroup.controls; }

  getActiveMetalsData() {
    let self = this;
    self._metalService.getActiveMetalData().subscribe(data => {
      let response: any = data;
      if (response.metal_data != null) {
        let metalDataArray = response.metal_data;
        metalDataArray.forEach(obj => {
          let metal_id = obj.metal_id;
          // check wheather the meatl is already added for this item or not
          if (self.data.MetalArr.indexOf(metal_id) == '-1') { // false
            let metal = new MetalVM();
            metal = obj;
            self.metalData.push(metal); // adding obj to the dropdown array
          }
        });
        self.select2MetalData = self._commonService.dropdownArray(self.metalData, "metal_id", "metal_name");
      }
    });
  }

  getActivePurity() {
    let self = this;
    self._itemService.getActivePurity().subscribe(data => {
      let response: any = data;
      if (response.purity != null) {
        self.purityData = response.purity;
        self.select2PurityData = self._commonService.dropdownArray(self.purityData, "id", "purity");
      }
    });
  }

  saveMetal() {
    let self = this;
    self.submitted = true;
    if (self.addMetalFormGroup.invalid) {
      return;
    }
    self.dialogRef.close(self.addMetalFormGroup.value);
  }

  onChangeStoreName(metalId: number) {
    let self = this;
    let metalDataArray = self.metalData;
    metalDataArray.forEach(obj => {
      let metal_id = obj.metal_id;
      if (metal_id == metalId) { // true
        let metal = new MetalVM();
        metal = obj;
        $('.metal_unit_term_display').html('<small> (Unit: ' + metal.unit.unit_name + ')</small>');
        self.addMetalFormGroup.controls['metal_rate'].patchValue(metal.current_price);
        self.addMetalFormGroup.controls['metal'].patchValue(metal);
      }
    });
  }

  onChangePurity(purityId: number) {
    let self = this;
    let purityDataArray = self.purityData;
    purityDataArray.forEach(obj => {
      let purity_id = obj.id;
      if (purity_id == purityId) { // true
        let purity = new PurityVM();
        purity = obj;
        self.addMetalFormGroup.controls['purity_data'].patchValue(purity);
      }
    });
  }
}
