// @Packages
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { MatTableModule, MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatTooltipModule, MatDialogModule, MatDatepicker, MatFormField, MatInput, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatTabsModule, MatRadioModule } from '@angular/material';
import { MatStepperModule } from '@angular/material/stepper';
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgSelect2Module } from 'ng-select2';
import { LightboxModule } from 'ngx-lightbox';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AudioRecordingService } from './services/audio-recording.service';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';



// @Pages
import { AppComponent } from './app.component';
import { HeaderComponent } from './base/controls/header/header.component';
import { SidebarComponent } from './base/controls/sidebar/sidebar.component';
import { FooterComponent } from './base/controls/footer/footer.component';
import { AppLayoutComponent } from './base/layouts/app-layout/app-layout.component';
import { LoginLayoutComponent } from './base/layouts/login-layout/login-layout.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './pages/account/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './pages/account/forgot-password/forgot-password.component';

// @Services
import { REGEXP } from './shared/regexp';
import { AppConfig } from './shared/app-config';
import { AuthService } from './services/auth/auth.service';
import { HttpService } from './services/http.service';
import { CommonService } from './services/common.service';
import { RoleAddComponent } from './pages/role/role-add/role-add.component';
import { RoleListComponent } from './pages/role/role-list/role-list.component';
import { RoleEditComponent } from './pages/role/role-edit/role-edit.component';
import { ResetPasswordViewComponent } from './pages/account/reset-password-view/reset-password-view.component';
import { ChangePasswordComponent } from './pages/account/change-password/change-password.component';
import { SiteConfigComponent } from './pages/site-config/site-config.component';
import { WarehouseListComponent } from './pages/warehouse/warehouse-list/warehouse-list.component';
import { WarehouseAddComponent } from './pages/warehouse/warehouse-add/warehouse-add.component';
import { CompaniesListComponent } from './pages/company/companies-list/companies-list.component';
import { CompaniesEditComponent } from './pages/company/companies-edit/companies-edit.component';
import { CompaniesAddComponent } from './pages/company/companies-add/companies-add.component';
import { WarehouseEditComponent } from './pages/warehouse/warehouse-edit/warehouse-edit.component';
import { AttributeListComponent } from './pages/attribute/attribute-list/attribute-list.component';
import { AttributeAddComponent } from './pages/attribute/attribute-add/attribute-add.component';
import { AttributeEditComponent } from './pages/attribute/attribute-edit/attribute-edit.component';
import { ProductionhouseListComponent } from './pages/productionhouse/productionhouse-list/productionhouse-list.component';
import { CategoryAddComponent } from './pages/category/category-add/category-add.component';
import { CategoryListComponent } from './pages/category/category-list/category-list.component';
import { CategoryEditComponent } from './pages/category/category-edit/category-edit.component';
import { ProductionhouseAddComponent } from './pages/productionhouse/productionhouse-add/productionhouse-add.component';
import { ProductionhouseEditComponent } from './pages/productionhouse/productionhouse-edit/productionhouse-edit.component';
import { MetalListComponent } from './pages/metal/metal-list/metal-list.component';
import { MetalAddComponent } from './pages/metal/metal-add/metal-add.component';
import { TermcategoryAddComponent } from './pages/term_category/termcategory-add/termcategory-add.component';
import { TermcategoryListComponent } from './pages/term_category/termcategory-list/termcategory-list.component';
import { TermcategoryEditComponent } from './pages/term_category/termcategory-edit/termcategory-edit.component';
import { TermsAddComponent } from './pages/terms/terms-add/terms-add.component';
import { TermsEditComponent } from './pages/terms/terms-edit/terms-edit.component';
import { TermsListComponent } from './pages/terms/terms-list/terms-list.component';
import { MetalEditComponent } from './pages/metal/metal-edit/metal-edit.component';
import { DistributorgroupAddComponent } from './pages/distributor-group/distributorgroup-add/distributorgroup-add.component';
import { DistributorgroupListComponent } from './pages/distributor-group/distributorgroup-list/distributorgroup-list.component';
import { DistributorgroupEditComponent } from './pages/distributor-group/distributorgroup-edit/distributorgroup-edit.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { UsersAddComponent } from './pages/users/users-add/users-add.component';
import { UsersEditComponent } from './pages/users/users-edit/users-edit.component';
import { DistributorListComponent } from './pages/distributor/distributor-list/distributor-list.component';
import { DistributorAddComponent } from './pages/distributor/distributor-add/distributor-add.component';
import { DistributorEditComponent } from './pages/distributor/distributor-edit/distributor-edit.component';
import { ItemGroupAddComponent } from './pages/item-groups/item-group-add/item-group-add.component';
import { ItemGroupEditComponent } from './pages/item-groups/item-group-edit/item-group-edit.component';
import { ItemGroupListComponent } from './pages/item-groups/item-group-list/item-group-list.component';
import { SubcategoryListComponent } from './pages/subcategory/subcategory-list/subcategory-list.component';
import { SubcategoryAddComponent } from './pages/subcategory/subcategory-add/subcategory-add.component';
import { SubcategoryEditComponent } from './pages/subcategory/subcategory-edit/subcategory-edit.component';
import { ItemListComponent } from './pages/items/item-list/item-list.component';
import { ItemAddComponent } from './pages/items/item-add/item-add.component';
import { OnlynumberDirectiveDirective } from './onlynumber-directive.directive';
import { RemoveSpaceDirectiveDirective } from './remove-space-directive.directive';
import { MetalModalComponent } from './pages/items/metal-modal/metal-modal.component';
import { EditProfileComponent } from './pages/account/edit-profile/edit-profile.component';
import { AccessoriesListComponent } from './pages/accessories/accessories-list/accessories-list.component';
import { StorageService } from './services/storage.service';
import { AccessoriesAddComponent } from './pages/accessories/accessories-add/accessories-add.component';
import { AccessoriesEditComponent } from './pages/accessories/accessories-edit/accessories-edit.component';
import { ItemViewComponent } from './pages/items/item-view/item-view.component';
import { ItemDetailComponent } from './pages/items/item-detail/item-detail.component';
import { PurityListComponent } from './pages/purity-master/purity-list/purity-list.component';
import { PurityAddComponent } from './pages/purity-master/purity-add/purity-add.component';
import { PurityEditComponent } from './pages/purity-master/purity-edit/purity-edit.component';
import { AccessoryModalComponent } from './pages/items/accessory-modal/accessory-modal.component';
import { DistributorViewComponent } from './pages/distributor/distributor-view/distributor-view.component';
import { ItemDistributorListComponent } from './pages/items/item-distributor-list/item-distributor-list.component';
import { RolePermissionComponent } from './pages/role-permission/role-permission.component';
import { NotificationListComponent } from './pages/notification/notification-list/notification-list.component';
import { SalesOrdersAddComponent } from './pages/sales-orders/sales-orders-add/sales-orders-add.component';
import { SalesOrdersListComponent } from './pages/sales-orders/sales-orders-list/sales-orders-list.component';
import { SalesOrdersEditComponent } from './pages/sales-orders/sales-orders-edit/sales-orders-edit.component';
import { MySalesOrdersListComponent } from './pages/sales-orders/my-sales-orders-list/my-sales-orders-list.component';
import { OrderDetailListComponent } from './pages/sales-orders/order-detail-list/order-detail-list.component';
import { ItemEditComponent } from './pages/items/item-edit/item-edit.component';
import { CustomizeItemsListComponent } from './pages/customize-items/customize-items-list/customize-items-list.component';
import { CustomizeOrderAddComponent } from './pages/customize-items/customize-order-add/customize-order-add.component';
import { CustomizeDetailComponent } from './pages/customize-items/customize-detail/customize-detail.component';
import { ItemDistributorViewComponent } from './pages/items/item-distributor-view/item-distributor-view.component';
import { ShowcaseComponent } from './pages/showcase/showcase.component';
import { ShowCaseLayoutComponent } from './base/layouts/show-case-layout/show-case-layout.component';
import { ShowDetailLayoutComponent } from './base/layouts/show-detail-layout/show-detail-layout.component';
import { ShowItemlistLayoutComponent } from './base/layouts/show-itemlist-layout/show-itemlist-layout.component';
import { AssignItemModalComponent } from './pages/distributor/assign-item-modal/assign-item-modal.component';
import { AssignItemModalEditComponent } from './pages/distributor/assign-item-modal-edit/assign-item-modal-edit.component';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { AuthorizationComponent } from './base/layouts/authorization/authorization.component';
import { NgxWatermarkModule } from 'ngx-watermark';
import { ScriptService } from './shared/_helpers/script-loader/script.service';
import { NgxImgZoomModule } from 'ngx-img-zoom';
import { NgxMasonryModule } from 'ngx-masonry';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { BankDetailComponent } from './pages/bank-detail/bank-detail.component';
import { BankDetailViewComponent } from './pages/bank-detail/bank-detail-view/bank-detail-view.component';
import { SubscriptionListComponent } from './pages/subscription/subscription-list/subscription-list.component';
import { SubscriptionAddComponent } from './pages/subscription/subscription-add/subscription-add.component';
import { SubscriptionEditComponent } from './pages/subscription/subscription-edit/subscription-edit.component';
import { RetailerListComponent } from './pages/retailers/retailer-list/retailer-list.component';
import { RetailerAddComponent } from './pages/retailers/retailer-add/retailer-add.component';
import { RetailerEditComponent } from './pages/retailers/retailer-edit/retailer-edit.component';
import { RetailerViewComponent } from './pages/retailers/retailer-view/retailer-view.component';
import { AssignRetailerItemModalComponent } from './pages/retailers/assignretailer-item-modal/assignretailer-item-modal.component';
import { AssignRetailerItemModalEditComponent } from './pages/retailers/assignretailer-item-modal-edit/assignretailer-item-modal-edit.component';
import { SubscriptionViewComponent } from './pages/subscription/subscription-view/subscription-view.component';
import { AssignSubscriptionComponent } from './pages/subscription/assign-subscription/assign-subscription.component';
import { ItemRetailerListComponent } from './pages/retailers/item-retailer-list/item-retailer-list.component';
import { ItemRetailerViewComponent } from './pages/retailers/item-retailer-view/item-retailer-view.component';
import { RetailerPasswordComponent } from './pages/retailers/retailer-password/retailer-password.component';
import { MatCheckboxModule } from '@angular/material';
import { AssignSubscriptionListComponent } from './pages/subscription/assign-subscription-list/assign-subscription-list.component';
import { AssignSubscriptionEditComponent } from './pages/subscription/assign-subscription-edit/assign-subscription-edit.component';
import { AssignSubscriptionViewComponent } from './pages/subscription/assign-subscription-view/assign-subscription-view.component';
import { InqueriesComponent } from './pages/inqueries/inqueries.component';
import { PartnersComponent } from './partners/partners.component';
import { PartnerviewComponent } from './pages/partnerview/partnerview.component';
import { InquiryviewComponent } from './pages/inquiryview/inquiryview.component';
import { AssingItemSubComponent } from './pages/subscription/assing-item-sub/assing-item-sub.component';
import { AssingItemEditSubComponent } from './pages/subscription/assing-item-edit-sub/assing-item-edit-sub.component';
import { SalesOrdersViewComponent } from './pages/sales-orders/sales-orders-view/sales-orders-view.component';
import { CustomizeItemsViewComponent } from './pages/customize-items/customize-items-view/customize-items-view.component';
import { MySalesOrderGridViewComponent } from './pages/sales-orders/my-sales-order-grid-view/my-sales-order-grid-view.component';
import { AddToCartListComponent } from './pages/add-to-cart-list/add-to-cart-list.component';
import { EditCartItemComponent } from './pages/add-to-cart-list/edit-cart-item/edit-cart-item.component';
import { CustomerListComponent } from './pages/customer/customer-list/customer-list.component';
import { CustomerViewComponent } from './pages/customer/customer-view/customer-view.component';
import { RetailerRequestListComponent } from './pages/retailer-request/retailer-request-list/retailer-request-list.component';
import { RetailerRequestViewComponent } from './pages/retailer-request/retailer-request-view/retailer-request-view.component';
import { CustomerInquiryListComponent } from './pages/customer-inquiry-list/customer-inquiry-list.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    AppLayoutComponent,
    LoginLayoutComponent,
    LoginComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    RoleAddComponent,
    RoleListComponent,
    RoleEditComponent,
    ChangePasswordComponent,
    ResetPasswordViewComponent,
    SiteConfigComponent,
    WarehouseListComponent,
    WarehouseAddComponent,
    CompaniesListComponent,
    CompaniesEditComponent,
    CompaniesAddComponent,
    WarehouseEditComponent,
    AttributeListComponent,
    AttributeAddComponent,
    AttributeEditComponent,
    ProductionhouseListComponent,
    CategoryAddComponent,
    CategoryListComponent,
    CategoryEditComponent,
    ProductionhouseAddComponent,
    ProductionhouseEditComponent,
    MetalListComponent,
    MetalAddComponent,
    MetalEditComponent,
    TermcategoryAddComponent,
    TermcategoryListComponent,
    TermcategoryEditComponent,
    TermsAddComponent,
    TermsEditComponent,
    TermsListComponent,
    DistributorgroupAddComponent,
    DistributorgroupListComponent,
    DistributorgroupEditComponent,
    UsersListComponent,
    UsersAddComponent,
    UsersEditComponent,
    DistributorListComponent,
    DistributorAddComponent,
    DistributorEditComponent,
    ItemGroupAddComponent,
    ItemGroupEditComponent,
    ItemGroupListComponent,
    SubcategoryListComponent,
    SubcategoryAddComponent,
    SubcategoryEditComponent,
    ItemListComponent,
    ItemAddComponent,
    OnlynumberDirectiveDirective,
    RemoveSpaceDirectiveDirective,
    MetalModalComponent,
    EditProfileComponent,
    AccessoriesListComponent,
    AccessoriesAddComponent,
    AccessoriesEditComponent,
    ItemViewComponent,
    ItemDetailComponent,
    PurityEditComponent,
    PurityListComponent,
    PurityAddComponent,
    AccessoryModalComponent,
    DistributorViewComponent,
    ItemDistributorListComponent,
    RolePermissionComponent,
    NotificationListComponent,
    SalesOrdersAddComponent,
    SalesOrdersListComponent,
    SalesOrdersEditComponent,
    OrderDetailListComponent,
    MySalesOrdersListComponent,
    ItemEditComponent,
    CustomizeItemsListComponent,
    CustomizeOrderAddComponent,
    CustomizeDetailComponent,
    ItemDistributorViewComponent,
    ShowcaseComponent,
    ShowCaseLayoutComponent,

    ShowDetailLayoutComponent,

    ShowItemlistLayoutComponent,

    AssignItemModalComponent,

    AssignItemModalEditComponent,

    AuthorizationComponent,

    BankDetailComponent,

    BankDetailViewComponent,

    SubscriptionListComponent,

    SubscriptionAddComponent,

    SubscriptionEditComponent,

    RetailerListComponent,

    RetailerAddComponent,

    RetailerEditComponent,

    RetailerViewComponent,

    AssignRetailerItemModalComponent,

    AssignRetailerItemModalEditComponent,

    SubscriptionViewComponent,

    AssignSubscriptionComponent,

    ItemRetailerListComponent,

    ItemRetailerViewComponent,

    RetailerPasswordComponent,
    AssignSubscriptionListComponent,
    AssignSubscriptionEditComponent,
    AssignSubscriptionViewComponent,
    InqueriesComponent,
    PartnersComponent,
    PartnerviewComponent,
    InquiryviewComponent,
    AssingItemSubComponent,
    AssingItemEditSubComponent,
    SalesOrdersViewComponent,
    CustomizeItemsViewComponent,
    MySalesOrderGridViewComponent,
    AddToCartListComponent,
    EditCartItemComponent,
    CustomerListComponent,
    CustomerViewComponent,
    RetailerRequestListComponent,
    RetailerRequestViewComponent,
    CustomerInquiryListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      preventDuplicates: true,
      tapToDismiss: true,
      closeButton: true
    }),
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDialogModule,
    MatDatepickerModule,
    MatRadioModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    RxReactiveFormsModule,
    GooglePlaceModule,
    NgSelect2Module,
    LightboxModule,
    Ng2SearchPipeModule,
    NgxDaterangepickerMd.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSelectFilterModule,
    NgxMatSelectSearchModule,
    InfiniteScrollModule,
    MatSelectInfiniteScrollModule,
    JwSocialButtonsModule,
    NgxWatermarkModule,
    NgxImgZoomModule,
    NgxMasonryModule,
    PinchZoomModule









  ],
  exports: [
    MatSelectModule, MatCheckboxModule
  ],
  providers: [
    AppConfig,
    REGEXP,
    AuthService,
    HttpService,
    CommonService,
    StorageService,
    AudioRecordingService,
    ScriptService
  ],
  bootstrap: [AppComponent],
  entryComponents: [MetalModalComponent, AccessoryModalComponent, RetailerPasswordComponent]
})
export class AppModule { }

