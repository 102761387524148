import { CategoryAttributeVM } from './CategoryAttributeVM';
import { ItemVM } from './ItemVM';

export class CategoryVM {
    category_id: number
    ref_category_id?: number
    category_code: string
    category_name: string
    display_name: string
    description: string
    category_thumbnail_image?: string
    category_image?: string
    icon?: string
    update_log?: string
    is_active: number
    sequence_no: string
    distributor_id?: number
    created_at?: string
    updated_at?: string
    attributes?: CategoryAttributeVM[]
    attributeRows?: CategoryAttributeVM[]
    parent_category?: CategoryVM
    items_count?: number
    total_items?: number
    items?: ItemVM[]
    itemCount: number
    subcategory_id: number
    category_item_credit: number
}
