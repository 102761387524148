import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { PurityVM } from 'src/app/models/PurityVM';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { PurityService } from 'src/app/services/purity.service';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';


@Component({
  selector: 'app-purity-list',
  templateUrl: './purity-list.component.html',
  styleUrls: ['./purity-list.component.css']
})
export class PurityListComponent implements OnInit {

  addTermsCategoryForm: FormGroup;
  getSiteConfig: any;
  pageSize: number = 50;
  purityData: any;
  role: any;
  permissionsData = [];
  noData = false;
  marked = false;
  marked1 = false;
  marked2 = false;
  displayedColumns: string[] = ['purity', 'is_active', 'created_at', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private _purityService: PurityService,
    private _commonService: CommonService,
    private _router: Router,
    private _storageService: StorageService,
    private _roleService: RoleService,
    private _siteConfigService: SiteConfigService) {
  }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.loadPurity();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      self.marked = self.permissionsData[22].sub_permission[1].isSelected;

      self.marked1 = self.permissionsData[22].sub_permission[2].isSelected;

      self.marked2 = self.permissionsData[22].sub_permission[3].isSelected;

      if (self.permissionsData[22].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });

  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }
  loadPurity() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._purityService.getAllPurity()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.purityData = new MatTableDataSource<PurityVM>(response.data);
            self.purityData.paginator = self.paginator;
            self.purityData.sort = self.sort;
            self.purityData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.noData = (response.data.length > 0) ? false : true;
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  editPurity(category: PurityVM) {
    this._router.navigate(['/admin/purity/edit', category.id]);
  }

  deletePurity(category: PurityVM) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this purity ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    Swal.fire(options).then((result) => {
      if (result.value) {

        self._commonService.showLoader();
        self._purityService.deletePurity(category.id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Purity deleted successfully.", "success");
          self.loadPurity();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  applyFilter(filterValue: string) {
    this.purityData.filter = filterValue.trim().toLowerCase();
    this.noData = (this.purityData.filteredData.length > 0) ? false : true;
    // this.noData = (this.purityData._paginator._length > 0) ? false : true;
  }
}
