export class CompanyVM {
  company_id: number
  company_code: string
  company_name : string
  display_name: string
  logo:string
  company_reg_no: number
  phone_no: string
  contact_email: string
  registration_address: string
  billing_address: string
  website_name: string
  is_active: number
  is_default_selected:number
  created_at: string
  updated_at?: string

}
