import { ItemVM } from './ItemVM'
import { UserVM } from './UserVM'
import { CategoryVM } from './CategoryVM'
import { MetalVM } from './MetalVM'
import { DistributorVM } from './DistributorVM'
import { PurityVM } from './PurityVM'

export class CustomOrderVM {
  custom_order_id: number
  category_id: number
  item_name: string
  item_code: string
  weight: string
  metal_id: number
  purity: string
  total: string
  user_id: number
  distributor_id: number
  item_image: string
  item_note: string
  created_at?: string
  updated_at?: string
  item?: ItemVM
  category?: CategoryVM
  metal?: MetalVM
  purity_data?: PurityVM
  order_no: string
  actual_date_of_delivery:string
  expected_date_of_delivery:string
  order_status:string
  my_note:string
  customer:string
}
