import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TermsVM } from 'src/app/models/TermsVM';
import { TermsService } from 'src/app/services/terms.service';
import { CommonService } from 'src/app/services/common.service';
import { REGEXP } from 'src/app/shared/regexp';
import { Router } from '@angular/router';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { TermscategoryService } from 'src/app/services/termscategory.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Options } from 'selenium-webdriver/opera';
import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'app-terms-add',
  templateUrl: './terms-add.component.html',
  styleUrls: ['./terms-add.component.css']
})
export class TermsAddComponent implements OnInit {


  @ViewChild("term_icon", { static: false }) termIconFileInput;

  public select2Options: Options;
  public select2TermsData: Array<Select2OptionData>;

  TermsForm: FormGroup;
  TermsVM = new TermsVM();
  submitted = false;
  temrsCategory_Data = [];
  termIconImageUrl: string;


  constructor(private _formBuilder: FormBuilder,
    private _termscategoryService: TermscategoryService,
    private _termsService: TermsService,
    private _commonService: CommonService,
    private _regex: REGEXP,
    private _router: Router) { }

  ngOnInit() {
    this.getActiveTermsCategories();
    this.createTermsForm();
  }


  getActiveTermsCategories() {
    let self = this;
    self._termscategoryService.getActiveTermsCategories().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.temrsCategory_Data = response.data;
        self.select2TermsData = self._commonService.dropdownArray(self.temrsCategory_Data, "term_category_id", "category_name");
      }
    });
  }


  createTermsForm() {


    this.TermsForm = this._formBuilder.group({
      term_code: [this.TermsVM.term_code, [Validators.required, Validators.maxLength(255)]],
      term_name: [this.TermsVM.term_name, [Validators.required, Validators.maxLength(255)]],
      term_category_id: [this.TermsVM.term_category_id, [Validators.required]],
      term_details: [this.TermsVM.term_details],
      hand_code_rule: [this.TermsVM.hand_code_rule, [Validators.required, Validators.maxLength(255)]],
      ideal_value: [this.TermsVM.ideal_value, [Validators.required, Validators.maxLength(255)]],
      term_icon: [this.TermsVM.term_icon, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      sequence_no: [this.TermsVM.sequence_no, [Validators.required]],
      back_color: [this.TermsVM.back_color, [Validators.required, Validators.maxLength(255)]],
      for_color: [this.TermsVM.for_color, [Validators.required, Validators.maxLength(255)]],
      is_active: [1, Validators.required],
      is_default: [0, Validators.required]
    });

  }


  gotoCompnayList() {
    this._router.navigate(['/admin/terms']);
  }


  get f() { return this.TermsForm.controls; }

  save() {
    let self = this;
    self.submitted = true;
    if (self.TermsForm.invalid) {
      return;
    }
    // stop here if form is invalid
    let formData = new FormData();

    let siteConfigData = (self.TermsForm.value as TermsVM);
    for (const prop in siteConfigData) {

      let fi = this.termIconFileInput.nativeElement;
      if (prop == 'term_icon') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.TermsForm.value.term_icon = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }
    }


    self._commonService.showLoader();
    self._termsService.addterm(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoCompnayList();
        self._commonService.showToaster("Term added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

}
