// @Packages
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

// @Services
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { MustMatch } from 'src/app/validator/must-match.validator';
import { UserService } from 'src/app/services/user.service';
import { ChangePasswordVM } from 'src/app/models/ChangePasswordVM';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  submitted = false;
  changePasswordVM = new ChangePasswordVM;

  constructor(private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private router: Router,
    private _auth: AuthService,
    private _userService: UserService) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.changePasswordForm = this._formBuilder.group({
      old_password: [this.changePasswordVM.old_password, Validators.required],
      new_password: [this.changePasswordVM.new_password, [Validators.required, Validators.minLength(6), Validators.maxLength(14)]],
      confirm_password: [this.changePasswordVM.confirm_password, [Validators.required]]
    },
      {
        validator: MustMatch('new_password', 'confirm_password')
      });
  }

  // get all form controls
  get f() { return this.changePasswordForm.controls; }

  save() {
    let self = this;
    self.submitted = true;

    // stop here if form is invalid
    if (self.changePasswordForm.invalid) {
      return;
    }

    self._commonService.showLoader();
    self._userService.changePassword(self.changePasswordForm.value).subscribe(data => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self._commonService.showToaster('Password changed successfully.', 'success');
        self.submitted = false;
        this._auth.logout();
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, 'error');
    });
  }

}
