//@Packages
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
//@Constant
import { AppConfig } from '../shared/app-config';

//@Services
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiUrl: String;
  // set http Authorization headers
  httpAuthOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };
  // set http headers
  httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
    })
  };

  constructor(private _httpService: HttpService,
    private _api: AppConfig) {
    this.apiUrl = this._api.ApiUrl;
  }

  getAllUsers(){

    return this._httpService.get(`${this.apiUrl}users/getAllUsers`,this.httpAuthOptions)
    .pipe(
      map((response: any) => {
        return this._httpService.extractData(response);
      }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllActiveRoles(){

    return this._httpService.get(`${this.apiUrl}users/getAllActiveRoles`,this.httpAuthOptions)
    .pipe(
      map((response: any) => {
        return this._httpService.extractData(response);
      }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  addUser(role) {
    return this._httpService.post(`${this.apiUrl}users/addUser`, role, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getUserById(id) {
    return this._httpService.get(`${this.apiUrl}users/getusersById/${id}`,this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getLoginUserById() {
    return this._httpService.get(`${this.apiUrl}user`,this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getUserByEmail(formdata) {

    return this._httpService.post(`${this.apiUrl}forgot/create`, formdata, this.httpOptions)
      .pipe(
        map((response: Response) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      )
  }



  updateUser(user) {
    return this._httpService.post(`${this.apiUrl}users/updateusers`, user, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  editProfile(user) {
    return this._httpService.post(`${this.apiUrl}editProfile`, user, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  deleteUsers(id){
    return this._httpService.post(`${this.apiUrl}users/deleteusers/${id}`,'',this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  changePassword(data) {

    return this._httpService.post(`${this.apiUrl}user/changePassword`,data,this.httpAuthOptions)
    .pipe(
      map((response: any) => {
        return this._httpService.extractData(response);
      }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  resetPassword(formdata) {
    return this._httpService.post(`${this.apiUrl}forgot/reset`, formdata, this.httpOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }











  // this.http.post('https://localhost:5001/api/upload', formData, {reportProgress: true, observe: 'events'})
  //     .subscribe(event => {
  //       if (event.type === HttpEventType.UploadProgress)
  //         this.progress = Math.round(100 * event.loaded / event.total);
  //       else if (event.type === HttpEventType.Response) {
  //         this.message = 'Upload success.';
  //         this.onUploadFinished.emit(event.body);
  //       }
  //     });
}
