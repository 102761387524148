import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { NotificationVM } from 'src/app/models/NotificationVM';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['message', 'read_at', 'created_at'];
  notificationdata: any;
  noData = false;
  submitted = false;
  pageSize: number = 50;
  roleType: number;
  constructor(private _notificationService: NotificationService,
    private _commonService: CommonService,
    private _router: Router,
    private _siteConfig: SiteConfigService,
    private _storageService: StorageService
  ) { }

  ngOnInit() {
    this.roleType = this._storageService.getUserRolesType();
    this.getNotification();
    this.notificationReadAll();
  }
  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  getNotification() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._notificationService.getNotification()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.notificationdata = new MatTableDataSource<NotificationVM>(response.all_notifications);
            self.notificationdata.paginator = self.paginator;
            self.notificationdata.sort = self.sort;
            self.notificationdata.sortingDataAccessor = (data, sortHeaderId) => {
              switch (sortHeaderId) {
                case 'message': return data.data.message;
                default: return data[sortHeaderId];
              }
            }
            self.notificationdata.filterPredicate = (data: any, filter) => {
              const dataStr = JSON.stringify(data).toLowerCase();
              const strippedString = dataStr.replace(/(<([^>]+)>)/gi, "");
              return strippedString.indexOf(filter) != -1;
            }
            if (response.data != null) {
              self.noData = (response.data.length > 0) ? false : true;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  applyFilter(filterValue: string) {
    this.notificationdata.filter = filterValue.trim().toLowerCase();
    this.noData = (this.notificationdata._paginator._length > 0) ? false : true;
  }

  notificationReadAll() {
    let self = this;
    self.submitted = true;
    let formData = new FormData();
    formData.append('readAll', '1');
    self._notificationService.notificationRead(formData).subscribe(data => {
      if (!self._commonService.isNullOrEmpty(data)) {
        self._notificationService.updateNotification(true);
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  itemDetail(item) {
    if (item.item_id != undefined && item.item_id != "") {
      this._router.navigate(['/admin/items/detail/', item.item_id], { queryParams: { id: item.item_id } });
    }
    else if (item.custom_order_id != undefined) {
      this._router.navigate(['/admin/customize-order-detail', item.custom_order_id], { queryParams: { id: item.custom_order_id } });

    }
    else if (item.retailer_id != undefined && this.roleType == 1) {
      this._router.navigate(['/admin/distributor/retailerdetail', item.retailer_id], { queryParams: { id: item.retailer_id } });

    }
    else if (item.retailer_id != undefined && this.roleType == 2) {
      this._router.navigate(['/admin/retailer/view', item.retailer_id], { queryParams: { id: item.retailer_id } });

    } else {
      this._router.navigate(['/admin/sales-order-details', item.sales_order_id], { queryParams: { id: item.sales_order_id } });
    }
  }
}
