import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SubscriptionVM } from 'src/app/models/SubscriptionVM';
import { CommonService } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubscriptionService } from 'src/app/services/subscriprion.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { UserSubscriptionVM } from 'src/app/models/SubscriptionVM';


@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.css']
})
export class SubscriptionListComponent implements OnInit {

  role: any;
  subscriptionData: any;
  noData = false;
  value: '';
  displayedColumns: string[] = ['SubscriptionCode', 'SubscriptionName', 'ValidDays', 'TotalCredit', 'count', 'IsActive', 'created_at', 'actions'];
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  UserSubscriptionVM = new UserSubscriptionVM();
  SubscriptionVM = new SubscriptionVM();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columnDirection: any;
  columnSorting: any;
  roleType: any;

  constructor(
    private _storageService: StorageService,
    private _siteConfig: SiteConfigService,
    private _commonService: CommonService,
    private _subscriptionService: SubscriptionService,
    private _router: Router,

  ) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();

    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.getAllSubsciption(event);
        this.getUserSubscriptionById();
      }
    });
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.getAllSubsciption(event);
      }
    });
  }

  getUserSubscriptionById() {

    let self = this;
    self._commonService.showLoader();
    self._subscriptionService.getDistSubscriptionByLoginId()
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.UserSubscriptionVM = response.data;

      });
  }

  getAllSubsciption(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        //this.getSiteConfigData(),
      ]).then((data: any) => {
        self._subscriptionService.getAllSubscription(event, this.value, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.subscriptionData = new MatTableDataSource<SubscriptionVM>(response.data);
            if (response.data.length > 0) {
              self.totalLength = response.data[0].totalcount;
            }
            self.noData = (response.data.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  applyFilter(filterValue: any) {

    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllSubsciption(event);
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;

    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllSubsciption(event);
  }


  editSubscription(subscription: SubscriptionVM) {
    this._router.navigate(['/admin/subscription/edit', subscription.SubscriptionId]);
  }

  deleteSubscription(subscription: SubscriptionVM) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    Swal.fire(options).then((result) => {
      if (result.value) {

        self._commonService.showLoader();
        self._subscriptionService.deleteSubscription(subscription.SubscriptionId).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Subscription deleted successfully.", "success");
          var event: any = {
            "previousPageIndex": '0',
            "pageIndex": '0',
            "pageSize": this.pageSize,
            "length": '0',
          }
          self.getAllSubsciption(event);
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  viewSubscription(subscription: SubscriptionVM) {
    this._router.navigate(['/admin/subscription/view', subscription.SubscriptionId]);
  }

  assignSubscription(subscription: SubscriptionVM) {
    this._router.navigate(['/admin/assign-subscription', subscription.SubscriptionId]);
  }
}
