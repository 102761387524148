import { Component, OnInit, Self, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubscriptionService } from 'src/app/services/subscriprion.service';
import { ImageSubscriptioVM, SubscriptionVM, UserSubscriptionVM } from 'src/app/models/SubscriptionVM';
import { RetailerService } from 'src/app/services/retailer.service';
import { DistributorVM } from 'src/app/models/DistributorVM';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { Options } from 'select2';
import { MatCheckboxChange, MatOption } from '@angular/material';
import { combineAll } from 'rxjs/operators';

@Component({
  selector: 'app-assign-subscription',
  templateUrl: './assign-subscription.component.html',
  styleUrls: ['./assign-subscription.component.css']
})
export class AssignSubscriptionComponent implements OnInit {
  selectedRoleType: string = '';

  role: number;
  id: number;
  public select2Options: Options;
  public assignTypeData: Array<Select2OptionData>;
  addSubscriptionForm: FormGroup;
  UserSubscriptionVM = new UserSubscriptionVM();
  submitted = false;
  activeDistributor: any = [];
  activeRetailer: any = [];
  activeDistributorRetailer: any = [];
  checkDistributor: any = [];
  isActiveDistributor = false;
  isActiveRetailer = false;
  isActiveDistributorRetailer = false;
  isActiveInput = false;
  Follow_list = [];
  AttributeArr: any = [];
  SubscriptionVM = new SubscriptionVM();
  public select2OptionsDistributor: Options;
  text = 'Select All';
  public select2DistributorData: Array<Select2OptionData>;
  values = [];
  disArr = [];
  retArr = [];
  typeDistributor = false;
  typeRetailer = false;
  userSubscription = false;
  userSubscriptionRows: UserSubscriptionVM[] = new Array();
  ImageSubscriptioVM = new ImageSubscriptioVM();
  public totalfiles: Array<File> = [];
  public totalFileName = [];
  public lengthCheckToaddMore = 0;
  public tempImage: ImageSubscriptioVM[] = [];
  url: any;
  OrderNo = 1;
  @ViewChild('allSelected', { static: false }) private allSelected: MatOption;
  base64Image: any;

  constructor(
    private _storageService: StorageService,
    private _commonService: CommonService,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _subscriptionService: SubscriptionService,
    private _retailerService: RetailerService,
    private _router: Router,

  ) { }

  ngOnInit() {
    this.role = this._commonService.convertToNumber(this._storageService.getUserRoles());
    this._route.params.subscribe(params => { this.id = params['id']; });
    this.assignSubscriptionForm();
    this.getSubscriptionById();
    this.select2OptionsDistributor = {
      multiple: true
    };
    this.assignTypeData = [
      {
        id: '1',
        text: 'Distributor'
      },
      {
        id: '2',
        text: 'Retailer',
      },
      {
        id: '3',
        text: 'Distributor Retailer',
      }
    ];
  }

  assignSubscriptionForm() {
    this.addSubscriptionForm = this._formBuilder.group({
      AssociationType_Term: ['', Validators.required],
      AssignName: ['', Validators.required],
      userSubscriptionRows: this._formBuilder.array([]),
    });
  }


  addItem() {
    let self = this;
    (<FormArray>self.addSubscriptionForm.controls.userSubscriptionRows).push(
      this._formBuilder.group({
        SubscriptionId: [self.SubscriptionVM.SubscriptionId],
        AmountPaid: ['', [Validators.maxLength(360), RxwebValidators.numeric({ allowDecimal: true })]],
        Credit: [''],
        TransactionRefNo: ['', [Validators.maxLength(360)]],
        ReferenceImage: ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
        AssociationId: [''],
        OrderNo: [''],
        SubscriptionImageFile: []
      })
    );

    // return this._formBuilder.group({
    //   UserSubscriptionId: '',
    //   AmountPaid: '',
    //   Credit: '',
    //   TransactionRefNo: '',
    //   ReferenceImage: '',
    //   AssociationId: ''
    // });
  }

  // convenience getter for easy access to form fields
  get f() { return this.addSubscriptionForm.controls; }

  get userSubscriptionForms() {
    return this.addSubscriptionForm.get('userSubscriptionRows') as FormArray;
  }

  getSubscriptionById() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._subscriptionService.getSubscriptionById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.SubscriptionVM = response.data.subscription;
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }

  onChangeGetItem(selectRole) {
    let self = this;
    self.selectedRoleType = selectRole.data[0].id;
    this.addSubscriptionForm.controls.AssignName.setValue([]);
    if (selectRole.value == 1) {
      self._subscriptionService.getAllActiveDistributor().subscribe(data => {
        let response: any = data;
        if (response.distributor_data != null) {
          self.activeDistributor = response.distributor_data;
          this.isActiveDistributor = true;
          this.isActiveRetailer = false;
          this.isActiveDistributorRetailer = false;
          const arr = <FormArray>this.addSubscriptionForm.controls.userSubscriptionRows;
          arr.controls = [];
        }
        else {
          this.isActiveDistributor = true;
          this.isActiveRetailer = false;
          this.isActiveDistributorRetailer = false;
          self.activeDistributor = [];
          self._commonService.showToaster(response.message, "error");
        }
      });
    }
    else if (selectRole.value == 2) {
      self._subscriptionService.getActiveRetailer().subscribe(data => {
        let response: any = data;
        if (response.data != null) {
          self.activeRetailer = response.data;
          this.isActiveRetailer = true;
          this.isActiveDistributor = false;
          this.isActiveDistributorRetailer = false;
          const arr = <FormArray>this.addSubscriptionForm.controls.userSubscriptionRows;
          arr.controls = [];
        }
        else {
          this.isActiveRetailer = true;
          this.isActiveDistributor = false;
          this.isActiveDistributorRetailer = false;
          self.activeRetailer = [];
          self._commonService.showToaster(response.message, "error");
        }
      });
    }
    else {
      self._subscriptionService.getActiveDistributorRetailer().subscribe(data => {
        let response: any = data;
        if (response.data != null) {
          self.activeDistributorRetailer = response.data;
          this.isActiveDistributor = false;
          this.isActiveRetailer = false;
          this.isActiveDistributorRetailer = true;
        }
        else {
          this.isActiveDistributor = false;
          this.isActiveRetailer = false;
          this.isActiveDistributorRetailer = true;
          self.activeDistributorRetailer = [];
          self._commonService.showToaster(response.message, "error");
        }
      });
    }
  }

  save() {
    let self = this;
    self.submitted = true;
    if (self.addSubscriptionForm.invalid) {
      return;
    }
    const finalArray = self.addSubscriptionForm.value.userSubscriptionRows
    let selectedItems = finalArray.filter(c => c.selected == true);

    if (!self._commonService.isEmptyArray(selectedItems)) {
      for (let item of selectedItems) {

        let itemImageIndex = -1;
        if (!self._commonService.isEmptyArray(self.tempImage)) {
          itemImageIndex = self.tempImage.findIndex(c => c.OrderNo == item.OrderNo);
          if (itemImageIndex != -1) {
            item.SubscriptionImageFile = File;
            item.SubscriptionImageFile = self.tempImage[itemImageIndex].SubscriptionImage;
          }
        }
      }
    }
    if (selectedItems != null && selectedItems != undefined && selectedItems.length > 0) {
      // let body = {

      //   AssociationType_Term: self.addSubscriptionForm.controls.AssociationType_Term.value,
      //   AssignName: self.addSubscriptionForm.controls.AssignName.value,
      //   userSubscriptionRows: selectedItems,
      // }

      let AllFilesObj = [];
      selectedItems.forEach((element, item) => {
        var eachObj =
        {
          'SubscriptionId': this.id,
          'AmountPaid': element.AmountPaid,
          'AssociationId': element.AssociationId,
          'Credit': element.Credit,
          'DisplayName': element.DisplayName,
          'OrderNo': element.OrderNo,
          //'ReferenceImage': element.ReferenceImage,
          'SubscriptionImageFile': element.SubscriptionImageFile,
          'TransactionRefNo': element.TransactionRefNo,
          'roleType': element.roleType,
          'selected': element.selected,
        }
        AllFilesObj.push(eachObj);
      });


      let formData = new FormData();
      formData.append("userSubscriptionRows", JSON.stringify(AllFilesObj));
      // formData = self._commonService.objectToFormData(body);
      self._commonService.showLoader();
      self._subscriptionService.assignSubscription(formData).subscribe(data => {
        self._commonService.hideLoader();

        if (!self._commonService.isNullOrEmpty(data)) {
          this._router.navigate(['/admin/userassign-subscription-list']);
          self._commonService.showToaster("Assign Subscription successfully.", "success");
        }
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
    }
    else {
      self._commonService.showToaster("Please Select Checkbox and enter value for assign Subscription.", "error");
    }
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }


  toggleVisibility(Id, event, index) {
    const checked = event.target.checked;
    let self = this;
    if (checked) {
      let array = [];
    } else {
      //this.isActiveInput = false;
    }
  }


  public fileSelectionEvent(fileInput: any, oldIndex?: number, orderNo?: number) {
    let self = this;
    this.url = null;
    let fileObj = new ImageSubscriptioVM();
    if (fileInput.target.files && fileInput.target.files[0]) {
      var reader = new FileReader();
      //this.url = fileInput.target.files[0];
      reader.onload = (fileInput: any) => {
        this.url = fileInput.target.result;
        fileObj.OrderNo = orderNo;
        fileObj.SubscriptionImage = this.url;
        self.tempImage.push(fileObj);
      }
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  assignSubPerOne(event, objItem, type) {
    let self = this;
    if (type == '1') {
      if (event.source._selected == true) {
        let selectedItem = this.activeDistributor.find(c => c.distributor_id == objItem.distributor_id);
        const ImageArr = self.f.userSubscriptionRows as FormArray;
        ImageArr.push(this._formBuilder.group({
          SubscriptionId: [self.SubscriptionVM.SubscriptionId],
          AmountPaid: [''],
          AssociationId: [selectedItem.distributor_id],
          DisplayName: [selectedItem.display_name],
          Credit: [self.SubscriptionVM.TotalCredit],
          selected: [false],
          TransactionRefNo: [''],
          roleType: [type],
          ReferenceImage: ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
          OrderNo: [self.OrderNo++],
          SubscriptionImageFile: [self.ImageSubscriptioVM.SubscriptionImage],
        }));
        //self.userSubscriptionRows.push(selectedItem);
      }
      else {
        let fromArrayOfUserSubscription = self.f.userSubscriptionRows as FormArray;
        let removeItemFromArrayIndex = -1;
        let fromControls = fromArrayOfUserSubscription.controls;
        fromControls.forEach((ele: FormGroup, ind) => {
          if (ele.controls.AssociationId.value == objItem.distributor_id) {
            removeItemFromArrayIndex = ind;
          }
        });
        if (removeItemFromArrayIndex != -1) {
          fromArrayOfUserSubscription.removeAt(removeItemFromArrayIndex);
        }

        // let selectedItemIndex = this.disArr.findIndex(c => c.distributor_id == objItem.distributor_id);

        // if (selectedItemIndex != undefined && selectedItemIndex != -1) {
        //   //self.disArr.splice(selectedItemIndex, 1);

        // }
      }
      // if (this.allSelected.selected) {
      //   this.allSelected.deselect();
      //   return false;
      // }
      if (this.addSubscriptionForm.controls.AssignName.value.length == this.activeDistributor.length) {
        this.allSelected.select();
      }
    }
    else if (type == '2') {
      if (event.source._selected == true) {
        let selectedItem = this.activeRetailer.find(c => c.RetailerId == objItem.RetailerId);
        const ImageArr = self.f.userSubscriptionRows as FormArray;
        ImageArr.push(this._formBuilder.group({
          SubscriptionId: [self.SubscriptionVM.SubscriptionId],
          AmountPaid: [''],
          AssociationId: [selectedItem.RetailerId],
          DisplayName: [selectedItem.RetailerName],
          Credit: [self.SubscriptionVM.TotalCredit],
          selected: [false],
          TransactionRefNo: [''],
          roleType: [type],
          ReferenceImage: ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
          OrderNo: [self.OrderNo++],
          SubscriptionImageFile: [self.ImageSubscriptioVM.SubscriptionImage],
        }));
        //self.retArr.push(selectedItem);
      }
      else {
        let fromArrayOfUserSubscription = self.f.userSubscriptionRows as FormArray;
        let removeItemFromArrayIndex = -1;
        let fromControls = fromArrayOfUserSubscription.controls;
        fromControls.forEach((ele: FormGroup, ind) => {
          if (ele.controls.AssociationId.value == objItem.RetailerId) {
            removeItemFromArrayIndex = ind;
          }
        });
        if (removeItemFromArrayIndex != -1) {
          fromArrayOfUserSubscription.removeAt(removeItemFromArrayIndex);
        }

        // let selectedItemIndex = this.retArr.findIndex(c => c.RetailerId == objItem.RetailerId);
        // if (selectedItemIndex != undefined && selectedItemIndex != -1) {
        //   self.typeRetailer = true;
        //   self.retArr.splice(selectedItemIndex, 1);
        // }
      }
      // if (this.allSelected.selected) {
      //   this.allSelected.deselect();
      //   return false;
      // }
      if (this.addSubscriptionForm.controls.AssignName.value.length == this.activeRetailer.length) {
        this.allSelected.select();
      }
    }
    else {
      if (event.source._selected == true) {
        let selectedItem = this.activeDistributorRetailer.find(c => c.RetailerId == objItem.RetailerId);
        const ImageArr = self.f.userSubscriptionRows as FormArray;
        ImageArr.push(this._formBuilder.group({
          SubscriptionId: [self.SubscriptionVM.SubscriptionId],
          AmountPaid: [''],
          AssociationId: [selectedItem.RetailerId],
          DisplayName: [selectedItem.RetailerName],
          Credit: [self.SubscriptionVM.TotalCredit],
          selected: [false],
          TransactionRefNo: [''],
          roleType: [type],
          ReferenceImage: ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
          OrderNo: [self.OrderNo++],
          SubscriptionImageFile: [self.ImageSubscriptioVM.SubscriptionImage],
        }));
        //self.retArr.push(selectedItem);
      }
      else {
        let fromArrayOfUserSubscription = self.f.userSubscriptionRows as FormArray;
        let removeItemFromArrayIndex = -1;
        let fromControls = fromArrayOfUserSubscription.controls;
        fromControls.forEach((ele: FormGroup, ind) => {
          if (ele.controls.AssociationId.value == objItem.RetailerId) {
            removeItemFromArrayIndex = ind;
          }
        });
        if (removeItemFromArrayIndex != -1) {
          fromArrayOfUserSubscription.removeAt(removeItemFromArrayIndex);
        }
      }
      // if (this.allSelected.selected) {
      //   this.allSelected.deselect();
      //   return false;
      // }
      if (this.addSubscriptionForm.controls.AssignName.value.length == this.activeDistributorRetailer.length) {
        this.allSelected.select();
      }
    }

  }

  toggleAllSelection(event, type) {
    let self = this;
    if (type == '1') {
      if (this.allSelected.selected) {
        this.addSubscriptionForm.controls.AssignName.setValue([...this.activeDistributor.map(item => item.distributor_id), 0]);
      } else {
        this.addSubscriptionForm.controls.AssignName.setValue([]);
      }
    }
    else if (type == '2') {
      if (this.allSelected.selected) {
        this.addSubscriptionForm.controls.AssignName.setValue([...this.activeRetailer.map(item => item.RetailerId), 0]);
      } else {
        this.addSubscriptionForm.controls.AssignName.setValue([]);
      }
    }
    else {
      if (this.allSelected.selected) {
        this.addSubscriptionForm.controls.AssignName.setValue([...this.activeDistributorRetailer.map(item => item.RetailerId), 0]);
      } else {
        this.addSubscriptionForm.controls.AssignName.setValue([]);
      }
    }
  }



  deleteItemImageRow(index: number) {
    let ImageArr = this.f.userSubscriptionRows as FormArray;
    ImageArr.removeAt(index);
  }

}
