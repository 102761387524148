// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2'

// @Services
import { WarehouseService } from 'src/app/services/warehouse.service';
import { CommonService } from 'src/app/services/common.service';


// @Models
import { WarehouseVM } from 'src/app/models/WarehouseVM';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-warehouse-list',
  templateUrl: './warehouse-list.component.html',
  styleUrls: ['./warehouse-list.component.css']
})
export class WarehouseListComponent implements OnInit {

  getSiteConfig: any;
  pageSize: number = 50;
  warehousesData: any;
  role: any;
  permissionsData = [];
  noData = false;
  marked = false;
  marked1 = false;
  marked2 = false;
  displayedColumns: string[] = ['code', 'ware_house_name', 'contact_user_id', 'phone_no', 'email', 'address', 'items_count', 'is_active', 'created_at', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private warehouseService: WarehouseService,
    private _commonService: CommonService,
    private _router: Router,
    private _storageService: StorageService,
    private _roleService: RoleService,
    private _siteConfig: SiteConfigService) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.loadWarehouses();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      self.marked = self.permissionsData[10].sub_permission[1].isSelected;

      self.marked1 = self.permissionsData[10].sub_permission[2].isSelected;

      self.marked2 = self.permissionsData[10].sub_permission[3].isSelected;

      if (self.permissionsData[10].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });

  }

  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadWarehouses() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self.warehouseService.getAllWarehouses()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.warehousesData = new MatTableDataSource<WarehouseVM>(response.data);
            self.warehousesData.filterPredicate = (data: any, filter) => {
              const dataStr = JSON.stringify(data).toLowerCase();
              const strippedString = dataStr.replace(/(<([^>]+)>)/gi, "");
              return strippedString.indexOf(filter) != -1;
            }
            self.warehousesData.paginator = self.paginator;
            self.warehousesData.sort = self.sort;
            self.warehousesData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.noData = (response.data.length > 0) ? false : true;
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }
  editWarehouse(warehouse: WarehouseVM) {
    this._router.navigate(['/admin/warehouses/edit', warehouse.warehouse_id]);
  }

  deleteWarehouse(warehouse: WarehouseVM) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this warehouse and associated items?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };


    swal.fire(options).then((result) => {
      if (result.value) {

        self._commonService.showLoader();
        self.warehouseService.deletewarehouse(warehouse.warehouse_id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Warehouse deleted successfully.", "success");
          self.loadWarehouses();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }


  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }
  applyFilter(filterValue: string) {
    this.warehousesData.filter = filterValue.trim().toLowerCase();
    this.noData = (this.warehousesData.filteredData.length > 0) ? false : true;
    // this.noData = (this.warehousesData._paginator._length > 0) ? false : true;
  }

}
