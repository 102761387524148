import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2'

// @Services
import { RoleService } from 'src/app/services/role.service';
import { CommonService } from 'src/app/services/common.service';
import { CompanyService } from 'src/app/services/company.service';

// @Models
import { SiteConfigService } from 'src/app/services/site-config.service';
import { CompanyVM } from 'src/app/models/CompanyVM';


@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.css']
})
export class CompaniesListComponent implements OnInit {

  constructor(private _companyService: CompanyService,
    private _commonService: CommonService,
    private _router: Router,
    private _siteConfigService: SiteConfigService) {

  }

  getSiteConfig: any;
  pageSize: number = 50;
  companyData: any;
  noData = false;
  displayedColumns: string[] = ['company_name', 'company_code', 'display_name', 'phone_no', 'is_active', 'contact_email', 'created_at', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.loadcompanies();
  }
  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }
  loadcompanies() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._companyService.getAllCompanyData()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.companyData = new MatTableDataSource<CompanyVM>(response.data);
            self.companyData.paginator = self.paginator;
            self.companyData.sort = self.sort;
            self.companyData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.noData = (response.data.length > 0) ? false : true;
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  editCompany(company: CompanyVM) {
    this._router.navigate(['/admin/company/edit', company.company_id]);
  }

  deleteCompany(company: CompanyVM) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this company?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    swal.fire(options).then((result) => {
      if (result.value) {

        self._commonService.showLoader();
        self._companyService.deleteCompany(company.company_id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Role deleted successfully.", "success");
          self.loadcompanies();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }
  applyFilter(filterValue: string) {
    this.companyData.filter = filterValue.trim().toLowerCase();
    this.noData = (this.companyData.filteredData.length > 0) ? false : true;
    // this.noData = (this.companyData._paginator._length > 0) ? false : true;
  }
}
