import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TermscategoryVM } from 'src/app/models/TermscategoryVM';
import { TermscategoryService } from 'src/app/services/termscategory.service';
import { CommonService } from 'src/app/services/common.service';
import { REGEXP } from 'src/app/shared/regexp';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-termcategory-add',
  templateUrl: './termcategory-add.component.html',
  styleUrls: ['./termcategory-add.component.css']
})
export class TermcategoryAddComponent implements OnInit {

  TermsCategoryForm: FormGroup;
  TermscategoryVM = new TermscategoryVM();
  submitted = false;


  constructor(private _formBuilder: FormBuilder,
    private _termscategoryService: TermscategoryService,
    private _commonService: CommonService,
    private _regex: REGEXP,
    private _router: Router) { }

  ngOnInit() {
    this.createTermsCategoryForm();
  }

  get f() { return this.TermsCategoryForm.controls; }

  createTermsCategoryForm() {
    this.TermsCategoryForm = this._formBuilder.group({
      category_name: [this.TermscategoryVM.category_name, [Validators.required,Validators.maxLength(255)]],
      category_details: [this.TermscategoryVM.category_details],
      is_active: [1,Validators.required],
    });
  }

  gotoTermsCategoryList() {
    this._router.navigate(['/admin/terms-categories']);
  }

  save() {
    let self = this;
    self.submitted = true;

    if (self.TermsCategoryForm.invalid) {
      return;
    }
    self._commonService.showLoader();
    self._termscategoryService.addTermsCategory(self.TermsCategoryForm.value).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoTermsCategoryList();
        self._commonService.showToaster("Terms category has added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }
}
