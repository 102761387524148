import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BankDetailVM } from 'src/app/models/BankDetailVM';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BankDetailService } from 'src/app/services/bank-detail.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-bank-detail-view',
  templateUrl: './bank-detail-view.component.html',
  styleUrls: ['./bank-detail-view.component.css']
})
export class BankDetailViewComponent implements OnInit {

  permissionsData = [];
  BankDetailVM = new BankDetailVM();
  loginUserId: any;

  constructor(
    private _commonService: CommonService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _bankDetailService: BankDetailService,
    private _storageService: StorageService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
  ) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.getUserActive();
    this.getPaymentDetailById();
    this.getPermission();
  }


  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[23].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }


  getPaymentDetailById() {
    let self = this;
    self._commonService.showLoader();
    self._bankDetailService.getBankDetailsData()
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.BankDetailVM = response.data[0];
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }
}
