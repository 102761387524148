//@Packages
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// @Services
import { AuthGuard } from './services/auth/auth.guard';

// @Pages
import { AppLayoutComponent } from './base/layouts/app-layout/app-layout.component';
import { LoginLayoutComponent } from './base/layouts/login-layout/login-layout.component';
import { LoginComponent } from './pages/account/login/login.component';
import { ForgotPasswordComponent } from './pages/account/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { RoleListComponent } from './pages/role/role-list/role-list.component';
import { RoleAddComponent } from './pages/role/role-add/role-add.component';
import { RoleEditComponent } from './pages/role/role-edit/role-edit.component';
import { ResetPasswordViewComponent } from './pages/account/reset-password-view/reset-password-view.component';
import { ChangePasswordComponent } from './pages/account/change-password/change-password.component';
import { SiteConfigComponent } from './pages/site-config/site-config.component';
import { WarehouseListComponent } from './pages/warehouse/warehouse-list/warehouse-list.component';
import { WarehouseAddComponent } from './pages/warehouse/warehouse-add/warehouse-add.component';
import { CompaniesListComponent } from './pages/company/companies-list/companies-list.component';
import { CompaniesEditComponent } from './pages/company/companies-edit/companies-edit.component';
import { WarehouseEditComponent } from './pages/warehouse/warehouse-edit/warehouse-edit.component';
import { AttributeListComponent } from './pages/attribute/attribute-list/attribute-list.component';
import { AttributeAddComponent } from './pages/attribute/attribute-add/attribute-add.component';
import { AttributeEditComponent } from './pages/attribute/attribute-edit/attribute-edit.component';
import { ProductionhouseListComponent } from './pages/productionhouse/productionhouse-list/productionhouse-list.component';
import { CategoryListComponent } from './pages/category/category-list/category-list.component';
import { CategoryEditComponent } from './pages/category/category-edit/category-edit.component';
import { CategoryAddComponent } from './pages/category/category-add/category-add.component';
import { ProductionhouseAddComponent } from './pages/productionhouse/productionhouse-add/productionhouse-add.component';
import { ProductionhouseEditComponent } from './pages/productionhouse/productionhouse-edit/productionhouse-edit.component';
import { MetalListComponent } from './pages/metal/metal-list/metal-list.component';
import { MetalAddComponent } from './pages/metal/metal-add/metal-add.component';
import { TermcategoryListComponent } from './pages/term_category/termcategory-list/termcategory-list.component';
import { TermcategoryAddComponent } from './pages/term_category/termcategory-add/termcategory-add.component';
import { TermcategoryEditComponent } from './pages/term_category/termcategory-edit/termcategory-edit.component';
import { TermsListComponent } from './pages/terms/terms-list/terms-list.component';
import { TermsAddComponent } from './pages/terms/terms-add/terms-add.component';
import { TermsEditComponent } from './pages/terms/terms-edit/terms-edit.component';
import { MetalEditComponent } from './pages/metal/metal-edit/metal-edit.component';
import { DistributorgroupListComponent } from './pages/distributor-group/distributorgroup-list/distributorgroup-list.component';
import { DistributorgroupAddComponent } from './pages/distributor-group/distributorgroup-add/distributorgroup-add.component';
import { DistributorgroupEditComponent } from './pages/distributor-group/distributorgroup-edit/distributorgroup-edit.component';
import { UsersListComponent } from './pages/users/users-list/users-list.component';
import { UsersAddComponent } from './pages/users/users-add/users-add.component';
import { UsersEditComponent } from './pages/users/users-edit/users-edit.component';
import { DistributorListComponent } from './pages/distributor/distributor-list/distributor-list.component';
import { DistributorAddComponent } from './pages/distributor/distributor-add/distributor-add.component';
import { DistributorEditComponent } from './pages/distributor/distributor-edit/distributor-edit.component';
import { ItemGroupListComponent } from './pages/item-groups/item-group-list/item-group-list.component';
import { ItemGroupAddComponent } from './pages/item-groups/item-group-add/item-group-add.component';
import { ItemGroupEditComponent } from './pages/item-groups/item-group-edit/item-group-edit.component';
import { SubcategoryListComponent } from './pages/subcategory/subcategory-list/subcategory-list.component';
import { SubcategoryAddComponent } from './pages/subcategory/subcategory-add/subcategory-add.component';
import { SubcategoryEditComponent } from './pages/subcategory/subcategory-edit/subcategory-edit.component';
import { ItemListComponent } from './pages/items/item-list/item-list.component';
import { ItemAddComponent } from './pages/items/item-add/item-add.component';
import { EditProfileComponent } from './pages/account/edit-profile/edit-profile.component';
import { AccessoriesListComponent } from './pages/accessories/accessories-list/accessories-list.component';
import { AccessoriesAddComponent } from './pages/accessories/accessories-add/accessories-add.component';
import { AccessoriesEditComponent } from './pages/accessories/accessories-edit/accessories-edit.component';
import { ItemViewComponent } from './pages/items/item-view/item-view.component';
import { ItemDetailComponent } from './pages/items/item-detail/item-detail.component';
import { PurityListComponent } from './pages/purity-master/purity-list/purity-list.component';
import { PurityAddComponent } from './pages/purity-master/purity-add/purity-add.component';
import { PurityEditComponent } from './pages/purity-master/purity-edit/purity-edit.component';
import { DistributorViewComponent } from './pages/distributor/distributor-view/distributor-view.component';
import { ItemDistributorListComponent } from './pages/items/item-distributor-list/item-distributor-list.component';
import { RolePermissionComponent } from './pages/role-permission/role-permission.component';
import { NotificationListComponent } from './pages/notification/notification-list/notification-list.component';
import { SalesOrdersListComponent } from './pages/sales-orders/sales-orders-list/sales-orders-list.component';
import { SalesOrdersEditComponent } from './pages/sales-orders/sales-orders-edit/sales-orders-edit.component';
import { SalesOrdersAddComponent } from './pages/sales-orders/sales-orders-add/sales-orders-add.component';
import { OrderDetailListComponent } from './pages/sales-orders/order-detail-list/order-detail-list.component';
import { MySalesOrdersListComponent } from './pages/sales-orders/my-sales-orders-list/my-sales-orders-list.component';
import { ItemEditComponent } from './pages/items/item-edit/item-edit.component';
import { CustomizeItemsListComponent } from './pages/customize-items/customize-items-list/customize-items-list.component';
import { CustomizeOrderAddComponent } from './pages/customize-items/customize-order-add/customize-order-add.component';
import { CustomizeDetailComponent } from './pages/customize-items/customize-detail/customize-detail.component';
import { ItemDistributorViewComponent } from './pages/items/item-distributor-view/item-distributor-view.component';
import { ShowCaseLayoutComponent } from './base/layouts/show-case-layout/show-case-layout.component';
import { ShowcaseComponent } from './pages/showcase/showcase.component';
import { ShowDetailLayoutComponent } from './base/layouts/show-detail-layout/show-detail-layout.component';
import { ShowItemlistLayoutComponent } from './base/layouts/show-itemlist-layout/show-itemlist-layout.component';
import { AssignItemModalComponent } from './pages/distributor/assign-item-modal/assign-item-modal.component';
import { AssignItemModalEditComponent } from './pages/distributor/assign-item-modal-edit/assign-item-modal-edit.component';
import { AuthorizationComponent } from './base/layouts/authorization/authorization.component';
import { BankDetailComponent } from './pages/bank-detail/bank-detail.component';
import { BankDetailViewComponent } from './pages/bank-detail/bank-detail-view/bank-detail-view.component';
import { SubscriptionListComponent } from './pages/subscription/subscription-list/subscription-list.component';
import { SubscriptionAddComponent } from './pages/subscription/subscription-add/subscription-add.component';
import { SubscriptionEditComponent } from './pages/subscription/subscription-edit/subscription-edit.component';
import { RetailerListComponent } from './pages/retailers/retailer-list/retailer-list.component';
import { RetailerAddComponent } from './pages/retailers/retailer-add/retailer-add.component';
import { AssignRetailerItemModalComponent } from './pages/retailers/assignretailer-item-modal/assignretailer-item-modal.component';
import { AssignRetailerItemModalEditComponent } from './pages/retailers/assignretailer-item-modal-edit/assignretailer-item-modal-edit.component';
import { SubscriptionViewComponent } from './pages/subscription/subscription-view/subscription-view.component';
import { AssignSubscriptionComponent } from './pages/subscription/assign-subscription/assign-subscription.component';
import { RetailerEditComponent } from './pages/retailers/retailer-edit/retailer-edit.component';
import { RetailerViewComponent } from './pages/retailers/retailer-view/retailer-view.component';
import { ItemRetailerListComponent } from './pages/retailers/item-retailer-list/item-retailer-list.component';
import { ItemRetailerViewComponent } from './pages/retailers/item-retailer-view/item-retailer-view.component';
import { AssignSubscriptionListComponent } from './pages/subscription/assign-subscription-list/assign-subscription-list.component';
import { AssignSubscriptionEditComponent } from './pages/subscription/assign-subscription-edit/assign-subscription-edit.component';
import { AssignSubscriptionViewComponent } from './pages/subscription/assign-subscription-view/assign-subscription-view.component';
import { InqueriesComponent } from './pages/inqueries/inqueries.component';
import { PartnersComponent } from './partners/partners.component';
import { InquiryviewComponent } from './pages/inquiryview/inquiryview.component';
import { PartnerviewComponent } from './pages/partnerview/partnerview.component';
import { AssingItemSubComponent } from './pages/subscription/assing-item-sub/assing-item-sub.component';
import { AssingItemEditSubComponent } from './pages/subscription/assing-item-edit-sub/assing-item-edit-sub.component';
import { SalesOrdersViewComponent } from './pages/sales-orders/sales-orders-view/sales-orders-view.component';
import { CustomizeItemsViewComponent } from './pages/customize-items/customize-items-view/customize-items-view.component';
import { MySalesOrderGridViewComponent } from './pages/sales-orders/my-sales-order-grid-view/my-sales-order-grid-view.component';
import { AddToCartListComponent } from './pages/add-to-cart-list/add-to-cart-list.component';
import { EditCartItemComponent } from './pages/add-to-cart-list/edit-cart-item/edit-cart-item.component';
import { CustomerListComponent } from './pages/customer/customer-list/customer-list.component';
import { CustomerViewComponent } from './pages/customer/customer-view/customer-view.component';
import { RetailerRequestListComponent } from './pages/retailer-request/retailer-request-list/retailer-request-list.component';
import { RetailerRequestViewComponent } from './pages/retailer-request/retailer-request-view/retailer-request-view.component';
import { CustomerInquiryListComponent } from './pages/customer-inquiry-list/customer-inquiry-list.component';




const routes: Routes = [
  {
    path: 'admin',
    component: AppLayoutComponent,
    //canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'Dashboard' }
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: { title: 'Change Password' }
      },
      {
        path: 'edit-profile',
        component: EditProfileComponent,
        data: { title: 'Edit Profile' }
      },
      {
        path: 'users',
        component: UsersListComponent,
        data: { title: 'Users' }
      },
      {
        path: 'users/add',
        component: UsersAddComponent,
        data: { title: 'Add New User' }
      },
      {
        path: 'users/edit/:id',
        component: UsersEditComponent,
        data: { title: 'Edit User' }
      },
      {
        path: 'roles',
        component: RoleListComponent,
        data: { title: 'Roles' }
      },
      {
        path: 'roles/add',
        component: RoleAddComponent,
        data: { title: 'Add New Role' }
      },
      {
        path: 'roles/edit/:id',
        component: RoleEditComponent,
        data: { title: 'Edit Role' }
      },
      {
        path: 'attributes',
        component: AttributeListComponent,
        data: { title: 'Attributes' }
      },
      {
        path: 'attributes/add',
        component: AttributeAddComponent,
        data: { title: 'Add New Attribute' }
      },
      {
        path: 'attributes/edit/:id',
        component: AttributeEditComponent,
        data: { title: 'Edit Attribute' }
      },
      {
        path: 'categories',
        component: CategoryListComponent,
        data: { title: 'Categories' }
      },
      {
        path: 'categories/add',
        component: CategoryAddComponent,
        data: { title: 'Add New Category' }
      },
      {
        path: 'categories/edit/:id',
        component: CategoryEditComponent,
        data: { title: 'Edit Category' }
      },
      {
        path: 'terms-categories',
        component: TermcategoryListComponent,
        data: { title: 'Term Categories' }
      },
      {
        path: 'terms-categories/add',
        component: TermcategoryAddComponent,
        data: { title: 'Add Term Category' }
      },
      {
        path: 'terms-categories/edit/:id',
        component: TermcategoryEditComponent,
        data: { title: 'Edit Term Category' }
      },
      {
        path: 'terms',
        component: TermsListComponent,
        data: { title: 'Terms' }
      },
      {
        path: 'terms/add',
        component: TermsAddComponent,
        data: { title: 'Add Term' }
      },
      {
        path: 'terms/edit/:id',
        component: TermsEditComponent,
        data: { title: 'Edit Term' }
      },
      {
        path: 'site-configuration',
        component: SiteConfigComponent,
        data: { title: 'Site Configuration' }
      },
      {
        path: 'bank-details',
        component: BankDetailComponent,
        data: { title: 'Bank Details' }
      },
      {
        path: 'payment-details/view',
        component: BankDetailViewComponent,
        data: { title: 'Payment Details View' }
      },
      {
        path: 'warehouses',
        component: WarehouseListComponent,
        data: { title: 'Warehouses' }
      },
      {
        path: 'warehouses/add',
        component: WarehouseAddComponent,
        data: { title: 'Add New Warehouse' }
      },
      {
        path: 'warehouses/edit/:id',
        component: WarehouseEditComponent,
        data: { title: 'Edit Warehouse' }
      },
      {
        path: 'company',
        component: CompaniesListComponent,
        data: { title: 'Companies' }
      },
      {
        path: 'company/edit/:id',
        component: CompaniesEditComponent,
        data: { title: 'Edit Company' }
      },
      {
        path: 'production-houses',
        component: ProductionhouseListComponent,
        data: { title: 'Production Houses' }
      },
      {
        path: 'production-houses/add',
        component: ProductionhouseAddComponent,
        data: { title: 'Add New Production House' }
      },
      {
        path: 'production-houses/edit/:id',
        component: ProductionhouseEditComponent,
        data: { title: 'Edit Production House' }
      },
      {
        path: 'metal',
        component: MetalListComponent,
        data: { title: 'Metals' }
      },
      {
        path: 'metal/add',
        component: MetalAddComponent,
        data: { title: 'Add New Metal' }
      },
      {
        path: 'metal/edit/:id',
        component: MetalEditComponent,
        data: { title: 'Edit Metal' }
      },
      {
        path: 'distributor-groups',
        component: DistributorgroupListComponent,
        data: { title: 'Distributor Group' }
      },
      {
        path: 'distributor-groups/add',
        component: DistributorgroupAddComponent,
        data: { title: 'Add New DistributorGroup' }
      },
      {
        path: 'distributor-groups/edit/:id',
        component: DistributorgroupEditComponent,
        data: { title: 'Edit DistributorGroup' }
      },
      {
        path: 'distributor',
        component: DistributorListComponent,
        data: { title: 'Distributor' }
      },
      {
        path: 'distributor/add',
        component: DistributorAddComponent,
        data: { title: 'Add New Distributor' }
      },
      {
        path: 'distributor/edit/:id',
        component: DistributorEditComponent,
        data: { title: 'Edit Distributor' }
      },
      {
        path: 'distributor/view/:id',
        component: DistributorViewComponent,
        data: { title: 'View Distributor' }
      },
      {
        path: 'item-groups',
        component: ItemGroupListComponent,
        data: { title: 'Item Groups' }
      },
      {
        path: 'item-groups/add',
        component: ItemGroupAddComponent,
        data: { title: 'Add New Item Group' }
      },
      {
        path: 'item-groups/edit/:id',
        component: ItemGroupEditComponent,
        data: { title: 'Edit Item Group' }
      },
      {
        path: 'sub-categories',
        component: SubcategoryListComponent,
        data: { title: 'Sub Categories' }
      },
      {
        path: 'sub-categories/add',
        component: SubcategoryAddComponent,
        data: { title: 'Add New Sub Category' }
      },
      {
        path: 'sub-categories/edit/:id',
        component: SubcategoryEditComponent,
        data: { title: 'Edit Sub Category' }
      },
      {
        path: 'items',
        component: ItemListComponent,
        data: { title: 'Items' }
      },
      {
        path: 'distributor-items',
        component: ItemDistributorListComponent,
        data: { title: 'Items' }
      },
      {
        path: 'items/add',
        component: ItemAddComponent,
        data: { title: 'Add New Item' }
      },
      {
        path: 'items/grid-view',
        component: ItemViewComponent,
        data: { title: 'View Item' }
      },
      {
        path: 'distributor/itemdetail/:id',
        component: ItemDistributorViewComponent,
        data: { title: 'Distributor Item Detail' }
      },
      {
        path: 'items/detail/:id',
        component: ItemDetailComponent,
        data: { title: 'Item Detail' }
      },
      {
        path: 'items/edit/:id',
        component: ItemEditComponent,
        data: { title: 'Edit Item' }
      },
      {
        path: 'accessories',
        component: AccessoriesListComponent,
        data: { title: 'Accessories' }
      },
      {
        path: 'accessories/add',
        component: AccessoriesAddComponent,
        data: { title: 'Add New Accessory' }
      },
      {
        path: 'accessories/edit/:id',
        component: AccessoriesEditComponent,
        data: { title: 'Edit Accessory' }
      },
      {
        path: 'purity',
        component: PurityListComponent,
        data: { title: 'Purity' }
      },
      {
        path: 'purity/add',
        component: PurityAddComponent,
        data: { title: 'Add New Purity' }
      },
      {
        path: 'purity/edit/:id',
        component: PurityEditComponent,
        data: { title: 'Edit purity' }
      },
      {
        path: 'role-permission/edit/:id',
        component: RolePermissionComponent,
        data: { title: 'Edit Role Permission' }

      },
      {
        path: 'notifications',
        component: NotificationListComponent,
        data: { title: 'Notifications' }

      },
      {
        path: 'sales-orders/new/:item_id',
        component: SalesOrdersAddComponent,
        data: { title: 'Add Sales Order' }
      },
      {
        path: 'sales-orders/edit/:id',
        component: SalesOrdersEditComponent,
        data: { title: 'Edit Sales Order' }
      },
      {
        path: 'sales-orders',
        component: SalesOrdersListComponent,
        data: { title: 'Sales Orders' }
      },
      {
        path: 'sales-orders/grid-view',
        component: SalesOrdersViewComponent,
        data: { title: 'View Item' }
      },
      {
        path: 'sales-orders/:item',
        component: SalesOrdersListComponent,
        data: { title: 'Sales Orders' }
      },
      {
        path: 'sales-order-details/:id',
        component: OrderDetailListComponent,
        data: { title: 'Sales Order Details' }
      },
      {
        path: 'my-sales-orders',
        component: MySalesOrdersListComponent,
        data: { title: 'My Sales Orders' }
      },
      // {
      //   path: 'my-sales-orders/:item',
      //   component: MySalesOrdersListComponent,
      //   data: { title: 'My Sales Orders' }
      // },
      {
        path: 'my-sales-orders/grid-view-item',
        component: MySalesOrderGridViewComponent,
        data: { title: 'My Sales Orders View' }
      },
      {
        path: 'customize-items',
        component: CustomizeItemsListComponent,
        data: { title: 'Customize Item' }
      },
      {
        path: 'customize-items/grid-view',
        component: CustomizeItemsViewComponent,
        data: { title: 'View Item' }
      },
      {
        path: 'customize-order/add',
        component: CustomizeOrderAddComponent,
        data: { title: 'Add Customize Order ' }
      },

      {
        path: 'customize-order-detail/:id',
        component: CustomizeDetailComponent,
        data: { title: 'Customize Order Detail' }
      },

      {
        path: 'assign-item-modal',
        component: AssignItemModalComponent,
        data: { title: 'Assign Item' }
      },
      {
        path: 'assign-item-modal-edit/:id',
        component: AssignItemModalEditComponent,
        data: { title: 'Assign Item Edit' }
      },
      {
        path: 'assign-item-subscription-modal',
        component: AssingItemSubComponent,
        data: { title: 'Assign Item' }
      },
      {
        path: 'assign-item-subscription-modal-edit/:id',
        component: AssingItemEditSubComponent,
        data: { title: 'Assign Item Edit' }
      },
      {
        path: 'subscription',
        component: SubscriptionListComponent,
        data: { title: 'Subscription' }
      },
      {
        path: 'inquiries',
        component: InqueriesComponent,
        data: { title: 'Inquiries' }
      },
      {
        path: 'inquiries/views/:id',
        component: InquiryviewComponent,
        data: { title: 'View Inquiries' }
      },
      {
        path: 'partners',
        component: PartnersComponent,
        data: { title: 'partners' }
      },
      {
        path: 'partners/views/:id',
        component: PartnerviewComponent,
        data: { title: 'View Partners' }
      },
      {
        path: 'subscription/add',
        component: SubscriptionAddComponent,
        data: { title: 'Add New Subscription' }
      },
      {
        path: 'subscription/edit/:id',
        component: SubscriptionEditComponent,
        data: { title: 'Edit Subscription' }
      },
      {
        path: 'subscription/view/:id',
        component: SubscriptionViewComponent,
        data: { title: 'View Subscription' }
      },
      {
        path: 'retailer',
        component: RetailerListComponent,
        data: { title: 'Retailer' }
      },
      {
        path: 'retailer/add',
        component: RetailerAddComponent,
        data: { title: 'Add New Retailer' }
      },
      {
        path: 'retailer/edit/:id',
        component: RetailerEditComponent,
        data: { title: 'Edit Retailer' }
      },
      {
        path: 'retailer/view/:id',
        component: RetailerViewComponent,
        data: { title: 'View Retailer' }
      },
      {
        path: 'retailer-assign-item-modal',
        component: AssignRetailerItemModalComponent,
        data: { title: 'Retailer Assign Item' }
      },
      {
        path: 'retailer-assign-item-modal-edit/:id',
        component: AssignRetailerItemModalEditComponent,
        data: { title: 'Retailer Assign Item Edit' }
      },
      {
        path: 'assign-subscription/:id',
        component: AssignSubscriptionComponent,
        data: { title: 'Assign Subscription' }
      },
      {
        path: 'distributor-retailer',
        component: ItemRetailerListComponent,
        data: { title: 'Distributor Retailer' }
      },
      {
        path: 'distributor/retailerdetail/:id',
        component: ItemRetailerViewComponent,
        data: { title: 'Distributor Retailer Detail' }
      },
      {
        path: 'userassign-subscription-list',
        component: AssignSubscriptionListComponent,
        data: { title: 'User Assign Subscription' }
      },
      {
        path: 'userassign-subscription-edit/:id',
        component: AssignSubscriptionEditComponent,
        data: { title: 'Edit User Assign Subscription' }
      },
      {
        path: 'userassign-subscription-view/:id',
        component: AssignSubscriptionViewComponent,
        data: { title: 'View User Assign Subscription' }
      },
      {
        path: 'add-to-cart-list',
        component: AddToCartListComponent,
        data: { title: 'Add To Cart' }

      },
      {
        path: 'edit-cart-item/:id',
        component: EditCartItemComponent,
        data: { title: 'Edit To Cart' }

      },
      {
        path: 'customer-list',
        component: CustomerListComponent,
        data: { title: 'Customer' }
      },
      {
        path: 'customer-view/:id',
        component: CustomerViewComponent,
        data: { title: 'Customer' }
      },
      {
        path: 'customer/retailerRequest-list',
        component: RetailerRequestListComponent,
        data: { title: 'Retailer Request' }
      },
      {
        path: 'customer/retailerRequest-view/:id',
        component: RetailerRequestViewComponent,
        data: { title: 'View Retailer Request' }
      },
      {
        path: 'customer-inquiry-list',
        component: CustomerInquiryListComponent,
        data: { title: 'Customer Inquiry' }
      },
    ]
  },

  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Login' }
      },
      //Forgot Password
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { title: 'Forgot Password' }
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordViewComponent,
        data: { title: 'Reset Password' }
      },
    ]
  },
  {
    path: 'mainshowcase',
    component: ShowCaseLayoutComponent,
    children: [
      {
        path: 'showcase',
        component: ShowcaseComponent,
        data: { title: 'Showcase' }
      },
      {
        path: 'showdetail/:id',
        component: ShowDetailLayoutComponent,
        data: { title: 'Showdetail' }
      },
      {
        path: 'showitemlist/:id',
        component: ShowItemlistLayoutComponent,
        data: { title: 'ShowItemList' }
      },
      {
        path: 'authorization',
        component: AuthorizationComponent,
        data: { title: 'Authorization' }
      },

    ]
  },


  // otherwise redirect to home

];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    onSameUrlNavigation: "reload"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
