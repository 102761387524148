import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ItemVM } from 'src/app/models/ItemVM';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ItemService } from 'src/app/services/item.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { SiteConfigService } from 'src/app/services/site-config.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { StorageService } from 'src/app/services/storage.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Moment } from 'moment';
import { RoleService } from 'src/app/services/role.service';


@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.css']
})


export class ItemListComponent implements OnInit {

  itemsData: any;
  noData = false;
  displayedColumns: string[];
  distributor: any;
  role: number;
  roleType: number;
  loginUserId: any;
  datasource: null;
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  filterValue: '';
  value: '';
  dateselect?: any;
  itemCode: ItemVM[];
  item_code?: any;
  selectCode?: any;
  selectName?: any;
  selectGroup?: any;
  availableItem?: any;
  searchText?: any = "";
  show = false;
  buttonName = 'Show';
  hide: any;
  permissionsData = [];
  marked = false;
  marked1 = false;
  marked2 = false;
  columnSorting: any;
  columnDirection: any
  public limit: number;
  public offset: number;
  public limitValue: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  onScroll() {
    this.limitValue = this.offset * this.limit;
    this.offset++;
    this.getItemCode(this.limitValue, this.limit);
  }

  constructor(private _itemService: ItemService,
    private _commonService: CommonService,
    private _router: Router,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _siteConfigService: SiteConfigService,
    private _roleService: RoleService,
    private _storageService: StorageService) {
  }

  ngOnInit() {
    this.offset = 1;
    this.limit = 10;
    this.limitValue = 0;
    // this.loginUserId=this._storageService.getUserId();
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();

    if (this.role != 1) {
      this.getPermission();
    }
    this.distributor = this._commonService.convertToNumber(this._storageService.getDistributorId());
    this.getItemCode(this.limitValue, this.limit);
    if (this.roleType == 1) {
      this.displayedColumns = ['item_name', 'code', 'group_name', 'ware_house_name', 'is_active', 'status', 'created_at', 'actions'];
    } else {
      this.displayedColumns = ['item_name', 'code', 'group_name', 'is_active', 'status', 'is_approve', 'created_at', 'actions'];
    }

    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.loadItems(event);
      }
    });
  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      //check permission
      self.marked = self.permissionsData[15].sub_permission[1].isSelected;
      self.marked1 = self.permissionsData[15].sub_permission[2].isSelected;
      self.marked2 = self.permissionsData[15].sub_permission[3].isSelected;
      if (self.permissionsData[15].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getItemCode(limitValue, limit) {
    let self = this;
    self._itemService.getItemCode(this.item_code, limitValue, limit).subscribe(data => {
      let response: any = data;
      if (self.itemCode != undefined && self.itemCode.length > 0) {
        response.data.forEach(element => {
          self.itemCode.push(element);
        });
      } else {
        self.itemCode = response.data;
      }
    });
  }

  // getSiteConfigData() {
  //   let self = this;
  //   self._siteConfigService.getSiteConfigData().subscribe(data => {
  //     let response: any = data;
  //     if (response.data != null) {
  //       self.pageSize = response.data.default_row_perpage_term;
  //     }
  //   });
  // }

  loadItems(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        // this.getSiteConfigData(),
      ]).then((data: any) => {
        self._itemService.getAllItems(event, this.value, this.dateselect, this.selectCode, this.selectName, this.selectGroup, this.availableItem, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.itemsData = new MatTableDataSource<ItemVM>(response.data);
            if (response.data.length > 0) {
              self.totalLength = response.data[0].totalcount;
            }
            self.noData = (response.data.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }


  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  editItem(itemGroup: ItemVM) {
    this._router.navigate(['/admin/items/edit', itemGroup.item_id]);
  }

  viewItem(item: ItemVM) {
    this._router.navigate(['/admin/items/detail/', item.item_id], { queryParams: { id: item.item_id } });
  }

  deleteItem(item: ItemVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._itemService.deleteItem(item.item_id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Item deleted successfully.", "success");
          var event: any = {
            "previousPageIndex": '0',
            "pageIndex": '0',
            "pageSize": this.pageSize,
            "length": '0',
          }
          self.loadItems(event);
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  // toggle() {
  //   this.show = !this.show
  //   if (this.show) {
  //     this.buttonName = 'Hide'
  //   }
  //   else {
  //     this.buttonName = 'Show'
  //   }
  // }

  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadItems(event);
  }

  // choosedDate(date) {
  //   this.dateselect = date;
  //   var event: any = {
  //     "previousPageIndex": '0',
  //     "pageIndex": '0',
  //     "pageSize": this.pageSize,
  //     "length": '0',
  //   }
  //   this.loadItems(event);
  // }

  // sarchCode(code) {
  //   this.item_code = code;
  //   this.getItemCode(this.limitValue, this.limit);
  // }

  // selectChangeHandler(selectCode: any) {
  //   this.selectCode = selectCode;
  //   var event: any = {
  //     "previousPageIndex": '0',
  //     "pageIndex": '0',
  //     "pageSize": this.pageSize,
  //     "length": '0',
  //   }
  //   this.loadItems(event);
  // }

  // selectChangeHandler1(selectName: any) {
  //   this.selectName = selectName;
  //   var event: any = {
  //     "previousPageIndex": '0',
  //     "pageIndex": '0',
  //     "pageSize": this.pageSize,
  //     "length": '0',
  //   }
  //   this.loadItems(event);
  // }

  // selectChangeHandler2(selectGroup: any) {
  //   this.selectGroup = selectGroup;
  //   var event: any = {
  //     "previousPageIndex": '0',
  //     "pageIndex": '0',
  //     "pageSize": this.pageSize,
  //     "length": '0',
  //   }
  //   this.loadItems(event);
  // }

  // assignOrder(event) {
  //   if (event.isUserInput) {
  //     this.selectCode = '';
  //     this.selectName = '';
  //     this.selectGroup = '';
  //     this.availableItem = '';
  //     var event: any = {
  //       "previousPageIndex": '0',
  //       "pageIndex": '0',
  //       "pageSize": this.pageSize,
  //       "length": '0',
  //     }
  //     this.loadItems(event);
  //   }
  // }

  // selectAvailable(availableItem) {
  //   this.availableItem = availableItem;
  //   var event: any = {
  //     "previousPageIndex": '0',
  //     "pageIndex": '0',
  //     "pageSize": this.pageSize,
  //     "length": '0',
  //   }
  //   this.loadItems(event);
  // }

  // getFullUrl(item_image) {
  //   return this._commonService.getFullImageUrl(item_image);
  // }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadItems(event);
  }

}
