import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ItemGroupVM } from 'src/app/models/ItemGroupVM';
import { CommonService } from 'src/app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ItemGroupService } from 'src/app/services/item-group.service';
import { REGEXP } from 'src/app/shared/regexp';
import { RxwebValidators, NumericValueType } from '@rxweb/reactive-form-validators';
import { Route } from '@angular/compiler/src/core';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-item-group-edit',
  templateUrl: './item-group-edit.component.html',
  styleUrls: ['./item-group-edit.component.css']
})

export class ItemGroupEditComponent implements OnInit {

  editItemGroupForm: FormGroup;
  submitted = false;
  itemGroupVM = new ItemGroupVM();
  id: number;
  role: any;
  loginUserId: any;
  permissionsData = [];

  constructor(private _commonService: CommonService,
    private _router: Router,
    private _itemGroupService: ItemGroupService,
    private _formBuilder: FormBuilder,
    private _storageService: StorageService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _roleService: RoleService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.getUserActive();
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.buildForm();
    this.initializeFormValue();
  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      //check permission
      if (self.permissionsData[14].sub_permission[2].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  initializeFormValue() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._itemGroupService.getItemGroupById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.itemGroupVM = response.item_group;
        this.buildForm();
      });
  }

  buildForm() {
    this.editItemGroupForm = this._formBuilder.group({
      item_group_id: [this.itemGroupVM.item_group_id, [Validators.required]],
      group_name: [this.itemGroupVM.group_name, [Validators.required, Validators.maxLength(255)]],
      group_code: [this.itemGroupVM.group_code, [Validators.required, Validators.maxLength(255)]],
      item_type_term: [this.itemGroupVM.item_type_term, [Validators.maxLength(255)]],
      is_active: [this.itemGroupVM.is_active, Validators.required]
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.editItemGroupForm.controls; }

  gotoItemGroupList() {
    this._router.navigate(['/admin/item-groups']);
  }

  save() {
    let self = this;
    self.submitted = true;
    // stop here if form is invalid
    if (self.editItemGroupForm.invalid) {
      return;
    }
    self._commonService.showLoader();
    self._itemGroupService.updateItemGroup(self.editItemGroupForm.value).subscribe(data => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoItemGroupList();
        self._commonService.showToaster("Item group updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }
}
