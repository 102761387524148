export class RetailerRequestVM {
    AssociationId: number;
    AssociationTypeTerm: string;
    City: string;
    ContactEmail: string;
    ContactMobNo: string;
    ContactName: string;
    Country: string;
    CreatedAt: string;
    CustRetailerReqId: any;
    District: string;
    GstNo: string;
    IsActive: number;
    PostalCode: string;
    RequestStatus: string;
    RetailerAddress: string;
    RetailerName: string;
    State: string;
    UpdatedAt: string;
}