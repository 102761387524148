
export class ProductionhouseVM {
  production_house_id: number
  production_house_code: string
  production_house_name: string
  production_house_details: string
  address: string
  latitude?: string
  longitude?: string
  primary_image?: string
  is_active: string
  update_log?: string
  production_manage_id: number
  created_at?: string
  updated_at?: string
}
