import { FormControl, FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function requiredFileType(types: string) {
    return function (control: FormControl) {
        const file = control.value;
        if (file) {
            //const extension = file.split('.')[1].toLowerCase();
            const extension = file.split('.').pop();
            if (!types.toLowerCase().includes(extension.toLowerCase())) {
                return {
                    requiredFileType: true
                };
            } else {
                return null;
            }
        }
        return null;
    };

    // return (formGroup: FormGroup) => {
    //     const control = formGroup.controls[controlName];

    //     if (control.errors && !control.errors.requiredFileType) {
    //         // return if another validator has already found an error on the matchingControl
    //         return;
    //     }

    //     const file = control.value;
    //     if (file) {
    //         //const extension = file.split('.')[1].toLowerCase();
    //         const extension = file.split('.').pop();
    //         // set error on control if validation fails
    //         if (!types.toLowerCase().includes(extension.toLowerCase())) {
    //             control.setErrors({ requiredFileType: true });
    //         } else {
    //             control.setErrors(null);
    //         }
    //     }
    // }
}
