import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RetailerRequestVM } from 'src/app/models/RetailerRequestVM';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { CommonService } from 'src/app/services/common.service';
import { DistributorService } from 'src/app/services/distributor.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubscriptionService } from 'src/app/services/subscriprion.service';

@Component({
  selector: 'app-retailer-request-view',
  templateUrl: './retailer-request-view.component.html',
  styleUrls: ['./retailer-request-view.component.css']
})
export class RetailerRequestViewComponent implements OnInit {

  retailerRequestVM: RetailerRequestVM = new RetailerRequestVM();
  loginUserId: any;
  id: number;

  constructor(private _commonService: CommonService,
    private _router: Router,
    private _route: ActivatedRoute,
    private distributorService: DistributorService,
    private _storageService: StorageService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.getUserActive();
    this.getSubscriptionById();
  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getSubscriptionById() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self.distributorService.getCustomerRetailerRequestById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.retailerRequestVM = response.data[0];
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }
}
