import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PurityVM } from 'src/app/models/PurityVM';
import { PurityService } from 'src/app/services/purity.service';
import { CommonService } from 'src/app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-purity-edit',
  templateUrl: './purity-edit.component.html',
  styleUrls: ['./purity-edit.component.css']
})
export class PurityEditComponent implements OnInit {

  id: number;
  editPurityForm: FormGroup;
  PurityVM = new PurityVM();
  submitted = false;
  role: number;
  permissionsData = [];
  constructor(private _formBuilder: FormBuilder,
    private _purityService: PurityService,
    private _commonService: CommonService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _roleService: RoleService,
    private _storageService: StorageService) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.initializeFormValue();
    this.buildForm();

  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[22].sub_permission[2].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }

    });

  }

  initializeFormValue() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;

    self._commonService.showLoader();
    self._purityService.getPurityById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.PurityVM = response.purity;
        this.buildForm();
      });
  }

  buildForm() {
    this.editPurityForm = this._formBuilder.group({
      id: [this.PurityVM.id],
      purity: [this.PurityVM.purity, [Validators.required, Validators.maxLength(255)]],
      is_active: [this._commonService.convertToNumber(this.PurityVM.is_active), [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.editPurityForm.controls; }

  gotoPurityList() {
    this._router.navigate(['/admin/purity']);
  }

  save() {
    let self = this;
    self.submitted = true;

    // stop here if form is invalid
    if (self.editPurityForm.invalid) {
      return;
    }

    self._commonService.showLoader();
    self._purityService.updatePurity(self.editPurityForm.value).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoPurityList();
        self._commonService.showToaster("Purity updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

}
