//@Packages
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';

@Injectable()
export class HttpService {

  constructor(private _http: HttpClient, private _router: Router, private _storageService: StorageService) { }

  get(url: string, httpOptions?: any): Observable<any> {
    return this._http.get(url, httpOptions);
  }

  post(url: string, body: any, httpOptions?: any): Observable<any> {
    return this._http.post(url, body, httpOptions);
  }

  postFile(url: string, body: any, httpOptions?: any): Observable<any> {
    return this._http.post(url, body, httpOptions);
  }

  put(url: string, body: string, httpOptions?: any): Observable<any> {
    return this._http.put(url, body, httpOptions);
  }

  delete(url: string, httpOptions?: any): Observable<any> {
    return this._http.delete(url, httpOptions);
  }

  request(url: string, httpOptions?: any): Observable<any> {
    return this._http.request(url, httpOptions);
  }

  handleError(error: HttpErrorResponse | any) {
    let errorMessage: any;

    if (error instanceof HttpErrorResponse) {

      if (error.status !== 0) {
        try {

          const response = error.error || '';
          if (typeof response == 'object' && response != null) {
            ///errorMessage = { field: 'custom', message: response.message };
            let firstPropertyName = Object.keys(response)[0];
            let errorMsg = response[firstPropertyName];

            if (errorMsg) {
              errorMessage = { field: 'custom', message: errorMsg };
            } else {
              errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
            }

          } else {
            //errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
            errorMessage = { field: 'custom', message: response };
          }
        } catch (exception) {
          errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
        }
      } else {
        errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
      }
    } else {
      errorMessage = { field: 'custom', message: 'Oops! Something went wrong, please try again!' };
    }
    if (errorMessage.message == 'Unauthenticated.') {
      this._storageService.clearCredentials();
      return this._router.navigate(['/login']);
    } else {
      return throwError(errorMessage);
    }
  }

  extractData(res: Response) {
    let body = res;
    return body || {};
  }
}