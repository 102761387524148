import { Component, OnInit, Directive, HostListener } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { AppConfig } from 'src/app/shared/app-config';
import { CommonService } from 'src/app/services/common.service';
import { NotificationVM } from 'src/app/models/NotificationVM';
import { NotificationService } from 'src/app/services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DistributorService } from 'src/app/services/distributor.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  profileImageUrl: string;
  displayName: string;
  notificationdata: NotificationVM[];
  notificationcount: any;
  cartItemCount: any;
  submitted = false;
  roleType: number;

  constructor(private _auth: AuthService,
    private _storageService: StorageService,
    private _notificationService: NotificationService,
    private _appConfig: AppConfig,
    private _router: Router,
    private _route: ActivatedRoute,
    private _commonService: CommonService,
    private distributorService: DistributorService) { }

  ngOnInit() {
    this.roleType = this._storageService.getUserRolesType();
    this.setProfileImage();
    this.setDisplayName();
    this.getNotification();
    this.getCartItemCount();
    this._storageService.profileImageChange.subscribe(isChanged => {
      if (isChanged == true) {
        this.setProfileImage();
      }
    });
    this._storageService.displayNameChange.subscribe(isChanged => {
      if (isChanged == true) {
        this.setDisplayName();
      }
    });
    this._notificationService.notificationChange.subscribe(isChanged => {
      if (isChanged == true) {
        this.getNotification();
      }
    });
    this._storageService.cartItemChange.subscribe(isChanged => {
      if (isChanged == true) {
        this.getCartItemCount();
      }
    });
  }

  getNotification() {
    let self = this;
    self._notificationService.getNotification().subscribe(data => {
      let response: any = data;
      if (response.notifications != null) {
        self.notificationdata = response.notifications;
        self.notificationcount = response.notification_count;
      }
    });
  }

  getCartItemCount() {
    let self = this;
    self.distributorService.getCartItems()
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (!self._commonService.isEmptyArray(response.cart_items)) {
          self.cartItemCount = response.total_count;
        }
        else {
          self.cartItemCount = 0;
        }

      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }

  viewItem(id) {
    this._router.navigate(['/admin/items/detail/', id], { queryParams: { id: id } });
  }

  viewSalesOrder(id) {
    this._router.navigate(['/admin/sales-order-details/', id], { queryParams: { id: id } });
  }

  viewCustomOrder(id) {
    this._router.navigate(['/admin/customize-order-detail/', id], { queryParams: { id: id } });
  }

  viewAllNotification() {
    this._router.navigate(['/admin/notifications']);
  }

  viewRetailer(id) {
    if (this.roleType == 1) {
      this._router.navigate(['/admin/distributor/retailerdetail', id], { queryParams: { id: id } });
    }
    else {
      this._router.navigate(['/admin/retailer/view', id], { queryParams: { id: id } });
    }
  }

  notificationRead(notificationrow) {

    let self = this;
    self.submitted = true;
    let formData = new FormData();
    formData.append('read', notificationrow.id);
    self._notificationService.notificationRead(formData).subscribe(data => {

      if (!self._commonService.isNullOrEmpty(data)) {
        self.getNotification();
        //self.viewAllNotification();
        if (notificationrow.type == 'App\\Notifications\\systemNotifications\\AddItemToAdminUsers' || notificationrow.type == 'App\\Notifications\\systemNotifications\\ApproveItemToDistributor' || notificationrow.type == 'App\\Notifications\\systemNotifications\\RejectItemToDistributor') {
          self.viewItem(notificationrow.data.item_id);
          //self._router.navigate(['/admin/items/detail/', notificationrow.data.item_id]);
        }
        if (notificationrow.type == 'App\\Notifications\\systemNotifications\\AddSalesOrderToAdmin' || notificationrow.type == 'App\\Notifications\\systemNotifications\\CancelSalesOrder' ||
          notificationrow.type == 'App\\Notifications\\systemNotifications\\requestToOrder' ||
          notificationrow.type == 'App\\Notifications\\systemNotifications\\ConfirmSalesOrderToUser' ||
          notificationrow.type == 'App\\Notifications\\systemNotifications\\CompleteSalesOrderToUser') {
          self.viewSalesOrder(notificationrow.data.sales_order_id);
          //self._router.navigate(['/admin/sales-order-details/', notificationrow.data.sales_order_id]);
        }

        if (notificationrow.type == 'App\\Notifications\\systemNotifications\\AddCustomOrderToAdmin' || notificationrow.type == 'App\\Notifications\\systemNotifications\\ConfirmCustomOrderToUser' ||
          notificationrow.type == 'App\\Notifications\\systemNotifications\\CancelCustomOrder' ||
          notificationrow.type == 'App\\Notifications\\systemNotifications\\requestCancelCustomOrder' ||
          notificationrow.type == 'App\\Notifications\\systemNotifications\\CompleteCustomOrderToUser') {
          self.viewCustomOrder(notificationrow.data.custom_order_id);
          //self._router.navigate(['/admin/customize-order-detail/', notificationrow.data.custom_order_id]);

        }

        if (notificationrow.type == 'App\\Notifications\\systemNotifications\\addRetailer' ||
          notificationrow.type == 'App\\Notifications\\systemNotifications\\RetailerApprove' ||
          notificationrow.type == 'App\\Notifications\\systemNotifications\\RetailerReject') {
          self.viewRetailer(notificationrow.data.retailer_id);
        }

      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  notificationReadAll(notificationrow) {
    let self = this;
    self.submitted = true;
    let formData = new FormData();
    formData.append('readAll', notificationrow);
    self._notificationService.notificationRead(formData).subscribe(data => {
      if (!self._commonService.isNullOrEmpty(data)) {
        self.getNotification();
        self.viewAllNotification();
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }


  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }


  logout() {
    this._auth.logout();
  }

  setProfileImage() {

    if (this._storageService.getProfileImage() != "null") {

      this.profileImageUrl = this._appConfig.ImageUrl + this._storageService.getProfileImage();

    } else {

      this.profileImageUrl = this._appConfig.ImageUrl + "no-profile.png";
    }
  }
  setDisplayName() {
    this.displayName = this._storageService.getDisplayName();
  }

  redirectToCartList() {
    if (this.cartItemCount != 0) {
      this._router.navigate(['/admin/add-to-cart-list'])
    }
  }
}
