import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { CompanyService } from 'src/app/services/company.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { REGEXP } from 'src/app/shared/regexp';
import { CompanyVM } from 'src/app/models/CompanyVM';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-companies-edit',
  templateUrl: './companies-edit.component.html',
  styleUrls: ['./companies-edit.component.css']
})
export class CompaniesEditComponent implements OnInit {

  @ViewChild("logo", { static: false }) companyLogoFileInput;
  @ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;
  @ViewChild("placesRef1", { static: false }) placesRef1: GooglePlaceDirective;

  id: number;
  companyForm: FormGroup;
  company_module = new CompanyVM();
  companyLogoImageUrl: string;
  submitted = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _regex: REGEXP,

    private _commonService: CommonService,
    private _companyService: CompanyService,
    private _router: Router,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.buildForm();
    this.getSingleCompany();
  }

  get f() { return this.companyForm.controls; }

  gotoCompnayList() {
    this._router.navigate(['/admin/company']);
  }

  getSingleCompany() {

    this._route.params.subscribe(params => { this.id = params['id']; });

    let self = this;

    self._commonService.showLoader();
    self._companyService.getCompanyById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.company_module = response.company;
        this.buildForm();
      });

  }
  buildForm() {

    let companyFormGroup = {
      company_code: [this.company_module.company_code, [Validators.required, Validators.maxLength(255)]],
      company_id: [this._commonService.convertToNumber(this.company_module.company_id)],
      company_name: [this.company_module.company_name, [Validators.required, Validators.maxLength(255)]],
      display_name: [this.company_module.display_name, [Validators.required, Validators.maxLength(255)]],
      registration_address: [this.company_module.registration_address, [Validators.required, Validators.maxLength(255)]],
      billing_address: [this.company_module.billing_address, [Validators.required, Validators.maxLength(255)]],
      contact_email: [this.company_module.contact_email, [Validators.required, Validators.pattern(this._regex.EMAIL_REGEXP)]],
      phone_no: [this.company_module.phone_no, [Validators.required, Validators.pattern(this._regex.NUMBER_REGEXP), Validators.minLength(10), Validators.maxLength(13)]],
      company_reg_no: [this.company_module.company_reg_no, [Validators.required, Validators.maxLength(255)]],
      website_name: [this.company_module.website_name, [RxwebValidators.url()]],
      is_active: [this._commonService.convertToNumber(this.company_module.is_active)],
      is_default_selected: [this._commonService.convertToNumber(this.company_module.is_default_selected)]
    };

    if (this._commonService.isNullOrEmpty(this.company_module.logo)) {
      companyFormGroup['logo'] = [this.company_module.logo, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
    } else {
      companyFormGroup['logo'] = ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
      this.companyLogoImageUrl = this._commonService.getFullImageUrl(this.company_module.logo);
    }
    this.companyForm = this._formBuilder.group(companyFormGroup);

  }

  save() {
    let self = this;
    self.submitted = true;

    // stop here if form is invalid
    if (self.companyForm.invalid) {
      return;
    }
    let formData = new FormData();

    let siteConfigData = (self.companyForm.value as CompanyVM);

    for (const prop in siteConfigData) {

      let fi = this.companyLogoFileInput.nativeElement;
      if (prop == 'logo') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.companyForm.value.logo = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        //   let billingAddress = this.placesRef.place.formatted_address;
        //   formData.append(prop,billingAddress);
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }
    }

    self._commonService.showLoader();
    self._companyService.updateCompany(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoCompnayList();
        self._commonService.showToaster("Company updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

  public handleRegAddressChange(address) {
    this.companyForm.controls['registration_address'].setValue(address.formatted_address);
  }
  public handleBillAddressChange(address) {
    this.companyForm.controls['billing_address'].setValue(address.formatted_address);
  }


}
