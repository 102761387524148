
export class SubscriptionVM {
    SubscriptionId: number
    SubscriptionCode: string
    SubscriptionName: string
    SubscriptionDetail: string
    ValidDays: number
    TotalCredit: number
    IsActive: number
    UpdateLog?: string
    price: number
    created_at?: string
    updated_at?: string
    role_id?:string;
    is_default?:number;
}



export class UserSubscriptionVM {
    UserSubscriptionId: number
    SubscriptionId: any
    UserId: number
    AssociationId: number
    AssociationType_Term: string
    DistributorName: string
    PurchaseDate?: Date;
    DateofExpiry?: Date;
    AmountPaid: string
    Credit: string
    CreditOnHand: string
    IsActive: number
    UpdateLog: string
    TransactionRefNo: string
    ReferenceImage: string
    IsVarified: string
    IsReconcile: string
    ReconcileBy: string
    ReconcileOn: string
    created_at?: string
    updated_at?: string
    role_type: string
    SubscriptionName: SubscriptionVM
    RetailerName: string
    distributor_name: string
    customer_name: string
    user_type: string
    user_display_name : string
}


export class ImageSubscriptioVM {
    OrderNo: number
    SubscriptionImage: string
}
