import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { RetailerVM } from 'src/app/models/RetailerVM';
import { CommonService } from 'src/app/services/common.service';
import { DistributorService } from 'src/app/services/distributor.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2';
import { RetailerRequestVM } from 'src/app/models/RetailerRequestVM';

@Component({
  selector: 'app-retailer-request-list',
  templateUrl: './retailer-request-list.component.html',
  styleUrls: ['./retailer-request-list.component.css']
})
export class RetailerRequestListComponent implements OnInit {

  pageSize: number = 50;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columnSorting: any;
  columnDirection: any;
  totalLength: any;
  value: '';
  filterValue: '';
  retailerRequestData: any;
  customerList: any;
  noData = false;
  displayedColumns: string[];

  constructor(private _siteConfigService: SiteConfigService, private _commonService: CommonService, private _distributorService: DistributorService,
    private router: Router) { }

  ngOnInit() {
    this._commonService.showLoader();
    this._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        this.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "pageIndex": '0',
          "pageSize": this.pageSize,
        }
        this.loadRetailerRequest(event);
      }
    });

    this.displayedColumns = ['RetailerName', 'ContactMobNo', 'ContactEmail', 'CreatedAt', 'actions'];
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadRetailerRequest(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._distributorService.getCustomerRetailerRequestList(event, this.value, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            if (!self._commonService.isNullOrEmpty(response.data)) {

              self.retailerRequestData = new MatTableDataSource<RetailerRequestVM>(response.data.list);
              if (response.data.list.length > 0) {
                self.totalLength = response.data.total_count;
              }

              self.noData = (response.data.list != null && response.data.list.length > 0) ? false : true;
              if (self.noData == true) {
                self.totalLength = 0;
              }
            }

          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }


  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadRetailerRequest(event);
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;

    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadRetailerRequest(event);
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  redirectToRetailerView(customer: RetailerRequestVM) {
    this.router.navigate(['/admin/customer/retailerRequest-view', customer.CustRetailerReqId])
  }

  redirectToRetailerInquiry(customer: RetailerRequestVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to accept request for this retailer?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    swal.fire(options).then((result) => {
      if (result.value) {
        if (result.value != '' && result.value != null) {
          let formData = new FormData();
          formData.append("id", customer.CustRetailerReqId);
          formData.append("RequestStatus", customer.RequestStatus == "pending" ? "accept":"pending");
          self._commonService.showLoader();
          self._distributorService.updateRetailerREquestStatus(formData).subscribe((data:any) => {
            self._commonService.hideLoader();
            self._commonService.showToaster(data.message, "success");
            var event: any = {
              "previousPageIndex": '0',
              "pageIndex": '0',
              "pageSize": this.pageSize,
              "length": '0',
            }
            self.loadRetailerRequest(event);
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
            return false;
          });
        } else {
          swal.enableButtons();
        }
      }
    })
  }

}
