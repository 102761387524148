import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

// @Services
import { HttpService } from './http.service';
import { AppConfig } from '../shared/app-config';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    apiUrl: String;
    // set http Authorization headers
    httpAuthOptions = {
        headers: new HttpHeaders({
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        })
    };

    constructor(private _httpService: HttpService,
        private _api: AppConfig) {
        this.apiUrl = this._api.ApiUrl;
    }

    getAllSubscription(event, filter, columnSorting, columnDirection) {
        var body = {
            "event": event,
            "filter": filter,
            "columnSorting": columnSorting,
            "columnDirection": columnDirection,
        }

        // var body = {
        //     "event": {'pageIndex':1,'pageSize':1},
        //     "filter": '',
        //     "columnSorting": 0,
        //     "columnDirection": 0,
        // }
        return this._httpService.post(`${this.apiUrl}subscription/getAllSubscription`, body, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getSubscriptionCode() {
        return this._httpService.get(`${this.apiUrl}subscription/code`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    addSubscription(subscription) {
        return this._httpService.post(`${this.apiUrl}subscription/add`, subscription, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    assignSubscription(subscription) {
        return this._httpService.post(`${this.apiUrl}userSubscription/subscriptionAssign`, subscription, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getSubscriptionById(id) {
        return this._httpService.get(`${this.apiUrl}subscription/getSubscriptionById/${id}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

     getRetailerSubscriptionById(id) {
        return this._httpService.get(`${this.apiUrl}userSubscription/getRetailerSubscriptionById/${id}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }
       deleteUserSubscription(id) {
        return this._httpService.get(`${this.apiUrl}userSubscription/usersubscriptionDelete/${id}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }
    
  getDistSubscriptionStatus() {
        return this._httpService.get(`${this.apiUrl}userSubscription/getDistSubscriptionStatus`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

  getDistSubscriptionByLoginId() {
        return this._httpService.get(`${this.apiUrl}userSubscription/getDistSubscriptionByLoginId`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }
    
    

     getDistSubscriptionById(id) {
        return this._httpService.get(`${this.apiUrl}userSubscription/getDistSubscriptionById/${id}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }
    
     getUserSubscriptionById(id) {
        return this._httpService.get(`${this.apiUrl}userSubscription/getUserSubscriptionById/${id}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getAssignSubscriptionHistory(event, filter, columnSorting, columnDirection, user_subscription_id){
        var body = {
            "event": event,
            "value": filter,
            "columnSorting": columnSorting,
            "columnDirection": columnDirection,
            "user_subscription_id": user_subscription_id
        }
        return this._httpService.post(`${this.apiUrl}subscription_history_by_user`,body, this.httpAuthOptions)
        .pipe(
            map((response: any) => {
                return this._httpService.extractData(response);
            }), catchError((errorRes: any) => {
                return this._httpService.handleError(errorRes);
            })
        ); 
    }
    updateSubscription(subscription) {
        return this._httpService.post(`${this.apiUrl}subscription/getSubscriptionUpdate`, subscription, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }
    
     updateUserSubscription(subscription) {
        return this._httpService.post(`${this.apiUrl}userSubscription/subscriptionAssignUpdate`, subscription, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    deleteSubscription(id) {
        return this._httpService.get(`${this.apiUrl}subscription/subscriptionDelete/${id}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getAllActiveDistributor() {
        return this._httpService.post(`${this.apiUrl}distributor/getAllDistributorData`, '', this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getActiveRetailer() {
        return this._httpService.get(`${this.apiUrl}retailer/getAdminActiveRetailer`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getActiveDistributorRetailer() {
        return this._httpService.get(`${this.apiUrl}retailer/getDistributorRetailer`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

  getAllActiveSubscriptionSelected(id) {
  return this._httpService.get(`${this.apiUrl}subscription/getAllActiveSubscriptionSelected/${id}`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getAllActiveSubscription() {
        return this._httpService.get(`${this.apiUrl}subscription/getAllActiveSubscription`, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

    getAllAssignSubscription(event, filter, search, columnSorting, columnDirection) {

        var body = {
            "event": event,
            "value": filter,
            "search": search,
            "columnSorting": columnSorting,
            "columnDirection": columnDirection,

        }
        return this._httpService.post(`${this.apiUrl}userSubscription/userSubscriptionList`, body, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }


    getSubscriptionItems(id,event, filterValue, columnSorting, columnDirection) {
        var body = {
            "SubscriptionId":id,
            "event": event,
            "value": filterValue,
            "columnSorting": columnSorting,
            "columnDirection": columnDirection
        }
        return this._httpService.post(`${this.apiUrl}subscription/getSubscriptionItems`, body, this.httpAuthOptions)
            .pipe(
                map((response: any) => {
                    return this._httpService.extractData(response);
                }), catchError((errorRes: any) => {
                    return this._httpService.handleError(errorRes);
                })
            );
    }

}
