import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// @Models
import { MetalVM } from 'src/app/models/MetalVM';
import { UnitVM } from 'src/app/models/UnitVM';

// @Services
import { REGEXP } from 'src/app/shared/regexp';
import { MetalService } from 'src/app/services/metal.service';
import { CommonService } from 'src/app/services/common.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'selenium-webdriver/ie';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-metal-edit',
  templateUrl: './metal-edit.component.html',
  styleUrls: ['./metal-edit.component.css']
})
export class MetalEditComponent implements OnInit {

  public select2Options: Options;
  public select2UnitData: Array<Select2OptionData>;

  id: number;
  editMetalForm: FormGroup;
  MetalVM = new MetalVM();
  submitted = false;
  unitData: UnitVM[];
  loginUserId: any;
  role: any;
  permissionsData = [];

  constructor(private _formBuilder: FormBuilder,
    private metalService: MetalService,
    private _commonService: CommonService,
    private _regex: REGEXP,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _roleService: RoleService,
    private _storageService: StorageService,
    private _router: Router,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.getUserActive();
    this.buildForm();
    Promise.all([
      this.getAllUnitData()
    ]).then((data: any) => {
      this.getMetal();
    });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[7].sub_permission[2].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }
  get f() { return this.editMetalForm.controls; }

  gotoMetalList() {
    this._router.navigate(['/admin/metal']);
  }

  getAllUnitData() {
    let self = this;
    self.metalService.getAllUnitData().subscribe(data => {
      let response: any = data;
      if (response.unit_data != null) {
        self.unitData = response.unit_data;
        self.select2UnitData = self._commonService.dropdownArray(self.unitData, "unit_id", "unit_name");
        self.buildForm();
      }
    });
  }

  getMetal() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self.metalService.getMetalById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.MetalVM = response.metal;
        self.buildForm();
      });
  }
  buildForm() {

    this.editMetalForm = this._formBuilder.group({

      metal_id: [this._commonService.convertToNumber(this.MetalVM.metal_id)],
      metal_code: [this.MetalVM.metal_code, [Validators.required, Validators.maxLength(255)]],
      metal_name: [this.MetalVM.metal_name, [Validators.required, Validators.maxLength(255)]],
      metal_detail: [this.MetalVM.metal_detail],
      current_price: [this.MetalVM.current_price, [Validators.required, RxwebValidators.numeric({ allowDecimal: true })]],
      unit_of_mesure_id: [this._commonService.convertToNumber(this.MetalVM.unit_of_mesure_id), [Validators.required]],
      total_sold: [this.MetalVM.total_sold, [Validators.required, RxwebValidators.numeric({ allowDecimal: true })]],
      total_produce: [this.MetalVM.total_produce, [Validators.required, RxwebValidators.numeric({ allowDecimal: true })]],
      total_in_stock: [this.MetalVM.total_in_stock, [Validators.required, RxwebValidators.numeric({ allowDecimal: true })]],
      is_active: [this.MetalVM.is_active, Validators.required]
    });
  }

  save() {

    let self = this;
    self.submitted = true;
    if (self.editMetalForm.invalid) {
      return;
    }

    self._commonService.showLoader();
    self.metalService.updateMetal(self.editMetalForm.value).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoMetalList();
        self._commonService.showToaster("Metal updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }


}
