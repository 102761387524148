// @Packages
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
// @Models
import { RolePermissionVM, GetRolePermissionsVM } from 'src/app/models/RolePermissionVM';
// @Services
import { RoleService } from 'src/app/services/role.service';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-role-permission',
  templateUrl: './role-permission.component.html',
  styleUrls: ['./role-permission.component.css']
})
export class RolePermissionComponent implements OnInit {

  id: number;
  editRolePermissionForm: FormGroup;
  RolePermissionVM = new RolePermissionVM();
  submitted = false;
  permissionData = [];
  data: GetRolePermissionsVM[] = [];
  displayName: any;
  role: number;
  permission: any;
  currentRoleTypeID: any;

  constructor(private _formBuilder: FormBuilder,
    private _roleService: RoleService,
    private _commonService: CommonService,
    private _router: Router,
    private _storageService: StorageService,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this.getRoleUser(),
      this.role = this._storageService.getUserRoles();
    this.initializeFormValue();
  }

  getRoleUser() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._roleService.getRoleById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.RolePermissionVM = response.role;
        self.displayName = response.role.display_name;
      });
  }

  initializeFormValue() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    this.buildForm();
    Promise.all([
      self.getallPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      //      self.data = list;
      if (list.length > 0) {
        let permission = list.filter(c => c.display_name == "showcase" || c.display_name == "customize order" || c.display_name == "Companies" || c.display_name == "sales-order" || c.display_name == "mysales-order" || c.display_name == "payment-detail");
        if (permission != undefined && permission != null) {
          permission.forEach((ele) => {
            if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
              let remainLength = 4 - ele.sub_permission.length;
              if (remainLength > 0) {
                for (let k = 0; k < remainLength; k++) {
                  ele.sub_permission.push(null);
                }
              }
            }
            self.data = list;
            self.currentRoleTypeID = self.RolePermissionVM.role_type;
            self.data.forEach((ele) => {
              if (ele.role_types.indexOf(self.currentRoleTypeID) != -1) {
                ele.isShowPermission = true;
              }
              if (ele.sub_permission != undefined && ele.sub_permission != null && ele.sub_permission.length > 0) {
                ele.sub_permission.forEach((sub) => {
                  if (sub != null && sub != undefined) {
                    if (sub.role_types != null && sub.role_types != undefined) {
                      if (sub.role_types.indexOf(self.currentRoleTypeID) != -1) {
                        sub.isShowPermission = true;
                        ele.isShowPermission = true;
                      }
                    }
                  }
                });
              }
            });
          });
        }
      }
      //self.addCheckboxes();
    });
  }

  getallPermission() {
    let role_id = this.id;
    return new Promise((resolve, reject) => {
      let self = this;
      self._commonService.showLoader();
      self._roleService.getAllPermissions(role_id)
        .subscribe(data => {
          self._commonService.hideLoader();
          resolve({ data });
        }, error => {
          resolve({ error });
        });
    });
  }

  buildForm() {
    this.editRolePermissionForm = this._formBuilder.group({
      role_id: [this._commonService.convertToNumber(this.RolePermissionVM.role_id)],
      permissions: this._formBuilder.array([])
    });
  }

  // addCheckboxes() {
  //   let self = this;
  // }

  get f() { return this.editRolePermissionForm.controls; }

  save() {
    let self = this;
    self.submitted = true;
    let input: any = {};
    input.role_id = self.id;
    input.permissions = self.data;
    // stop here if form is invalid
    // if (self.editRolePermissionForm.invalid) {
    //   return;
    // }
    self._commonService.showLoader();
    self._roleService.updatePermission(input).subscribe(data => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        //self.gotoRoleList();
        this._router.navigate(['admin/roles']);
        self._commonService.showToaster("Permission updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

  selectSubPermission(event, permission_id, sub_permission_id) {
    let self = this;
    const classNames = event.target.classList.value;
    const isViewAll = classNames.includes("view_all");
    if (isViewAll == true || isViewAll == "true") {
      if (event.target.checked) {
        self.data.find(i => i.id == permission_id).sub_permission.forEach(item => {
          if (item != null) {
            item.isSelected = true;
          }
        })
      } else {
        self.data.find(i => i.id == permission_id).sub_permission.forEach(item => {

          if (item != null) {
            item.isSelected = false;
          }
        })
      }
    } else {
      if (event.target.checked) {
        self.data.find(i => i.id == permission_id).sub_permission.find(i => i.id == sub_permission_id).isSelected = true;
      } else {
        self.data.find(i => i.id == permission_id).sub_permission.find(i => i.id == sub_permission_id).isSelected = false;
      }
    }
  }

  selectAll(event) {
    let self = this;
    if (self.data.length > 0) {
      if (event.target.checked) {
        if (self.RolePermissionVM.role_type == 1) {
          self.permission = self.data.filter(c => c.display_name == "Attributes" || c.display_name == "Categories" || c.display_name == "Sub Categories" || c.display_name == "Metals" || c.display_name == "Accessories" || c.display_name == "Distributor Groups" || c.display_name == "Item Groups" || c.display_name == "Items" || c.display_name == "customize order" || c.display_name == "Warehouses" || c.display_name == "Distributors" || c.display_name == "Distributor Items" || c.display_name == "sales-order" || c.display_name == "Production Houses" || c.display_name == "purity" || c.display_name == "retailer" || c.display_name == "distributor-retailer");
          if (self.permission != undefined && self.permission != null) {
            self.permission.forEach((ele) => {
              ele.isSelected = true;
              if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
                ele.sub_permission.forEach(k => {
                  if (k != null) {
                    k.isSelected = true;
                  }
                });
              }
            });
          }
        }
        else if (self.RolePermissionVM.role_type == 3) {
          self.permission = self.data.filter(c => c.display_name == "Items" || c.display_name == "customize order" || c.display_name == "showcase" || c.display_name == "mysales-order" || c.display_name == "payment-detail" || c.display_name == "customer");
          if (self.permission != undefined && self.permission != null) {
            self.permission.forEach((ele) => {
              ele.isSelected = true;
              if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
                ele.sub_permission.forEach(k => {
                  if (k != null) {
                    k.isSelected = true;
                  }
                });
              }
            });
          }
        }
        else {
          self.permission = self.data.filter(c => c.display_name == "Items" || c.display_name == "customize order" || c.display_name == "showcase" || c.display_name == "mysales-order" || c.display_name == "payment-detail" || c.display_name == "retailer");
          if (self.permission != undefined && self.permission != null) {
            self.permission.forEach((ele) => {
              ele.isSelected = true;
              if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
                ele.sub_permission.forEach(k => {
                  if (k != null) {
                    k.isSelected = true;
                  }
                });
              }
            });
          }
        }
      }
      // self.data.forEach(item => {
      //   item.isSelected = true;
      //   item.sub_permission.forEach(k => {
      //     if (k != null) {
      //       k.isSelected = true;
      //     }
      //   })
      // })
      else {
        if (self.RolePermissionVM.role_type == 1) {
          self.permission = self.data.filter(c => c.display_name == "Attributes" || c.display_name == "Categories" || c.display_name == "Sub Categories" || c.display_name == "Metals" || c.display_name == "Accessories" || c.display_name == "Distributor Groups" || c.display_name == "Item Groups" || c.display_name == "Items" || c.display_name == "customize order" || c.display_name == "Warehouses" || c.display_name == "Distributors" || c.display_name == "Distributor Items" || c.display_name == "sales-order" || c.display_name == "Production Houses" || c.display_name == "purity" || c.display_name == "retailer" || c.display_name == "distributor-retailer");
          if (self.permission != undefined && self.permission != null) {
            self.permission.forEach((ele) => {
              ele.isSelected = false;
              if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
                ele.sub_permission.forEach(k => {
                  if (k != null) {
                    k.isSelected = false;
                  }
                });
              }
            });
          }
        }
        else if (self.RolePermissionVM.role_type == 3) {
          self.permission = self.data.filter(c => c.display_name == "Items" || c.display_name == "customize order" || c.display_name == "showcase" || c.display_name == "mysales-order" || c.display_name == "payment-detail" || c.display_name == "customer");
          if (self.permission != undefined && self.permission != null) {
            self.permission.forEach((ele) => {
              ele.isSelected = false;
              if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
                ele.sub_permission.forEach(k => {
                  if (k != null) {
                    k.isSelected = false;
                  }
                });
              }
            });
          }
        }
        else {
          self.permission = self.data.filter(c => c.display_name == "Items" || c.display_name == "customize order" || c.display_name == "showcase" || c.display_name == "mysales-order" || c.display_name == "payment-detail" || c.display_name == "retailer");
          if (self.permission != undefined && self.permission != null) {
            self.permission.forEach((ele) => {
              ele.isSelected = false;
              if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
                ele.sub_permission.forEach(k => {
                  if (k != null) {
                    k.isSelected = false;
                  }
                });
              }
            });
          }
        }
      }
    }
  }

  // selectAllSubPermission(event, permission_id) {
  //   let self = this;
  //   if (event.target.checked) {
  //     self.data.find(i => i.id == permission_id).sub_permission.forEach(item => {
  //       item.isSelected = true;
  //     })
  //   } else {
  //     self.data.find(i => i.id == permission_id).sub_permission.forEach(item => {
  //       item.isSelected = false;
  //     })
  //   }
  // }

  trackByFn(index, item) {
    if (!item) return null;
    return index; // or item.disaggregationID; 
  }
}
