// @Packages
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// @Models
import { RoleVM } from 'src/app/models/RoleVM';
// @Services
import { RoleService } from 'src/app/services/role.service';
import { CommonService } from 'src/app/services/common.service';
import { Options } from 'selenium-webdriver';
import { Select2OptionData } from 'ng-select2';

@Component({
  selector: 'app-role-add',
  templateUrl: './role-add.component.html',
  styleUrls: ['./role-add.component.css']
})
export class RoleAddComponent implements OnInit {

  public select2Options: Options;
  public roleTypeData: Array<Select2OptionData>;
  public options: Options;

  addRoleForm: FormGroup;
  RoleVM = new RoleVM();
  submitted = false;
  last_role_id: any;
  constructor(private _formBuilder: FormBuilder,
    private _roleService: RoleService,
    private _commonService: CommonService,
    private _router: Router) { }

  ngOnInit() {
    this.createRoleForm();
    this.roleTypeData = [
      {
        id: '1',
        text: 'Admin'
      },
      {
        id: '2',
        text: 'Distributor'
      },
      {
        id: '3',
        text: 'Retailer',
        // disabled: true
      },
      {
        id: '4',
        text: 'Customer',
        disabled: true
      }
    ];
  }

  createRoleForm() {
    this.addRoleForm = this._formBuilder.group({
      code: [this.RoleVM.code, [Validators.required, Validators.maxLength(255)]],
      name: [this.RoleVM.name, [Validators.required, Validators.maxLength(255)]],
      display_name: [this.RoleVM.display_name, [Validators.required, Validators.maxLength(255)]],
      description: [this.RoleVM.description],
      is_active: [1, Validators.required],
      is_default: [0, Validators.required],
      role_type: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.addRoleForm.controls; }

  gotoRoleList(last_role_id) {
    this._router.navigate(['/admin/role-permission/edit', last_role_id]);
  }

  save() {
    let self = this;
    self.submitted = true;
    // stop here if form is invalid
    if (self.addRoleForm.invalid) {
      return;
    }
    self._commonService.showLoader();
    self._roleService.addRole(self.addRoleForm.value).subscribe(data => {
      let response: any = data;
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self.last_role_id = response.last_insert_id;
        self.gotoRoleList(self.last_role_id);
        self._commonService.showToaster("Role added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }
}
