// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { Router } from '@angular/router';
// @Models
import { UserVM } from 'src/app/models/UserVM';
// @Services
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';
import { REGEXP } from 'src/app/shared/regexp';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-users-add',
  templateUrl: './users-add.component.html',
  styleUrls: ['./users-add.component.css']
})
export class UsersAddComponent implements OnInit {

  @ViewChild("profile_pic_path", { static: false }) userPhotoFileInput;
  UsersForm: FormGroup;
  UserVM = new UserVM();
  roleData = [];
  submitted = false;
  default_role_id: any;
  public select2Options: Options;
  public select2RolesData: Array<Select2OptionData>;

  constructor(private _formBuilder: FormBuilder,
    private _usersService: UserService,
    private _commonService: CommonService,
    private _regex: REGEXP,
    private _router: Router) { }

  ngOnInit() {
    this.createUsersForm();
    this.getAllActiveRoles();
  }

  getAllActiveRoles() {
    let self = this;
    self._usersService.getAllActiveRoles().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.roleData = response.data;
        self.select2RolesData = self._commonService.dropdownArray(self.roleData, "id", "name");
      }
    });
  }

  get f() { return this.UsersForm.controls; }

  createUsersForm() {
    this.UsersForm = this._formBuilder.group({
      first_name: [this.UserVM.first_name, [Validators.required, Validators.maxLength(255)]],
      last_name: [this.UserVM.last_name, [Validators.required, Validators.maxLength(255)]],
      user_detail: [this.UserVM.user_detail],
      display_name: [this.UserVM.display_name, [Validators.required, Validators.maxLength(255)]],
      email: [this.UserVM.email, [Validators.required, Validators.maxLength(255), Validators.pattern(this._regex.EMAIL_REGEXP)]],
      profile_pic_path: [this.UserVM.profile_pic_path, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      mobile_no: [this.UserVM.mobile_no, [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
      is_active: [1, Validators.required],
      role_id: ['', Validators.required],
      gender: ['', Validators.required],
    });
  }

  gotoUsersList() {
    this._router.navigate(['/admin/users']);
  }

  save() {
    let self = this;
    self.submitted = true;
    if (self.UsersForm.invalid) {
      return;
    }
    // stop here if form is invalid
    let formData = new FormData();
    let siteConfigData = (self.UsersForm.value as UserVM);
    for (const prop in siteConfigData) {
      let fi = this.userPhotoFileInput.nativeElement;
      if (prop == 'profile_pic_path') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.UsersForm.value.logo = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }
    }
    self._commonService.showLoader();
    self._usersService.addUser(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoUsersList();
        self._commonService.showToaster("User added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }
}
