// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2'
// @Services
import { DistributorGroupService } from 'src/app/services/distributorgroup.service';
import { CommonService } from 'src/app/services/common.service';
// @Models
import { DistributorGroupVM } from 'src/app/models/DistributorGroupVM';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-distributorgroup-list',
  templateUrl: './distributorgroup-list.component.html',
  styleUrls: ['./distributorgroup-list.component.css']
})

export class DistributorgroupListComponent implements OnInit {

  role: any;
  roleType: any
  permissionsData = [];
  pageSize: number = 50;
  distributorgroupData: any;
  distributor: any;
  noData = false;
  marked = false;
  marked1 = false;
  marked2 = false;
  displayedColumns: string[] = ['group_code', 'group_name', 'group_details', 'is_active', 'created_at', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private distributorgroupService: DistributorGroupService,
    private _commonService: CommonService,
    private _router: Router,
    private _roleService: RoleService,
    private _storageService: StorageService,
    private _siteConfigService: SiteConfigService
  ) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();
    if (this.role != 1) {
      this.getPermission();
    }
    this.loadDistributorGroup();
    this.distributor = this._storageService.getDistributorId();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      self.marked = self.permissionsData[12].sub_permission[1].isSelected;
      self.marked1 = self.permissionsData[12].sub_permission[2].isSelected;
      self.marked2 = self.permissionsData[12].sub_permission[3].isSelected;
      if (self.permissionsData[12].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadDistributorGroup() {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self.distributorgroupService.getAllDistributorGroup()
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.distributorgroupData = new MatTableDataSource<DistributorGroupVM>(response.data);
            self.distributorgroupData.paginator = self.paginator;
            self.distributorgroupData.sort = self.sort;
            self.distributorgroupData.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId] ? data[sortHeaderId].toString().toLowerCase() : null;
            self.noData = (response.data.length > 0) ? false : true;
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  editDestributorGroup(distributorgroup: DistributorGroupVM) {
    this._router.navigate(['/admin/distributor-groups/edit/', distributorgroup.distributor_group_id]);
  }

  deleteDestributorGroup(distributorgroup: DistributorGroupVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this distributor group?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self.distributorgroupService.deleteDestributorGroup(distributorgroup.distributor_group_id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Distributor group deleted successfully.", "success");
          self.loadDistributorGroup();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  applyFilter(filterValue: string) {
    this.distributorgroupData.filter = filterValue.trim().toLowerCase();
    // this.noData = (this.distributorgroupData._paginator._length > 0) ? false : true;
    this.noData = (this.distributorgroupData.filteredData.length > 0) ? false : true;
  }
}
