//package
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { REGEXP } from 'src/app/shared/regexp';
import { Router, ActivatedRoute } from '@angular/router';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';

//service
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';

//model
import { UserVM } from 'src/app/models/UserVM';
import { StorageService } from 'src/app/services/storage.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  @ViewChild("profile_pic_path", { static: false }) userPhotoFileInput;

  id: number;
  profile_pic_url: string;
  UsersForm: FormGroup;
  UserVM = new UserVM();
  submitted = false;

  constructor(private _formBuilder: FormBuilder,
    private _regex: REGEXP,
    private _storageService: StorageService,
    private _commonService: CommonService,
    private _usersService: UserService,
    private _router: Router) { }

  ngOnInit() {
    this.getLoginUserById();
    this.buildForm();

  }

  get f() { return this.UsersForm.controls; }

  gotoUsersList() {
    this._router.navigate(['/admin/edit-profile']);
  }

  getLoginUserById() {
    let self = this;

    self._commonService.showLoader();
    self._usersService.getLoginUserById()
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        // if (!this._commonService.isNullOrEmpty(response)) {
        self.UserVM = response;
        self._storageService.setProfileImg(self.UserVM.profile_pic_path);
        self._storageService.setDisplayName(self.UserVM.display_name);
        self.buildForm();
        // }

      });
  }

  buildForm() {

    let UsersLoginForm = {
      id: [this._commonService.convertToNumber(this.UserVM.id)],
      first_name: [this.UserVM.first_name, [Validators.required, Validators.maxLength(255)]],
      last_name: [this.UserVM.last_name, [Validators.required, Validators.maxLength(255)]],
      display_name: [this.UserVM.display_name, [Validators.required, Validators.maxLength(255)]],
      email: [this.UserVM.email],
      mobile_no: [this.UserVM.mobile_no, [Validators.required, Validators.pattern(this._regex.NUMBER_REGEXP), Validators.minLength(10), Validators.maxLength(13)]],
    };
    if (this._commonService.isNullOrEmpty(this.UserVM.profile_pic_path)) {
      UsersLoginForm['profile_pic_path'] = [this.UserVM.profile_pic_path, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT),RxwebValidators.fileSize({maxSize:5347737.6})]];
    } else {
      UsersLoginForm['profile_pic_path'] = ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT),RxwebValidators.fileSize({maxSize:5347737.6})]];
      this.profile_pic_url = this._commonService.getFullImageUrl(this.UserVM.profile_pic_path);
    }

    this.UsersForm = this._formBuilder.group(UsersLoginForm);

  }

  save() {
    let self = this;
    self.submitted = true;

    if (self.UsersForm.invalid) {
      return;
    }

    let formData = new FormData();

    let siteConfigData = (self.UsersForm.value as UserVM);

    for (const prop in siteConfigData) {
      let fi = this.userPhotoFileInput.nativeElement;
      if (prop == 'profile_pic_path') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.UsersForm.value.profile_pic_path = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }
    }

    self._commonService.showLoader();
    self._usersService.editProfile(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.getLoginUserById();
        self._commonService.showToaster("Profile updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }
}
