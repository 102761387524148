// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2';

// @Services
import { DistributorService } from 'src/app/services/distributor.service';
import { CommonService } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { AuthService } from 'src/app/services/auth/auth.service';

// @Models
import { DistributorVM } from 'src/app/models/DistributorVM';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';
import { RetailerPasswordComponent } from '../../retailers/retailer-password/retailer-password.component';
declare var $: any;
@Component({
  selector: 'app-distributor-list',
  templateUrl: './distributor-list.component.html',
  styleUrls: ['./distributor-list.component.css']
})
export class DistributorListComponent implements OnInit {
  getSiteConfig: any;
  distributorData: any;
  noData = false;
  role: any;
  roleType: any;
  permissionsData = [];
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  filterValue: '';
  value: '';
  marked = false;
  marked1 = false;
  marked2 = false;
  columnSorting: any;
  columnDirection: any

  displayedColumns: string[] = ['display_name', 'pending_item_count', 'rejected_item_count', 'approve_item_count', 'assign_item_count', 'total_item_count', 'retailer_count', 'is_active', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private distributorService: DistributorService,
    private _commonService: CommonService,
    private _authService: AuthService,
    private _router: Router,
    private _roleService: RoleService,
    private _storageService: StorageService,
    private dialog: MatDialog,
    private _siteConfig: SiteConfigService) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();
    if (this.role != 1) {
      this.getPermission();
    }

    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "pageIndex": '0',
          "pageSize": this.pageSize,
        }
        this.loadDistributor(event);
      }
    });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;
      self.marked = self.permissionsData[13].sub_permission[1].isSelected;

      self.marked1 = self.permissionsData[13].sub_permission[2].isSelected;

      self.marked2 = self.permissionsData[13].sub_permission[3].isSelected;

      if (self.permissionsData[13].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });

  }

  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }
  loadDistributor(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self.distributorService.getAllDistributor(event, this.value, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.distributorData = new MatTableDataSource<DistributorVM>(response.data);
            if (response.data.length > 0) {
              self.totalLength = response.data[0].totalcount;
            }
            self.noData = (response.data.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }
  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }
  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }
  editDistributor(distributor: DistributorVM) {
    this._router.navigate(['/admin/distributor/edit', distributor.distributor_id]);
  }
  viewDistributor(distributor: DistributorVM) {
    this._router.navigate(['/admin/distributor/view', distributor.distributor_id]);
  }
  deleteDistributor(distributor: DistributorVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this distributor?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    swal.fire(options).then((result) => {
      if (result.value) {
        // code for admin password confirmation.
        const options1: SweetAlertOptions = {
          title: 'Enter Your Password',
          //input: 'text',
          html: '<input id="swal-input1" class="string_trim1" type="password">',
          inputAttributes: {
            autocapitalize: 'off'
          },
          type: 'error',
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          focusCancel: true,
          preConfirm: function () {

            return new Promise(function (resolve, reject) {
              setTimeout(function () {
                let text = $('#swal-input1').val();
                self._authService.checkAdminPassword(text).subscribe(data => {
                  let response: any = data;
                  self._commonService.hideLoader();
                  if (response.success === false) {
                    swal.showValidationMessage('Invalid Password Try Again.');
                    swal.enableButtons();
                  }
                  else {
                    resolve(response)
                  }
                }, error => {
                  self._commonService.hideLoader();
                });
              }, 2000)
            })
          },
          allowOutsideClick: false,
        };
        swal.fire(options1).then((result) => {
          if (result.value != '' && result.value != null) {
            self.distributorService.deleteDistributor(distributor.distributor_id, result.value).subscribe(data => {
              self._commonService.hideLoader();
              self._commonService.showToaster("Distributor deleted successfully.", "success");
              var event: any = {
                "previousPageIndex": '0',
                "pageIndex": '0',
                "pageSize": this.pageSize,
                "length": '0',
              }
              self.loadDistributor(event);
            }, error => {
              self._commonService.hideLoader();
              self._commonService.showToaster(error.message, "error");
              return false;
            });
          } else {
            swal.enableButtons();
          }
        });
      }
    })
  }

  applyFilter(filterValue: any) {

    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadDistributor(event);
  }


  isNullOrEmpty(item) {
    if (item == null || item == '' || item == undefined) {
      return true;
    } else {
      return false;
    }
  }


  convertToNumber(value): number {
    if (!this.isNullOrEmpty(value)) {
      return Number(value);
    } else {
      return Number(0);
    }
  }

  sortData(event) {


    this.columnSorting = event.active;
    this.columnDirection = event.direction;

    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadDistributor(event);
  }

  openPasswordDialog(distributorData: DistributorVM) {
    const dialogRef = this.dialog.open(RetailerPasswordComponent, {
      width: '50%',
      data: { dialogTitle: 'Change Password', DistributerId: distributorData.distributor_id },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }


}
