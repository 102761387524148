import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

// @Services
import { HttpService } from './http.service';
import { AppConfig } from '../shared/app-config';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  apiUrl: String;
  // set http Authorization headers
  httpAuthOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };

  constructor(private _httpService: HttpService,
    private _api: AppConfig) {
    this.apiUrl = this._api.ApiUrl;
  }

  getAllCategories(event, filter, columnSorting, columnDirection) {
    var body = {
      "event": event,
      "filter": filter,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection,
    }
    return this._httpService.post(`${this.apiUrl}categories/getAllCategories`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  addCategory(category) {
    return this._httpService.post(`${this.apiUrl}categories/addCategory`, category, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getCategoryById(id) {
    return this._httpService.get(`${this.apiUrl}categories/getCategoryById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  updateCategory(category) {
    return this._httpService.post(`${this.apiUrl}categories/updateCategory`, category, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  deleteCategory(id) {
    return this._httpService.post(`${this.apiUrl}categories/deleteCategory/${id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  uploadFile(file) {
    return this._httpService.post(`${this.apiUrl}categories/uploadFile`, file, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
}
