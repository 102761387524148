import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { RoleService } from 'src/app/services/role.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubCategoryService } from 'src/app/services/subcategory.service';

@Component({
  selector: 'app-partnerview',
  templateUrl: './partnerview.component.html',
  styleUrls: ['./partnerview.component.css']
})


export class PartnerviewComponent implements OnInit {

  id: number;
  partnerData:any;


  constructor(private _subcategory: SubCategoryService,
    private _commonService: CommonService,
    private _router: Router,
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _siteConfigService: SiteConfigService,
    private _roleService: RoleService,
    private _storageService: StorageService) { }

  ngOnInit() {

    this._route.params.subscribe(params => { this.id = params['id'];
    this.getPartnerDetailById(this.id);
    });

  }

  getPartnerDetailById(id){

    let self = this;
    self._subcategory.getPartnerById(id)
    .subscribe(data => {

      let response: any = data;
      //debugger;
      this.partnerData = response.data[0];

    });


  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

}
