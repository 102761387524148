import { Component, OnInit, ViewChild } from '@angular/core';
import { SubCategoryService } from 'src/app/services/subcategory.service';
import { CommonService } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { InquieryVM } from 'src/app/models/InquieryVM';
@Component({
  selector: 'app-inqueries',
  templateUrl: './inqueries.component.html',
  styleUrls: ['./inqueries.component.css']
})
export class InqueriesComponent implements OnInit {

  getSiteConfig: any;
  pageSize: number = 50;
  inquiersData: any;
  inquieries: any;
  noData = false;
  displayedColumns: string[];
  loginUserId: any;
  marked = false;
  marked1 = false;
  marked2 = false;
  pageIndex: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columnSorting: any;
  columnDirection: any;
  totalLength: any;
  value: '';
  filterValue: '';

  constructor(private _commonService: CommonService,
    private _subcategory: SubCategoryService,
    private _siteConfigService: SiteConfigService) {

  }

  ngOnInit() {

    this._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        this.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.loadSubCategories(event);
      }
    });
    this.displayedColumns = ['first_name', 'last_name', 'city', 'mobile', 'email', 'IsActive', 'created_at', 'actions'];

  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadSubCategories(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._subcategory.getAllInquieries(event, this.value, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.inquiersData = new MatTableDataSource<InquieryVM>(response.data);
            if (response.data != null && response.data.length > 0) {
              self.inquieries = self.inquiersData.data;
              self.totalLength = self.inquieries[0].totalcount;
            }
            self.noData = (response.data != null && response.data.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  viewInquiery($id = 0) {
  }

  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadSubCategories(event);
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;
    
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadSubCategories(event);
  }

}
