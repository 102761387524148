export class CountryNewVM {
  id: number
  name: string
}

export class StateNewVM {
  id: number
  name: string
}



