import { Component, OnInit } from '@angular/core';
import { ItemService } from 'src/app/services/item.service';
import { CommonService } from 'src/app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { Lightbox } from 'ngx-lightbox';
import { StorageService } from 'src/app/services/storage.service';
//model
import { ItemVM } from 'src/app/models/ItemVM';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-item-distributor-view',
  templateUrl: './item-distributor-view.component.html',
  styleUrls: ['./item-distributor-view.component.css']
})
export class ItemDistributorViewComponent implements OnInit {

  id: number;
  ItemVM = new ItemVM();
  submitted = false;
  _albums = new Array();
  show = false;
  show1 = false;
  reasonData: string;
  role: number;
  permissionsData = [];
  constructor(private _itemService: ItemService,
    private _commonService: CommonService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _siteConfigService: SiteConfigService, private _lightbox: Lightbox,
    private _roleService: RoleService,
    private _storageService: StorageService) {
  }

  ngOnInit() {
    this.role = this._commonService.convertToNumber(this._storageService.getUserRoles());
    if (this.role != 1) {
      this.getPermission();
    }
    this.getItemDetailById();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      //check permission
      if (self.permissionsData[16].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getItemDetailById() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._itemService.getItemDetailById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (response.item_detail.item_gallery_data.length > 0) {
          for (let i = 0; i < response.item_detail.item_gallery_data.length; i++) {
            if (response.item_detail.item_gallery_data[i].original_image_path != null && response.item_detail.item_gallery_data[i].process_image_path) {
              const src = this._commonService.getFullImageUrl(response.item_detail.item_gallery_data[i].original_image_path);
              const caption = response.item_detail.item_gallery_data[i].image_details;
              const thumb = this._commonService.getFullImageUrl(response.item_detail.item_gallery_data[i].process_image_path);
              const album = {
                src: src == null ? '' : src,
                caption: caption == undefined ? '' : caption,
                thumb: thumb == null ? '' : thumb
              };
              this._albums.push(album);
            }
          }
        }
        self.ItemVM = response.item_detail;
      });
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  goToDistributorItems() {
    this._router.navigate(['/admin/distributor-items']);
  }

  showRejectReason() {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Reason',
      html: self.ItemVM.reject_reason,
      confirmButtonText: "Close"
    };
    swal.fire(options);
  }

  approveItem(item: ItemVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to approve this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._itemService.approveItem(item.item_id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Item approved successfully.", "success");
          self.goToDistributorItems();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  rejectItem(item: ItemVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to reject this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };

    swal.fire(options).then((result) => {
      if (result.value) {
        // code for admin password confirmation.
        const options1: SweetAlertOptions = {
          title: 'Rejection Reason',
          input: 'textarea',
          inputAttributes: {
            autocapitalize: 'off'
          },
          type: 'question',
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          focusCancel: true,
          preConfirm: function (textarea) {
            return new Promise(function (resolve, reject) {
              setTimeout(function () {
                if (textarea != '' && textarea != null) {
                  self.reasonData = textarea;
                  resolve()
                } else {
                  swal.showValidationMessage('Please Enter Reason');
                  swal.enableButtons();
                }
              }, 2000)
            })
          },
          allowOutsideClick: false,
        };
        swal.fire(options1).then((result) => {
          if (result.value != '' && result.value != null) {
            self._commonService.showLoader();
            let formReasonData = new FormData();
            formReasonData.delete('reject_reason');
            formReasonData.append('reject_reason', self._commonService.parseString(self.reasonData));

            self._itemService.rejectItem(item.item_id, formReasonData).subscribe(data => {
              self._commonService.hideLoader();
              self._commonService.showToaster("Item rejected successfully.", "success");
              self.goToDistributorItems();
            }, error => {
              self._commonService.hideLoader();
              self._commonService.showToaster(error.message, "error");
            });
          }
        })
      }
    })
  }

}
