// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { Router } from '@angular/router';
import { Options } from 'select2';

// @Services
import { SiteConfigService } from 'src/app/services/site-config.service';
import { REGEXP } from 'src/app/shared/regexp';
import { CommonService } from 'src/app/services/common.service';
import { CompanyService } from 'src/app/services/company.service';
import { WarehouseService } from 'src/app/services/warehouse.service';

// @Models
import { SiteConfigVM } from 'src/app/models/SiteConfigVM';
import { WarehouseVM } from 'src/app/models/WarehouseVM';
import { TimezoneVM } from 'src/app/models/TimezoneVM';
import { CompanyVM } from 'src/app/models/CompanyVM';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { StorageService } from 'src/app/services/storage.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

declare var $: any;

@Component({

  selector: 'app-site-config',
  templateUrl: './site-config.component.html',
  styleUrls: ['./site-config.component.css']
})
export class SiteConfigComponent implements OnInit {


  siteConfigForm: FormGroup;
  siteConfig = new SiteConfigVM();
  submitted = false;
  companyData: CompanyVM[];
  warehouseData: WarehouseVM[];
  timezoneData: TimezoneVM[];
  siteLogoImageUrl: string;
  loginLogoImageUrl: string;
  billerLogoImageUrl: string;

  @ViewChild("site_logo", { static: false }) siteLogoFileInput;
  @ViewChild("login_logo", { static: false }) loginLogoFileInput;
  @ViewChild("biller_logo", { static: false }) billerLogoFileInput;
  //Select 2
  public select2Options: Options;
  public select2TimezoneData: Array<Select2OptionData>;
  public select2WarehouseData: Array<Select2OptionData>;
  public select2CompanyData: Array<Select2OptionData>;

  constructor(private _siteConfigService: SiteConfigService,
    private _formBuilder: FormBuilder,
    private _regex: REGEXP,
    private _commonService: CommonService,
    private _companyService: CompanyService,
    private _warehouseService: WarehouseService,
    private _storageService: StorageService
  ) { }

  ngOnInit() {
    this.buildForm();
    this.getSiteConfigData();
  }

  getActiveCompanyData() {
    let self = this;
    self._companyService.getActiveCompanyData().subscribe(data => {
      let response: any = data;
      if (response.company_data != null) {
        self.companyData = response.company_data;
        self.select2CompanyData = self._commonService.dropdownArray(self.companyData, "company_id", "company_name");
      }
    });
  }

  getActiveWarehouseData() {
    let self = this;
    self._warehouseService.getActiveWarehouseData().subscribe(data => {
      let response: any = data;
      if (response.warehouse_data != null) {
        self.warehouseData = response.warehouse_data;
        self.select2WarehouseData = self._commonService.dropdownArray(self.warehouseData, "warehouse_id", "ware_house_name");
      }
    });
  }

  getAllTimezoneData() {
    let self = this;
    self._siteConfigService.getAllTimezoneData().subscribe(data => {
      let response: any = data;
      if (response.timezone_data != null) {
        self.timezoneData = response.timezone_data;
        self.select2TimezoneData = self._commonService.dropdownArray(self.timezoneData, "timezone", "timezone");
      }
    });
  }

  getSiteConfigData() {
    let self = this;
    self._commonService.showLoader();
    Promise.all([
      self.getActiveCompanyData(),
      self.getActiveWarehouseData(),
      self.getAllTimezoneData()
    ]).then((data: any) => {
      self._siteConfigService.getSiteConfigData().subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (response.data != null) {
          self.siteConfig = response.data;
          self._storageService.setSiteConfigsiteLogo(self.siteConfig.site_logo);
          self._storageService.setSiteConfigsiteName(self.siteConfig.site_name);
          self._storageService.setSiteConfigDateFormatTerm(self.siteConfig.date_format_term);
          self._storageService.setSiteConfigbillerLogo(self.siteConfig.biller_logo);
          self.buildForm();
          $('select').formSelect();
        }
      });
    });
  }

  buildForm() {
    let siteConfigFormGroup = {
      company_id: [this._commonService.convertToNumber(this.siteConfig.company_id)],
      site_name: [this.siteConfig.site_name],
      admin_item_code: [this.siteConfig.admin_item_code],
      language_term: [this.siteConfig.language_term],
      default_currency_term: [this.siteConfig.default_currency_term],
      acconting_method_term: [this.siteConfig.acconting_method_term],
      default_email: [this.siteConfig.default_email, [Validators.pattern(this._regex.EMAIL_REGEXP)]],
      default_customer_group_term: [this.siteConfig.default_customer_group_term],
      default_row_perpage_term: [this.siteConfig.default_row_perpage_term],
      date_format_term: [this.siteConfig.date_format_term],
      timezone_term: [this.siteConfig.timezone_term],
      default_warehouse_id: [this._commonService.convertToNumber(this.siteConfig.default_warehouse_id)],
      default_biller_id: [this.siteConfig.default_biller_id],
      max_upload_images: [this.siteConfig.max_upload_images, [Validators.required]],
      max_upload_scan_images: [this.siteConfig.max_scan_upload_images, [Validators.required]],
      max_png_upload_images: [this.siteConfig.max_png_upload_images, [Validators.required]],
      image_size_width: [this.siteConfig.image_size_width, [Validators.pattern(this._regex.NUMBER_REGEXP)]],
      image_size_height: [this.siteConfig.image_size_height, Validators.pattern(this._regex.NUMBER_REGEXP)],
      thumb_nail_width: [this.siteConfig.thumb_nail_width, Validators.pattern(this._regex.NUMBER_REGEXP)],
      thumb_nail_height: [this.siteConfig.thumb_nail_height, Validators.pattern(this._regex.NUMBER_REGEXP)],
      is_watermark_enable: [this.siteConfig.is_watermark_enable],
      decimals: [this.siteConfig.decimals, Validators.pattern(this._regex.NUMBER_REGEXP)],
      qty_decimals: [this.siteConfig.qty_decimals, Validators.pattern(this._regex.NUMBER_REGEXP)],
      decimal_seprator_term: [this.siteConfig.decimal_seprator_term],
      thousands_seprator_term: [this.siteConfig.thousands_seprator_term],
      email_protocol_term: [this.siteConfig.email_protocol_term],
      smtp_host: [this.siteConfig.smtp_host],
      smtp_user: [this.siteConfig.smtp_user],
      smtp_password: [this.siteConfig.smtp_password],
      smtp_port: [this.siteConfig.smtp_port],
      smtp_crypto: [this.siteConfig.smtp_crypto],
      last_updated_on: [this.siteConfig.last_updated_on],
      barcode_symbology_term: [this.siteConfig.barcode_symbology_term]
    };

    if (this._commonService.isNullOrEmpty(this.siteConfig.site_logo)) {
      siteConfigFormGroup['site_logo'] = [this.siteConfig.site_logo, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
    } else {
      siteConfigFormGroup['site_logo'] = ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
      this.siteLogoImageUrl = this._commonService.getFullImageUrl(this.siteConfig.site_logo);
    }
    if (this._commonService.isNullOrEmpty(this.siteConfig.login_logo)) {
      siteConfigFormGroup['login_logo'] = [this.siteConfig.login_logo, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
    } else {
      siteConfigFormGroup['login_logo'] = ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
      this.loginLogoImageUrl = this._commonService.getFullImageUrl(this.siteConfig.login_logo);
    }
    if (this._commonService.isNullOrEmpty(this.siteConfig.biller_logo)) {
      siteConfigFormGroup['biller_logo'] = [this.siteConfig.biller_logo, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
    } else {
      siteConfigFormGroup['biller_logo'] = ['', [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
      this.billerLogoImageUrl = this._commonService.getFullImageUrl(this.siteConfig.biller_logo);
    }

    this.siteConfigForm = this._formBuilder.group(siteConfigFormGroup);;

  }

  // convenience getter for easy access to form fields
  get f() { return this.siteConfigForm.controls; }

  save() {
    let self = this;
    self.submitted = true;

    // stop here if form is invalid
    if (self.siteConfigForm.invalid) {
      this._commonService.scrollToTop();
      return;
    }

    let formData = new FormData();

    let siteConfigData = (self.siteConfigForm.value as SiteConfigVM);
    for (const prop in siteConfigData) {
      if (!siteConfigData.hasOwnProperty(prop)) { continue; }

      if (prop == 'site_logo' || prop == 'login_logo' || prop == 'biller_logo') {
        if (prop == 'site_logo') {
          let fi = this.siteLogoFileInput.nativeElement;
          if (fi.files && fi.files[0]) {
            let fileToUpload = fi.files[0];
            self.siteConfigForm.value.site_logo = fileToUpload;
            formData.append(prop, fileToUpload, (fileToUpload as File).name);
          } else {
            let file = new File([], ''); //empty file.
            formData.append(prop, file, "");
          }
        }
        if (prop == 'login_logo') {
          let fi = this.loginLogoFileInput.nativeElement;
          if (fi.files && fi.files[0]) {
            let fileToUpload = fi.files[0];
            self.siteConfigForm.value.login_logo = fileToUpload;
            formData.append(prop, fileToUpload, (fileToUpload as File).name);
          } else {
            let file = new File([], ''); //empty file.
            formData.append(prop, file, "");
          }
        }
        if (prop == 'biller_logo') {
          let fi = this.billerLogoFileInput.nativeElement;
          if (fi.files && fi.files[0]) {
            let fileToUpload = fi.files[0];
            self.siteConfigForm.value.biller_logo = fileToUpload;
            formData.append(prop, fileToUpload, (fileToUpload as File).name);
          } else {
            let file = new File([], ''); //empty file.
            formData.append(prop, file, "");
          }
        }
      } else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }
    }
    self._commonService.showLoader();
    self._siteConfigService.saveSiteConfig(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        let response: any;
        response = data;
        self._commonService.showToaster("Site configuration saved successfully.", "success");
        // window.location.reload();
        self.getSiteConfigData();
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

}
