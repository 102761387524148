import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/validator/must-match.validator';
import { AppConfig } from 'src/app/shared/app-config';

//@Services
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user.service';
import { REGEXP } from 'src/app/shared/regexp';
//@Models
import { ResetPasswordVM } from 'src/app/models/ResetPasswordVM';

@Component({
  selector: 'app-reset-password-view',
  templateUrl: './reset-password-view.component.html',
  styleUrls: ['./reset-password-view.component.css']
})
export class ResetPasswordViewComponent implements OnInit {

  token: string;
  email: string;
  submitted = false;
  resetPasswordForm: FormGroup;
  resetPasswordVM = new ResetPasswordVM();
  SubmitButtonText = "Submit";
  public isValidDate: Boolean;
  public errorMessage: String;
  baseURL: string;

  constructor(public router: Router,
    private _regex: REGEXP,
    private _formBuilder: FormBuilder,
    private _userService: UserService,
    private _commonService: CommonService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.buildForm();
  }



  // getForgotPasswordDetails() {
  //   return new Promise((resolve, reject) => {
  //     let self = this;
  //     self._userService.getForgotPasswordDetail(this.userId, this.expiryDate).subscribe(data => {
  //       resolve({ data });
  //     }, error => {
  //       resolve({ error });
  //     });
  //   });
  // }

  buildForm() {
    this._route.params.subscribe(params => { this.token = params['token']; });
    this.resetPasswordVM.email = this.email;
    this.resetPasswordForm = this._formBuilder.group({
      email: [this.resetPasswordVM.email],
      token: [this.token],
      newPassword: [this.resetPasswordVM.newPassword, [Validators.required, Validators.minLength(6), Validators.maxLength(14)]],
      confirmPassword: [this.resetPasswordVM.confirmPassword, [Validators.required, Validators.minLength(6)]],
    }, {
      validator: MustMatch('newPassword', 'confirmPassword')
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.resetPasswordForm.controls; }

  goToLoginPage() {
    this.router.navigate(['/login']);
  }

  resetPassword() {

    let self = this;
    self.submitted = true;

    //stop here if form is invalid
    if (self.resetPasswordForm.invalid) {
      return;
    }

    self.SubmitButtonText = "Processing... <i class='fa fa-spinner fa-spin'>";

    self.SubmitButtonText = "Submit";

    self._commonService.showLoader();
    self._userService.resetPassword(self.resetPasswordForm.value).subscribe(data => {
      self.SubmitButtonText = "Submit";
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self._commonService.showToasterTopCenter("Password changed successfully.", "success");
        this.goToLoginPage();
      }
    }, error => {
      self.SubmitButtonText = "Submit";
      self._commonService.hideLoader();
      self._commonService.showToasterTopCenter(error.message, "error");
    });
  }


  keyDownResetPassword(event) {

    if (event.keyCode == 13) {
      this.resetPassword();
    }
  }

}
