import { Subscription } from "rxjs";
import { ItemVM } from "./ItemVM";
import { RetailerVM } from "./RetailerVM";

export class CustomerVM {
    id: any;
    association_id: string
    association_type_term: string
    first_name: string
    last_name: string
    gender: string
    distributer_id: string
    mobile_no: string
    email: string
    user_name: string
    password: string
    user_key: string
    display_name: string
    status_term: string
    last_login_date: string
    is_active: number
    update_log: string
    user_detail: string
    profile_pic_path: string
    thumbnail_path: string
    remember_token: string
    email_verified_at: string
    created_at: string
    updated_at: string
}

export class CustomerDetailVM {
    details: RetailerVM = new RetailerVM();
    items: ItemVM[] = [];
    subscription: Subscription = new Subscription();
}