import { CategoryVM } from './CategoryVM';

export class AttributeVM {
    attribute_id: number
    attribute_name: string
    attribute_code: string
    attribute_details: string
    is_active: number
    default_value: string
    input_type_term: string
    update_log?: string
    distributor_id:number
    created_at: string
    updated_at?: string
    category?: CategoryVM
}
