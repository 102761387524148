// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2'
// @Services
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
// @Models
import { CategoryVM } from 'src/app/models/CategoryVM';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {
  addCategoryForm: FormGroup;
  getSiteConfig: any;
  pageIndex: number;
  pageSize: number;
  pageEvent: PageEvent;
  totalLength: number;
  categoriesData: any;
  distributor: any;
  role: any;
  permissionsData = [];
  noData = false;
  loginUserId: any;
  displayedColumns: string[];
  filterValue: '';
  value: '';
  marked = false;
  marked1 = false;
  marked2 = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columnSorting: any;
  columnDirection: any;

  constructor(private _categoryService: CategoryService,
    private _commonService: CommonService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _storageService: StorageService,
    private _router: Router,
    private _roleService: RoleService,
    private _siteConfigService: SiteConfigService) {
  }
  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.getUserActive();
    this._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        this.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.loadCategories(event);
      }
    });


    this.distributor = this._storageService.getDistributorId();

    this.displayedColumns = ['category_name', 'category_code', 'display_name', 'sequence_no', 'category_image', 'item_count', 'is_active', 'created_at', 'actions'];

  }


  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;

      self.marked = self.permissionsData[3].sub_permission[1].isSelected;

      self.marked1 = self.permissionsData[3].sub_permission[2].isSelected;

      self.marked2 = self.permissionsData[3].sub_permission[3].isSelected;

      if (self.permissionsData[3].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }
  loadCategories(event) {
    let self = this;
    self._commonService.showLoader();

    Promise.all(
      [
        this.getSiteConfigData(),


      ]).then((data: any) => {

        self._categoryService.getAllCategories(event, this.value, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.categoriesData = new MatTableDataSource<CategoryVM>(response.data);
            if (response.data.length > 0) {
              self.totalLength = response.data[0].totalcount;
            }
            self.noData = (response.data.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }
  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }
  editCategory(category: CategoryVM) {
    this._router.navigate(['/admin/categories/edit', category.category_id]);
  }
  deleteCategory(category: CategoryVM) {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        } else {
          const options: SweetAlertOptions = {
            title: 'Are you sure you want to delete this category and associated items?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            focusCancel: true
          };
          swal.fire(options).then((result) => {
            if (result.value) {
              self._commonService.showLoader();
              self._categoryService.deleteCategory(category.category_id).subscribe(data => {
                self._commonService.hideLoader();
                self._commonService.showToaster("Category deleted successfully.", "success");
                var event: any = {
                  "previousPageIndex": '0',
                  "pageIndex": '0',
                  "pageSize": this.pageSize,
                  "length": '0',
                }
                self.loadCategories(event);
              }, error => {
                self._commonService.hideLoader();
                self._commonService.showToaster(error.message, "error");
              });
            }
          })
        }
      });
  }
  // applyFilter(filterValue: string) {
  //   this.categoriesData.filter = filterValue.trim().toLowerCase();
  //   this.noData = (this.categoriesData._paginator._length > 0) ? false : true;
  // }
  applyFilter(filterValue: any) {

    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadCategories(event);
  }



  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;

    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadCategories(event);
  }
}
