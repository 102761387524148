import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';

// @Models
import { AccessoryVM } from 'src/app/models/AccessoryVM';

// @Services
import { AccessoryService } from 'src/app/services/accessory.service';
import { CommonService } from 'src/app/services/common.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-accessories-edit',
  templateUrl: './accessories-edit.component.html',
  styleUrls: ['./accessories-edit.component.css']
})
export class AccessoriesEditComponent implements OnInit {

  @ViewChild("accessory_image", { static: false }) accessoryimageFileInput;

  id: number;
  editAccessoryForm: FormGroup;
  AccessoryVM = new AccessoryVM();
  accessoryimageurl: string;
  submitted = false;
  loginUserId: any;
  role: any;
  permissionsData = [];

  constructor(private _formBuilder: FormBuilder,
    private accessoryService: AccessoryService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _roleService: RoleService,
    private _storageService: StorageService,
    private _commonService: CommonService,
    private _router: Router,
    private _route: ActivatedRoute,) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.getUserActive();
    this.getAccessory();
    this.buildForm();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      self.permissionsData = list;

      if (self.permissionsData[8].sub_permission[2].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });

  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  get f() { return this.editAccessoryForm.controls; }

  gotoAccessoryList() {
    this._router.navigate(['/admin/accessories']);
  }

  getAccessory() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self.accessoryService.getAccessoryById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.AccessoryVM = response.accessory;
        self.buildForm();
      });
  }

  buildForm() {

    let AccessoryFormData = {
      accessory_id: [this._commonService.convertToNumber(this.AccessoryVM.accessory_id)],
      accessory_code: [this.AccessoryVM.accessory_code, [Validators.required, Validators.maxLength(255)]],
      accessory_name: [this.AccessoryVM.accessory_name, [Validators.required, Validators.maxLength(255)]],
      details: [this.AccessoryVM.details],
      unit_of_measure_term: [this.AccessoryVM.unit_of_measure_term, [Validators.required, Validators.maxLength(255)]],
      cost_per_unit: [this.AccessoryVM.cost_per_unit, [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      display_name: [this.AccessoryVM.display_name, [Validators.required, Validators.maxLength(255)]],
      labour_rate: [this.AccessoryVM.labour_rate, [Validators.required, Validators.maxLength(25), RxwebValidators.numeric({ allowDecimal: true })]],
      is_active: [this.AccessoryVM.is_active, Validators.required]
    }

    if (this._commonService.isNullOrEmpty(this.AccessoryVM.accessory_image)) {
      AccessoryFormData['accessory_image'] = [this.AccessoryVM.accessory_image, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
    } else {
      AccessoryFormData['accessory_image'] = [this.AccessoryVM.accessory_image, [requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
      this.accessoryimageurl = this._commonService.getFullImageUrl(this.AccessoryVM.accessory_image);
    }
    this.editAccessoryForm = this._formBuilder.group(AccessoryFormData);
  }
  save() {
    let self = this;
    self.submitted = true;

    if (self.editAccessoryForm.invalid) {
      return;
    }
    let formData = new FormData();

    let accessoryData = (self.editAccessoryForm.value as AccessoryVM);
    for (const prop in accessoryData) {
      let fi = self.accessoryimageFileInput.nativeElement;
      if (prop == 'accessory_image') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.editAccessoryForm.value.accessory_image = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, self._commonService.parseString(accessoryData[prop]));
      }
    }

    self._commonService.showLoader();
    self.accessoryService.updateAccessory(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoAccessoryList();
        self._commonService.showToaster("Accessory updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

}
