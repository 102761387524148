import { Component, OnInit, ViewChild } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { CommonService, DateFormat, DateSeprator } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { CustomOrderVM } from 'src/app/models/CustomOrderVM';
import { SalesOrderService } from 'src/app/services/sales-order.service';
import { CustomizeItemService } from 'src/app/services/customize-item.service';
import { RoleService } from 'src/app/services/role.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-customize-items-list',
  templateUrl: './customize-items-list.component.html',
  styleUrls: ['./customize-items-list.component.css']
})
export class CustomizeItemsListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  customizeOrderData: any;
  noData = false;
  role: any;
  roleType: any;
  displayedColumns: string[];
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  filterValue: '';
  value: '';
  permissionsData = [];
  marked = false;
  dateselect?: any;
  availableItem?: any;
  columnSorting: any;
  columnDirection: any;
  applyseacrhCustomizeOrderForm: FormGroup;
  search: { orderDate: any; deliveryDate: any; selectStatus: any; };
  orderdate: { start: string; end: string; };
  deliverydate: { start: string; end: string; };

  constructor(
    private _commonService: CommonService,
    private _customizeService: CustomizeItemService,
    private _router: Router,
    private _storageService: StorageService,
    private _roleService: RoleService,
    private _siteConfig: SiteConfigService,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();
    if (this.role != 1) {
      this.getPermission();
    }
    this._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        this.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.getAllCustomizeOrders(event);
      }
    });
    if (this.role == 1) {
      this.displayedColumns = ['order_date', 'dis_displayName', 'item_name', 'customer','category_name', 'actual_date_of_delivery', 'order_no', 'order_status', 'item_image', 'actions'];
    } else {
      this.displayedColumns = ['order_date', 'item_name', 'customer','category_name', 'actual_date_of_delivery', 'order_no', 'order_status', 'item_image', 'actions'];
    }
    this.createCustomizeOrderForm();
  }

  createCustomizeOrderForm() {
    this.applyseacrhCustomizeOrderForm = this._formBuilder.group({
      orderDate: [''],
      deliveryDate: [''],
      selectStatus: [''],
    });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      //check permission
      self.marked = self.permissionsData[18].sub_permission[1].isSelected;
      if (self.permissionsData[18].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  viewCustomizeOrderDetail(orderdetail: CustomOrderVM) {
    this._router.navigate(['/admin/customize-order-detail', orderdetail.custom_order_id], { queryParams: { id: orderdetail.custom_order_id } });
  }

  getAllCustomizeOrders(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._customizeService.getAllCustomizeOrders(event, this.value, this.dateselect, this.availableItem, this.search, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.customizeOrderData = new MatTableDataSource<CustomOrderVM>(response.data);
            if (response.data.length > 0) {
              self.totalLength = response.data[0].totalcount;
            }
            self.noData = (response.data.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllCustomizeOrders(event);
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  // choosedDate(date) {
  //   this.dateselect = date;
  //   var event: any = {
  //     "previousPageIndex": '0',
  //     "pageIndex": '0',
  //     "pageSize": this.pageSize,
  //     "length": '0',
  //   }
  //   this.getAllCustomizeOrders(event);
  // }

  // assignOrder(event) {
  //   if (event.isUserInput) {
  //     this.dateselect = '';
  //     this.availableItem = '';
  //     this.value = '';
  //     var event: any = {
  //       "previousPageIndex": '0',
  //       "pageIndex": '0',
  //       "pageSize": this.pageSize,
  //       "length": '0',
  //     }
  //     this.getAllCustomizeOrders(event);
  //   }
  // }

  // selectAvailable(availableItem) {
  //   this.availableItem = availableItem;
  //   var event: any = {
  //     "previousPageIndex": '0',
  //     "pageIndex": '0',
  //     "pageSize": this.pageSize,
  //     "length": '0',
  //   }
  //   this.getAllCustomizeOrders(event);
  // }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllCustomizeOrders(event);
  }

  applySearch() {
    let self = this;
    if (this.applyseacrhCustomizeOrderForm.controls.orderDate.value.start != null && this.applyseacrhCustomizeOrderForm.controls.orderDate.value.end != null) {
      self.orderdate = {
        start: self._commonService.getDateTimeString(this.applyseacrhCustomizeOrderForm.controls.orderDate.value.start._d, DateFormat.DDMMYYYY, DateSeprator.DASH),
        end: self._commonService.getDateTimeString(this.applyseacrhCustomizeOrderForm.controls.orderDate.value.end._d, DateFormat.DDMMYYYY, DateSeprator.DASH)
      }
    }

    if (this.applyseacrhCustomizeOrderForm.controls.deliveryDate.value.start != null && this.applyseacrhCustomizeOrderForm.controls.deliveryDate.value.end != null) {
      self.deliverydate = {
        start: self._commonService.getDateTimeString(this.applyseacrhCustomizeOrderForm.controls.deliveryDate.value.start._d, DateFormat.DDMMYYYY, DateSeprator.DASH),
        end: self._commonService.getDateTimeString(this.applyseacrhCustomizeOrderForm.controls.deliveryDate.value.end._d, DateFormat.DDMMYYYY, DateSeprator.DASH)
      }
    }
    this.search = {
      "orderDate": (this.applyseacrhCustomizeOrderForm.controls.orderDate.value.start && this.applyseacrhCustomizeOrderForm.controls.orderDate.value.end) == undefined ? "" : self.orderdate,
      "deliveryDate": (this.applyseacrhCustomizeOrderForm.controls.deliveryDate.value.start && this.applyseacrhCustomizeOrderForm.controls.deliveryDate.value.end) == undefined ? "" : self.deliverydate,
      "selectStatus": this.applyseacrhCustomizeOrderForm.controls.selectStatus.value
    }
    // this.search = {
    //   "orderDate": this.applyseacrhCustomizeOrderForm.controls.orderDate.value,
    //   "deliveryDate": this.applyseacrhCustomizeOrderForm.controls.deliveryDate.value,
    //   "selectStatus": this.applyseacrhCustomizeOrderForm.controls.selectStatus.value
    // }
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllCustomizeOrders(event);
  }

  clearSearch() {
    this.value = '';
    this.search = {
      "orderDate": '',
      "deliveryDate": '',
      "selectStatus": '',
    }
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllCustomizeOrders(event);
  }
}