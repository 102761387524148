// @Packages
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// @Models
import { RoleVM } from 'src/app/models/RoleVM';
// @Services
import { RoleService } from 'src/app/services/role.service';
import { CommonService } from 'src/app/services/common.service';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'selenium-webdriver/edge';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.css']
})
export class RoleEditComponent implements OnInit {

  id: number;
  editRoleForm: FormGroup;
  RoleVM = new RoleVM();
  submitted = false;
  public select2Options: Options;
  public roleTypeData: Array<Select2OptionData>;
  public options: Options;

  constructor(private _formBuilder: FormBuilder,
    private _roleService: RoleService,
    private _commonService: CommonService,
    private _router: Router,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this.roleTypeData = [
      {
        id: '1',
        text: 'Admin'
      },
      {
        id: '2',
        text: 'Distributor'
      },
      {
        id: '3',
        text: 'Retailer',
        // disabled: true
      },
      {
        id: '4',
        text: 'Customer',
        disabled: true
      }
    ];
    this.initializeFormValue();
  }

  initializeFormValue() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._roleService.getRoleById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.RoleVM = response.role;
        this.buildForm();
      });
  }

  buildForm() {
    this.editRoleForm = this._formBuilder.group({
      id: [this._commonService.convertToNumber(this.RoleVM.id)],
      code: [this.RoleVM.code, [Validators.required, Validators.maxLength(255)]],
      name: [this.RoleVM.name, [Validators.required, Validators.maxLength(255)]],
      display_name: [this.RoleVM.display_name, [Validators.required, Validators.maxLength(255)]],
      description: [this.RoleVM.description],
      is_active: [this._commonService.convertToNumber(this.RoleVM.is_active), Validators.required],
      role_type: [this._commonService.convertToNumber(this.RoleVM.role_type), Validators.required],
      is_default: [this._commonService.convertToNumber(this.RoleVM.is_default), Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.editRoleForm.controls; }

  gotoRoleList() {
    this._router.navigate(['/admin/roles']);
  }

  save() {
    let self = this;
    self.submitted = true;
    // stop here if form is invalid
    if (self.editRoleForm.invalid) {
      return;
    }
    self._commonService.showLoader();
    self._roleService.updateRole(self.editRoleForm.value).subscribe(data => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoRoleList();
        self._commonService.showToaster("Role updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }
}
