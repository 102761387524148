// @Packages
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

// @Services
import { AppConfig } from '../shared/app-config';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  apiUrl: String;
  // set http Authorization headers
  httpAuthOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };

  constructor(private _httpService: HttpService, private _api: AppConfig) {
    this.apiUrl = this._api.ApiUrl;
  }

  getActiveCompanyData() {
    return this._httpService.get(`${this.apiUrl}getActiveCompanies`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllTermsData() {
    return this._httpService.get(`${this.apiUrl}terms/getAllTerms`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  addterm(role) {
    return this._httpService.post(`${this.apiUrl}terms/addTerms`, role, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getTermById(id) {
    return this._httpService.get(`${this.apiUrl}terms/getTermsById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  updateTerm(company) {
    return this._httpService.post(`${this.apiUrl}terms/updateTerms`, company, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }



  deleteTerm(id) {
    return this._httpService.post(`${this.apiUrl}terms/deleteTerms/${id}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

}
