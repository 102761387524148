import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Select2OptionData } from 'ng-select2';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Options } from 'select2';
import { DistributorVM } from 'src/app/models/DistributorVM';
import { RetailerVM } from 'src/app/models/RetailerVM';
import { UserVM } from 'src/app/models/UserVM';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { CommonService } from 'src/app/services/common.service';
import { RetailerService } from 'src/app/services/retailer.service';
import { StorageService } from 'src/app/services/storage.service';
import { REGEXP } from 'src/app/shared/regexp';
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { MustMatch } from 'src/app/validator/must-match.validator';
import { CountryVM } from 'src/app/models/CountryVM';
import { StateVM } from 'src/app/models/StateVM';
import { DistrictVM } from 'src/app/models/DistrictVM';
import { DistributorService } from 'src/app/services/distributor.service';
import { CountryNewVM, StateNewVM } from 'src/app/models/CommonVM';
import { SubscriptionService } from 'src/app/services/subscriprion.service';
import { SubscriptionVM } from 'src/app/models/SubscriptionVM';

@Component({
  selector: 'app-retailer-edit',
  templateUrl: './retailer-edit.component.html',
  styleUrls: ['./retailer-edit.component.css']
})
export class RetailerEditComponent implements OnInit {

  @ViewChild("RetailerLogo", { static: false }) retailerlogoInput;
  @ViewChild("WatermarkImage", { static: false }) watermarkimageInput;
  @ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;

  public select2CountryData: Array<Select2OptionData>;
  public select2StateData: Array<Select2OptionData>;
  public select2DistrictData: Array<Select2OptionData>;
  
  role: number;
  roleType: number;
  editRetailerForm: FormGroup;
  distributorData: DistributorVM[];
  RetailerVM = new RetailerVM();
  public select2Options: Options;
  UserVM = new UserVM();
  public select2OptionsDistributor: Options;
  public select2DistributorData: Array<Select2OptionData>;
  public select2SubscriptionData: Array<Select2OptionData>;
  subscriptionData: SubscriptionVM[];

  submitted = false;
  loginUserId: any;
  permissionsData = [];
  itemImageUrl: string;
  wateritemImageUrl: string;
  formData = new FormData();
  countrydata: CountryVM[];
  statedata: StateVM[];
  districtdata: DistrictVM[];
  statedatanew: StateNewVM[];
  id: number;
  constructor(
    private _retailerService: RetailerService,
    private _commonService: CommonService,
    private distributorService: DistributorService,
    private _formBuilder: FormBuilder,
    private _regex: REGEXP,
    private _storageService: StorageService,
    private _router: Router,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _subscriptionService: SubscriptionService
  ) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.getUserActive();
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();
    if (this.role != 1) {
      this.getPermission();
    }
    this.select2OptionsDistributor = {
      multiple: false
    }
    this.getActiveDistributors();
        this.getAllCountryData();
    this.getAllStateData();
    // this.getAllDistrictData(); 
    this.getAllDistrictDataDefault();
    this.getAllSubscriptionData();
    this.updateRetailerForm();
    this.getRetailerById();
  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      //check permission
      if (self.permissionsData[24].sub_permission[2].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }



  get f() { return this.editRetailerForm.controls; }

  updateRetailerForm() {
    this.editRetailerForm = this._formBuilder.group({
      // let RetailerForm = {
      RetailerId: [this.RetailerVM.RetailerId],
      RetailerName: [this.RetailerVM.RetailerName, [Validators.required, Validators.maxLength(260)]],
      DistributerId: [this.RetailerVM.DistributerId, [Validators.required]],
      RetailerLogo: [this.RetailerVM.RetailerLogo, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      WatermarkImage: [this.RetailerVM.WatermarkImage, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      RetailerAddress: [this.RetailerVM.RetailerAddress, [Validators.required, Validators.maxLength(8790)]],
      ContactName: [this.RetailerVM.ContactName, [Validators.required, Validators.maxLength(160)]],
      ContactMobNo: [this.RetailerVM.ContactMobNo, [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
      ContactEmail: [this.RetailerVM.email, [Validators.required, Validators.pattern(this._regex.EMAIL_REGEXP)]],
      GstNo: [this.RetailerVM.GstNo, [Validators.required, Validators.maxLength(369)]],
      IsActive: [this.RetailerVM.IsActive, Validators.required],
      
      country: [this.RetailerVM.rcnt, Validators.required],
      state: [this.RetailerVM.rst, [Validators.required, Validators.maxLength(255)]],
      city: [this.RetailerVM.rct, [Validators.required, Validators.maxLength(255)]],

      postal_code: [this.RetailerVM.rpostal_code, RxwebValidators.alphaNumeric()],
      subscription_id: [this.RetailerVM.subscription_id, Validators.required],
      is_item_show: [this.RetailerVM.is_item_show == null ? 0 : this.RetailerVM.is_item_show]
    });
    // }

    if (this._commonService.isNullOrEmpty(this.RetailerVM.RetailerLogo)) {
      this.itemImageUrl = ''
    } else {
      this.itemImageUrl = this._commonService.getFullImageUrl(this.RetailerVM.RetailerLogo);
    }
    
      if (this._commonService.isNullOrEmpty(this.RetailerVM.WatermarkImage)) {
      this.wateritemImageUrl = ''
    } else {
      this.wateritemImageUrl = this._commonService.getFullImageUrl(this.RetailerVM.WatermarkImage);
    }

    // if (this._commonService.isNullOrEmpty(this.RetailerVM.RetailerLogo)) {
    //   RetailerForm['RetailerLogo'] = [this.RetailerVM.RetailerLogo, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
    // } else {
    //   RetailerForm['RetailerLogo'] = [this.RetailerVM.RetailerLogo, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]];
    //   this.itemImageUrl = this._commonService.getFullImageUrl(this.RetailerVM.RetailerLogo);
    // }
    // this.editRetailerForm = this._formBuilder.group(RetailerForm);
  }

  getAllDistrictDataDefault() {
    let self = this;
    // self.distributorService.getAllDistrictData().subscribe(data => {
      self.distributorService.getAllDistrictDataById(12).subscribe(data => {  
      let response: any = data;
      if (response.data != null) {
       // self.districtdata = response.district_data;
       self.districtdata = response.data;
        self.select2DistrictData = self._commonService.dropdownArray(self.districtdata, "id", "city_name");
      }
    });
  }


  getActiveDistributors() {
    let self = this;
    self._retailerService.getAllActiveDistributor().subscribe(data => {
      let response: any = data;
      if (response.distributor_data != null) {
        self.distributorData = response.distributor_data;
        self.select2DistributorData = self._commonService.dropdownArrayDistributor(self.distributorData, "distributor_id", "display_name");
      }
    });
  }

  getAllSubscriptionData() {
    let self = this;
    self._subscriptionService.getAllActiveSubscription().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.subscriptionData = response.data;
        self.select2SubscriptionData = self._commonService.dropdownArray(self.subscriptionData, "SubscriptionId", "SubscriptionName");
      }
    });
  }

  public handleAddressChange(RetailerAddress) {
    this.editRetailerForm.controls['RetailerAddress'].setValue(RetailerAddress.formatted_address);
  }

  getRetailerById() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._retailerService.getRetailerById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.RetailerVM = response.data[0];
        self.updateRetailerForm();
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }


  save() {
    let self = this;
    self.submitted = true;
    if (self.editRetailerForm.invalid) {
      return;
    }
    // stop here if form is invalid
    let formData = new FormData();
    let siteConfigData = (self.editRetailerForm.value as RetailerVM);
    
    if (self.editRetailerForm.controls.is_item_show.value == true) {
      formData.append("is_item_show", "1");
    }
    else {
      formData.append("is_item_show", "0");
    }

    for (const prop in siteConfigData) {
      let fi = this.retailerlogoInput.nativeElement;
      let fiwater = this.watermarkimageInput.nativeElement;
      if (prop == 'RetailerLogo') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.editRetailerForm.value.RetailerLogo = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      }
      else if (prop == 'WatermarkImage') {
        if (fiwater.files && fiwater.files[0]) {
          let fileToUploadwater = fiwater.files[0];
          self.editRetailerForm.value.WatermarkImage = fileToUploadwater;
          formData.append(prop, fileToUploadwater, (fileToUploadwater as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      }
      
       else {
        formData.append(prop, self._commonService.parseString(siteConfigData[prop]));
      }
    }
    self._commonService.showLoader();
    self._retailerService.updateRetailer(formData).subscribe((data: any) => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        if (this.roleType == 1) {
          if (data.DistributerId == 1) {
            this._router.navigate(['/admin/retailer']);
          }
          else {
            this._router.navigate(['/admin/distributor-retailer']);
          }
        }
        else {
          this._router.navigate(['/admin/retailer']);
        }
        self._commonService.showToaster("Retailer updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }


  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

  gotoRetailerList() {
    this._router.navigate(['/admin/retailer']);
  }
 
  getAllCountryData() {
    var mCountryNewVM = new CountryVM();
    mCountryNewVM.id = 101;
    mCountryNewVM.name = "India";
    this.countrydata = new Array();
    this.countrydata.push(mCountryNewVM);
    let temp: any = [];
    this.countrydata.forEach(item => {
      temp.push({
        id: item.id,
        text: item.name,
      })
    });
    this.select2CountryData = temp
  }

  getAllStateData() {
    /*
    var mStateDataNewVM = new StateVM();
    mStateDataNewVM.id = 12;
    mStateDataNewVM.name = "Gujarat";
    this.statedata = new Array();
    this.statedata.push(mStateDataNewVM);
    let temp: any = [];
    this.statedata.forEach(item => {
      temp.push({
        id: item.id,
        text: item.name,
      })
    });
    this.select2StateData = temp */

     
  var mStateDataNewVM = new StateNewVM();
  this.statedatanew = new Array();
  this.statedatanew.push(mStateDataNewVM);
  let temp: any = [];
    let self = this;
    self._commonService.showLoader();
    self.distributorService.getStateById(101).subscribe(data => {
      let response: any;
      response = data;
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self.statedatanew = response.data;
        self.select2StateData = self._commonService.dropdownArray(self.statedatanew, "id", "name");
      }
    }); 

  }

  getAllDistrictData(selectedCode) {
    let self = this;
    // self.distributorService.getAllDistrictData().subscribe(data => {
      self.distributorService.getAllDistrictDataById(selectedCode.data[0].id).subscribe(data => {              
      let response: any = data;
      if (response.data != null) {
        self.districtdata = response.data;
        self.select2DistrictData = self._commonService.dropdownArray(self.districtdata, "id", "city_name");
      }
    });
  }


}
