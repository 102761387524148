import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { StorageService } from 'src/app/services/storage.service';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';
import { RolePermissionVM } from 'src/app/models/RolePermissionVM';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  userCount: number;
  distributorCount: number;
  itemCount: number;
  salesCount: number;
  disitemCount: number;
  role: number;
  roleType: number;
  distributor: number;
  pendingOrderCount: number;
  pendingDistributorItem: number;
  assign_items: number;
  own_items: number;
  activeUser: any;
  permissionsData = [];
  marked = false;
  userSelected = false;
  salesOrderSelected = false;
  mySalesOrderSelected = false;
  itemSelected = false;
  distributorSelected = false;
  distributorItemSelected = false;
  attributeSelected = false;
  customizeorder = false;
  categoriesSelected = false;
  subcategoriesSelected = false;
  metalsSelected = false;
  accessoriesSelected = false;
  warehousesSelected = false;
  puritySelected = false;
  productionHousesSelected = false;
  distributorGroupsSelected = false;
  ItemGroupsSelected = false;
  customizeOrderSelected = false;
  showcaseSelected = false;
  paymetDetailSelected = false;
  retailerSelected = false;
  distributorRetailerSelected = false;
  RolePermissionVM = new RolePermissionVM();
  permission: any[];

  constructor(
    private _dashboardService: DashboardService,
    private _storageService: StorageService,
    private _router: Router,
    private _roleService: RoleService,
    private _commonService: CommonService
  ) { }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();
    if (this.role != 1) {
      this.getPermission();
    }
    this.distributor = this._commonService.convertToNumber(this._storageService.getDistributorId());
    this.setDashboardCounts();
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      self.marked = self.permissionsData[19].sub_permission[0].isSelected;
      self.userSelected = self.permissionsData[0].sub_permission[0].isSelected;
      self.salesOrderSelected = self.permissionsData[20].sub_permission[0].isSelected;
      self.mySalesOrderSelected = self.permissionsData[21].sub_permission[0].isSelected;
      self.itemSelected = self.permissionsData[15].sub_permission[0].isSelected;
      self.distributorSelected = self.permissionsData[13].sub_permission[0].isSelected;
      self.distributorItemSelected = self.permissionsData[16].sub_permission[0].isSelected;
      self.customizeorder = self.permissionsData[18].sub_permission[0].isSelected;
      self.attributeSelected = self.permissionsData[2].sub_permission[0].isSelected;
      self.categoriesSelected = self.permissionsData[3].sub_permission[0].isSelected;
      self.subcategoriesSelected = self.permissionsData[4].sub_permission[0].isSelected;
      self.metalsSelected = self.permissionsData[7].sub_permission[0].isSelected;
      self.accessoriesSelected = self.permissionsData[8].sub_permission[0].isSelected;
      self.warehousesSelected = self.permissionsData[10].sub_permission[0].isSelected;
      self.puritySelected = self.permissionsData[22].sub_permission[0].isSelected;
      self.productionHousesSelected = self.permissionsData[11].sub_permission[0].isSelected;
      self.distributorGroupsSelected = self.permissionsData[12].sub_permission[0].isSelected;
      self.ItemGroupsSelected = self.permissionsData[14].sub_permission[0].isSelected;
      self.showcaseSelected = self.permissionsData[19].sub_permission[0].isSelected;
      self.paymetDetailSelected = self.permissionsData[23].sub_permission[0].isSelected;
      self.retailerSelected = self.permissionsData[24].sub_permission[0].isSelected;
      self.distributorRetailerSelected = self.permissionsData[26].sub_permission[0].isSelected;

      if (self.attributeSelected == false && self.categoriesSelected == false && self.subcategoriesSelected == false && self.metalsSelected == false && self.accessoriesSelected == false && self.warehousesSelected == false && self.puritySelected == false && self.userSelected == false && self.salesOrderSelected == false && self.mySalesOrderSelected == false && self.itemSelected == false && self.distributorSelected == false && self.distributorItemSelected == false && self.productionHousesSelected == false && self.distributorGroupsSelected == false && self.ItemGroupsSelected == false && self.customizeorder == false && self.paymetDetailSelected == false && self.retailerSelected == false && self.distributorRetailerSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }

      if (this.roleType == 2 && self.showcaseSelected == true) {
        this._router.navigate(['admin/dashboard']);
      }
    });

  }

  setDashboardCounts() {
    let self = this;
    self._dashboardService.getDashboardCounts().subscribe(data => {
      let response: any = data;
      self.userCount = response.data.user_count;
      self.distributorCount = response.data.distributor_count;
      self.itemCount = response.data.item_count;
      self.salesCount = response.data.sales_count;
      self.disitemCount = response.data.disItemCount;
      self.pendingOrderCount = response.data.pendingorder;
      self.pendingDistributorItem = response.data.pendingDistributorItem;
      self.assign_items = response.data.assign_items;
      self.own_items = response.data.own_items;
    });
  }

  getPendingOrder(item) {
    if (item != '' && item != undefined) {
      this._router.navigate(['admin/sales-orders', item]);
    }
    else {
      this._router.navigate(['admin/sales-orders']);
    }
  }

  getDistributorPendingOrder(pendingItem) {
    if (pendingItem != '' && pendingItem != undefined) {
      this._router.navigate(['admin/my-sales-orders', pendingItem]);
    }
    else {
      this._router.navigate(['admin/my-sales-orders']);
    }
  }
}
