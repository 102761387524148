import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemVM } from 'src/app/models/ItemVM';
import { SubscriptionVM } from 'src/app/models/SubscriptionVM';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BankDetailService } from 'src/app/services/bank-detail.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { CommonService } from 'src/app/services/common.service';
import { RetailerService } from 'src/app/services/retailer.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubscriptionService } from 'src/app/services/subscriprion.service';

@Component({
  selector: 'app-subscription-view',
  templateUrl: './subscription-view.component.html',
  styleUrls: ['./subscription-view.component.css']
})
export class SubscriptionViewComponent implements OnInit {

  SubscriptionVM = new SubscriptionVM();
  loginUserId: any;
  id: number;

  totalLength: number;
  noData = false;
  displayedColumns: string[] = ['item_name', 'code', 'group_id', 'purity', 'is_active', 'status', 'created_at', 'actions'];
  columnSorting: any;
  columnDirection: any;
  filterValue: '';
  value: '';
  pageSize: number;
  retailerItemViewVM: any;

  constructor(
    private _commonService: CommonService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _subscriptionService: SubscriptionService,
    private _storageService: StorageService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _retailerService: RetailerService,
    private _siteConfigService: SiteConfigService
  ) { }

  ngOnInit() {
    let self = this;
    this.loginUserId = this._storageService.getUserId();
    this.getUserActive();

    self._commonService.showLoader();
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.getSubscriptionById();
        this.subscriptionItemsById(event);
      }
    });

  }


  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getSubscriptionById() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;
    self._commonService.showLoader();
    self._subscriptionService.getSubscriptionById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.SubscriptionVM = response.data.subscription;
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }

  subscriptionItemsById(event) {
    let self = this;
    self._commonService.showLoader();
    self._subscriptionService.getSubscriptionItems(self.id, event, this.value, this.columnSorting, this.columnDirection)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (!this._commonService.isEmptyArray(response.data.list)) {
          self.retailerItemViewVM = new MatTableDataSource<ItemVM>(response.data.list)
          if (response.data.list != null && response.data.list.length > 0) {
            self.totalLength = response.data.total_count;
          }
          self.noData = (response.data.list != null && response.data.list.length > 0) ? false : true;
          if (self.noData == true) {
            self.totalLength = 0;
          }
        }
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }

  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.subscriptionItemsById(event);
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.subscriptionItemsById(event);
  }

  viewItem(item: ItemVM) {
    this._router.navigate(['/admin/items/detail/', item.item_id], { queryParams: { id: item.item_id } });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }
}
