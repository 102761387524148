export class BankDetailVM {
    BankDetailTo: number
    BankAccountName: string
    BankName: string
    AccountNo: string
    IFSCCode: string
    SwiftCode: string
    PANNumber: string
    GSTNumber: string
    IsActive: number
    Branch: string
}
