import { UserVM } from './UserVM';
import { ItemVM } from './ItemVM';
import { DistributorGroupVM } from './DistributorGroupVM';
import { CountryVM } from './CountryVM';
import { StateVM } from './StateVM';
import { RoleVM } from './RoleVM';
import { Subscription } from 'rxjs';
import { SubscriptionVM } from './SubscriptionVM';

export class DistributorVM {
  distributor_id: number
  distributor_code: string
  company_name: string
  display_name: string
  logo: string
  watermark: string;
  business_address: string
  mobile_no: string
  gst_no: string
  item_add_limit: number
  registration_no: string
  group_id: number
  group_id_multiple: string
  group_names?: string
  date_of_registration: string
  last_order_date: string
  total_business: string
  user_id: number
  country: CountryVM
  state: StateVM
  city: string
  district: string
  postal_code: string
  is_active: string
  update_log?: string
  receivable_account_id?: number
  credit_dues: string
  credit_limit: string
  payment_term_in_days: number
  created_at?: string
  updated_at?: string
  user?: UserVM
  items_count?: string
  items?: ItemVM[]
  distributor_group?: DistributorGroupVM
  role?: RoleVM
  dis_group_name: string
  dis_active: number
  name: string
  password: UserVM
  confirm_password: UserVM
  subscription?:SubscriptionVM
  subscription_id?:any;
  is_item_show?:any;
}
