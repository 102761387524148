import { Component, OnInit, ViewChild } from '@angular/core';
import { ItemVM } from 'src/app/models/ItemVM';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ItemService } from 'src/app/services/item.service';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { SiteConfigService } from 'src/app/services/site-config.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-item-distributor-list',
  templateUrl: './item-distributor-list.component.html',
  styleUrls: ['./item-distributor-list.component.css']
})
export class ItemDistributorListComponent implements OnInit {

  itemsData: any;
  noData = false;
  displayedColumns: string[];
  dis_id: any;
  datasource: null;
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  filterValue: '';
  value: '';
  role: any;
  roleType: any;
  permissionsData = [];
  marked = false;
  marked1 = false;
  marked2 = false;
  columnSorting: any;
  columnDirection: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private _itemService: ItemService,
    private _commonService: CommonService,
    private _router: Router,
    private _roleService: RoleService,
    private _siteConfigService: SiteConfigService,
    private _storageService: StorageService) {
  }

  ngOnInit() {
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();
    if (this.role != 1) {
      this.getPermission();
    }
    this.displayedColumns = ['item_name', 'code', 'group_id', 'purity', 'distributor', 'is_active', 'status', 'is_approve', 'created_at', 'actions'];
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.loadItems(event);
      }
    });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      self.marked = self.permissionsData[16].sub_permission[1].isSelected;
      self.marked1 = self.permissionsData[16].sub_permission[2].isSelected;
      self.marked2 = self.permissionsData[16].sub_permission[3].isSelected;
      if (self.permissionsData[16].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  loadItems(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._itemService.getAllDistributorItems(event, this.value, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.itemsData = new MatTableDataSource<ItemVM>(response.data);
            if (response.data.length > 0) {
              self.totalLength = response.data[0].totalcount;
            }
            self.noData = (response.data.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  viewItem(item: ItemVM) {
    this._router.navigate(['/admin/distributor/itemdetail/', item.item_id]);
  }

  deleteItem(item: ItemVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._itemService.deleteItem(item.item_id).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Item deleted successfully.", "success");
          var event: any = {
            "previousPageIndex": '0',
            "pageIndex": '0',
            "pageSize": this.pageSize,
            "length": '0',
          }
          self.loadItems(event);
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadItems(event);
  }

  editItem(itemGroup: ItemVM) {
    this._itemService.getItemDetailById(itemGroup.item_id).subscribe(data => {
      let response: any = data;
      this.dis_id = response.item_detail.distributor_id;
      this._router.navigate(['/admin/items/edit/' + itemGroup.item_id], { queryParams: { source: this.dis_id } });
    }, error => {
      console.log('some errror in redirection');
    });
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.loadItems(event);
  }
}
