import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

// @Services
import { HttpService } from './http.service';
import { AppConfig } from '../shared/app-config';

@Injectable({
  providedIn: 'root'
})
export class DistributorService {

  apiUrl: String;
  // set http Authorization headers
  httpAuthOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    })
  };

  constructor(private _httpService: HttpService,
    private _api: AppConfig) {
    this.apiUrl = this._api.ApiUrl;
  }

  getAllActiveDistributor() {
    return this._httpService.post(`${this.apiUrl}distributor/getAllDistributorData`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getDistributorDesign(itemid) {
    let body = {
      'itemid': itemid
    }
    return this._httpService.post(`${this.apiUrl}distributor/getDistributorDesign`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }



  getAllDistributor(event, value, columnSorting, columnDirection) {

    var body = {
      "event": event,
      "value": value,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection,


    }
    return this._httpService.post(`${this.apiUrl}distributor/getAllDistributor`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllUserData() {
    return this._httpService.get(`${this.apiUrl}getAllUserData`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllCountryData() {
    return this._httpService.get(`${this.apiUrl}getAllCountryData`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllStateData() {
    return this._httpService.get(`${this.apiUrl}getAllStateData`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
  getAllDistrictData() {
    return this._httpService.get(`${this.apiUrl}getAllDistrictData`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
  getAllDistrictDataById(id) {
    return this._httpService.get(`${this.apiUrl}getDistrictById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getcodeById(id) {
    return this._httpService.get(`${this.apiUrl}getcodeById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }


  getAllGroupData() {
    return this._httpService.get(`${this.apiUrl}getAllGroupData`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  addDistributor(distributor) {
    return this._httpService.post(`${this.apiUrl}distributor/addDistributor`, distributor, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getDistributorById(id) {
    return this._httpService.get(`${this.apiUrl}distributor/getDistributorById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
  getDistributorDetailpById(id, event, value, columnSorting, columnDirection) {

    var body = {
      "id": id,
      "event": event,
      "value": value,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection,
    }

    return this._httpService.post(`${this.apiUrl}distributor/getDistributorDetailpById`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getDistributorDetailpByIds(id) {
    return this._httpService.get(`${this.apiUrl}distributor/getDistributorDetailpByIds/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
  getStateById(id) {
    return this._httpService.get(`${this.apiUrl}getStateById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  updateDistributor(Distributor) {
    return this._httpService.post(`${this.apiUrl}distributor/updateDistributor`, Distributor, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  deleteDistributor(id, password) {

    return this._httpService.post(`${this.apiUrl}distributor/deleteDistributor/${id}/${password}`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getLoginDistributorDetails() {
    return this._httpService.post(`${this.apiUrl}distributor/getLoginDistributorDetails`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getAllCustomers(event, filter, columnSorting, columnDirection) {
    var body = {
      "event": event,
      "value": filter,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection
    }
    return this._httpService.post(`${this.apiUrl}customer/getAllCustomer`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getCustomerById(id) {
    // var body = {
    //   "id": id,
    //   "event": event,
    //   "value": value,
    //   "columnSorting": columnSorting,
    //   "columnDirection": columnDirection,
    // }
    return this._httpService.get(`${this.apiUrl}customer/getcustomerById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getCustomerItemsById(id,event,value,columnSorting,columnDirection) {
    var body = {
      "id": id,
      "event": event,
      "value": value,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection,
    }
    return this._httpService.post(`${this.apiUrl}customer/getcustomerItems`,body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getCustomerRetailerRequestList(event, filter, columnSorting, columnDirection) {
    var body = {
      "event": event,
      "value": filter,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection
    }
    return this._httpService.post(`${this.apiUrl}customer/getCustomerRetailerRequestList`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getCustomerRetailerRequestById(id) {
    return this._httpService.get(`${this.apiUrl}customer/getCustomerRetailerRequestById/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  updateRetailerREquestStatus(updateStatus) {
    return this._httpService.post(`${this.apiUrl}customer/updateCustomerRetailerRequestStatus`, updateStatus, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getCustomerInquiryList(event, filter, columnSorting, columnDirection) {
    var body = {
      "event": event,
      "value": filter,
      "columnSorting": columnSorting,
      "columnDirection": columnDirection
    }
    return this._httpService.post(`${this.apiUrl}customer/getCustomerInquiryList`, body, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  getCartItems() {
    return this._httpService.post(`${this.apiUrl}sales-orders/getCartItems`, '', this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }

  deleteCartItem(id) {
    return this._httpService.get(`${this.apiUrl}sales-orders/deleteCartItem/${id}`, this.httpAuthOptions)
      .pipe(
        map((response: any) => {
          return this._httpService.extractData(response);
        }), catchError((errorRes: any) => {
          return this._httpService.handleError(errorRes);
        })
      );
  }
}
