import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TermscategoryVM } from 'src/app/models/TermscategoryVM';
import { TermscategoryService } from 'src/app/services/termscategory.service';
import { CommonService } from 'src/app/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-termcategory-edit',
  templateUrl: './termcategory-edit.component.html',
  styleUrls: ['./termcategory-edit.component.css']
})
export class TermcategoryEditComponent implements OnInit {

  id: number;
  editTermsCategoryForm: FormGroup;
  TermscategoryVM = new TermscategoryVM();
  submitted = false;

  constructor(private _formBuilder: FormBuilder,
    private _termscategoryService: TermscategoryService,
    private _commonService: CommonService,
    private _router: Router,
    private _route: ActivatedRoute) { }

  ngOnInit() {
    this.initializeFormValue();
    this.buildForm();
  }

  initializeFormValue() {
    this._route.params.subscribe(params => { this.id = params['id']; });
    let self = this;

    self._commonService.showLoader();
    self._termscategoryService.getCategoryById(self.id)
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        self.TermscategoryVM = response.termscategory;
        this.buildForm();
      });
  }

  buildForm() {
    this.editTermsCategoryForm = this._formBuilder.group({
      term_category_id: [this.TermscategoryVM.term_category_id],
      category_name: [this.TermscategoryVM.category_name, [Validators.required, Validators.maxLength(255)]],
      category_details: [this.TermscategoryVM.category_details],
      is_active: [this._commonService.convertToNumber(this.TermscategoryVM.is_active), [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.editTermsCategoryForm.controls; }

  gotoTermsCategoryList() {
    this._router.navigate(['/admin/terms-categories']);
  }

  save() {
    let self = this;
    self.submitted = true;

    // stop here if form is invalid
    if (self.editTermsCategoryForm.invalid) {
      return;
    }

    self._commonService.showLoader();
    self._termscategoryService.updateCategory(self.editTermsCategoryForm.value).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoTermsCategoryList();
        self._commonService.showToaster("Terms Category updated successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }
}
