import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { CommonService } from 'src/app/services/common.service';
import { AppConfig } from 'src/app/shared/app-config';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  siteLogoUrl: string;
  siteName: string;
  role: any;
  roleType: any;
  permissionsData = [];
  isusermenu: boolean;
  ismanagedismenu: boolean;
  isitemmenu: boolean;
  isitemmastermenu: boolean;
  marked = false;
  marked1 = false;
  permission: any;
  constructor(private _router: Router,
    private _roleservice: RoleService,
    private _storageService: StorageService,
    private _commonService: CommonService,
    private _appConfig: AppConfig
  ) { }

  ngOnInit() {
    this.getPermission();
    this.setSiteLogo();
    this.setSiteName();
    this._storageService.siteLogoChange.subscribe(isChanged => {
      if (isChanged == true) {
        this.setSiteLogo();
      }
    });
    this._storageService.siteNameChange.subscribe(isChanged => {
      if (isChanged == true) {
        this.setSiteName();
      }
    });
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();


  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {

      let list = data[0].data.data;
      list.forEach(element => {
        element.sub_permission.slice(0, 1).forEach(permission => {
          if (element.name == 'user' || element.name == "user-role") {
            if (permission.isSelected == true) {
              this.isusermenu = true;
            } else {
              this.isusermenu = false;
            }
          }
          if (element.name == "distributor-groups" || element.name == "distributors") {
            if (permission.isSelected == true) {

              this.ismanagedismenu = true;
            } else {
              this.ismanagedismenu = false;
            }
          }
        });
        //if()
      });
      self.permissionsData = list;
      self.marked = self.permissionsData[12].sub_permission[0].isSelected
    });

  }

  isShowManageUsersParentMenu() {
    let self = this;
    let isActiveManageUsers = false;
    if (self.permissionsData.length > 0) {
      self.permission = self.permissionsData.filter(c => c.display_name == "Users");
      if (self.permission != undefined && self.permission != null) {
        self.permission.forEach((ele) => {
          if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
            ele.sub_permission.forEach(subPermission => {
              if (subPermission.isSelected == true) {
                isActiveManageUsers = true;
              }
            });
          }
        });
      }
    }
    return isActiveManageUsers;
  }

  isShowItemMasterParentMenu() {
    let self = this;
    let isActiveItemMaster = false;
    if (self.permissionsData.length > 0) {
      let permission = self.permissionsData.filter(c => c.display_name == "Attributes" || c.display_name == "Categories" || c.display_name == "Sub Categories" || c.display_name == "Metals" || c.display_name == "Accessories" || c.display_name == "Warehouses" || c.display_name == "purity");
      if (permission != undefined && permission != null) {
        permission.forEach((ele) => {
          if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
            ele.sub_permission.forEach(subPermission => {
              if (subPermission.isSelected == true) {
                isActiveItemMaster = true;
              }
            });
          }
        });
      }
    }
    return isActiveItemMaster;

  }

  isShowDistributorsParentMenu() {
    let self = this;
    let isActivemanageDistributors = false;
    if (self.permissionsData.length > 0) {
      let permission = self.permissionsData.filter(c => c.display_name == "Distributor Groups" || c.display_name == "Distributors");
      if (permission != undefined && permission != null) {
        permission.forEach((ele) => {
          if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
            ele.sub_permission.forEach(subPermission => {
              if (subPermission.isSelected == true) {
                isActivemanageDistributors = true;
              }
            });
          }
        });
      }
    }
    return isActivemanageDistributors;
  }

  isShowSettingsParentMenu() {
    let self = this;
    let isActiveSetting = false;
    if (self.permissionsData.length > 0) {
      self.permission = self.permissionsData.filter(c => c.display_name == "Companies" || c.display_name == "Site Configuration");
      if (self.permission != undefined && self.permission != null) {
        self.permission.forEach((ele) => {
          if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
            ele.sub_permission.forEach(subPermission => {
              if (subPermission.isSelected == true) {
                isActiveSetting = true;
              }
            });
          }
        });
      }
    }
    return isActiveSetting;
  }

  isShowManageItemsParentMenu() {
    let self = this;
    let isActiveManageItems = false;
    if (self.permissionsData.length > 0) {
      let permission = self.permissionsData.filter(c => c.display_name == "Item Groups" || c.display_name == "Items" || c.display_name == "Distributor Items");
      if (permission != undefined && permission != null) {
        permission.forEach((ele) => {
          if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
            ele.sub_permission.forEach(subPermission => {
              if (subPermission.isSelected == true) {
                isActiveManageItems = true;
              }
            });
          }
        });
      }
    }
    return isActiveManageItems;
  }

  isShowManageRetailerMenu() {
    let self = this;
    let isActiveManageItems = false;
    if (self.permissionsData.length > 0) {
      let permission = self.permissionsData.filter(c => c.display_name == "retailer" || c.display_name == "distributor-retailer");
      if (permission != undefined && permission != null) {
        permission.forEach((ele) => {
          if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
            ele.sub_permission.forEach(subPermission => {
              if (subPermission.isSelected == true) {
                isActiveManageItems = true;
              }
            });
          }
        });
      }
    }
    return isActiveManageItems;
  }

  isShowProductionHousesMenu() {
    let self = this;
    let isActiveProductionHouses = false;
    if (self.permissionsData.length > 0) {
      self.permission = self.permissionsData.filter(c => c.display_name == "Production Houses");
      if (self.permission != undefined && self.permission != null) {
        self.permission.forEach((ele) => {
          if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
            ele.sub_permission.forEach(subPermission => {
              if (subPermission.isSelected == true) {
                isActiveProductionHouses = true;
              }
            });
          }
        });
      }
    }
    return isActiveProductionHouses;
  }

  isShowCustomizeOrderMenu() {
    let self = this;
    let isActiveCustomizeOrder = false;
    if (self.permissionsData.length > 0) {
      self.permission = self.permissionsData.filter(c => c.display_name == "customize order");
      if (self.permission != undefined && self.permission != null) {
        self.permission.forEach((ele) => {
          if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
            ele.sub_permission.forEach(subPermission => {
              if (subPermission.isSelected == true) {
                isActiveCustomizeOrder = true;
              }
            });
          }
        });
      }
    }
    return isActiveCustomizeOrder;
  }

  isShowSalesOrderMenu() {
    let self = this;
    let isActiveSalesOrder = false;
    if (self.permissionsData.length > 0) {
      self.permission = self.permissionsData.filter(c => c.display_name == "sales-order");
      if (self.permission != undefined && self.permission != null) {
        self.permission.forEach((ele) => {
          if (ele.sub_permission != null && ele.sub_permission != undefined && ele.sub_permission.length > 0) {
            ele.sub_permission.forEach(subPermission => {
              if (subPermission.isSelected == true) {
                isActiveSalesOrder = true;
              }
            });
          }
        });
      }
    }
    return isActiveSalesOrder;
  }

  setSiteLogo() {
    let siteLogoUrl = this._storageService.getsiteConfigsiteLogo();
    if (!this._commonService.isNullOrEmpty(siteLogoUrl)) {
      this.siteLogoUrl = this._appConfig.ImageUrl + siteLogoUrl;
    }
  }

  setSiteName() {
    this.siteName = this._storageService.getsiteConfigsiteName();
  }

  //Show Active Arrow on route change - START

  isActive(url): boolean {
    if (this._router.url.indexOf(url) != -1) {
      return true;
    } else {
      return false;
    }
  }


  isMenuActive(currentURL, url, exactMatch = true): boolean {
    if (exactMatch == true) {
      if (currentURL == ("/" + url)) {
        return true;
      }
    } else {
      if (currentURL.indexOf(url) != -1) {
        return true;
      }
    }
    return false;
  }

  // Dashboard Menu - START
  get isDashboardMenuActive(): boolean {
    if (this.isDashboardMenu) {
      return true;
    }
    return false;
  }

  get isDashboardMenu(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/dashboard')) {
      return true;
    }
    return false;
  }
  // Dashboard Menu - END

  //--- Manage Users Menu - START
  get isUsersMenuActive(): boolean {

    if (this.isUserRoleMenuActive || this.isUserMenuActive) {
      return true;
    }
    return false;
  }
  get isUserRoleMenuActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/roles') || this.isMenuActive(url, 'admin/roles/add') || this.isMenuActive(url, 'admin/roles/edit', false) || this.isMenuActive(url, 'admin/role-permission/edit/', false)) {
      return true;
    }
    return false;
  }
  //--- Manage Users Menu - END

  get isSiteConfigMenu(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/site-configuration')) {
      return true;
    }
    return false;
  }

  get isBankDetailMenu(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/bank-details') || this.isMenuActive(url, 'admin/payment-details/view')) {
      return true;
    }
    return false;
  }

  //--- Masters Menu - START
  get isManageMastersMenuActive(): boolean {
    if (this.isAttributeMenuActive || this.isCategoryMenuActive || this.isSubCategoryMenuActive || this.isTermsMenuActive || this.isTermCategoryMenuActive || this.isMetalMenu || this.isAccessoriesMenu || this.isWarehouseMenu || this.isPurityMenu) {
      return true;
    }
    return false;
  }

  get isAttributeMenuActive(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/attributes') || this.isMenuActive(url, 'admin/attributes/add') || this.isMenuActive(url, 'admin/attributes/edit', false)) {
      return true;
    }
    return false;
  }

  get isCategoryMenuActive(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/categories') || this.isMenuActive(url, 'admin/categories/add') || this.isMenuActive(url, 'admin/categories/edit', false)) {
      return true;
    }
    return false;
  }

  get isSubCategoryMenuActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/sub-categories') || this.isMenuActive(url, 'admin/sub-categories/add') || this.isMenuActive(url, 'admin/sub-categories/edit', false)) {
      return true;
    }
    return false;
  }

  get isTermsMenuActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/terms') || this.isMenuActive(url, 'admin/terms/add') || this.isMenuActive(url, 'admin/terms/edit', false)) {
      return true;
    }
    return false;
  }

  get isTermCategoryMenuActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/terms-categories') || this.isMenuActive(url, 'admin/terms-categories/add') || this.isMenuActive(url, 'admin/terms-categories/edit', false)) {
      return true;
    }
    return false;
  }
  //--- Masters Menu - END

  get isWarehouseMenu(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/warehouses') || this.isMenuActive(url, 'admin/warehouses/add') || this.isMenuActive(url, 'admin/warehouses/edit', false)) {
      return true;
    }
    return false;
  }

  get isProductionHouseMenu(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/production-houses') || this.isMenuActive(url, 'admin/production-houses/add') || this.isMenuActive(url, 'admin/production-houses/edit', false)) {
      return true;
    }
    return false;
  }

  get isSubscriptionMenu(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/subscription') || this.isMenuActive(url, 'admin/subscription/add') || this.isMenuActive(url, 'admin/subscription/edit', false) || this.isMenuActive(url, 'admin/subscription/view', false) || this.isMenuActive(url, 'admin/assign-subscription', false)) {
      return true;
    }
    return false;
  }

  get isInquiersMenu(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/inquiries') || this.isMenuActive(url, 'admin/inquiries/views', false)) {
      return true;
    }
    return false;
  }

  get isPartnersMenu(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/partners') || this.isMenuActive(url, 'admin/partners/views', false)) {
      return true;
    }
    return false;
  }

  get isAssignSubscription(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/userassign-subscription-list') || this.isMenuActive(url, 'admin/userassign-subscription-edit', false) || this.isMenuActive(url, 'admin/userassign-subscription-view', false)) {
      return true;
    }
    return false;
  }

  get isCustomer(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/customer-list') || this.isMenuActive(url, 'admin/customer-view', false)) {
      return true;
    }
    return false;
  }

  get isRetailerRequest(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/customer/retailerRequest-list') || this.isMenuActive(url, 'admin/customer/retailerRequest-view', false)) {
      return true;
    }
    return false;
  }

  get isCustomerInquiry(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/customer-inquiry-list')) {
      return true;
    }
    return false;
  }

  get isCompanyMenu(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/company') || this.isMenuActive(url, 'admin/company/edit', false)) {
      return true;
    }
    return false;
  }

  get isMetalMenu(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/metal') || this.isMenuActive(url, 'admin/metal/add') || this.isMenuActive(url, 'admin/metal/edit', false)) {
      return true;
    }
    return false;
  }

  get isAccessoriesMenu(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/accessories') || this.isMenuActive(url, 'admin/accessories/add') || this.isMenuActive(url, 'admin/accessories/edit', false)) {
      return true;
    }
    return false;
  }

  get istermsCategoryMenu(): boolean {
    const url = this._router.url;
    if (this.isMenuActive(url, 'admin/terms-categories')) {
      return true;
    }
    return false;
  }

  get isManageDistributorMenuActive(): boolean {
    if (this.isDistributorGroupMenuActive || this.isDistributorMenuActive) {
      return true;
    }
    return false;
  }

  get isDistributorGroupMenuActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/distributor-groups') || this.isMenuActive(url, 'admin/distributor-groups/add') || this.isMenuActive(url, 'admin/distributor-groups/edit', false)) {
      return true;
    }
    return false;
  }

  get isDistributorMenuActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/distributor') || this.isMenuActive(url, 'admin/distributor/add') || this.isMenuActive(url, 'admin/distributor/edit', false) || this.isMenuActive(url, 'admin/distributor/view', false)) {
      return true;
    }
    return false;
  }


  get isManageItemsMenuActive(): boolean {
    if (this.isItemGroupMenuActive || this.isItemMenuActive || this.isDistributorItemMenuActive) {
      return true;
    }
    return false;
  }


  get isManageRetailerActive(): boolean {
    if (this.isRetailerActive || this.isRetailerItemMenuActive) {
      return true;
    }
    return false;
  }

  get isItemGroupMenuActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/item-groups') || this.isMenuActive(url, 'admin/item-groups/add') || this.isMenuActive(url, 'admin/item-groups/edit', false)) {
      return true;
    }
    return false;
  }

  get isItemMenuActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/items') || this.isMenuActive(url, 'admin/items/add') || this.isMenuActive(url, 'admin/items/edit', false) || this.isMenuActive(url, 'admin/items/grid-view') || this.isMenuActive(url, 'admin/items/detail', false)) {
      return true;
    }
    return false;
  }

  get isRetailerActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/retailer') || this.isMenuActive(url, 'admin/retailer/add') || this.isMenuActive(url, 'admin/retailer/edit', false) || this.isMenuActive(url, 'admin/retailer/view', false)) {
      return true;
    }
    return false;
  }

  get isDistributorItemMenuActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/distributor-items') || this.isMenuActive(url, 'admin/distributor/itemdetail', false)) {
      return true;
    }
    return false;
  }

  get isRetailerItemMenuActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/distributor-retailer') || this.isMenuActive(url, 'admin/distributor/retailerdetail', false)) {
      return true;
    }
    return false;
  }

  get isUserMenuActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/users') || this.isMenuActive(url, 'admin/users/add') || this.isMenuActive(url, 'admin/users/edit', false)) {
      return true;
    }
    return false;
  }

  get isPurityMenu(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/purity') || this.isMenuActive(url, 'admin/purity/add') || this.isMenuActive(url, 'admin/purity/edit', false)) {
      return true;
    }
    return false;
  }

  get isSalesOrders(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/sales-orders') || this.isMenuActive(url, 'admin/sales-orders/grid-view', false) || this.isMenuActive(url, 'admin/sales-order-details', false)) {
      return true;
    }
    return false;
  }

  get isMyOrderMenu(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/my-sales-orders') || this.isMenuActive(url, 'admin/my-sales-orders/grid-view-item', false) || this.isMenuActive(url, 'admin/sales-order-details', false)) {
      return true;
    }
    return false;
  }
  get isCustomizeItemMenuActive(): boolean {
    const url = this._router.url;

    if (this.isMenuActive(url, 'admin/customize-items') || this.isMenuActive(url, 'admin/customize-items/grid-view', false) || this.isMenuActive(url, 'admin/customize-order/add') || this.isMenuActive(url, 'admin/customize-items/grid-view', false) || this.isMenuActive(url, 'admin/customize-items/edit', false) || this.isMenuActive(url, 'admin/customize-order-detail', false)) {
      return true;
    }
    return false;
  }


}
