import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BankDetailVM } from 'src/app/models/BankDetailVM';
import { AuthService } from 'src/app/services/auth/auth.service';
import { BankDetailService } from 'src/app/services/bank-detail.service';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { CommonService } from 'src/app/services/common.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-bank-detail',
  templateUrl: './bank-detail.component.html',
  styleUrls: ['./bank-detail.component.css']
})
export class BankDetailComponent implements OnInit {

  bankDetailForm: FormGroup;
  bankDetail = new BankDetailVM();
  submitted = false;
  loginUserId: any;

  constructor(
    private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _bankDetailService: BankDetailService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _storageService: StorageService
  ) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.getUserActive();
    this.buildForm();
    this.getBankDetailsData();
  }


  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }

  getBankDetailsData() {
    let self = this;
    self._commonService.showLoader();
    self._bankDetailService.getBankDetailsData().subscribe(data => {
      self._commonService.hideLoader();
      let response: any = data;
      if (response.data != null && response.data != undefined) {
        self.bankDetail = response.data[0];
        self.buildForm();
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  buildForm() {
    this.bankDetailForm = this._formBuilder.group({
      BankDetailTo: [this._commonService.convertToNumber(this.bankDetail.BankDetailTo)],
      BankAccountName: [this.bankDetail.BankAccountName, [Validators.required, Validators.maxLength(120)]],
      BankName: [this.bankDetail.BankName, [Validators.required, Validators.maxLength(120)]],
      AccountNo: [this.bankDetail.AccountNo, [Validators.required, Validators.maxLength(30), RxwebValidators.numeric({ allowDecimal: true })]],
      IFSCCode: [this.bankDetail.IFSCCode, [Validators.required, Validators.maxLength(15)]],
      SwiftCode: [this.bankDetail.SwiftCode, [Validators.required, Validators.maxLength(10)]],
      PANNumber: [this.bankDetail.PANNumber, [Validators.required, Validators.maxLength(16)]],
      GSTNumber: [this.bankDetail.GSTNumber, [Validators.required, Validators.maxLength(16)]],
      IsActive: [this.bankDetail.IsActive, Validators.required],
      Branch: [this.bankDetail.Branch, [Validators.required, Validators.maxLength(36)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.bankDetailForm.controls; }


  save() {
    let self = this;
    self.submitted = true;
    // stop here if form is invalid
    if (self.bankDetailForm.invalid) {
      this._commonService.scrollToTop();
      return;
    }
    self._commonService.showLoader();
    self._bankDetailService.saveBankDetail(self.bankDetailForm.value).subscribe(data => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        let response: any;
        response = data;
        self._commonService.showToaster("Bank Details saved successfully.", "success");
        self.getBankDetailsData();
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }
}
