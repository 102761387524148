// @Packages
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

// @Services
import { requiredFileType } from 'src/app/shared/_helpers/required-file-type.validator';
import { SubCategoryService } from 'src/app/services/subcategory.service';
import { CommonService } from 'src/app/services/common.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { REGEXP } from 'src/app/shared/regexp';

// @Models
import { CategoryVM } from 'src/app/models/CategoryVM';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { Select2OptionData } from 'ng-select2';
import { CheckActiveUserService } from 'src/app/services/checkactiveuser.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { RoleService } from 'src/app/services/role.service';


@Component({
  selector: 'app-subcategory-add',
  templateUrl: './subcategory-add.component.html',
  styleUrls: ['./subcategory-add.component.css']
})
export class SubcategoryAddComponent implements OnInit {

  @ViewChild('category_image', { static: false }) CategoryImageFileInput;

  public select2Options: Options;
  public select2CategoryData: Array<Select2OptionData>;

  addSubCategoryForm: FormGroup;
  categoryVM = new CategoryVM();
  categorydata: CategoryVM[];
  submitted = false;
  loginUserId: any;
  role: any;
  permissionsData = [];

  constructor(private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _checkUser: CheckActiveUserService,
    private _auth: AuthService,
    private _storageService: StorageService,
    private _subcategoryService: SubCategoryService,
    private _router: Router,
    private _roleService: RoleService,
    private _regex: REGEXP) { }

  ngOnInit() {
    this.loginUserId = this._storageService.getUserId();
    this.role = this._storageService.getUserRoles();
    if (this.role != 1) {
      this.getPermission();
    }
    this.getUserActive();
    this.createSubCategoryForm();
    this.getallCategoryData();
  }

  getUserActive() {
    let self = this;
    self._checkUser.getUserActive(self.loginUserId)
      .subscribe(data => {
        let response: any = data
        if (response.user.is_active != 1) {
          this._auth.logout();
        }
      });
  }
  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      if (self.permissionsData[4].sub_permission[1].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getallCategoryData() {
    let self = this;

    self._subcategoryService.getallCategoryData().subscribe(data => {
      let response: any = data;
      if (response.category_data != null) {
        self.categorydata = response.category_data;
        let select2CategoryData: any = [];

        if (self.categorydata != null && self.categorydata.length > 0) {
          self.categorydata.forEach(item => {
            select2CategoryData.push({
              id: item.category_id,
              text: `${item.display_name} - ${item.category_code}`,
            })
          });
        }

        self.select2CategoryData = select2CategoryData;
      }
    });
  }

  get f() { return this.addSubCategoryForm.controls; }

  createSubCategoryForm() {
    this.addSubCategoryForm = this._formBuilder.group({
      category_code: [this.categoryVM.category_code, [Validators.required, Validators.maxLength(255)]],
      category_name: [this.categoryVM.category_name, [Validators.required, Validators.maxLength(255)]],
      display_name: [this.categoryVM.display_name, [Validators.required, Validators.maxLength(255)]],
      sequence_no: [this.categoryVM.sequence_no, [Validators.required, Validators.required, Validators.maxLength(255)]],
      description: [this.categoryVM.description],
      category_image: [this.categoryVM.category_image, [Validators.required, requiredFileType(this._commonService.IMAGE_FILE_FORMAT), RxwebValidators.fileSize({ maxSize: 5347737.6 })]],
      ref_category_id: ['', Validators.required],
      is_active: [1, Validators.required]
    });
  }

  gotoSubCategoryList() {
    this._router.navigate(['/admin/sub-categories']);
  }

  save() {

    let self = this;
    self.submitted = true;

    if (self.addSubCategoryForm.invalid) {
      return;
    }
    // stop here if form is invalid
    let formData = new FormData();

    let siteConfigData = (self.addSubCategoryForm.value as CategoryVM);
    for (const prop in siteConfigData) {

      let fi = this.CategoryImageFileInput.nativeElement;
      if (prop == 'category_image') {
        if (fi.files && fi.files[0]) {
          let fileToUpload = fi.files[0];
          self.addSubCategoryForm.value.category_image = fileToUpload;
          formData.append(prop, fileToUpload, (fileToUpload as File).name);
        } else {
          let file = new File([], ''); //empty file.
          formData.append(prop, file, "");
        }
      } else {
        formData.append(prop, siteConfigData[prop]);
      }
    }
    self._commonService.showLoader();
    self._subcategoryService.addSubCategory(formData).subscribe(data => {
      self._commonService.hideLoader();

      if (!self._commonService.isNullOrEmpty(data)) {
        self.gotoSubCategoryList();
        self._commonService.showToaster("Sub Category added successfully.", "success");
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }

  keyDownSubmit(event) {
    if (event.keyCode == 13) {
      this.save();
    }
  }

}
