import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { RetailerVM } from 'src/app/models/RetailerVM';
import { CommonService } from 'src/app/services/common.service';
import { RetailerService } from 'src/app/services/retailer.service';
import { RoleService } from 'src/app/services/role.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { DistributorVM } from 'src/app/models/DistributorVM';
import { RetailerPasswordComponent } from '../retailer-password/retailer-password.component';
import { ChangePasswordVM } from 'src/app/models/ChangePasswordVM';

@Component({
  selector: 'app-item-retailer-list',
  templateUrl: './item-retailer-list.component.html',
  styleUrls: ['./item-retailer-list.component.css']
})
export class ItemRetailerListComponent implements OnInit {

  distributorRetailerData: any;
  noData = false;
  dis_id: any;
  datasource: null;
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  filterValue: '';
  value: '';
  role: any;
  roleType: any;
  permissionsData = [];
  marked = false;
  marked1 = false;
  marked2 = false;
  columnSorting: any;
  columnDirection: any;
  displayedColumns: string[] = ['display_name', 'RetailerName', 'ContactName', 'email', 'RetailerLogo', 'IsActive', 'RetailerStatus_Term', 'assign_item_count', 'created_at', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  applyDistributorRetailerForm: FormGroup;
  public select2OptionsDistributor: Options;
  public select2DistributorData: Array<Select2OptionData>;
  distributorData: DistributorVM[];
  search: { DistributerId: any; };

  constructor(private _retailerService: RetailerService,
    private _commonService: CommonService,
    private _router: Router,
    private _roleService: RoleService,
    private _siteConfigService: SiteConfigService,
    private _storageService: StorageService,
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.createDisRetailerForm();
    this.role = this._storageService.getUserRoles();
    this.roleType = this._storageService.getUserRolesType();
    if (this.role != 1) {
      this.getPermission();
    }
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.getAllDistributorRetailer(event);
      }
    });
    this.select2OptionsDistributor = {
      multiple: false
    }
    this.getActiveDistributors();
  }

  createDisRetailerForm() {
    this.applyDistributorRetailerForm = this._formBuilder.group({
      DistributerId: [''],
    });
  }

  getPermission() {
    let self = this;
    Promise.all([
      self._commonService.getAllPermission()
    ]).then((data: any) => {
      let list = data[0].data.data;
      self.permissionsData = list;
      self.marked = self.permissionsData[26].sub_permission[1].isSelected;
      self.marked1 = self.permissionsData[26].sub_permission[2].isSelected;
      self.marked2 = self.permissionsData[26].sub_permission[3].isSelected;
      if (self.permissionsData[26].sub_permission[0].isSelected == false) {
        this._router.navigate(['mainshowcase/authorization']);
      }
    });
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfigService.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

  getAllDistributorRetailer(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._retailerService.getAllDistributorRetailer(event, this.value, this.columnSorting, this.columnDirection, this.search,)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;
            self.distributorRetailerData = new MatTableDataSource<RetailerVM>(response.data);
            if (response.data.length > 0) {
              self.totalLength = response.data[0].totalcount;
            }
            self.noData = (response.data.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  viewDisRetailer(item: RetailerVM) {
    this._router.navigate(['/admin/distributor/retailerdetail/', item.RetailerId]);
  }

  deleteDisRetailer(item: RetailerVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this Distributor Retailer ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self._retailerService.deleteDistributorRetailer(item.RetailerId).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("Distributor Retailer deleted successfully.", "success");
          var event: any = {
            "previousPageIndex": '0',
            "pageIndex": '0',
            "pageSize": this.pageSize,
            "length": '0',
          }
          self.getAllDistributorRetailer(event);
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  applyFilter(filterValue: any) {
    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllDistributorRetailer(event);
  }

  editDisRetailer(distributorRetailer: RetailerVM) {
    this._retailerService.getRetailerById(distributorRetailer.RetailerId).subscribe(data => {
      let response: any = data;
      this.dis_id = response.data[0].DistributerId;
      this._router.navigate(['/admin/retailer/edit/' + distributorRetailer.RetailerId], { queryParams: { source: this.dis_id } });
    }, error => {
      console.log('some errror in redirection');
    });
  }

  sortData(event) {
    this.columnSorting = event.active;
    this.columnDirection = event.direction;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllDistributorRetailer(event);
  }
  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  getActiveDistributors() {
    let self = this;
    self._retailerService.getAllActiveDistributor().subscribe(data => {
      let response: any = data;
      if (response.distributor_data != null) {
        self.distributorData = response.distributor_data;
        self.select2DistributorData = self._commonService.dropdownArrayDistributor(self.distributorData, "distributor_id", "display_name");
      }
    });
  }

  applySearch() {
    this.search = {
      "DistributerId": this.applyDistributorRetailerForm.controls.DistributerId.value
    }
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllDistributorRetailer(event);
  }

  clearSearch() {
    this.search = {
      "DistributerId": "",
    }
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllDistributorRetailer(event);
  }

  openPasswordDialog(retailerData: RetailerVM) {
    const dialogRef = this.dialog.open(RetailerPasswordComponent, {
      width: '50%',
      data: { dialogTitle: 'Change Password', RetailerId: retailerData.RetailerId, DistributerId: retailerData.DistributerId },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
