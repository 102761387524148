import { WarehouseVM } from './WarehouseVM';
import { ProductionhouseVM } from './ProductionhouseVM';
import { CategoryVM } from './CategoryVM';
import { ItemGroupVM } from './ItemGroupVM';
import { ItemGalleryVM, ScanItemGalleryVM } from './ItemGalleryVM';
import { ItemAttributeVM } from './ItemAttributeVM';
import { ItemMetalVM } from './ItemMetalVM';
import { MetalVM } from './MetalVM';
import { ItemStatusVM } from './ItemStatusVM';
import { ItemAccessoryVM } from './ItemAccessoryVM';
import { DistributorVM } from './DistributorVM';
import { PurityVM } from './PurityVM';

export class ItemVM {
    item_id: number
    code: string
    item_unique_id: string
    item_name: string
    group_id: number
    warehouse_id: number
    hsn_code: string
    item_details: string
    size: string
    gross_wt: string
    purity: string
    wastage: string
    labour_rate: string
    less_weight: string
    fine_weight: string
    net_weight: string
    rate: string
    amount: string
    mrs: string
    labour_on_id: number
    pcs: string
    category_id_mutiple: string
    category_names?: string
    category_id: number
    display_name: string
    tax_code_int: string
    tax_method_term: string
    product_details_invoice: string
    customer_id: number
    date_of_production: string
    last_date_of_sales: string
    total_qty_sales: string
    design_id: number
    item_type_id: number
    total_order_received: number
    rhodium: string
    extra_rate: string
    express_delivery_percentage: string
    distributor_id: string
    income_account_id: string
    sales_rate: string
    ref_item_id: number
    production_house_id: number
    production_in_change_id: number
    is_available_on_for_display: string
    is_available_for_distributor: string
    main_image_path: string
    thumbnail_image_path: string
    product_details: string
    product_details_on_invoice: string
    is_active: number
    status: number
    is_approve: number
    reject_reason: string
    update_log: string
    sequence_no: number
    even_type_term: string
    design_type_term: string
    total_review: number
    ratings: string
    item_url: string
    no_of_days_required: number
    major_metal_id: number
    created_at: string
    updated_at: string
    distributor?: DistributorVM
    warehouse?: WarehouseVM
    production_house?: ProductionhouseVM
    production_house_in_change?: ProductionhouseVM
    category?: CategoryVM
    item_group?: ItemGroupVM
    item_status?: ItemStatusVM
    metal?: MetalVM
    major_metal?: MetalVM
    purity_data?: PurityVM
    catid: number
    item_gallery_id: number
    process_image_path: string
    original_image_path: string
    is_cover_image: string
    image_details: string
    is_water_mark: string
    is_live: string
    cover_image: string
    is_scanned?: number
    totalcount?: number
    is_own: boolean
    item_metals?: ItemMetalVM[]
    item_gallery?: ItemGalleryVM[]
    item_attributes?: ItemAttributeVM[]
    item_accessories?: ItemAccessoryVM[]
    is_scan?: ScanItemGalleryVM[]
    selected: boolean = false
    total_weight: string
    AssociatedItemCategoryID: number
    credit_per_image: number
    sales_order_details_id: any;

}
