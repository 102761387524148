import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import { Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { Lightbox } from 'ngx-lightbox';
import { DistributorVM } from 'src/app/models/DistributorVM';
import { ItemVM } from 'src/app/models/ItemVM';
import { RetailerVM } from 'src/app/models/RetailerVM';
import { SalesOrderVM } from 'src/app/models/SalesOrderVM';
import { CommonService } from 'src/app/services/common.service';
import { DistributorService } from 'src/app/services/distributor.service';
import { ItemService } from 'src/app/services/item.service';
import { StorageService } from 'src/app/services/storage.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-add-to-cart-list',
  templateUrl: './add-to-cart-list.component.html',
  styleUrls: ['./add-to-cart-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddToCartListComponent implements OnInit {

  distributorRetailerData: any[];
  roleType: any[] = [];
  defaultRoleType: any[] = [];
  public select2Options: Options;
  public select2DistributorRetailerData: Array<Select2OptionData>;
  type: string = "distributor";
  isActiveSelf: boolean = false;
  isActiveDistributor: boolean = false;

  addToCartForm: FormGroup;
  submitted: boolean = false;
  salesOrder = new SalesOrderVM();
  pipe = new DatePipe('en-US');
  mySimpleFormat: any;
  cartItemList: ItemVM[] = [];
  basic_albums = new Array();
  distributorId: any;
  minDate;

  constructor(private _commonService: CommonService, private _itemService: ItemService, private router: Router,
    private _formBuilder: FormBuilder, private distributorService: DistributorService, private _lightbox: Lightbox,
    private _storageService: StorageService) {
    this.roleType = [
      'Self',
      'Retailer',
    ];
    this.defaultRoleType = [
      'Self',
      'Distributor',
      'Retailer',
    ]
    this.minDate = new Date();
    this.distributorId = this._storageService.getDistributorId();
  }

  ngOnInit() {
    this.inintForm();
    this.loadCartItems();
  }

  inintForm() {
    this.addToCartForm = this._formBuilder.group({
      edate: ['', [Validators.required]],
      association_type_term: [1, Validators.required],
      association_id: [""],
      distributor_id: [this.distributorId]
    });
  }

  get f() {
    return this.addToCartForm.controls;
  }


  loadCartItems() {
    let self = this;
    this.basic_albums = [];
    self._commonService.showLoader();
    self.distributorService.getCartItems()
      .subscribe(data => {
        self._commonService.hideLoader();
        let response: any = data;
        if (!self._commonService.isEmptyArray(response.cart_items)) {
          self.cartItemList = response.cart_items;
        }
        else {
          self.cartItemList = [];
        }
      }, error => {
        self._commonService.hideLoader();
        self._commonService.showToaster(error.message, "error");
      });
  }

  getImageUrl(image) {
    return this._commonService.getFullImageUrl(image);
  }

  open_basic_albumb(index: number): void {
    // open lightbox
    this._lightbox.open(this.basic_albums, index);
  }

  getTotalPromotCardData(): number {
    let self = this;
    let totalLocationPrice: any = 0;
    self.cartItemList.forEach((cartDataList: any) => {
      totalLocationPrice = parseFloat(cartDataList.item_total_weight) + totalLocationPrice;
    });

    return totalLocationPrice.toFixed(2) || 0;
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.mySimpleFormat = this.pipe.transform(event.target.value, 'dd/MM/yyyy')
  }

  isShowDistributor(event: any) {
    if (event == "Self") {
      this.isActiveDistributor = false;
    }
    else if (event == "Distributor") {
      this.isActiveDistributor = true;
      this.getDistributorRetailerType(event.toLowerCase());
    }
    else if (event == "Retailer") {
      this.isActiveDistributor = true;
      this.getDistributorRetailerType(event.toLowerCase());
    }
  }

  editItem(item: ItemVM) {
    this.router.navigate(['/admin/edit-cart-item', item.sales_order_details_id]);
  }

  deleteItem(item: ItemVM) {
    let self = this;
    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    Swal.fire(options).then((result) => {
      if (result.value) {
        self._commonService.showLoader();
        self.distributorService.deleteCartItem(item.sales_order_details_id).subscribe(data => {
          let response: any = data;
          if (response.status == 1) {
            self._commonService.showToaster("Item deleted successfully.", "success");
            this._storageService.cartItemChange.emit(true);
            self.loadCartItems();
          }
          self._commonService.hideLoader();
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }

  getDistributorRetailerType(type) {
    let self = this;
    self._commonService.showLoader();
    self._itemService.getDistributorRetailerType(type).subscribe(data => {
      let response: any = data;
      if (response.data != null && response.data != undefined) {
        if (type == "distributor") {
          self.distributorRetailerData = response.data;
          self.select2DistributorRetailerData = self._commonService.dropdownArray(self.distributorRetailerData, "distributer_id", "display_name");
        }
        else if (type == "retailer") {
          self.distributorRetailerData = response.data;
          self.select2DistributorRetailerData = self._commonService.dropdownArray(self.distributorRetailerData, "association_id", "display_name");
        }
        self._commonService.hideLoader();
      }
    }, error => {
    });
  }

  save() {
    let self = this;
    this.submitted = true;
    if (this.addToCartForm.invalid) {
      return
    }

    if (self.addToCartForm.controls.association_type_term.value == "Self") {
      self.addToCartForm.controls.association_type_term.setValue("distributor");
      self.addToCartForm.controls.association_id.setValue(this.distributorId);
    }
    else if (self.addToCartForm.controls.association_type_term.value == "Distributor") {
      self.addToCartForm.controls.association_type_term.setValue("distributor");
    }
    else if (self.addToCartForm.controls.association_type_term.value == "Retailer") {
      self.addToCartForm.controls.association_type_term.setValue("retailer");
    }
    else if (self.addToCartForm.controls.association_id.value == 1 || self.addToCartForm.controls.association_id.value == "") {
      self.addToCartForm.controls.association_type_term.setValue("distributor");
      self.addToCartForm.controls.association_id.setValue(this.distributorId);
    }

    self.addToCartForm.controls.distributor_id.setValue(this.distributorId);

    self._commonService.showLoader();
    self._itemService.addPlaceOrder(self.addToCartForm.value).subscribe(data => {
      self._commonService.hideLoader();
      if (!self._commonService.isNullOrEmpty(data)) {
        self._commonService.showToaster("Order placed successfully.", "success");
        this._storageService.cartItemChange.emit(true);
        this.router.navigate(['/admin/my-sales-orders']);
      }
    }, error => {
      self._commonService.hideLoader();
      self._commonService.showToaster(error.message, "error");
    });
  }
}
