import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSubscriptionVM } from 'src/app/models/SubscriptionVM';
import { CommonService, DateFormat, DateSeprator } from 'src/app/services/common.service';
import { SiteConfigService } from 'src/app/services/site-config.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubscriptionService } from 'src/app/services/subscriprion.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-assign-subscription-list',
  templateUrl: './assign-subscription-list.component.html',
  styleUrls: ['./assign-subscription-list.component.css']
})
export class AssignSubscriptionListComponent implements OnInit {


  UserSubscriptionData: any;
  applyFilterSubscriptionForm: FormGroup;
  expirydate: any;
  purchasedate: any;
  columnSorting: any;
  columnDirection: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  activeSubscription = [];
  displayedColumns: string[] = ['AssociationType_Term', 'SubscriptionName', 'PurchaseDate', 'expirydate', 'role_type', 'AmountPaid', 'CreditOnHand', 'Status' , 'actions'];
  salesOrderData: any;
  noData = false;
  pageIndex: number;
  pageSize: number;
  totalLength: number;
  filterValue: '';
  value: '';
  role: any;
  search: { purchaseDate: any; expiryDate: any; roleType: any; subscriptionName: any; };
  constructor(private _formBuilder: FormBuilder,
    private _commonService: CommonService,
    private _siteConfig: SiteConfigService,
    private _route: ActivatedRoute,
    private _subscriptionService: SubscriptionService,
    private _storageService: StorageService,
    private _router: Router) { }

  ngOnInit() {
    let self = this;
    this.role = this._storageService.getUserRoles();
    this.createFilterSubscriptionForm();
    this.getAllActiveSubscription();
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      console.log('response' ,response)

      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
        var event: any = {
          "previousPageIndex": '0',
          "pageIndex": '0',
          "pageSize": this.pageSize,
          "length": '0',
        }
        this.getAllAssignSubscriptionList(event);
      }
    });
  }

  getSiteConfigData() {
    let self = this;
    self._siteConfig.getSiteConfigData().subscribe(data => {
      let response: any = data;
      if (response.data != null) {
        self.pageSize = response.data.default_row_perpage_term;
      }
    });
  }

 deleteSubscription(subscription: UserSubscriptionVM) {
    let self = this;

    const options: SweetAlertOptions = {
      title: 'Are you sure you want to delete this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusCancel: true
    };
    Swal.fire(options).then((result) => {
      if (result.value) {

        self._commonService.showLoader();
        self._subscriptionService.deleteUserSubscription(subscription.UserSubscriptionId).subscribe(data => {
          self._commonService.hideLoader();
          self._commonService.showToaster("User Subscription deleted successfully.", "success");
          var event: any = {
            "previousPageIndex": '0',
            "pageIndex": '0',
            "pageSize": this.pageSize,
            "length": '0',
          }
          self.getAllAssignSubscriptionList(event);
        }, error => {
          self._commonService.hideLoader();
          self._commonService.showToaster(error.message, "error");
        });
      }
    })
  }


  getAllActiveSubscription() {
    let self = this;
    self._subscriptionService.getAllActiveSubscription().subscribe(data => {
      let response: any = data;
      console.log('response getAllActiveSubscription' ,response)
      if (response.data != null) {
        self.activeSubscription = response.data;
      }
    });
  }

  getAllAssignSubscriptionList(event) {
    let self = this;
    self._commonService.showLoader();
    Promise.all(
      [
        this.getSiteConfigData(),
      ]).then((data: any) => {
        self._subscriptionService.getAllAssignSubscription(event, this.value, this.search, this.columnSorting, this.columnDirection)
          .subscribe(data => {
            self._commonService.hideLoader();
            let response: any = data;

            console.log('response' , response);

            self.UserSubscriptionData = new MatTableDataSource<UserSubscriptionVM>(response.data.list);
            if (response.data.list.length > 0) {
              self.totalLength = response.data.total_count;
            }
            self.noData = (response.data.list.length > 0) ? false : true;
            if (self.noData == true) {
              self.totalLength = 0;
            }
          }, error => {
            self._commonService.hideLoader();
            self._commonService.showToaster(error.message, "error");
          });
      });
  }

  createFilterSubscriptionForm() {
    this.applyFilterSubscriptionForm = this._formBuilder.group({
      purchaseDate: [''],
      expiryDate: [''],
      roleType: [''],
      subscriptionName: ['']
    });
  }


  applySearch() {
    let self = this;
    if (this.applyFilterSubscriptionForm.controls.purchaseDate.value.start != null && this.applyFilterSubscriptionForm.controls.purchaseDate.value.end != null) {
      self.purchasedate = {
        start: self._commonService.getDateTimeString(this.applyFilterSubscriptionForm.controls.purchaseDate.value.start._d, DateFormat.DDMMYYYY, DateSeprator.DASH),
        end: self._commonService.getDateTimeString(this.applyFilterSubscriptionForm.controls.purchaseDate.value.end._d, DateFormat.DDMMYYYY, DateSeprator.DASH)
      }
    }

    if (this.applyFilterSubscriptionForm.controls.expiryDate.value.start != null && this.applyFilterSubscriptionForm.controls.expiryDate.value.end != null) {
      self.expirydate = {
        start: self._commonService.getDateTimeString(this.applyFilterSubscriptionForm.controls.expiryDate.value.start._d, DateFormat.DDMMYYYY, DateSeprator.DASH),
        end: self._commonService.getDateTimeString(this.applyFilterSubscriptionForm.controls.expiryDate.value.end._d, DateFormat.DDMMYYYY, DateSeprator.DASH)
      }
    }
    this.search = {
      "purchaseDate": (this.applyFilterSubscriptionForm.controls.purchaseDate.value.start && this.applyFilterSubscriptionForm.controls.purchaseDate.value.end) == undefined ? "" : self.purchasedate,
      "expiryDate": (this.applyFilterSubscriptionForm.controls.expiryDate.value.start && this.applyFilterSubscriptionForm.controls.expiryDate.value.end) == undefined ? "" : self.expirydate,
      "roleType": this.applyFilterSubscriptionForm.controls.roleType.value,
      "subscriptionName": this.applyFilterSubscriptionForm.controls.subscriptionName.value
    }
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllAssignSubscriptionList(event);
  }

  clearSearch() {
    this.value = '';
    this.search = {
      "purchaseDate": "",
      "expiryDate": "",
      "roleType": "",
      "subscriptionName": "",
    }
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllAssignSubscriptionList(event);
  }

  parseDateTime(date_time: string) {
    return this._commonService.parseDateTime(date_time);
  }

  applyFilter(filterValue: any) {

    this.value = filterValue;
    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllAssignSubscriptionList(event);
  }

  sortData(event) {
    debugger
    this.columnSorting = event.active;
    this.columnDirection = event.direction;

    var event: any = {
      "previousPageIndex": '0',
      "pageIndex": '0',
      "pageSize": this.pageSize,
      "length": '0',
    }
    this.getAllAssignSubscriptionList(event);
  }




  editUserSubscription(userSubscription: UserSubscriptionVM) {
    this._router.navigate(['/admin/userassign-subscription-edit',userSubscription.UserSubscriptionId]);
    //this._router.navigate(['/admin/userassign-subscription-edit', userSubscription.UserSubscriptionId]);
  }

  viewUserSubscription(userSubscription: UserSubscriptionVM) {
    this._router.navigate(['/admin/userassign-subscription-view', userSubscription.UserSubscriptionId]);
    //this._router.navigate(['/admin/userassign-subscription-view', userSubscription.UserSubscriptionId]);
  }



}
